import { computed, inject, provide, reactive } from "vue";

export const initStore = () => {
  // State
    // User
    const stateUser = reactive({
      userAccess: 0,
      userValid: false,
      userId: null,
      clientCount: 0,
      clientLimit: 0,
      shootCount: 0,
      shootLimit: 0,
      imageCount: 0,
      imageLimit: 0,
    });
    // Client
    const stateClient = reactive({
      selClient: null,
      selClientEmail: null,
      selClientNumShoots: null,
      selClientNumImages: null,
      selClientNumOrders: null
    });
    // Shoot
    const stateShoot = reactive({
      selShoot: null,
      selShootTitle: null,
      selShootNumImages: null,
      selShootNumCollections: null,
      selShootNumOrders: null
    });
    // Images
    const stateImage = reactive({
      selImage: null,
      selImageTitle: null
    });

  // Getters
    // User
    const getUserAccess = computed(() => stateUser.userAccess);
    const getUserValid = computed(() => stateUser.userValid);
    const getUserId = computed(() => stateUser.userId);
    const getClientCount = computed(() => stateUser.clientCount);
    const getClientLimit = computed(() => stateUser.clientLimit);
    const getShootCount = computed(() => stateUser.shootCount);
    const getShootLimit = computed(() => stateUser.shootLimit);
    const getImageCount = computed(() => stateUser.imageCount);
    const getImageLimit = computed(() => stateUser.imageLimit);
    // Client
    const getSelClient = computed(() => stateClient.selClient);
    const getSelClientEmail = computed(() => stateClient.selClientEmail);
    const getSelClientNumShoots = computed(() => stateClient.selClientNumShoots);
    const getSelClientNumImages = computed(() => stateClient.selClientNumImages);
    const getSelClientNumOrders = computed(() => stateClient.selClientNumOrders);
    // Shoot
    const getSelShoot = computed(() => stateShoot.selShoot);
    const getSelShootTitle = computed(() => stateShoot.selShootTitle);
    const getSelShootNumImages = computed(() => stateShoot.selShootNumImages);
    const getSelShootNumCollections = computed(() => stateShoot.selShootNumCollections);
    const getSelShootNumOrders = computed(() => stateShoot.selShootNumOrders);
    // Images
    const getSelImage = computed(() => stateImage.selImage);
    const getSelImageTitle = computed(() => stateImage.selImageTitle);

  // Mutations
    // User
    const setUser = (access, valid, uid) => {
      console.log("Setting User ", access, valid, uid)
      stateUser.userAccess = access;
      stateUser.userValid = valid; 
      stateUser.userId = uid; 
    };
    const setUserLimits = (cLimit, sLimit, iLimit) => {
      console.log("Setting User Limits ", cLimit, sLimit, iLimit)
      stateUser.clientLimit = cLimit;
      stateUser.shootLimit = sLimit;
      stateUser.imageLimit = iLimit;
    };
    const setUserCounts = (cCount, sCount, iCount) => {
      console.log("Setting User Counts ", cCount, sCount, iCount);
      stateUser.clientCount = cCount;
      stateUser.shootCount = sCount;
      stateUser.imageCount = iCount;
    };
    // Client
    const setSelectedClient = (cId, cEmail, cNumShoots, cNumImages, cNumOrders) => {
      console.log("Setting Selected Client ", cId, cEmail, cNumShoots, cNumImages, cNumOrders);
      stateClient.selClient = cId;
      stateClient.selClientEmail = cEmail;
      stateClient.selClientNumShoots = cNumShoots;
      stateClient.selClientNumImages = cNumImages;
      stateClient.selClientNumOrders = cNumOrders;
    };
    const clearSelectedClient = () => {
      // console.log("Clearing Selected Client ");
      stateClient.selClient = null;
      stateClient.selClientEmail = null;
      stateClient.selClientNumShoots = null;
      stateClient.selClientNumImages = null;
      stateClient.selClientNumOrders = null;
    };
    // Shoot
    const setSelectedShoot = (sId, sTitle, sNumImages, sNumColl, sNumOrders) => {
      console.log("Setting Selected Shoot ", sId, sTitle, sNumImages, sNumColl, sNumOrders)
      stateShoot.selShoot = sId;
      stateShoot.selShootTitle = sTitle;
      stateShoot.selShootNumImages = sNumImages;
      stateShoot.selShootNumCollections = sNumColl;
      stateShoot.selShootNumOrders = sNumOrders;
    };
    const clearSelectedShoot = () => {
      // console.log("Clearing Selected Shoot ")
      stateShoot.selShoot = null;
      stateShoot.selShootTitle = null;
      stateShoot.selShootNumImages = null;
      stateShoot.selShootNumCollections = null;
      stateShoot.selShootNumOrders = null;
    };
    // Images
    const setSelectedImage = (iId, iTitle) => {
      console.log("Setting Selected Image ", iId, iTitle);
      stateImage.selImage = iId;
      stateImage.selImageTitle = iTitle;
    };
    const clearSelectedImage = () => {
      // console.log("Clearing Selected Image ");
      stateImage.selImage = null;
      stateImage.selImageTitle = null;
    };

  //Actions
    // User 
    const updateUserInfo = (access, valid, uid) => {
      console.log("running update userinfo store action")
      setUser(access, valid, uid);
    };
    const updateUserLimits = (cLimit, sLimit, iLimit) => {
      setUserLimits(cLimit, sLimit, iLimit);
    };
    const updateUserCounts = (cCount, sCount, iCount) => {
      setUserCounts(cCount, sCount, iCount);
    };
    const increaseClientCount = () => {
      stateUser.clientCount++;
    };
    const increaseShootCount = () => {
      stateUser.shootCount++;
    };
    const increaseImageCount = () => {
      stateUser.imageCount++;
    };
    const decreaseClientCount = () => {
      stateUser.clientCount--;
    };
    const decreaseShootCount = () => {
      stateUser.shootCount--;
    };
    const decreaseImageCount = () => {
      stateUser.imageCount--;
    };
    // Client
    const selectClient = (cId, cEmail, cNumShoots, cNumImages, cNumOrders) => {
      setSelectedClient(cId, cEmail, cNumShoots, cNumImages, cNumOrders);
    };
    const deselectClient = () => {
      clearSelectedClient();
    };    
    // Shoot
    const selectShoot = (sId, sTitle, sNumImages, sNumColl, sNumOrders) => {
      setSelectedShoot(sId, sTitle, sNumImages, sNumColl, sNumOrders);
    };
    const deselectShoot = () => {
      clearSelectedShoot();
    };
    // Images
    const selectImage = (iId, iTitle) => {
      setSelectedImage(iId, iTitle);
    };
    const deselectImage = () => {
      clearSelectedImage();
    }; 

    //Provide Getters
    provide("getUserAccess", getUserAccess);
    provide("getUserValid", getUserValid);
    provide("getUserId", getUserId);
    provide("getClientCount", getClientCount);
    provide("getClientLimit", getClientLimit);
    provide("getShootCount", getShootCount);
    provide("getShootLimit", getShootLimit);
    provide("getImageCount", getImageCount);
    provide("getImageLimit", getImageLimit);
    provide("getSelClient", getSelClient);
    provide("getSelClientEmail", getSelClientEmail);
    provide("getSelClientNumShoots", getSelClientNumShoots);
    provide("getSelClientNumImages", getSelClientNumImages);
    provide("getSelClientNumOrders", getSelClientNumOrders);
    provide("getSelShoot", getSelShoot);
    provide("getSelShootTitle", getSelShootTitle);
    provide("getSelShootNumImages", getSelShootNumImages);
    provide("getSelShootNumOrders", getSelShootNumOrders);
    provide("getSelShootNumCollections", getSelShootNumCollections);
    provide("getSelImage", getSelImage);
    provide("getSelImageTitle", getSelImageTitle);
    //Provide Actions
    provide("updateUserInfo", updateUserInfo); 
    provide("updateUserLimits", updateUserLimits);
    provide("updateUserCounts", updateUserCounts);
    provide("increaseClientCount", increaseClientCount);
    provide("increaseShootCount", increaseShootCount);
    provide("increaseImageCount", increaseImageCount);
    provide("decreaseClientCount", decreaseClientCount);
    provide("decreaseShootCount", decreaseShootCount);
    provide("decreaseImageCount", decreaseImageCount);
    // Client
    provide("selectClient", selectClient);
    provide("deselectClient", deselectClient);
    // Shoot
    provide("selectShoot", selectShoot);
    provide("deselectShoot", deselectShoot);
    // Images
    provide("selectImage", selectImage);
    provide("deselectImage", deselectImage);
};

export const useStore = () => ({
  getUserAccess: inject("getUserAccess"),
  getUserValid: inject("getUserValid"),
  getUserId: inject("getUserId"),
  getClientCount: inject("getClientCount"),
  getClientLimit: inject("getClientLimit"),
  getShootCount: inject("getShootCount"),
  getShootLimit: inject("getShootLimit"),
  getImageCount: inject("getImageCount"),
  getImageLimit: inject("getImageLimit"),
  getSelClient: inject("getSelClient"),
  getSelClientEmail: inject("getSelClientEmail"),
  getSelClientNumShoots: inject("getSelClientNumShoots"),
  getSelClientNumImages: inject("getSelClientNumImages"),
  getSelClientNumOrders: inject("getSelClientNumOrders"),
  getSelShoot: inject("getSelShoot"),
  getSelShootTitle: inject("getSelShootTitle"),
  getSelShootNumImages: inject("getSelShootNumImages"),
  getSelShootNumCollections: inject("getSelShootNumCollections"),
  getSelShootNumOrders: inject("getSelShootNumOrders"),
  getSelImage: inject("getSelImage"),
  getSelImageTitle: inject("getSelImageTitle"),
  updateUserInfo: inject("updateUserInfo"),
  updateUserLimits: inject("updateUserLimits"),
  updateUserCounts: inject("updateUserCounts"),
  increaseClientCount: inject("increaseClientCount"),
  increaseShootCount: inject("increaseShootCount"),
  increaseImageCount: inject("increaseImageCount"),
  decreaseClientCount: inject("decreaseClientCount"),
  decreaseShootCount: inject("decreaseShootCount"),
  decreaseImageCount: inject("decreaseImageCount"),
  selectClient: inject("selectClient"),
  deselectClient: inject("deselectClient"),
  selectShoot: inject("selectShoot"),
  deselectShoot: inject("deselectShoot"),
  selectImage: inject("selectImage"),
  deselectImage: inject("deselectImage"),
});
