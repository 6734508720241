<template>
  <div class="row">
    <div class="col">      
      <!-- <h2>PriceSelection</h2> -->
      <div v-for="(product, index) in products"
        :key="index + '-product'">
        <div v-if="signupPackage === product.role">
          <!-- <p class="small p-0 m-0 text-center mb-1 mb-md-2">{{product.description}}</p> -->
          <div v-for="(price, priceIndex) in product.prices"
          :key="priceIndex + '-price'" class="row mb-1 mb-md-2">
            <div class="col-6 p-0 m-0 text-end pe-2 pe-lg-3">
              <p v-if="price.interval === 'month'" class="small p-0 m-0 mb-0">Monthly Subscription</p>
              <p v-else-if="price.interval === 'year'" class="small p-0 m-0  mb-0">Annual Subscription</p>
              <p v-else class="small p-0 m-0 mb-0">One Off Cost</p>
            </div>
            <div class="col-6 p-0 m-0">
              <div class="form-check mb-1 mb-md-2">              
                <input type="radio" class="form-check-input" v-model="selectedPrice" :value="price.id" :id="price.id" @click="setInterval(price)"/>
                <label v-if="price.interval === 'month' || price.interval === 'year'" for="price.id" class="form-check-label fs-6">£ {{price.unit_amount / 100}} <span class="small"> per {{price.interval}}</span> </label>
                <label v-else for="price.id" class="form-check-label fs-6">£ {{price.unit_amount / 100}} </label>          
              </div> 
            </div>  
          </div>
        </div>        
        <!-- <div v-else>
          <p class="small p-0 m-0 text-center mb-1 mb-md-2">{{product.name}}</p>
          <div v-for="(price, priceIndex) in product.prices"
          :key="priceIndex + '-price'" class="row mb-1 mb-md-2">
            <div class="col-6 p-0 m-0 text-end pe-2 pe-lg-3">
              <p v-if="price.interval === 'month'" class="small p-0 m-0 mb-0">Monthly Subscription</p>
              <p v-else-if="price.interval === 'year'" class="small p-0 m-0  mb-0">Annual Subscription</p>
              <p v-else class="small p-0 m-0 mb-0">One Time Payment</p>
            </div>
            <div class="col-6 p-0 m-0">
              <div class="form-check mb-1 mb-md-2">              
                <input type="radio" class="form-check-input" v-model="selectedPrice" :value="price.id" :id="price.id" @click="setInterval(price)"/>
                <label v-if="price.interval === 'month' || price.interval === 'year'" for="price.id" class="form-check-label fs-6">£ {{price.unit_amount / 100}} <span class="small"> per {{price.interval}}</span> </label>
                <label v-else for="price.id" class="form-check-label fs-6">£ {{price.unit_amount / 100}} </label>          
              </div> 
            </div>  
          </div>
        </div>         -->
      </div>
    </div>
  </div>
</template>
<script>
import { collection, getDocs, getFirestore, where, query } from "@firebase/firestore";
import {ref, watchEffect} from "vue"
export default {
  name: "priceSelection", 
  props: ["signupPackage"], 
  expose: ["selectedPrice", "selectedPriceInterval"],
  setup(){
    const products = ref([])
    // const selectedPackage = ref("")
    const selectedPrice = ref("000")
    const selectedPriceInterval = ref("")
    const productsFetched = ref(false)

    function setInterval(price){
      // console.log("SetInterval - ", price.interval)
      if (price.interval == null){
        selectedPriceInterval.value = "oneoff"
      }
      if (price.interval === "month"){
        selectedPriceInterval.value = "monthly"
      }
      if (price.interval === "year"){
        selectedPriceInterval.value = "annually"
      }
    }

    const stopSelectedPackageWatch = watchEffect(() => {
      // selectedPackage.value = props.signupPackage
      if(productsFetched.value != true){
        fetchProducts()
      }  
    })

    async function fetchProducts() {
      console.log("Fetching Products")
      const db = getFirestore()
      const productsRef = collection(db, "package-pricing-plans");
      const productsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(productsQuery);
      // console.log("productsQuerySnap - ", productsQuerySnap)
      productsQuerySnap.forEach(async (doc) => {
        const pricesRef = collection(db, "package-pricing-plans", doc.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);
        products.value.push({
          id: doc.id, 
          ...doc.data(),
          prices: pricesQuerySnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          })
        })
      })
      stopSelectedPackageWatch()
    }
    // console.log("props.signupPackage - ", props.signupPackage)
    return{fetchProducts, products, selectedPrice, selectedPriceInterval, setInterval}
  }
}
</script>