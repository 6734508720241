<template>
  <div>
    <div v-show="modData.area != 'Image'" class="col-12 col-sm-11 p-0 m-0 mx-auto text-center">
      <p class="fs-5 fw-normal text-charcoal text-trucate text-capitalize p-0 m-0">{{modData.message}}</p>                  
      
    </div>
    <!-- SHOOT -->
    <div ref="updateShootFormMod" v-show="modData.area === 'Shoot'" class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper text-start px-2">
      <form class="needs-validation" id="editshootform" novalidate @submit.prevent="handleUpdateShoot">
        <div class="mb-1" @click="assignFocus('shootTitleUp')">
          <label for="shootTitleUp" class="form-label sUpLabel" >Shoot Title *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="text" class="form-control" id="shootTitleUp" :placeholder="sTitlePlaceholder" required v-model="shootTitleUp" autocomplete="off">
            <div class="invalid-tooltip">
              Please provide a valid title at least 4 chars
            </div>
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('shootTypeUp')">
          <label for="shootTypeUp" class="form-label sUpLabel">Shoot Type *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" name="shootTypeUp" id="shootTypeUp" :placeholder="sTypePlaceholder"  required v-model="shootTypeUp" autocomplete="off">          
            <div class="invalid-tooltip">
              Please provide a valid title at least 4 chars
            </div>
          </div>
        </div>
        <div class="mb-1 mt-4" @click="assignFocus('shootLocationUp')">
          <label for="shootLocationUp" class="form-label sUpLabel">Shoot Location</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="shootLocationUp" :placeholder="sLocationPlaceholder" v-model="shootLocationUp" autocomplete="off">          
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('shootDateUp')">
          <label for="shootDateUp" class="form-label sUpLabel">Shoot Date</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input type="date" class="form-control" id="shootDateUp" v-model="shootDateUp" autocomplete="off">          
          </div>
        </div>       
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateShootPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update Shoot</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button> 
        </div>
      </form>
    </div>
    <!-- IMAGE -->
    <div ref="updateImageFormMod" v-show="modData.area === 'Image'" class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper text-start px-2">
      <form class="needs-validation" id="editimageform" novalidate @submit.prevent="handleUpdateImage">
        <div class="mb-2 image-holder mx-auto text-center ">
          <img v-if="modData.obj.resizedImageUrl" class="img-fluid shadow-sm" :src="modData.obj.resizedImageUrl" :alt="modData.obj.imageTitle" style="max-height: 14rem;">
          <img v-else class="img-fluid shadow-sm" :src="modData.obj.resizedImageUrl" :alt="modData.obj.imageTitle" style="max-height: 14rem;">
        </div>
        <div class="mb-1" @click="assignFocus('imageTitleUp')">
          <label for="imageTitleUp" class="form-label sUpLabel" >Image Title *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="imageTitleUp" :placeholder="iTitlePlaceholder" required v-model="imageTitleUp" autocomplete="off">
            <div class="invalid-tooltip">
              Please provide a valid Title at least 4 chars
            </div>
          </div>
        </div>
        <div class="mb-2" @click="assignFocus('imageNotesUp')">
          <label for="imageNotesUp" class="form-label sUpLabel" >Additional Notes</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <textarea type="text" class="form-control" id="imageNotesUp" :placeholder="iNotesPlaceholder" v-model="imageNotesUp" autocomplete="off" style="max-height: 2rem;"/>
          </div>
        </div>  
        <div class="text-start">
          <button type="button" 
          class="btn w-100 btn-highlight text-white text-uppercase shadow-sm"  
          :class='{"btn-sm": mobileView}'
          @click="showReco = !showReco">Recommendations 
          <i class="bi ps-2" 
            :class='{"bi-caret-up": showReco, "bi-caret-down": !showReco}'></i></button>   
        </div>
        <div v-show="showReco" class="recommend-holder rounded" :class='{"expanded": showReco }' >
          <div class="row p-0 m-0">
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="canvas" id="canvas" value="canvas" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Canvas</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="aluminium" id="aluminium" value="aluminium" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Aluminium</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="framed" id="framed" value="framed" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Framed</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="other" id="other" value="other" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Other Product</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="collection3" value="collection3" id="collection3" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 3</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="collection4" value="collection4" id="collection4" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 4</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="collection5" value="collection5" id="collection5" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 5</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="blackwhite" id="blackwhite" value="blackwhite" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Black &amp; White</span>
              </label>
            </div>
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="colour" id="colour" value="colour" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Colour</span>
              </label>
            </div>          
            <div class="form-check col-6" :class='{"check-form-sm": mobileView}'>
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input " name="seeNotes" id="seeNotes" value="seeNotes" v-model="recommendationsUp" :class='{"check-box-sm": mobileView}'>
              <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>See Notes</span>
              </label>
            </div>          
          </div>          
        </div>        
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateImagePending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button>
        </div>        
      </form>
    </div>
    <!-- CLIENT -->
    <div ref="updateClientAddFormMod" v-show="modData.area === 'Client-Address'" class="col-12 col-sm-11 p-0 m-0 px-2 mx-auto text-start">
      <form id="editclientAddform" @submit.prevent="handleUpdateClientAdd">
        <div class="mb-1" @click="assignFocus('clientAddress1Up')">
          <label for="clientAddress1Up" class="form-label sUpLabel" >Address 1</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="text" class="form-control" id="clientAddress1Up" :placeholder="cAddress1Placeholder" v-model="cAddress1Up" autocomplete="off">            
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('clientAddress2Up')">
          <label for="clientAddress2Up" class="form-label sUpLabel" >Address 2</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="clientAddress2Up" :placeholder="cAddress2Placeholder" v-model="cAddress2Up" autocomplete="off">            
          </div>
        </div>
        <div class="row p-0 m-0 mb-1 gx-2">
          <div class="col-12 col-sm-6 ps-0" @click="assignFocus('clientAddressCityUp')">
            <label for="clientAddressCityUp" class="form-label sUpLabel" >City</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="clientAddressCityUp" :placeholder="cAddressCityPlaceholder" v-model="cAddressCityUp" autocomplete="off">            
            </div>
          </div>
          <div class="col-12 col-sm-6 text-start text-sm-end pe-0" @click="assignFocus('clientAddressPCodeUp')">
            <label for="clientAddressPCodeUp" class="form-label sUpLabel" >Postcode</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="clientAddressPCodeUp" :placeholder="cAddressPCodePlaceholder" v-model="cAddressPCodeUp" autocomplete="off">            
            </div>
          </div>
        </div>
        <div class="row p-0 m-0 mb-1 gx-2">
          <div class="col-12 col-sm-6 text-start ps-0 " @click="assignFocus('clientAddressRegionUp')">
            <label for="clientAddressRegionUp" class="form-label sUpLabel" >Region</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="clientAddressRegionUp" :placeholder="cAddressRegionPlaceholder" v-model="cAddressRegionUp" autocomplete="off">            
            </div>
          </div>
          <div class="col-12 col-sm-6 text-start text-sm-end pe-0 " @click="assignFocus('clientAddressCountryUp')">
            <label for="clientAddressCountryUp" class="form-label sUpLabel" >Country</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="clientAddressCountryUp" :placeholder="cAddressCountryPlaceholder" v-model="cAddressCountryUp" autocomplete="off">            
            </div>
          </div>
        </div>
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateCliAddressPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>In Progress</button> 
        </div>
      </form>
    </div>
    <div ref="updateClientDetFormMod" v-show="modData.area === 'Client-Details'" class="col-12 col-sm-11 p-0 m-0 px-2 mx-auto text-start">
      <form class="needs-validation" id="editclientDetform" novalidate @submit.prevent="handleUpdateClientDet">
        <div class="mb-1" @click="assignFocus('clientDetUsernameUp')">
          <label for="clientDetUsernameUp" class="form-label sUpLabel">Client Username *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="text" class="form-control" id="clientDetUsernameUp" required :placeholder="cUsernamePlaceholder" v-model="cUsernameUp" autocomplete="off">    
            <div class="invalid-tooltip">
                Please provide a valid Username at least 4 chars
              </div>        
          </div>
        </div>        
        <div class="mb-1" @click="assignFocus('clientDetFNameUp')">
          <label for="clientDetFNameUp" class="form-label sUpLabel" >First Name *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="clientDetFNameUp" required :placeholder="cFirstnamePlaceholder" v-model="cFirstnameUp" autocomplete="off">
            <div class="invalid-tooltip">Required Field</div>        
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('clientDetLNameUp')">
          <label for="clientDetLNameUp" class="form-label sUpLabel" >Last Name *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="clientDetLNameUp" required :placeholder="cLastnamePlaceholder" v-model="cLastnameUp" autocomplete="off">
            <div class="invalid-tooltip">Required Field</div>        
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('clientDetPhoneUp')">
          <label for="clientDetPhoneUp" class="form-label sUpLabel" >Phone</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input type="tel" class="form-control" id="clientDetPhoneUp" :placeholder="cPhonePlaceholder" v-model="cPhoneUp" autocomplete="off">        
          </div>
        </div>        
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateCliDetailsPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update Details</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button> 
        </div>
      </form>
    </div>
    <div ref="updateClientPWFormMod" v-show="modData.area === 'Client-Password'" class="col-12 col-sm-11 p-0 m-0 px-2 mx-auto text-start">
      <form class="needs-validation" id="editclientPWform" novalidate @submit.prevent="handleUpdateClientPW">
        <div class="mb-1" @click="assignFocus('clientPassword1Up')">
          <label for="clientPassword1Up" class="form-label sUpLabel">New Password *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="password" class="form-control" id="clientPassword1Up" required placeholder="New Password" v-model="cPasswordUp" autocomplete="off">
            <span class="input-group-text border-0">
                <i class="bi bi-eye" id="toggleResetClientPassword1" style="cursor: pointer"></i>
              </span>    
            <div class="invalid-tooltip">
                Please provide a valid Password at least 6 chars
              </div>        
          </div>
        </div>        
        <div class="mb-1" @click="assignFocus('clientPassword2Up')">
          <label for="clientPassword2Up" class="form-label sUpLabel">Confirm Password *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="password" class="form-control" id="clientPassword2Up" required placeholder="Confirm Password" v-model="cConfPasswordUp" autocomplete="off"> 
            <span class="input-group-text border-0">
              <i class="bi bi-eye" id="toggleResetClientPassword2" style="cursor: pointer"></i>
            </span>   
            <div class="invalid-tooltip">
                Please provide a valid Password at least 6 chars
              </div>        
          </div>
        </div> 
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateCliDetailsPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' >Reset Password</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Resetting</button> 
        </div>
      </form>
    </div>
    <!-- USER -->
    <div ref="updateUserDetFormMod" v-show="modData.area === 'User-Details'" class="col-12 col-sm-11 p-0 m-0 mx-auto px-2 text-start">
       <form class="needs-validation" id="edituserDetform" novalidate @submit.prevent="handleUpdateUserDet">
        <div class="mb-1" @click="assignFocus('userDetUsernameUp')">
          <label for="userDetUsernameUp" class="form-label sUpLabel">Username *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="text" class="form-control" id="userDetUsernameUp" required :placeholder="uUsernamePlaceholder" v-model="uUsernameUp" autocomplete="off">    
            <div class="invalid-tooltip">
                Please provide a valid Username at least 4 chars
              </div>        
          </div>
        </div>        
        <div class="mb-1" @click="assignFocus('userDetFNameUp')">
          <label for="userDetFNameUp" class="form-label sUpLabel" >First Name *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="userDetFNameUp" required :placeholder="uFirstnamePlaceholder" v-model="uFirstnameUp" autocomplete="off">
            <div class="invalid-tooltip">Required Field</div>        
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('userDetLNameUp')">
          <label for="userDetLNameUp" class="form-label sUpLabel" >Last Name *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="userDetLNameUp" required :placeholder="uLastnamePlaceholder" v-model="uLastnameUp" autocomplete="off">
            <div class="invalid-tooltip">Required Field</div>        
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('userDetPhoneUp')">
          <label for="userDetPhoneUp" class="form-label sUpLabel" >Phone *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input type="tel" class="form-control" id="userDetPhoneUp" required :placeholder="uPhonePlaceholder" v-model="uPhoneUp" autocomplete="off">
            <div class="invalid-tooltip">Required Field</div>         
          </div>
        </div>        
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateUserDetailsPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update Details</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button> 
        </div>
      </form>
    </div>
    <div ref="updateUserAddFormMod" v-show="modData.area === 'User-Address'" class="col-12 col-sm-11 p-0 m-0 mx-auto px-2 text-start">
      <form id="editUserAddform" class="needs-validation col-12" novalidate @submit.prevent="handleUpdateUserAdd">
        <div class="mb-1" @click="assignFocus('userAddress1Up')">
          <label for="userAddress1Up" class="form-label sUpLabel" >Address 1 *</label>
          <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="text" class="form-control" id="userAddress1Up" required :placeholder="uAddress1Placeholder" v-model="uAddress1Up" autocomplete="off"> 
            <div class="invalid-tooltip">Required Field</div>            
          </div>
        </div>
        <div class="mb-1" @click="assignFocus('userAddress2Up')">
          <label for="userAddress2Up" class="form-label sUpLabel" >Address 2</label>
          <div class="input-group" :class='{"input-group-sm": mobileView}'>
            <input type="text" class="form-control" id="userAddress2Up" :placeholder="uAddress2Placeholder" v-model="uAddress2Up" autocomplete="off">            
          </div>
        </div>
        <div class="row p-0 m-0 mb-1 gx-2">
          <div class="col-12 col-sm-6 ps-0" @click="assignFocus('userAddressCityUp')">
            <label for="userAddressCityUp" class="form-label sUpLabel" >City</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="userAddressCityUp" :placeholder="uAddressCityPlaceholder" v-model="uAddressCityUp" autocomplete="off">   
            </div>
          </div>
          <div class="col-12 col-sm-6 pe-0 text-start text-sm-end " @click="assignFocus('userAddressPCodeUp')">
            <label for="userAddressPCodeUp" class="form-label sUpLabel" >Postcode</label>
            <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="userAddressPCodeUp" required :placeholder="uAddressPCodePlaceholder" v-model="uAddressPCodeUp" autocomplete="off">
              <div class="invalid-tooltip">Required Field</div>             
            </div>
          </div>
        </div>
        <div class="row p-0 m-0 mb-1 gx-2">
          <div class="col-12 col-sm-6 ps-0" @click="assignFocus('userAddressRegionUp')">
            <label for="userAddressregionUp" class="form-label sUpLabel" >Region</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="userAddressRegionUp" :placeholder="uAddressRegionPlaceholder" v-model="uAddressRegionUp" autocomplete="off">            
            </div>
          </div>
          <div class="col-12 col-sm-6 ps-0 text-start text-sm-end " @click="assignFocus('userAddressCountryUp')">
            <label for="userAddressCountryUp" class="form-label sUpLabel" >Country</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="userAddressCountryUp" :placeholder="uAddressCountryPlaceholder" v-model="uAddressCountryUp" autocomplete="off">            
            </div>
          </div>
        </div>
        <div class="pt-4 mb-1 col-12 mx-auto">
          <button v-if="!updateUserAddressPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update Address</button> 
          <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button> 
        </div>
      </form>
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue"
import {validateImageTitleUp} from "../../../public/js/validation.js"
import {ref, watchEffect, onBeforeUnmount, onUnmounted, computed} from "vue"
import {validateShootTitleUp, validateShootTypeUp, validateClientUsernameUp, validateClientFirstnameUp, validateClientLastnameUp, validateClientResetPWUp, validateClientResetPWConUp, validateUserPostcodeUp, validateUserAddress1Up,validateUserLastnameUp, validateUserFirstnameUp, validateUserPhoneUp,validateUserUsernameUp} from "../../../public/js/validation.js"
import updateDocument from "@/composables/updateDocument"
import { useStore } from "@/composables/store/store"
import { httpsCallable, getFunctions } from "firebase/functions"
export default {
  name: "EditModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "showAlertSub"],
  components: {Spinner},
  setup(props, context){
    console.log("pModal>Edit -", props.modData)
    let { useUpdateDocument:UpdateShootDoc, error:updateShootError, isPending:updateShootPending } = updateDocument()
    let { useUpdateDocument:UpdateImageDoc, error:updateImageError, isPending:updateImagePending } = updateDocument()
    let { useUpdateDocument:UpdateCliAddressDoc, error:updateCliAddressError, isPending:updateCliAddressPending } = updateDocument()
    let { useUpdateDocument:UpdateCliDetailsDoc, error:updateCliDetailsError, isPending:updateCliDetailsPending } = updateDocument()
    let { useUpdateDocument:UpdateUserAddressDoc, error:updateUserAddressError, isPending:updateUserAddressPending } = updateDocument()
    let { useUpdateDocument:UpdateUserDetailsDoc, error:updateUserDetailsError, isPending:updateUserDetailsPending } = updateDocument()
    let store = useStore();
    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)
    let updateShootFormMod = ref(null)
    let updateImageFormMod = ref(null)
    let updateClientAddFormMod = ref(null)
    let updateClientDetFormMod = ref(null)
    let updateClientPWFormMod = ref(null)

    let updateUserDetFormMod = ref(null)
    let updateUserAddFormMod = ref(null)
    

    let firstFocus = ref(null)
    let updateObject = ref(props.modData.obj)
// USER
    //Update User Details vars
    let uUsernamePlaceholder = ref(null)
    let uUsernameUp = ref(null)   
    let uPhonePlaceholder = ref(null)
    let uPhoneUp = ref(null)
    let uFirstnamePlaceholder = ref(null)
    let uFirstnameUp = ref(null)
    let uLastnamePlaceholder = ref(null)
    let uLastnameUp = ref(null)
    //Update User Address vars
    let uAddress1Placeholder = ref(null)
    let uAddress1Up = ref(null)
    let uAddress2Placeholder = ref(null)
    let uAddress2Up = ref(null)
    let uAddressCityPlaceholder = ref(null)
    let uAddressCityUp = ref(null)
    let uAddressPCodePlaceholder = ref(null)
    let uAddressPCodeUp = ref(null)
    let uAddressRegionPlaceholder = ref(null)
    let uAddressRegionUp = ref(null)
    let uAddressCountryPlaceholder = ref(null)
    let uAddressCountryUp = ref(null)
   
    // SHOOT
    // Update Shoot vars
    let shootTitleUp = ref(null)
    let sTitlePlaceholder = ref(null)
    let shootTypeUp = ref(null)
    let sTypePlaceholder = ref(null)
    let shootLocationUp = ref(null)
    let sLocationPlaceholder = ref(null)
    let shootDateUp = ref(null)
    let sDatePlaceholder = ref(null)

    const sDtConvert = computed(() => {
      if((updateObject.value.shootDate) && (updateObject.value.shootDate != null || updateObject.value.shootDate.length != 0)){
        let d = updateObject.value.shootDate
        // console.log("d - ", d)
        d = d.toDate()
        // console.log("d - ", d)
        d = d.toLocaleDateString("en-GB", {
          year: "numeric", month:"2-digit", day:"2-digit"
        })
        // console.log("d - ", d)
        // console.log("d - ", typeof(d))
        let day = d.substring(0, 2)
        let month = d.substring(3, 5)
        let year = d.substring(6, 10)
        // console.log("day - ", day)
        // console.log("month - ", month)
        // console.log("year - ", year)
        let sd2 = year + "-" + month + "-" + day       
        // console.log("sd2 - ", sd2)
        return sd2
      }
      else{
        return "dd-mm-yyyy"
      }      
    })
//IMAGE
    //Update Image vars
    let imageTitleUp = ref(null)
    let iTitlePlaceholder = ref(null)
    let imageNotesUp = ref(null)
    let iNotesPlaceholder = ref(null)
    let recommendationsUp = ref([])
    let showReco = ref(false)
 
 //CLIENT
    //Update Client Address vars
    let cAddress1Placeholder = ref(null)
    let cAddress1Up = ref(null)
    let cAddress2Placeholder = ref(null)
    let cAddress2Up = ref(null)
    let cAddressCityPlaceholder = ref(null)
    let cAddressCityUp = ref(null)
    let cAddressPCodePlaceholder = ref(null)
    let cAddressPCodeUp = ref(null)
    let cAddressRegionPlaceholder = ref(null)
    let cAddressRegionUp = ref(null)
    let cAddressCountryPlaceholder = ref(null)
    let cAddressCountryUp = ref(null)
    //Update Client Details vars
    let cUsernamePlaceholder = ref(null)
    let cUsernameUp = ref(null)   
    let cPhonePlaceholder = ref(null)
    let cPhoneUp = ref(null)
    let cFirstnamePlaceholder = ref(null)
    let cFirstnameUp = ref(null)
    let cLastnamePlaceholder = ref(null)
    let cLastnameUp = ref(null)
    //Client PW Reset Vars
    let cPasswordUp = ref(null)
    let cConfPasswordUp = ref(null)
    const populated = ref(false)
    const doInitialPopulate = () => {
      console.log("Doing Initial Populate")
      if (props.modData.area === "Shoot")
      {
        if((updateObject.value.shootTitle) && (updateObject.value.shootTitle.length != 0 || updateObject.value.shootTitle != null)){
          sTitlePlaceholder.value = updateObject.value.shootTitle
        }
        if((updateObject.value.shootType) && (updateObject.value.shootType != null || updateObject.value.shootType.length != 0)){
          sTypePlaceholder.value = updateObject.value.shootType
        }
        if((updateObject.value.shootLocation) && (updateObject.value.shootLocation != null || updateObject.value.shootLocation.length != 0)){
          sLocationPlaceholder.value = updateObject.value.shootLocation
        }
        if((updateObject.value.shootDate) && (updateObject.value.shootDate != null || updateObject.value.shootDate.length != 0)){          
          shootDateUp.value = sDtConvert.value
        }
        populated.value = true  
      }
      if (props.modData.area === "Image")
      {
        if((updateObject.value.imageTitle) && (updateObject.value.imageTitle.length != 0 || updateObject.value.imageTitle != null)){
        iTitlePlaceholder.value = updateObject.value.imageTitle
        }
        if((updateObject.value.imageNotes) && (updateObject.value.imageNotes.length != 0 || updateObject.value.imageNotes != null)){
        iNotesPlaceholder.value = updateObject.value.imageNotes
        }
        if((updateObject.value.imageRecommendations) && (updateObject.value.imageRecommendations.length != 0)){
        recommendationsUp.value = updateObject.value.imageRecommendations
        }        
        populated.value = true
      }
      if (props.modData.area === "Client-Address")
      {
        console.log("updateObject - ", updateObject.value)
        if((updateObject.value.cAddress1) && (updateObject.value.cAddress1.length != 0 || updateObject.value.cAddress1 != null)){
        cAddress1Placeholder.value = updateObject.value.cAddress1
        }
        if((updateObject.value.cAddress2) && (updateObject.value.cAddress2.length != 0 || updateObject.value.cAddress2 != null)){
        cAddress2Placeholder.value = updateObject.value.cAddress2
        }
        if((updateObject.value.cCity) && (updateObject.value.cCity.length != 0 || updateObject.value.cCity != null)){
        cAddressCityPlaceholder.value = updateObject.value.cCity
        }
        if((updateObject.value.cPostcode) && (updateObject.value.cPostcode.length != 0 || updateObject.value.cPostcode != null)){
        cAddressPCodePlaceholder.value = updateObject.value.cPostcode
        }
        if((updateObject.value.cRegion) && (updateObject.value.cRegion.length != 0 || updateObject.value.cRegion != null)){
        cAddressRegionPlaceholder.value = updateObject.value.cRegion
        }
        if((updateObject.value.cCountry) && (updateObject.value.cCountry.length != 0 || updateObject.value.cCountry != null)){
        cAddressCountryPlaceholder.value = updateObject.value.cCountry
        }
        populated.value = true
      }
      if (props.modData.area === "Client-Password")
      {
        populated.value = true
      }
      if (props.modData.area === "Client-Details")
      {
        if((updateObject.value.cUsername) && (updateObject.value.cUsername.length != 0 || updateObject.value.cUsername != null)){
        cUsernamePlaceholder.value = updateObject.value.cUsername
        }              
        if((updateObject.value.cPhone) && (updateObject.value.cPhone.length != 0 || updateObject.value.cPhone != null)){
        cPhonePlaceholder.value = updateObject.value.cPhone
        }
        if((updateObject.value.cFirstname) && (updateObject.value.cFirstname.length != 0 || updateObject.value.cFirstname != null)){
        cFirstnamePlaceholder.value = updateObject.value.cFirstname
        }  
        if((updateObject.value.cLastname) && (updateObject.value.cLastname.length != 0 || updateObject.value.cLastname != null)){
        cLastnamePlaceholder.value = updateObject.value.cLastname
        }  
        populated.value = true
      }
      if (props.modData.area === "User-Address")
      {
        if((updateObject.value.uAddress1) && (updateObject.value.uAddress1.length != 0 || updateObject.value.uAddress1 != null)){
        uAddress1Placeholder.value = updateObject.value.uAddress1
        }
        if((updateObject.value.uAddress2) && (updateObject.value.uAddress2.length != 0 || updateObject.value.uAddress2 != null)){
        uAddress2Placeholder.value = updateObject.value.uAddress2
        }
        if((updateObject.value.uCity) && (updateObject.value.uCity.length != 0 || updateObject.value.uCity != null)){
        uAddressCityPlaceholder.value = updateObject.value.uCity
        }
        if((updateObject.value.uPostcode) && (updateObject.value.uPostcode.length != 0 || updateObject.value.uPostcode != null)){
        uAddressPCodePlaceholder.value = updateObject.value.uPostcode
        }
        if((updateObject.value.uRegion) && (updateObject.value.uRegion.length != 0 || updateObject.value.uRegion != null)){
        uAddressRegionPlaceholder.value = updateObject.value.uRegion
        }
        if((updateObject.value.uCountry) && (updateObject.value.uCountry.length != 0 || updateObject.value.uCountry != null)){
        uAddressCountryPlaceholder.value = updateObject.value.uCountry
        }
        populated.value = true
      }    
      if (props.modData.area === "User-Details")
      {
        if((updateObject.value.uUsername) && (updateObject.value.uUsername.length != 0 || updateObject.value.uUsername != null)){
        uUsernamePlaceholder.value = updateObject.value.uUsername
        }              
        if((updateObject.value.uPhone) && (updateObject.value.uPhone.length != 0 || updateObject.value.uPhone != null)){
        uPhonePlaceholder.value = updateObject.value.uPhone
        }
        if((updateObject.value.uFirstname) && (updateObject.value.uFirstname.length != 0 || updateObject.value.uFirstname != null)){
        uFirstnamePlaceholder.value = updateObject.value.uFirstname
        }  
        if((updateObject.value.uLastname) && (updateObject.value.uLastname.length != 0 || updateObject.value.uLastname != null)){
        uLastnamePlaceholder.value = updateObject.value.uLastname
        }  
        populated.value = true
      }
    }

    let stopUpdateSubWatch = watchEffect(() => {
      console.log("watchEffect - stopCreateShootSubWatch")
      if (firstFocus.value) {
        firstFocus.value.focus()
      }
      if (props.modData.area === "Shoot")
      {
        if (updateShootFormMod.value) {  
          if(updateShootFormMod.value.children.length > 0)
          {
            // console.log("updateObject.value.shootTitle - ", updateObject.value.shootTitle)
            updateShootFormMod.value.querySelector("#shootTitleUp").addEventListener("blur", validateShootTitleUp)
            updateShootFormMod.value.querySelector("#shootTypeUp").addEventListener("blur", validateShootTypeUp)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }
      if (props.modData.area === "Image")
      {
        if (updateImageFormMod.value) {  
          if(updateImageFormMod.value.children.length > 0)
          {
            updateImageFormMod.value.querySelector("#imageTitleUp").addEventListener("blur", validateImageTitleUp)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }
      if (props.modData.area === "Client-Address")
      {
        if (updateClientAddFormMod.value) {  
          if(updateClientAddFormMod.value.children.length > 0)
          {
            console.log("EDIT UPDATE OBJECT - ", updateObject.value)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }
      if (props.modData.area === "Client-Password")
      {
        if (updateClientPWFormMod.value) {  
          if(updateClientPWFormMod.value.children.length > 0)
          {
            let togglePassword1 = updateClientPWFormMod.value.querySelector("#toggleClientPassword1")
            let password1 = updateClientPWFormMod.value.querySelector("#clientPassword1Up")
            let togglePassword2 = updateClientPWFormMod.value.querySelector("#toggleClientPassword2")
            let password2 = updateClientPWFormMod.value.querySelector("#clientPassword2")
            updateClientPWFormMod.value.querySelector("#clientPassword1Up").addEventListener("blur", validateClientResetPWUp)
            updateClientPWFormMod.value.querySelector("#clientPassword2Up").addEventListener("blur", validateClientResetPWConUp)

            if(populated.value != true)
            {
              togglePassword1.addEventListener("click", function () {   
                let dType = password1.getAttribute("type") === "password" ? "text" : "password";
                password1.setAttribute("type", dType);
                togglePassword1.classList.toggle("bi-eye")
                togglePassword1.classList.toggle("bi-eye-slash")
              })
              togglePassword2.addEventListener("click", function () {   
                let dType = password2.getAttribute("type") === "password" ? "text" : "password";
                password2.setAttribute("type", dType);
                togglePassword2.classList.toggle("bi-eye")
                togglePassword2.classList.toggle("bi-eye-slash")
              })
              doInitialPopulate()
            }
          }
        }
      }
      if (props.modData.area === "Client-Details")
      {
        if (updateClientDetFormMod.value) {  
          if(updateClientDetFormMod.value.children.length > 0)
          {
            updateClientDetFormMod.value.querySelector("#clientDetUsernameUp").addEventListener("blur", validateClientUsernameUp)
            updateClientDetFormMod.value.querySelector("#clientDetFNameUp").addEventListener("blur", validateClientFirstnameUp)
            updateClientDetFormMod.value.querySelector("#clientDetLNameUp").addEventListener("blur", validateClientLastnameUp)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }
      if (props.modData.area === "User-Details")
      {
        if (updateUserDetFormMod.value) {  
          if(updateUserDetFormMod.value.children.length > 0)
          {
            updateUserDetFormMod.value.querySelector("#userDetUsernameUp").addEventListener("blur", validateUserUsernameUp)
            updateUserDetFormMod.value.querySelector("#userDetPhoneUp").addEventListener("blur", validateUserPhoneUp)
            updateUserDetFormMod.value.querySelector("#userDetFNameUp").addEventListener("blur", validateUserFirstnameUp)
            updateUserDetFormMod.value.querySelector("#userDetLNameUp").addEventListener("blur", validateUserLastnameUp)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }
      if (props.modData.area === "User-Address")
      {
        if (updateUserAddFormMod.value) {  
          if(updateUserAddFormMod.value.children.length > 0)
          {
            console.log("EDIT UPDATE OBJECT - ", updateObject.value)
            updateUserAddFormMod.value.querySelector("#userAddress1Up").addEventListener("blur", validateUserAddress1Up)
            updateUserAddFormMod.value.querySelector("#userAddressPCodeUp").addEventListener("blur", validateUserPostcodeUp)
            if(populated.value != true)
            {
              doInitialPopulate()
            }               
          }
        }
      }     
    })
    
    onBeforeUnmount(() => {  
       if (props.modData.area === "Shoot")
        {
          updateShootFormMod.value.querySelector("#shootTitleUp").removeEventListener("blur", validateShootTitleUp)
          updateShootFormMod.value.querySelector("#shootTypeUp").removeEventListener("blur", validateShootTypeUp)
        }
       if (props.modData.area === "Image")
        {
          updateImageFormMod.value.querySelector("#imageTitleUp").removeEventListener("blur", validateImageTitleUp)          
        }
      if (props.modData.area === "Client-Details")
        {
          updateClientDetFormMod.value.querySelector("#clientDetUsernameUp").removeEventListener("blur", validateClientUsernameUp)
          updateClientDetFormMod.value.querySelector("#clientDetFNameUp").removeEventListener("blur", validateClientFirstnameUp)
          updateClientDetFormMod.value.querySelector("#clientDetLNameUp").removeEventListener("blur", validateClientLastnameUp)
        }
      if (props.modData.area === "Client-Password")
        {
          updateClientPWFormMod.value.querySelector("#clientPassword1Up").removeEventListener("blur", validateClientResetPWUp)
          updateClientPWFormMod.value.querySelector("#clientPassword2Up").removeEventListener("blur", validateClientResetPWConUp)
          updateClientPWFormMod.value.querySelector("#toggleClientPassword1").removeEventListener("click", function () {})
          updateClientPWFormMod.value.querySelector("#toggleClientPassword2").removeEventListener("click", function () {})          
        }
      if (props.modData.area === "User-Details")
        {
          updateUserDetFormMod.value.querySelector("#userDetUsernameUp").removeEventListener("blur", validateUserUsernameUp)
          updateUserDetFormMod.value.querySelector("#userDetPhoneUp").removeEventListener("blur", validateUserPhoneUp)
          updateUserDetFormMod.value.querySelector("#userDetFNameUp").removeEventListener("blur", validateUserFirstnameUp)
          updateUserDetFormMod.value.querySelector("#userDetLNameUp").removeEventListener("blur", validateUserLastnameUp)
        }
      if (props.modData.area === "User-Address")
        {
          updateUserAddFormMod.value.querySelector("#userAddress1Up").removeEventListener("blur", validateUserAddress1Up)
          updateUserAddFormMod.value.querySelector("#userAddressPCodeUp").removeEventListener("blur", validateUserPostcodeUp)
          
        }
      })

    onUnmounted(() => {
      // initialSet.value = false 
      console.log("pModal>Edit - onUnmounted")
      stopUpdateSubWatch()
    }) 

    let assignFocus = (focusArea) => {
      console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus
      if (props.modData.area === "Shoot")
      {
        clickFocus = updateShootFormMod.value.querySelector(`${focusId}`)
      }
      if (props.modData.area === "Image")
      {
        clickFocus = updateImageFormMod.value.querySelector(`${focusId}`)
      }      
      if (props.modData.area === "Client-Address")
      {
        clickFocus = updateClientAddFormMod.value.querySelector(`${focusId}`)
      }      
      if (props.modData.area === "Client-Details")
      {
        clickFocus = updateClientDetFormMod.value.querySelector(`${focusId}`)
      }      
      if (props.modData.area === "Client-Password")
      {
        clickFocus = updateClientPWFormMod.value.querySelector(`${focusId}`)
      }   
      if (props.modData.area === "User-Details")
      {
        clickFocus = updateUserDetFormMod.value.querySelector(`${focusId}`)
      }        
      if (props.modData.area === "User-Address")
      {
        clickFocus = updateUserAddFormMod.value.querySelector(`${focusId}`)
      }        
      clickFocus.focus()
    }

     let handleUpdateUserDet = async () => {
      console.log("handleUpdateUserDet", updateObject.value)
      let usernameInput = updateUserDetFormMod.value.querySelector("#userDetUsernameUp")
      let phoneInput = updateUserDetFormMod.value.querySelector("#userDetPhoneUp")
      let firstnameInput = updateUserDetFormMod.value.querySelector("#userDetFNameUp")
      let lastnameInput = updateUserDetFormMod.value.querySelector("#userDetLNameUp")
      let uUsernameChange = true
      if(usernameInput.value.length == 0 || usernameInput.value == null){
        uUsernameChange = false
        usernameInput.value = uUsernamePlaceholder.value
        uUsernameUp.value = uUsernamePlaceholder.value
      }
      if(phoneInput.value.length == 0 || phoneInput.value == null){
        phoneInput.value = uPhonePlaceholder.value
        uPhoneUp.value = uPhonePlaceholder.value
      }
      if(firstnameInput.value.length == 0 || firstnameInput.value == null){
        firstnameInput.value = uFirstnamePlaceholder.value
        uFirstnameUp.value = uFirstnamePlaceholder.value
      }
      if(lastnameInput.value.length == 0 || lastnameInput.value == null){
        lastnameInput.value = uLastnamePlaceholder.value
        uLastnameUp.value = uLastnamePlaceholder.value
      }
      let form = updateUserDetFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateUserUsernameUp() || !validateUserPhoneUp() || !validateUserFirstnameUp() || !validateUserLastnameUp()) {
        errorMessage.value = "Error Updating - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
        return  
      }
      inProgress.value = true
      let functions = getFunctions()
      let userUpdateInfo = {
        displayName: uUsernameUp.value,
        userDetails: {
          firstName: uFirstnameUp.value,
          lastName: uLastnameUp.value,
          phoneNum: uPhoneUp.value
        }
      } 
      console.log("userDetUpdateInfo ", userUpdateInfo)
      await UpdateUserDetailsDoc("users", store.getUserId.value, userUpdateInfo)
      if (updateUserDetailsError.value != null){
        errorMessage.value = updateUserDetailsError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      if (uUsernameChange) {
        let dispNameUp = httpsCallable(functions, "updateDispName")
        await dispNameUp({uid: store.getUserId.value, displayName: uUsernameUp.value}) 
      }
      inProgress.value = false
      errorMessage.value = "Details successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      inProgress.value = false 
      closeSubMod()     
    }
    let handleUpdateUserAdd = async () => {
      console.log("handleUpdateUserAdd", updateObject.value)
      inProgress.value = true
      let uAdd1Input = updateUserAddFormMod.value.querySelector("#userAddress1Up")
      let uAdd2Input = updateUserAddFormMod.value.querySelector("#userAddress2Up")
      let uCityInput = updateUserAddFormMod.value.querySelector("#userAddressCityUp")
      let uPostcodeInput = updateUserAddFormMod.value.querySelector("#userAddressPCodeUp")
      let uRegionInput = updateUserAddFormMod.value.querySelector("#userAddressRegionUp")
      let uCountryInput = updateUserAddFormMod.value.querySelector("#userAddressCountryUp")
      if(uAdd1Input.value.length == 0 || uAdd1Input.value == null){
        uAdd1Input.value = uAddress1Placeholder.value
        uAddress1Up.value = uAddress1Placeholder.value
      }
      if(uAdd2Input.value.length == 0 || uAdd2Input.value == null){
        uAdd2Input.value = uAddress2Placeholder.value
        uAddress2Up.value = uAddress2Placeholder.value
      }
      if(uCityInput.value.length == 0 || uCityInput.value == null){
        uCityInput.value = uAddressCityPlaceholder.value
        uAddressCityUp.value = uAddressCityPlaceholder.value
      }
      if(uPostcodeInput.value.length == 0 || uPostcodeInput.value == null){
        uPostcodeInput.value = uAddressPCodePlaceholder.value
        uAddressPCodeUp.value = uAddressPCodePlaceholder.value
      }
      if(uRegionInput.value.length == 0 || uRegionInput.value == null){
        uRegionInput.value = uAddressRegionPlaceholder.value
        uAddressRegionUp.value = uAddressRegionPlaceholder.value
      }
      if(uCountryInput.value.length == 0 || uCountryInput.value == null){
        uCountryInput.value = uAddressCountryPlaceholder.value
        uAddressCountryUp.value = uAddressCountryPlaceholder.value
      }
      let form = updateUserAddFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateUserAddress1Up() || !validateUserPostcodeUp()) {
        errorMessage.value = "Error Updating - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
        return  
      }
      let userAddUpdateInfo = { 
        userAddress: {
          address1: uAddress1Up.value,
          address2: uAddress2Up.value,
          postcode: uAddressPCodeUp.value,
          country: uAddressCountryUp.value
          }   
      } 
      console.log("userAddressUpdateInfo ", userAddUpdateInfo)
      await UpdateUserAddressDoc("users", store.getUserId.value, userAddUpdateInfo)
      if (updateUserAddressError.value != null){
        errorMessage.value = updateUserAddressError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      inProgress.value = false
      errorMessage.value = "Address successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()      
    }

   

    let handleUpdateClientAdd = async () => {
      console.log("handleUpdateClientAdd", updateObject.value)
      inProgress.value = true
      let cAdd1Input = updateClientAddFormMod.value.querySelector("#clientAddress1Up")
      let cAdd2Input = updateClientAddFormMod.value.querySelector("#clientAddress2Up")
      let cCityInput = updateClientAddFormMod.value.querySelector("#clientAddressCityUp")
      let cPostcodeInput = updateClientAddFormMod.value.querySelector("#clientAddressPCodeUp")
      let cRegionInput = updateClientAddFormMod.value.querySelector("#clientAddressRegionUp")
      let cCountryInput = updateClientAddFormMod.value.querySelector("#clientAddressCountryUp")
      if(cAdd1Input.value.length == 0 || cAdd1Input.value == null){
        cAdd1Input.value = cAddress1Placeholder.value
        cAddress1Up.value = cAddress1Placeholder.value
      }
      if(cAdd2Input.value.length == 0 || cAdd2Input.value == null){
        cAdd2Input.value = cAddress2Placeholder.value
        cAddress2Up.value = cAddress2Placeholder.value
      }
      if(cCityInput.value.length == 0 || cCityInput.value == null){
        console.log("cCityInput.value.length", cCityInput.value.length)
        console.log("cAddressCityPlaceholder.value", cAddressCityPlaceholder.value)
        cCityInput.value = cAddressCityPlaceholder.value
        cAddressCityUp.value = cAddressCityPlaceholder.value
      }
      if(cPostcodeInput.value.length == 0 || cPostcodeInput.value == null){
        cPostcodeInput.value = cAddressPCodePlaceholder.value
        cAddressPCodeUp.value = cAddressPCodePlaceholder.value
      }
      if(cRegionInput.value.length == 0 || cRegionInput.value == null){
        cRegionInput.value = cAddressRegionPlaceholder.value
        cAddressRegionUp.value = cAddressRegionPlaceholder.value
      }
      if(cCountryInput.value.length == 0 || cCountryInput.value == null){
        cCountryInput.value = cAddressCountryPlaceholder.value
        cAddressCountryUp.value = cAddressCountryPlaceholder.value
      }
      let clientAddUpdateInfo = { 
        clientAddress: {
          address1: cAddress1Up.value,
          address2: cAddress2Up.value,
          city: cAddressCityUp.value,
          postcode: cAddressPCodeUp.value,
          region: cAddressRegionUp.value,
          country: cAddressCountryUp.value
          }   
      } 
      console.log("clientAddressUpdateInfo ", clientAddUpdateInfo)
      await UpdateCliAddressDoc("clients", store.getSelClient.value, clientAddUpdateInfo)
      if (updateCliAddressError.value != null){
        errorMessage.value = updateCliAddressError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      inProgress.value = false
      errorMessage.value = "Client Address successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()      
    }

    let handleUpdateClientDet = async () => {
      console.log("handleUpdateClientDet", updateObject.value)
      let usernameInput = updateClientDetFormMod.value.querySelector("#clientDetUsernameUp")
      let phoneInput = updateClientDetFormMod.value.querySelector("#clientDetPhoneUp")
      let firstnameInput = updateClientDetFormMod.value.querySelector("#clientDetFnameUp")
      let lastnameInput = updateClientDetFormMod.value.querySelector("clientDetLnameUp")
      let cUsernameChange = true
      if(usernameInput.value.length == 0 || usernameInput.value == null){
        cUsernameChange = false
        usernameInput.value = cUsernamePlaceholder.value
        cUsernameUp.value = cUsernamePlaceholder.value
      }
      if(phoneInput.value.length == 0 || phoneInput.value == null){
        phoneInput.value = cPhonePlaceholder.value
        cPhoneUp.value = cPhonePlaceholder.value
      }
      if(firstnameInput.value.length == 0 || firstnameInput.value == null){
        firstnameInput.value = cFirstnamePlaceholder.value
        cFirstnameUp.value = cFirstnamePlaceholder.value
      }
      if(lastnameInput.value.length == 0 || lastnameInput.value == null){
        lastnameInput.value = cLastnamePlaceholder.value
        cLastnameUp.value = cLastnamePlaceholder.value
      }
      let form = updateClientDetFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateClientUsernameUp() || !validateClientFirstnameUp() || !validateClientLastnameUp()) {
        errorMessage.value = "Error Updating Client - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
        return  
      }
      let functions = getFunctions()
      let clientUpdateInfo = {
        displayName: cUsernameUp.value,
        clientDetails: {
          firstName: cFirstnameUp.value,
          lastName: cLastnameUp.value,
          phoneNum: cPhoneUp.value
        }
      } 
      console.log("clientDetUpdateInfo ", clientUpdateInfo)
      await UpdateCliDetailsDoc("clients", store.getSelClient.value, clientUpdateInfo)
      if (updateCliDetailsError.value != null){
        errorMessage.value = updateCliDetailsError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      if (cUsernameChange) {
        let dispNameUp = httpsCallable(functions, "updateDispName")
        await dispNameUp({uid: store.getSelClient.value, displayName: cUsernameUp.value}) 
      }
      inProgress.value = false
      errorMessage.value = "Client successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()     
    }

    let handleUpdateShoot = async () => {
      console.log("handleUpdateShoot", updateObject.value)
      let titleInput = updateImageFormMod.value.querySelector("#shootTitleUp")
      let typeInput = updateImageFormMod.value.querySelector("#shootTypeUp")
      let locationInput = updateImageFormMod.value.querySelector("#shootLocationUp")
      let dateInput = updateImageFormMod.value.querySelector("#shootDateUp")

      if(titleInput.value.length == 0 || titleInput.value == null){
        titleInput.value = sTitlePlaceholder.value
        shootTitleUp.value = sTitlePlaceholder.value
      }
      if(typeInput.value.length == 0 || typeInput.value == null){
        typeInput.value = sTypePlaceholder.value
        shootTypeUp.value = sTypePlaceholder.value
      }
      if(locationInput.value.length == 0 || locationInput.value == null){
        locationInput.value = sLocationPlaceholder.value
        shootLocationUp.value = sLocationPlaceholder.value
      }
      if(dateInput.value.length == 0 || dateInput.value == null){
        dateInput.value = sDatePlaceholder.value
        shootDateUp.value = sDatePlaceholder.value
      }
      let form = updateShootFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateShootTitleUp() || !validateShootTypeUp()) {
        errorMessage.value = "Error Updating Shoot - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
        return  
      }
      let shootUpdateInfo = {
        shootTitle: shootTitleUp.value,
        shootType: shootTypeUp.value,
        shootLocation: shootLocationUp.value,
        shootDate: shootDateUp.value
      }
      console.log("shootUpdateInfo ", shootUpdateInfo)
      await UpdateShootDoc("shoots", updateObject.value.id, shootUpdateInfo)
      if (updateShootError.value != null){
        errorMessage.value = updateShootError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      inProgress.value = false
      errorMessage.value = "Shoot successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()     
    }

    let handleUpdateImage = async () => {
      console.log("handleUpdateImage", updateObject.value)
      let titleInput = updateImageFormMod.value.querySelector("#imageTitleUp")     
      if(titleInput.value.length == 0 || titleInput.value == null){
        titleInput.value = iTitlePlaceholder.value
        imageTitleUp.value = iTitlePlaceholder.value
      }
      // console.log("titleInput", titleInput.value)
      // console.log("titleInput Len", titleInput.value.length)
      let notesInput = updateImageFormMod.value.querySelector("#imageNotesUp")
      if(notesInput.value.length == 0 ){
        imageNotesUp.value = iNotesPlaceholder.value
      }
      let form = updateImageFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateImageTitleUp()) {
        errorMessage.value = "Error Updating Image - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)  
      }
      let imageUpdateInfo = {
        imageTitle: imageTitleUp.value,
        imageRecommendations: recommendationsUp.value,
        imageNotes: imageNotesUp.value
      }
      console.log("imageUpdateInfo ", imageUpdateInfo)
      await UpdateImageDoc("images", updateObject.value.id, imageUpdateInfo)
      if (updateImageError.value != null){
        errorMessage.value = updateImageError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      inProgress.value = false
      errorMessage.value = "Image successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()   
    }

    let handleUpdateClientPW = async () => {
      console.log("handleUpdateClientPW", updateObject.value)
      let form = updateClientPWFormMod.value.querySelector(".needs-validation")
      if (!form.checkValidity() || !validateClientResetPWUp() || !validateClientResetPWConUp()) 
      {
        errorMessage.value = "Error Updating Password - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
        return  
      }
      if(cPasswordUp.value === cConfPasswordUp.value) {
        inProgress.value = true
        let clientUpdateInfo = {
        password: cPasswordUp.value
        }
        await UpdateCliDetailsDoc("clients", store.getSelClient.value, clientUpdateInfo)
        if (updateCliDetailsError.value != null){
          errorMessage.value = updateCliDetailsError.value
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          return           
        }
        // NEED TO ADD IN FIREBASE FUNCTION TO CHANGE CLIENT PASSWORD IN AUTH
        // POSS REAUTHENTICATE
        inProgress.value = false
        errorMessage.value = "Password successfully changed"
        let alertLvl = 2
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)  
        closeSubMod()     
        }
      

    }

    let closeSubMod = () => {
      console.log("pModal>Edit>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    return{ closeSubMod, handleUpdateShoot, handleUpdateImage, inProgress, updateShootFormMod, updateImageFormMod, firstFocus, assignFocus, shootTitleUp, sTitlePlaceholder, shootTypeUp, sTypePlaceholder, shootLocationUp, sLocationPlaceholder, shootDateUp, sDatePlaceholder, imageTitleUp, iTitlePlaceholder, imageNotesUp, iNotesPlaceholder, recommendationsUp, updateImagePending, updateShootPending, 
    updateClientAddFormMod, cAddress1Placeholder, cAddress1Up, cAddress2Placeholder, cAddress2Up, cAddressCityPlaceholder, cAddressCityUp, cAddressPCodePlaceholder, cAddressPCodeUp, cAddressRegionPlaceholder, cAddressRegionUp, cAddressCountryPlaceholder, cAddressCountryUp, updateCliAddressPending, handleUpdateClientAdd, updateCliDetailsPending, handleUpdateClientDet, updateClientDetFormMod, cUsernamePlaceholder, cUsernameUp, cPhonePlaceholder, cPhoneUp,cFirstnamePlaceholder, cFirstnameUp, cLastnamePlaceholder, cLastnameUp, cPasswordUp, cConfPasswordUp,  updateClientPWFormMod, handleUpdateClientPW,
    updateUserDetFormMod, uUsernamePlaceholder, uUsernameUp, uPhonePlaceholder, uPhoneUp, uFirstnamePlaceholder, uFirstnameUp, uLastnamePlaceholder, uLastnameUp,
    updateUserAddFormMod, uAddress1Placeholder, uAddress1Up, uAddress2Placeholder, uAddress2Up, uAddressCityPlaceholder, uAddressCityUp,uAddressRegionPlaceholder, uAddressRegionUp, uAddressPCodePlaceholder, uAddressPCodeUp, uAddressCountryPlaceholder, uAddressCountryUp, updateUserAddressPending, updateUserDetailsPending, handleUpdateUserDet, handleUpdateUserAdd, showReco }


  }
}
</script>
<style scoped>
.sUpLabel{
  font-size: 1rem !important;
  color: #3D3B3A !important;
}
.check-label-sm {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.check-box-sm {
  margin-top: 0.3rem; 
  width: 1.2rem;
  height: 1.2rem;
  margin-left: -1.6rem
}
.check-form-sm {
  margin-bottom: 0;
  min-height: 1.4rem;
  /* padding-left: 1rem; */
}

.recommend-holder{
  border:#BBC4CB solid 1px;
  border-top: none;
  padding: 0.25rem;
}
.recommend-holder.expanded{
  transform: scaleY(0);
  transform-origin: top;
  animation: animate 0.3s linear forwards;
}

 @keyframes animate {
    100%{
      transform: scaleY(1);
    }
  }
</style>