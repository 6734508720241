<template> 
<path d="M913.5,845V189a72,72,0,0,0-72-72h-656a72,72,0,0,0-72,72h0V845a72,72,0,0,0,72,72h656A72,72,0,0,0,913.5,845ZM313.36,834a45.27,45.27,0,0,1,6.6-63.68L616.32,529.69a19,19,0,0,0,.16-29.36l-289-240a45.27,45.27,0,0,1,57.85-69.64L707,457.85a74.24,74.24,0,0,1,26.73,57.39v.42a74.19,74.19,0,0,1-27.35,57.49L377,840.64a45.28,45.28,0,0,1-63.68-6.6Z" />
</template>
<script>
export default {
  name: "IconNext"
}
</script>
<style scoped>

</style>
