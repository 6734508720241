<template>
  <div>
    <div ref="addClientFormMod" class="addClientFormCont">
      <!-- ADD CLIENT PROGRESS -->
      <div class="col-12 col-sm-11 mx-auto">
        <div class="form-steps row-cols-2 p-0 m-0">
          <div class="step text-center position-relative">
            <p class="fw-normal">Details</p>
            <div class="bullet">
                <span>1</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
          <div class="step text-center position-relative">
            <p class="fw-normal">Address</p>
            <div class="bullet">
                <span>2</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>        
        </div>
      </div>
      <!-- ADD CLIENT FORM -->
      <div class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper">
        <form class="needs-validation" id="addclientform" novalidate @submit.prevent="handleAddClient">
          <!-- ADD CLIENT - PAGE 1 -->
          <div class="page slide-page">
            <div class="mb-1" @click="assignFocus('clientEmail')">
              <label for="clientEmail" class="form-label sUpLabel" >Client Email *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input ref="firstFocus" type="email" class="form-control" id="clientEmail" placeholder="Client Email" required v-model="clientEmail" autocomplete="off">
                <div class="invalid-tooltip">Please provide a valid Email Address</div>
              </div>
            </div>  
            <div class="mb-1" @click="assignFocus('clientUsername')">
              <label for="clientUsername" class="form-label sUpLabel" >Client Username *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input type="text" class="form-control" id="clientUsername" placeholder="Client Username" required v-model="clientUsername" autocomplete="off">
                <div class="invalid-tooltip">
                  Please provide a valid Username at least 4 chars
                </div>
              </div>
            </div>
            <div class="mb-1" @click="assignFocus('clientPassword')">
              <label for="clientPassword" class="form-label sUpLabel"  >Client Password *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input type="password" class="form-control" name="clientPassword" id="clientPassword" placeholder="Client Password"  required v-model="clientPassword" autocomplete="off">
                <span class="input-group-text border-0">
                  <i class="bi bi-eye" id="toggleClientPassword" style="cursor: pointer"></i>
                </span>
                <div class="invalid-tooltip">Passwords must be at least 6 characters</div>
              </div>
            </div>                    
            <div class="row mb-1 mt-3 gx-2">                   
              <div class="col-6" @click="assignFocus('clientFirstname')">
                <label for="clientFirstname" class="form-label sUpLabel" >First Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input  type="text" class="form-control" id="clientFirstname" placeholder="First name" required v-model="clientFirstname" autocomplete="off">
                  <div class="invalid-tooltip">Required field</div>
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('clientLastname')">
                <label for="clientLastname" class="form-label sUpLabel" >Last Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientLastname" placeholder="Lastname" required v-model="clientLastname" autocomplete="off">
                  <div class="invalid-tooltip">Required field</div>
                </div>
              </div>            
            </div>
            <div class="mb-1" @click="assignFocus('clientPhone')">
              <label for="clientPhone" class="form-label sUpLabel" >Phone</label>
              <div class="input-group" :class='{"input-group-sm": mobileView}'>
                <input type="tel" class="form-control" id="clientPhone" placeholder=" Phone" v-model="clientPhone" autocomplete="off">              
              </div>
            </div>
            <div class="col-12 mx-auto p-0 m-0">
              <div class="mb-0 text-end">
                <p class="p-0 m-0 small text-charcoal"><span class="fs-6">* </span>required field</p>                
              </div>
            </div>      
            <div class="mt-4 col-12 mx-auto">
              <button v-if="!createClientPending" type="submit" class="btn w-100 btn-highlight text-white  text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>Create Client</button>  
              <button v-else class="btn w-100 btn-highlight text-white  text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' disabled>Creating</button> 
            </div>         
            <div class="mt-2 mb-3 col-12 mx-auto">
             <button type="button" class="btn w-100 btn-charcoal text-white text-uppercase w-100 shadow-sm firstNext next" :class='{"btn-sm": mobileView}'>Add Address <span class="fs-4 ps-1"> &#8594; </span></button> 
            </div>
          </div>
          <!-- ADD CLIENT - PAGE 2 -->
          <div class="page">
            <div class="row mb-1 gx-2">
              <div class="col-12 mb-1" @click="assignFocus('clientAddress1')">
                <label for="clientAddress1" class="form-label sUpLabel" >Address Line 1</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientAddress1" placeholder="Address 1" v-model="clientAddress1" autocomplete="off">              
                </div>
              </div>
              <div class="col-12 mb-1 text-start text-sm-end" @click="assignFocus('clientAddress2')">
                <label for="clientAddress2" class="form-label sUpLabel" >Address Line 2</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientAddress2" placeholder="Address 2" v-model="clientAddress2" autocomplete="off">              
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6 text-start" @click="assignFocus('clientCity')">
                <label for="clientCity" class="form-label sUpLabel" >City / Town </label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientCity" placeholder="City / Town" v-model="clientCity" autocomplete="off">              
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('clientPostcode')">
                <label for="clientPostcode" class="form-label sUpLabel" >Postcode</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientPostcode" placeholder="Postcode" v-model="clientPostcode" autocomplete="off">              
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('clientRegion')">
                <label for="clientRegion" class="form-label sUpLabel" >Region/County</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientRegion" placeholder="Region/County" v-model="clientRegion" autocomplete="off">              
                </div>
              </div>
              <div class="col-6" @click="assignFocus('clientCountry')">
                <label for="clientCountry" class="form-label sUpLabel" >Country</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="clientCountry" placeholder="Country" v-model="clientCountry" autocomplete="off">              
                </div>
              </div>
            </div>    
            <div class="mt-4 pt-2 col-12 col-md-10 col-lg-8 mx-auto">
              <button v-if="!createClientPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Create Client</button> 
              <button v-else class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' disabled>Creating</button> 
            </div>
            <div class="mt-2 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
              <button type="button" class="btn w-100 btn-charcoal text-white text-uppercase shadow-sm prev-1 prev" :class='{"btn-sm": mobileView}'><span class="fs-4 pe-2"> &#8592; </span>Back</button>  
            </div>            
          </div>
        </form>
      </div>
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>

<script>
import { useStore } from "@/composables/store/store"
import setDocument from "@/composables/setDocument"
import { httpsCallable, getFunctions } from "firebase/functions"
import {validateClientEmail, validateClientPassword, validateClientUsername, validateClientFirstname, validateClientLastname} from "../../../public/js/validation.js"
import Spinner from "@/components/Spinner.vue"
import { ref, watchEffect, onBeforeUnmount, onUnmounted, computed} from "vue"
import {auth} from "../../firebase/config"
import { doc, deleteDoc, updateDoc, arrayRemove, Timestamp} from "firebase/firestore";
// import {deleteUser} from "firebase-admin"
import { db } from "@/firebase/config"
// import { getAuth } from 'firebase/auth'
export default {
  name: "AddClientModContent",
  emits: ["closeModalSub", "showAlertSub", "switchModalDashData"],
  props: ["mobileView"],
  components: {Spinner},
    setup(props, context){
      let store = useStore();
      const { useSetDocument, error:createClientError, isPending:createClientPending } = setDocument()
      let addClientFormMod = ref(null)
      let firstFocus = ref(null)

      let errorMessage = ref("")
      let errorArray = ref([])
      let inProgress = ref(false)
      let newCid = ref("")
      let newCemail = ref("")

      // Create Client page progress bar vars
      let slidePage = ref(null)
      let nextBtnFirst = ref(null)
      let prevBtnSec = ref(null)
      let progressText = ref(null)
      let progressCheck = ref(null)
      let bullet = ref(null)
      let bulletSpan = ref(null)
      let current = ref(0)
      let currentSet = ref(false)
      let submitBtn = ref(null)      

      // Add Client vars
      let clientUsername = ref(null)
      let clientEmail = ref(null)
      let clientPassword = ref(null)
      let clientFirstname = ref(null)
      let clientLastname = ref(null)
      let clientPhone = ref(null)
      let clientAddress1 = ref(null)
      let clientAddress2 = ref(null)
      let clientCity = ref(null)
      let clientPostcode = ref(null)
      let clientRegion = ref(null)
      let clientCountry = ref(null)
      
      const addCurrent = () => {
        // console.log("RUNNING ADDCURRENT - ", current.value)
        bullet.value[current.value].classList.add("current");
        bulletSpan.value[current.value].classList.add("current");
        progressCheck.value[current.value].classList.add("current");
        progressText.value[current.value].classList.add("current");
      }
      const removeCurrent = () => {
        // console.log("RUNNING REMOVECURRENT - ", current.value)
        bullet.value[current.value].classList.remove("current");
        bulletSpan.value[current.value].classList.remove("current");
        progressCheck.value[current.value].classList.remove("current");
        progressText.value[current.value].classList.remove("current");
      }
      const addActive = () => {
        // console.log("RUNNING ADDACTIVE - ", current.value)
        bullet.value[current.value].classList.add("active");
        progressCheck.value[current.value].classList.add("active");
        progressText.value[current.value].classList.add("active");
      }
      const removeActive = () => {
        // console.log("RUNNING REMOVEACTIVE - ", current.value)
        bullet.value[current.value].classList.remove("active");
        progressCheck.value[current.value].classList.remove("active");
        progressText.value[current.value].classList.remove("active");
      }

      let createProgress = {
        createClientAcc: false,
        createClientDoc: false,
        setClientRoles: false,
        increaseUserClientCount: false
      }

      let clientCreated = computed(() => {
        if(createProgress.createClientAcc == true && createProgress.createClientDoc == true && createProgress.setClientRoles == true && createProgress.increaseUserClientCount == true){
          return true
        }
        else {
          return false
        }
      })

      // function showError() {
      //   let alertLvl = 1
      //   errorArray.value.push(alertLvl)  
      //   errorArray.value.push(errorMessage.value) 
      //   context.emit("showAlertSub", errorArray)             
      //   inProgress.value = false 
      //   cleanUp()      
      // }

      let baseUserClientCount = store.getClientCount.value

      const stopCreateClientSubWatch = watchEffect(() => {
        console.log("watchEffect - stopCreateClientSubWatch")
        if (firstFocus.value) {
          firstFocus.value.focus()
        }
        if (addClientFormMod.value) {  
          if(addClientFormMod.value.children.length > 0){
            //MULTI PAGE FORM CONTROLS
            //SELECTORS
            if(currentSet.value != true)
            {
              slidePage.value = addClientFormMod.value.querySelector(".slide-page")
              nextBtnFirst.value = addClientFormMod.value.querySelector(".firstNext")
              prevBtnSec.value = addClientFormMod.value.querySelector(".prev-1")            
              progressText.value = addClientFormMod.value.querySelectorAll(".step p")
              progressCheck.value = addClientFormMod.value.querySelectorAll(".step .check")
              bullet.value = addClientFormMod.value.querySelectorAll(".step .bullet")
              bulletSpan.value = addClientFormMod.value.querySelectorAll(".step .bullet span")
              submitBtn.value = addClientFormMod.value.querySelector(".submit")
              addCurrent()
              removeActive()
              //LISTENERS
              nextBtnFirst.value.addEventListener("click", function(event){
                console.log("Add Address Clicked ")
                event.preventDefault();
                if(validateClientUsername() && validateClientEmail() && validateClientPassword() && validateClientFirstname() && validateClientLastname())
                {
                  removeCurrent()
                  addActive()
                  slidePage.value.style.marginLeft = "-50%";
                  current.value += 1;
                  addCurrent()
                }
                else{
                  let alertLvl = 1
                  errorArray.value.push(alertLvl)
                  errorMessage.value = "Please complete required fields to proceed"
                  errorArray.value.push(errorMessage.value)  
                  context.emit("showAlertSub", errorArray)
                }              
              });            
              prevBtnSec.value.addEventListener("click", function(event){
                event.preventDefault();
                removeCurrent()
                slidePage.value.style.marginLeft = "0%";                
                current.value -= 1;
                removeActive()
                addCurrent()
              });

              //MULTIPAGE FORM VALIDATION START
              // PAGE 1
              addClientFormMod.value.querySelector("#clientUsername").addEventListener("blur", validateClientUsername)
              addClientFormMod.value.querySelector("#clientEmail").addEventListener("blur", validateClientEmail)
              addClientFormMod.value.querySelector("#clientPassword").addEventListener("blur", validateClientPassword)
              addClientFormMod.value.querySelector("#clientFirstname").addEventListener("blur", validateClientFirstname)
              addClientFormMod.value.querySelector("#clientLastname").addEventListener("blur", validateClientLastname)
              let togglePassword = addClientFormMod.value.querySelector("#toggleClientPassword")
              let password = addClientFormMod.value.querySelector("#clientPassword")
              // console.log("Password - ", password)
              togglePassword.addEventListener("click", function () {   
                let dType = password.getAttribute("type") === "password" ? "text" : "password";
                password.setAttribute("type", dType);
                togglePassword.classList.toggle("bi-eye")
                togglePassword.classList.toggle("bi-eye-slash")
              })
              currentSet.value = true
            }
          }
        }
      })

      onBeforeUnmount(() => {  
        nextBtnFirst.value.removeEventListener("click", function () {})
        prevBtnSec.value.removeEventListener("click", function () {})
        addClientFormMod.value.querySelector("#toggleClientPassword").removeEventListener("click", function () {})
        addClientFormMod.value.querySelector("#clientUsername").removeEventListener("blur", validateClientUsername)
        addClientFormMod.value.querySelector("#clientEmail").removeEventListener("blur", validateClientEmail)
        addClientFormMod.value.querySelector("#clientPassword").removeEventListener("blur", validateClientPassword)
        addClientFormMod.value.querySelector("#clientFirstname").removeEventListener("blur", validateClientFirstname)
        addClientFormMod.value.querySelector("#clientLastname").removeEventListener("blur", validateClientLastname)   
        removeCurrent()
        removeActive()
      })

      onUnmounted(() => {
        console.log("pModal>AddClient - onUnmounted")
        clearVars()
        stopCreateClientSubWatch()
      })     
      
      const clearVars = () => {
        console.log("Running AddClient clearVars")
        addClientFormMod.value = null
        firstFocus.value = null
        errorMessage.value = ""
        errorArray.value = []
        inProgress.value = false
        newCid.value = null
        newCemail.value = ""
        slidePage.value = null
        nextBtnFirst.value = null
        prevBtnSec.value = null
        progressText.value = null
        progressCheck.value = null
        bullet.value = null
        bulletSpan.value = null
        current.value = 0
        currentSet.value = false
        submitBtn.value = null
        clientUsername.value = null
        clientEmail.value = null
        clientPassword.value = null
        clientFirstname.value = null
        clientLastname.value = null
        clientPhone.value = null
        clientAddress1.value = null
        clientAddress2.value = null
        clientCity.value = null
        clientPostcode.value = null
        clientRegion.value = null
        clientCountry.value = null
        createProgress.createClientAcc = false
        createProgress.createClientDoc = false
        createProgress.setClientRoles = false
        createProgress.increaseUserClientCount = false
      }

      const assignFocus = (focusArea) => {
        console.log("Running assignFocus", focusArea)
        let focusId = "#" + focusArea
        let clickFocus = addClientFormMod.value.querySelector(`${focusId}`)
        clickFocus.focus()
      }

      const clientCreationDate = computed(() => {
        let test = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
        return test
      })

      const addClientSuccess = () => {
        console.log("pModal>AddClient>Success-Continue - switchModalDashData")
        let subtitle = `${newCemail.value} has been created`
        let modData = {title: "Success", area:"Client", message: subtitle, message2: "Do you wish to contine and add a shoot for this client?", cid:newCid.value, cEmail:newCemail.value, cNumShoots:0, cNumImages:0, cNumOrders:0 }
        context.emit("switchModalDashData", modData )
      }

      const handleAddClient = async () => {
        console.log("handleAddClient")
        let form = addClientFormMod.value.querySelector(".needs-validation")
        if ( !form.checkValidity() || !validateClientEmail() || !validateClientPassword() || !validateClientUsername() || !validateClientFirstname() || !validateClientLastname){
          errorMessage.value = "Error Creating Client - Please recheck form"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)            
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)  
        }
        else {
          form.classList.add("was-validated")
          inProgress.value = true
          let functions = getFunctions()
          let authResult
          let userUid
          let newClientUid
          try {
            const addNewClient = httpsCallable(functions, "createNewClient") 
            authResult = await addNewClient({email:clientEmail.value, password:clientPassword.value, displayName:clientUsername.value}) 
            // console.log("res1 - authResult - ", authResult)
            createProgress.createClientAcc = true
          } catch (error) {
            createProgress.createClientAcc = false
            errorMessage.value = "Error Occurred While Creating Client Account"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return         
          }
          try {
            userUid = store.getUserId.value
            newClientUid = authResult.data      
            newCid.value = authResult.data  
            console.log("407 - newCid.value - ", newCid.value)
            newCemail.value = clientEmail.value 
            const clientInfo = {
              userId: userUid,
              clientId: newClientUid,
              displayName: clientUsername.value,
              email: clientEmail.value,
              password: clientPassword.value,
              clientDetails: {
                firstName: clientFirstname.value,
                lastName: clientLastname.value,
                phoneNum: clientPhone.value,
              }, 
              clientAddress: {
                address1: clientAddress1.value,
                address2: clientAddress2.value,
                city: clientCity.value,
                postcode: clientPostcode.value,
                region: clientRegion.value,
                country: clientCountry.value,
              },     
              shoots: {
                numberShoots: 0,
                shootIDs:[],           
              },
              images: {
                numberImages: 0,
                imageIDs:[],            
              },
              orders: {
                numberOrders: 0,
                orderIDs:[],            
              },                  
              ClientCreationDate: clientCreationDate.value
            }
            await useSetDocument("clients", newClientUid, clientInfo)
            if (createClientError.value != null){
              createProgress.createClientDoc = false
              errorMessage.value = "Error creating client document"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                
            }
            else{
              createProgress.createClientDoc = true
            }
            //set client roles
            let cAccess = "0"
            try{    
              const setClientRole = httpsCallable(functions, "setClientRole")   
              // console.log("email:clientEmail.value - ", clientEmail.value)
              // console.log("accessLevel:access - ", access)
              // console.log("creator:auth.currentUser.email - ", auth.currentUser.email)
              const rescRole = await setClientRole({email:clientEmail.value, accessLevel:cAccess, creator:auth.currentUser.email})
              //const rescRole = await setClientRole({email:clientEmail.value, accessLevel:access})
              console.log("rescRole - ", rescRole)
              createProgress.setClientRoles = true
            } catch (error) {
              createProgress.setClientRoles = false
              errorMessage.value = "Error Occurred While Setting Client Role"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                         
            }           
            //increment user number of clients
            try{
               const increaseUserClientCount = httpsCallable(functions, "incrementNumClients")
               const res3 =  await increaseUserClientCount({cid:newClientUid})
              //  const res3 =  await increaseUserClientCount({cid:newClientUid})
               console.log("res3 - ", res3)
               createProgress.increaseUserClientCount = true
            } catch (error){
              createProgress.increaseUserClientCount = false
              errorMessage.value = "Error Occurred While Increasing User Client Count"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                         
            }
            inProgress.value = false
            if(clientCreated.value == true){
              errorMessage.value = "Client successfully created"
              let alertLvl = 2
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value)
              context.emit("showAlertSub", errorArray)  
              addClientSuccess() 
            }
            else{
              errorMessage.value = "An Error Occurred"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false
              cleanUp()
            }       
          }
          catch(error){
            createProgress.createClientDoc = false
            errorMessage.value = "Error Occurred While Creating Client Account"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return         
          } 
        }
      }

       const cleanUp = async () => {
        console.log("Running AddClient Cleanup")
        errorMessage.value = ""
        errorArray.value = []
        // console.log("createProgress.createClientAcc - ", createProgress.createClientAcc)
        // console.log("createProgress.createClientDoc - ", createProgress.createClientDoc)
        // console.log("createProgress.setClientRoles - ", createProgress.setClientRoles)
        console.log("createProgress.increaseUserClientCount - ", createProgress.increaseUserClientCount)
        if(createProgress.createClientAcc && newCid.value != null){
          console.log("Deleting Client Accoutn - ", newCid.value)
          let functions = getFunctions()
          const clientDelete = httpsCallable(functions, "deleteMyClient")
          const res4 = await clientDelete({uid:store.getUserId.value, cid:newCid.value}) 
          console.log("New Client Account Deleted - ", res4)         
        } 
        if(createProgress.createClientDoc && newCid.value != null){
          await deleteDoc(doc(db, "clients", newCid.value));
          console.log("Doc Deleted - ", newCid.value)
        }
        if(createProgress.increaseUserClientCount){
          const userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"clients.numberClients": baseUserClientCount})
          await updateDoc(userRef, {"clients.clientIDs": arrayRemove(newCid.value)})
          console.log("User Client Count Fixed & ID Removed")
        }
        context.emit("closeModalSub")
       }


      return {addClientFormMod, firstFocus, assignFocus, inProgress, clientUsername, clientEmail, clientPassword, clientFirstname, clientLastname, clientPhone, clientAddress1, clientAddress2, clientCity, clientPostcode, clientRegion, clientCountry, handleAddClient, createClientPending}
    }
}
</script>

<style scoped>

  ::selection{
    color: #fff;
    background: #00A4B0;
  }

  .addClientFormCont .form-wrapper{
    /* width: 100%; */
    overflow: hidden;
  }

  .addClientFormCont .form-wrapper form{
    display: flex;
    width: 200%;
  }

  .addClientFormCont .form-wrapper form .page{
    width: 50%;
    transition: margin-left 0.3s ease-in-out;
    padding: 0 1rem;
  }

  .addClientFormCont .form-steps{
    display: flex;
    margin: 0.25rem 0;
    user-select: none;
  }

  .sUpLabel{
    font-size: 1rem !important;
    color: #3D3B3A !important;
  }

  .addClientFormCont .form-steps .step p{
    font-weight: 600;
    font-size: 1.1rem;
    color: #3d3b3a;
    margin-bottom: 0.125rem;
  }

   /* CURRENT */
  .addClientFormCont .form-steps .step p.current{
    font-weight: 800;
    color: #00A4B0;
  }

  .form-steps .step .bullet.current{   
    border: 0.125rem solid #00A4B0;
    background-color: #00A4B0;   
  }

  .form-steps .step .bullet span.current{
    color: #FFF;    
  }

  .form-steps .step .bullet{
    height: 2.25rem;
    width: 2.25rem;
    border: 0.125rem solid #bbc4c8;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
  }

  .form-steps .step .bullet.active{
    border-color: #00757D;
    /* background: #00A4B0; */
  }

  .form-steps .step .bullet span{
    position: absolute;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Old Standard TT', serif;
    color: #bbc4c8;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-steps .step .bullet.active span{
    display: none;
  }

  .form-steps .step .bullet:before,
  .form-steps .step .bullet:after{
    position: absolute;
    content: '';
    bottom: 0.95rem;
    height: 0.125rem;    
    background: #bbc4c8;
  }

  @media only screen and (max-width:575px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{      
      right: calc(32vw * -1 );     
      width: calc(31vw);
    }
  }

  @media only screen and (max-width:767px) and (min-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.7rem;
      width: 9rem;     
    }
  }
  @media only screen and (min-width:768px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.4rem;
      width: 9rem;
    }
  }

  .form-steps .step .bullet.active:after{
    background: #00A4B0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
  }

  @keyframes animate {
    100%{
      transform: scaleX(1);
    }
  }

  .form-steps .step:last-child .bullet:before,
  .form-steps .step:last-child .bullet:after{
    display: none;
  }

  .form-steps .step p.active{
    color: #00A4B0;
    transition: 0.2s linear;
  }

  .form-steps .step .check{
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  .form-steps .step .check.active{
    display: block;
    color: #fff;
  }

  .check.bi::before {
    font-size: 2.25rem;
    line-height: 0.7;
    vertical-align: none;
  } 

  #clientPassword:focus ~ .bi-bi-eye{
    margin-left: -30px !important; 
    cursor: pointer !important;
    z-index: 5 !important;
  }

</style>