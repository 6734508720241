import { initializeApp } from "firebase/app"
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getFunctions } from "firebase/functions"
import { getAnalytics } from "firebase/analytics"

const app = initializeApp ({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
})



// if (process.env.NODE_ENV !== "production") {
//   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcuoyMeAAAAAMDSaX8GnfCljD6S-W3mn95B8d5k"),
  isTokenAutoRefreshEnabled: true
})

//init services
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { db, auth, storage, functions, app, analytics }