<template>
  <div>
    <div ref="loginFormMod" class="col-12 col-sm-11 p-0 m-0 mx-auto">
      <form class="needs-validation" novalidate @submit.prevent="handleLogin">
        <div class="mb-1" @click="assignFocus('loginEmail')">
          <label for="loginEmail" class="form-label" >Email</label>
          <div class="input-group position-relative" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="email" class="form-control" id="loginEmail" name="loginEmail" placeholder="Enter Email" required v-model="loginEmail" autocomplete="off">
            <div class="invalid-tooltip">Please provide a valid Email Address</div>
          </div>
        </div>                    
        <div class="mb-1" @click="assignFocus('loginPassword')">
          <label for="loginPassword" class="form-label">Password</label>
          <div class="input-group position-relative" :class='{"input-group-sm": mobileView}'>
            <input type="password" class="form-control" name="loginPassword" id="loginPassword" placeholder="Password"  required v-model="loginPassword" autocomplete="off">
            <span class="input-group-text border-0">
              <i class="bi bi-eye" id="togglePassword" style="cursor: pointer"></i>
            </span>
            <div class="invalid-tooltip">Passwords must contain at least (a Capital letter, a number and a special character).</div>
          </div>
        </div>
        <div class="form-check pt-2 mb-1">
          <label class="form-check-label">
          <input type="checkbox" class="form-check-input" id="remainSignedIn"  v-model="rememberSignin">
          <span class="small p text-charcoal">Remember me?</span>
          </label>
        </div>
        <div class="mt-3 col-12 mx-auto">                      
          <button v-show="!loginPending" type="submit" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm"  :class='{"btn-sm": mobileView}'>Login</button> 
          <button v-show="loginPending" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" disabled :class='{"btn-sm": mobileView}'>Loading</button> 
        </div>
      </form>
    </div>          
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2" @click="resetPwSubLnk">Forgot Password?</p>                 
    </div>      
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class=" p-link fs-5 p-0 m-0 mt-2 mb-2 text-decoration-underline text-highlight" @click="signUpSubLnk">Create Account Now</p>
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>       
</template>

<script>
  import {validateLoginEmail, validateLoginPassword} from "../../../public/js/validation.js"
  import useLogin from "@/composables/useLogin"   
  import { useRouter } from "vue-router"
  import Spinner from "@/components/Spinner.vue"
  import { ref, watchEffect, onBeforeUnmount, onUnmounted} from "vue"
  import { auth } from "../../firebase/config"
export default {
  name: "LoginModContent",
  emits: ["closeModalSub", "showAlertSub", "switchModalSub", "toggleNav", "switchModalDashData"],
  props: ["mobileView"],
  components: {Spinner},
  setup(props, context){
    const loginFormMod = ref(null)
    const firstFocus = ref(null)
    let errorMessage = ref("")
    let errorArray = ref([])
    let router = useRouter()
    let inProgress = ref(false)

    // Login vars
    const { login, error:loginErr, isPending:loginPending } = useLogin() 
    const rememberSignin = ref(false)
    const loginEmail = ref(null)
    const loginPassword = ref(null)

    const stopLoginModSubWatch = watchEffect(() => {
      // console.log("watchEffect - stopLoginModSubWatch")
      if (firstFocus.value) {
        firstFocus.value.focus()
      }
      if(loginFormMod.value) {
        if(loginFormMod.value.children.length > 0) {        
          // console.log("loginFormMod - setting uplisteners")
          loginFormMod.value.querySelector("#loginEmail").addEventListener("blur", validateLoginEmail)
          loginFormMod.value.querySelector("#loginPassword").addEventListener("blur", validateLoginPassword)          
          let togglePassword = loginFormMod.value.querySelector("#togglePassword")
          // console.log("togglePassword - ", togglePassword)
          let password = loginFormMod.value.querySelector("#loginPassword")
          // console.log("Password - ", password)
          togglePassword.addEventListener("click", function () {   
            // toggle the type attribute
            let dType = password.getAttribute("type") === "password" ? "text" : "password";
            password.setAttribute("type", dType);
            // toggle the eye icon
            togglePassword.classList.toggle("bi-eye")
            togglePassword.classList.toggle("bi-eye-slash")
          })
        }
      }
    })

    onBeforeUnmount(() => {      
      if(loginFormMod.value) {
        //FIELD BLUR LISTENERS
        loginFormMod.value.querySelector("#loginEmail").removeEventListener("blur", validateLoginEmail)
        loginFormMod.value.querySelector("#loginPassword").removeEventListener("blur", validateLoginPassword)
        //PW ICON LISTENER
        loginFormMod.value.querySelector("#togglePassword").removeEventListener("click", function () {})
        let form = loginFormMod.value.querySelector(".needs-validation")
        form.classList.remove("was-validated")

      }
    })

    onUnmounted(()=> {
      console.log("pModal>Login - onUnmounted")
      stopLoginModSubWatch()           
    })

    const resetPwSubLnk = () => {
      console.log("pModal>Login>resetPwSubLnk - Switch Modal")
      let switchTo = "Reset Password"
      context.emit("switchModalSub", switchTo)
    }
    const signUpSubLnk = () => {
      console.log("pModal>Login>signUpSubLnk - Switch Modal")
      let switchTo = "Signup"
      context.emit("switchModalSub", switchTo )
    }

    const closeSubMod = () => {
      console.log("pModal>Login>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    //assign focus
    const assignFocus = (focusArea) => {
      // console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = loginFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    const handleLogin = async () => {
      // console.log("handleLogin") 
      let form = loginFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateLoginEmail() || !validateLoginPassword()) {
        form.classList.remove("was-validated")
        console.log("Unable to submit - invalid")       
      }
      else {
        // console.log("LOGIN FORM VALIDATED")
        form.classList.add("was-validated")
        inProgress.value = true
        let res = await login(loginEmail.value, loginPassword.value, rememberSignin.value)
        // console.log("LOGIN ISSUE RES - ", res)
        if (!loginErr.value){
          let verified = res.user.emailVerified
          console.log("verified - ", verified)
          let _uid = res.user.uid
          if(verified != true)
          {
            //SHOW ALERT UNVERFIED
            errorMessage.value = "Please Verify Email Address to Login"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value)
            context.emit("showAlertSub", errorArray)            
            console.log("pModal>Login>Verify Email - Switch Modal")
            let switchTo = "Verify Email"
            context.emit("switchModalSub", switchTo)
            inProgress.value = false
            form.classList.remove("was-validated")
            return
          }
          if(auth.currentUser){
            console.log("LOGIN.VUE - User is verified - Check Access auth.currentUser")            
            auth.currentUser.getIdTokenResult().then((res) => {
              console.log("LOGIN.VUE - User is verified - Check Access auth.currentUser - ", res)  
              if(res.claims.accessLevel == 0){
                console.log("INDEX.JS > auth.currentUser is a Client - showPopup")
                // console.log("pModal>Login>Client Access - Switch Modal", res.claims.email)              
                let modData = {title: "Client Login", dName: auth.currentUser.displayName, photographer: res.claims.email }
                context.emit("switchModalDashData", modData )
                inProgress.value = false
                form.classList.remove("was-validated")
                return
              }
              else {
                console.log("All OK Proceed")
                inProgress.value = false
                router.push({ name: "Dashboard", params: {id: _uid}})  
                context.emit("toggleNav")
                context.emit("closeModalSub")
              }
            })
          }
        }
        else {
          errorMessage.value = loginErr.value 
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)  
          loginEmail.value = null
          loginPassword.value = null
          inProgress.value = false
          form.classList.remove("was-validated")
          context.emit("toggleNav")
          context.emit("showAlertSub", errorArray)
        } 
      }
    }
    return {resetPwSubLnk, signUpSubLnk, closeSubMod, firstFocus, loginFormMod, handleLogin, rememberSignin, loginEmail, loginPassword, loginPending, assignFocus, inProgress}
  }
}
</script>

<style scoped>
#loginPassword:focus ~ .bi-bi-eye{
  margin-left: -30px !important; 
  cursor: pointer !important;
  z-index: 5 !important;
}

</style>