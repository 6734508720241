<template>
  <div class="col-12 col-sm-11 mx-auto text-center">
    <div class="row">
      <div class="col-12 mx-auto p-0 m-0">
        <p class="h1 fs-3 fw-bolder mb-0">Hi {{displayName}}</p>
      </div>
      <div class="col-12 mx-auto p-0 m-0 mb-2">
        <p class="fs-5 p-0 m-0">Thanks for signing up to <span class="fw-bold fs-4">PHOT<span class="text-highlight">AR</span></span></p> 
      </div>
    </div>
    <div class="row">
      <div class="col-12 mx-auto p-0 mb-3">
        <p class="fs-6 p-0 m-0 lh-0">Please validate your email to proceed</p>
      </div>
      <div class="col-12 mx-auto">
        <p class="small">Email Verification Status: <span class="ps-2 fw-normal text-highlight">{{emailVerified}}</span></p>
      </div>
      
    </div>
    <div class="row">
       <div class="mt-1 mb-1 col-12  mx-auto">
        <button type="button" class="btn btn-outline-highlight text-uppercase " :class='{"btn-sm": mobileView}' @click="resendVerify">Resend Verfication Email</button>          
      </div>                 
      <div class="col-12 mx-auto text-end">
        <p class="p-link small text-decoration-underline p-0 m-0 mt-1" @click.prevent="closeSubMod" >Cancel</p>                 
      </div>
    </div>
  </div>              
</template>

<script>
import getUser from "@/composables/getUser"
import sendVerEmail from "@/composables/sendVerEmail"
import { onMounted, ref } from "vue"

export default {
name: "VerifyUser",
emits: ["closeModalSub", "showAlertSub"],
setup(props, context){
    const { user } = getUser() 
    const displayName = ref(user.value.displayName)
    const emailVerified = ref(user.value.emailVerified)
    const { emailVerify, error:emailVerError} = sendVerEmail()

    let errorMessage = ref("")
    let errorArray = ref([])
    
    onMounted(() => {
      console.log("VERIFY.VUE > OnMounted")
      if(!user){
        "VERIFY.VUE > OnMounted > noUser"
        errorMessage.value = "Please Create an Account and Verify your email to proceed"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)
        context.emit("closeModalSub") 
      }
    })
    const resendVerify = async () => {
      // console.log("ADD IN EMAIL BOX FOR RESEND VERIFICATION, VALIDATE")
      await emailVerify()
      if (emailVerError.value != null){
        console.log("ERROR VERIFYING EMAIL ", emailVerError.value)
        errorMessage.value = emailVerError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)
        // context.emit("closeModalSub") 
      }
      else{
        errorMessage.value = "Verification Email has been resent"
        let alertLvl = 2
        errorArray.value.push(alertLvl)
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)    
        // context.emit("closeModalSub")
      }
    }

    const closeSubMod = () => {
      console.log("pModal>VerifyEmail>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
    
    
    return {resendVerify, closeSubMod, displayName, emailVerified }
}
}
</script>

<style>

</style>