<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="d-flex flex-row align-items-center bg-charcoal p-2 my-2" v-if="currSubscription.cancel_at_period_end">
          <p class="m-0 p-0 fs-6 text-white text-center text-wrap flex-fill">This subscription will cancel at the end of the period.</p>
        </div>      
        <div class="d-flex flex-row align-items-center " v-if="!upgrade">
          <p class="small fw-normal text-muted m-0 p-0 text-start">Billing Period Start:</p>
          <p class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill"> {{new Date(currSubscription.current_period_start.seconds * 1000).toLocaleDateString()}}</p>
        </div>
        <div class="d-flex flex-row align-items-center " v-if="!upgrade">
          <p class="small fw-normal text-muted m-0 p-0 text-start">Billing Period End:</p>
          <p class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill"> {{new Date(currSubscription.current_period_end.seconds * 1000).toLocaleDateString()}}</p>
        </div>
        <div v-if="!upgrade" class="pt-3 col-12 col-md-10 col-lg-8 mx-auto">
          <button type="button" 
            class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " 
            :class='{"btn-sm": mobileView}' 
            :disabled="isLoading"
            @click="openCustomerPortal"> {{ isLoading ? "Loading..." : " Billing Portal" }}</button>                
        </div>   
        <div v-else class="pt-3 col-12 col-md-10 col-lg-8 mx-auto">
          <button type="button" 
            class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " 
            :class='{"btn-sm": mobileView}' 
            :disabled="isLoading"
            @click="openCustomerPortal"> {{ isLoading ? "Loading..." : "Upgrade Options" }}</button>                
        </div>   
      </div>
    </div>
    <div v-if="isLoading">     
      <Spinner />
    </div> 
  </div>
  
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import {app} from "../firebase/config"
import {ref} from "vue"
import Spinner from "@/components/Spinner.vue"
export default {
  name: "SubscribedAccount",
  emits: ["showAlertSub"],
  props: ["currSubscription", "mobileView", "upgrade"],
  components: {Spinner},
  setup(props, context){
    console.log("SUBSCRIBED ACCOUNT.VUE - Setup - ", props.currSubscription)
    // const subs = ref(null)
    let errorMessage = ref("")
    let errorArray = ref([])
    const isLoading = ref(false)
    const cloudFunctionsDeploymentLocation = "europe-west2"; 
    const openCustomerPortal = async () => {
      console.log("SUBSCRIBED ACCOUNT.VUE - openCustomerPortal Clicked")
      isLoading.value = true
      try {
        const portFunctions = getFunctions(app, cloudFunctionsDeploymentLocation );
        const functionRef = httpsCallable(portFunctions, "ext-firestore-stripe-payments-createPortalLink");
        console.log("SUBSCRIBED ACCOUNT.VUE - openCustomerPortal Clicked > Got functionRef", functionRef)
        const { data } = await functionRef({returnUrl: window.location.origin,});
        console.log(" openCustomerPortal - data", data)
        if (data.url) {
        window.location.assign(data.url);
        }
      }
      catch (error) {
        const { code, details } = JSON.parse(JSON.stringify(error))
        errorMessage.value = code + details
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray) 
        return 
      }      
    }
    return{isLoading, openCustomerPortal}
  }
}
</script>
