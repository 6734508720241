import { ref } from "vue"
// firebase imports
import { db } from "../firebase/config"
import { doc, getDoc} from "firebase/firestore"
const error = ref(null)  

const useCheckDocument = async (c, docId) => {
  error.value = null
  let docRef = doc(db, c, docId)
  try {
    const docSnap = await getDoc(docRef)
    // console.log("User doc exists");
    error.value = null
    return docSnap
  }
  catch(err){
    // console.log("User doc not found");
    error.value = "User doc not found"
  } 
}   

const checkDocument = () => {
  return {useCheckDocument, error}
}

export default checkDocument