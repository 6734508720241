import { ref } from "vue"

// firebase imports
import { auth } from "../firebase/config"
import { sendPasswordResetEmail } from "firebase/auth"
const error = ref(null)
const isPending = ref(false)

const resetPW = async (email) => {
  error.value = null
  isPending.value = true
  try {
    const actionCodeSettings = {url: `${process.env.VUE_APP_HOST_NAME}`};
    const res = await sendPasswordResetEmail(auth, email, actionCodeSettings)    
    error.value = null
    isPending.value = false
    return res
  }
  catch(err) {
    // console.log(err.code)    
    switch (err.code) {
      case "auth/invalid-email": 
        error.value = "Invalid email"
        isPending.value = false
        break;
      case "auth/user-not-found": 
        error.value = "No account for that email was found"
        isPending.value = false
        break;      
      case "auth/user-disabled": 
        error.value = "This account has been disabled"
        isPending.value = false
        break;
      default: 
        error.value = "Unable to send password reset email"
        isPending.value = false
        break;
    }
  }
}

const useResetPassword = () => {
  return {error, resetPW, isPending}
}

export default useResetPassword