<template> 
<path d="M113.5,189V845a72,72,0,0,0,72,72h656a72,72,0,0,0,72-72h0V189a72,72,0,0,0-72-72h-656A72,72,0,0,0,113.5,189Zm600.14,11a45.27,45.27,0,0,1-6.6,63.68L410.68,504.31a19,19,0,0,0-.16,29.36l289,240a45.27,45.27,0,0,1-57.85,69.64L320,576.15a74.24,74.24,0,0,1-26.73-57.39v-.42a74.19,74.19,0,0,1,27.35-57.49L650,193.36a45.28,45.28,0,0,1,63.68,6.6Z"/>
</template>
<script>
export default {
  name: "IconPrev"
}
</script>
<style scoped>

</style>
