import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"

// firebase imports
import { auth } from "./firebase/config"
import { onAuthStateChanged } from "firebase/auth"

let app
// console.log("main.js before onAuthStateChanged ")
onAuthStateChanged(auth, () => {
  // console.log("main.js onAuthStateChanged")
  if(!app) {
    // console.log("main.js if no app")
    app = createApp(App).use(router).mount("#app")
  }    
})

import "bootstrap/dist/js/bootstrap.js"