import {ref } from "vue"
// firebase imports
import { db } from "../firebase/config"
import { addDoc, collection, onSnapshot} from "firebase/firestore"
const retError = ref(null)

const useOneTimePayment = async (uId, pId) => {
  retError.value = null
  let docPath = "users/" + uId + "/checkout_sessions/"  
  try {
    const docRef = await addDoc(collection(db, docPath), {
      mode: "payment",
      line_items: [
        {
          price: pId,
          quantity: 1         
        }
      ],
      success_url: "https://photar-site-beta.web.app/verify",
      cancel_url: "https://photar-site-beta.web.app/problem"
    })
    onSnapshot(docRef, snapshot => {
      const {error, url} = snapshot.data()
      if (error) {
        // Show an error to your customer
        console.log("onetime payment error", error.message)
        retError.value = error.message
        return error
      }
      if (url) {
        console.log("onetime payment have url", url)
        // We have a Stripe Checkout URL, let's redirect.
        retError.value = null        
        window.location.assign(url)
        return url
      }
    })
  } catch(err){
    console.log("Error while doing onetime payment")
    retError.value = "Error while doing onetime payment"
  }
}

const oneTimePayment = () => {
  return {useOneTimePayment, retError}  
}
export default oneTimePayment