<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 1024 1024"
    role="icon"
  >
    <g
      class="icon"
      :fill="isHover ? iconHoverColor : iconColor"    
      @mouseover="isHover = true;"
      @mouseout="isHover = false;"
    >
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-base",
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    },
    iconColor: {
      type: String,
      default: "currentColor"
    },
    iconHoverColor: {
      type: String,
      default: "currentColor"
    }
  },
  data() {
    return {
      isHover: false
    };
  }
};
</script>

<style scoped>
.icon {  
  transition: fill 0.4s ease;  
  -webkit-transition: fill .4s ease;
  -moz-transition: fill .4s ease;
  -ms-transition: fill .4s ease;
  -o-transition: fill .4s ease;
}
</style>