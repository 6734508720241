<template>
  <div>
    <div ref="addImageFormMod" class="addImageFormCont">
      <!-- SECTION 1 - NO SLIDE - TOP TEXT - SELECTED CLIENT & SHOOT -->    
      <div v-if="!store.getSelClientEmail || !store.getSelShootTitle" class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
        <p class="fs-6 fw-normal text-charcoal"><span class="fw-bold">A Client &amp; Shoot must be selected</span><br>Select a client and shoot from dashboard</p>
      </div>    
      <div v-if="store.getSelClientEmail && store.getSelShootTitle">
      <!-- SECTION 2 - NO SLIDE - FORM PROGRESS BAR -->
        <!-- ADD IMAGE PROGRESS -->
        <div class="col-12 col-sm-11 mx-auto p-0 m-0">
          <div class="form-steps row p-0 m-0">
            <div class="col-6 step text-center position-relative">
              <p class="fw-normal">Image</p>
              <div class="bullet">
                  <span>1</span>
              </div>
              <div class="check bi bi-check-circle-fill text-highlight"></div>
            </div>
            <div class="col-6 step text-center position-relative">
              <p class="fw-normal">Advice</p>
              <div class="bullet">
                  <span>2</span>
              </div>
              <div class="check bi bi-check-circle-fill text-highlight"></div>
            </div>        
          </div>
        </div>
      <!-- SECTION 3 - SLIDE SECTION -->
      <!-- SECTION 3A - SELECT & UPLOAD IMAGE SECTION -->
        <div  class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper">
          <div class="form-container mt-2">
            <!-- ADD IMAGE - PAGE 1 -->
            <div ref="selImg" class="page slide-page">
              <form class="needs-validation selectImageform" id="selectImageform" novalidate @submit.prevent="handleSelectImage">
                <div class="mb-3 pt-3 mx-auto text-center">
                  <label class="img-upload-lbl w-100 "> 
                    <input ref="firstFocus" type="file" @change="handleChange" class="img-upload-input">
                    <span v-if="file || gotImage" class="btn btn-outline-highlight rounded-pill text-highlight text-uppercase shadow-sm px-3 w-100" :class='{"btn-sm": mobileView}'>Change Image</span>
                    <span v-else class="btn btn-highlight rounded-pill text-white text-uppercase shadow-sm px-3 w-100" :class='{"btn-sm": mobileView}'>Select Image</span>
                  </label>                
                </div>
                <div v-show="!gotImage" class="mb-2 pt-1 text-truncate">
                  <div class="col-12 my-2 img-upload-out text-center">
                    <p v-if="fileError" class="fs-6 text-charcoal lh-sm text-truncate">{{ fileError }}</p>
                    <p v-else-if="file" class="fs-6 text-charcoal lh-sm text-truncate" >{{ file.name }}</p>                       
                    <p v-else class="small text-muted text-charcoal lh-sm text-truncate " >No Image Selected</p>
                  </div>
                </div>       
                <div v-if="imageSelected && !gotImage" class="mt-2 mb-3 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
                  <button type="submit" v-if="!isPending" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>Upload This File</button> 
                  <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' disabled>Uploading</button> 
                </div>
                <div v-else-if="!imageSelected && !imgHolder" class="mt-2 mb-3 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
                  <button class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' disabled>Upload Image</button>               
                </div>
                <div v-if="uploading" class="mb-2 col-10 mx-auto">
                  <ProgressBar v-if="file" :file="file" :id="uid" :cid="cid" :sid="sid" @complete="file = null, gotImage = true, createProgress.imageUploaded = true, uploading = false, inProgress = false" @urlLink="getUrl"  /> 
                </div>
                <div v-show="gotImage" class="mt-2 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
                  <img :src="imgHolder" class="img-fluid w-100 max-vh-75"> 
                </div>
                <div v-show="gotImage" class="mt-2 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
                  <button type="button" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm firstNext next" :class='{"btn-sm": mobileView}'>Continue</button> 
                </div>
              </form>
            </div>
              <!-- ADD IMAGE - PAGE 2 -->        
            <div ref="addRec" class="page">
              <div class="col-12 col-lg-11 mx-auto p-0 m-0">
                <form class="needs-validation" id="addimageform" novalidate @submit.prevent="handleAddRecommendations">              
                  <div class="mb-2" @click="assignFocus('imageTitle')">
                    <label for="imageTitle" class="form-label sUpLabel" >Image Title *</label>
                    <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                      <input type="text" class="form-control" id="imageTitle" placeholder="Image Title" required v-model="imageTitle" autocomplete="off">
                      <div class="invalid-tooltip">
                        Please provide a valid Title at least 4 chars
                      </div>
                    </div>
                  </div>
                  <div class="mb-1 pt-2 text-start">
                    <p class="form-label sUpLabel">Recommendations</p> 
                  </div>
                  <div class="mb-2">
                    <div class="row mx-auto">
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" name="canvas" id="canvas" value="canvas" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Canvas</span>
                          </label>
                        </div>
                      </div>
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="aluminium" id="aluminium" value="aluminium" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Aluminium</span>
                          </label>
                        </div>
                      </div>              
                    </div>
                    <div class="row mx-auto">
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="framed" id="framed" value="framed" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Framed</span>
                          </label>
                        </div>
                      </div>
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="other" id="other" value="other" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Other Product</span>
                          </label>
                        </div>
                      </div>              
                    </div>           
                    <div class="row mx-auto">
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="collection3" value="collection3" id="collection3" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 3</span>
                          </label>
                        </div>
                      </div>
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="collection4" value="collection4" id="collection4" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 4</span>
                          </label>
                        </div>
                      </div>              
                    </div>
                    <div class="row mx-auto">
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="collection5" value="collection5" id="collection5" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Collection of 5</span>
                          </label>
                        </div>
                      </div>
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="blackwhite" id="blackwhite" value="blackwhite" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Black &amp; White</span>
                          </label>
                        </div>
                      </div>              
                    </div>
                    <div class="row mx-auto">
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="colour" id="colour" value="colour" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Colour</span>
                          </label>
                        </div>
                      </div>
                      <div class="col text-truncate">
                        <div class="form-check" :class='{"check-form-sm": mobileView}'>
                          <label class="form-check-label">
                          <input type="checkbox" class="form-check-input " name="seeNotes" value="seeNotes" id="seeNotes" v-model="recommendations" :class='{"check-box-sm": mobileView}'>
                          <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>See Notes</span>
                          </label>
                        </div>
                      </div>              
                    </div>
                  </div>            
                  <div class="mb-2 col-12 col-md-11 mx-auto" @click="assignFocus('imageNotes')">
                    <label for="imageNotes" class="form-label sUpLabel" >Additional Notes</label>
                    <div class="input-group" :class='{"input-group-sm": mobileView}'>
                      <textarea type="text" class="form-control" id="imageNotes" placeholder="Additional Notes" v-model="imageNotes" autocomplete="off" />
                    </div>
                  </div>
                   <div class="mt-4 pt-2 col-12 col-md-10 col-lg-8 mx-auto">
                    <button v-if="!isPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Save Image</button> 
                    <button v-else class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' disabled>Saving</button> 
                  </div>
                  <div class="mt-2 mb-3 col-12 col-md-10 col-lg-8 mx-auto">
                    <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm prev-1 prev" :class='{"btn-sm": mobileView}'>Back</button>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-3 mb-3 col-12 col-sm-11 mx-auto">
        <button type="button" @click="closeSubMod" class="btn btn-outline-highlight text-highlight text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Dashboard</button>
      </div>
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>

<script>
import {validateImageTitle} from "../../../public/js/validation.js"
import Spinner from "@/components/Spinner.vue"
import { ref, watchEffect, onBeforeUnmount, onUnmounted, computed, watch} from "vue"
import ProgressBar from "@/components/ProgressBar.vue"
import addDocument from "@/composables/addDocument"
import updateDocument from "@/composables/updateDocument"
import useDeleteImage from "@/composables/delImage"
import { useStore } from "@/composables/store/store"
import { getFunctions, httpsCallable } from "firebase/functions"
import { doc, deleteDoc, updateDoc, arrayRemove, Timestamp} from "firebase/firestore";
import { db } from "@/firebase/config"
export default {
  name: "AddImageModContent",
  emits: ["closeModalSub", "showAlertSub", "switchModalDashData"],
  props: ["mobileView"],
  components: {Spinner, ProgressBar},
  setup(props, context){
    let store = useStore();
    let addImageFormMod = ref(null)
    let selImg = ref(null)
    let addRec = ref(null)
    let firstFocus = ref(null)   

    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)

    let uid = ref(store.getUserId.value)
    let cid = ref(store.getSelClient.value)
    let sid = ref(store.getSelShoot.value)
    const { useAddDocument, error:addImageDocError, isPending:addImageDocPending } = addDocument()
    const { useUpdateDocument, error:updateImageDocError, isPending:updateImageDocPending } = updateDocument()
    // const { useDeleteImage  } = delImage()

    let isPending = computed(() => {
      if (addImageDocPending.value == true || updateImageDocPending.value == true || inProgress.value == true) 
      {
        return true
      }
      else {
        return false
      }
    })

    let createProgress = {
        imageUploaded: false,
        createImageDoc: false,
        increaseUserImageCount: false,
        increaseClientImageCount: false,
        increaseShootImageCount: false,
        updateImageDoc: false
      }

    let imageCreated = computed(() => {
        if(createProgress.imageUploaded == true && createProgress.createImageDoc == true && createProgress.increaseUserImageCount == true && createProgress.increaseClientImageCount == true && createProgress.increaseShootImageCount == true && createProgress.updateImageDoc == true){
          return true
        }
        else {
          return false
        }
      })

    let baseUserImageCount = store.getImageCount.value
    let baseClientImageCount = store.getSelClientNumImages.value
    let baseShootImageCount = store.getSelShootNumImages.value

     const clearVars = () => {
        console.log("Running Addimage clearVars")
        addImageFormMod.value = null
        firstFocus.value = null
        selImg.value = null
        addRec.value = null
        errorMessage.value = ""
        errorArray.value = []
        inProgress.value = false
        createProgress.imageUploaded = false,
        createProgress.createImageDoc = false,
        createProgress.increaseUserImageCount = false,
        createProgress.increaseClientImageCount = false,
        createProgress.increaseShootImageCount = false,
        createProgress.updateImageDoc = false
        slidePage.value = null
        nextBtnFirst.value = null
        prevBtnSec.value = null
        progressText.value = null
        progressCheck.value = null
        bullet.value = null
        bulletSpan.value = null
        current.value = 0
        currentSet.value = false
        submitBtn.value = null
        imageTitle.value = null
        imageNotes.value = null
        recommendations.value = []       
        newImageID.value = ""
        newImageTitle.value = ""  
        fileName.value = null
        fileError.value = null
        file.value = null
        imgHolder.value = null
        imgWidth.value = 0
        imgHeight.value = 0
        gotImage.value = false
        imageSelected.value = false
        uploading.value = false
        url.value = ""
        // removeCurrent()
        // removeActive() 
      }

    // Add Image page progress bar vars
    let slidePage = ref(null)
    let nextBtnFirst = ref(null)
    let prevBtnSec = ref(null)
    let progressText = ref(null)
    let progressCheck = ref(null)
    let bullet = ref(null)
    let bulletSpan = ref(null)
    let current = ref(0)
    let currentSet = ref(false)
    let submitBtn = ref(null)

    const addCurrent = () => {
      console.log("RUNNING ADDCURRENT - ", current.value)
      bullet.value[current.value].classList.add("current");
      bulletSpan.value[current.value].classList.add("current");
      progressCheck.value[current.value].classList.add("current");
      progressText.value[current.value].classList.add("current");
    }
    const removeCurrent = () => {
      console.log("RUNNING REMOVECURRENT - ", current.value)
      bullet.value[current.value].classList.remove("current");
      bulletSpan.value[current.value].classList.remove("current");
      progressCheck.value[current.value].classList.remove("current");
      progressText.value[current.value].classList.remove("current");
    }
    const addActive = () => {
      console.log("RUNNING ADDACTIVE - ", current.value)
      bullet.value[current.value].classList.add("active");
      progressCheck.value[current.value].classList.add("active");
      progressText.value[current.value].classList.add("active");
    }
    const removeActive = () => {
      console.log("RUNNING REMOVEACTIVE - ", current.value)
      bullet.value[current.value].classList.remove("active");
      progressCheck.value[current.value].classList.remove("active");
      progressText.value[current.value].classList.remove("active");
    }


    //Add Image vars
    let imageTitle = ref(null)
    let imageNotes = ref(null)
    let recommendations = ref([])

    let newImageID = ref("")   
    let newImageTitle = ref("")   
    let fileName = ref(null)
    const fileError = ref(null)

    let file = ref(null)   
    let imgHolder = ref(null)
    let imgWidth = ref(0)
    let imgHeight = ref(0)

    let gotImage = ref(false) 
    let imageSelected = ref(false)
    let uploading = ref(false) 
    let url = ref("")

    let resizedUrl = computed(() => {
      console.log("Getting Resized URL", url.value)
      let dimensions = "500x500"
      if(url.value){
        let extIndex = url.value.lastIndexOf(".")
        let ext = url.value.substring(extIndex)
        let newPath = `${url.value.substring(0, extIndex)}_${dimensions}${ext}`
        console.log("newPath", newPath)
        return newPath
      }
      else {
        console.log("Resized Url Fail")
        return "N/A"
      }
    })
    
    

    //allowed filetypes
    let types = ["image/png", "image/jpeg"]
    let maxSize = 26214400

    let imageCreationDate = computed(() => {
      let test = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
      return test
    })

    // watch file upload
    let stopWatch = watch(() => file.value, () => {   
      if (file.value != null){         
        imageSelected.value = true
      }     
      else {        
        imageSelected.value = false
      }
    })

    let stopAddImageSubWatch = watchEffect(() => {
      console.log("watchEffect - stopAddImageSubWatch")
      if (firstFocus.value) {
        console.log("watchEffect - firstFocus.value - ", firstFocus.value)
        firstFocus.value.focus()
      }
      if (addImageFormMod.value) {  
        console.log("watchEffect - addImageFormMod.value - ", addImageFormMod.value)
        if(addImageFormMod.value.children.length > 0){
          //MULTI PAGE FORM CONTROLS
          //SELECTORS
          if(currentSet.value != true)
          {
            slidePage.value = addImageFormMod.value.querySelector(".slide-page")
            nextBtnFirst.value = addImageFormMod.value.querySelector(".firstNext")
            prevBtnSec.value = addImageFormMod.value.querySelector(".prev-1")            
            progressText.value = addImageFormMod.value.querySelectorAll(".step p")
            progressCheck.value = addImageFormMod.value.querySelectorAll(".step .check")
            bullet.value = addImageFormMod.value.querySelectorAll(".step .bullet")
            bulletSpan.value = addImageFormMod.value.querySelectorAll(".step .bullet span")
            submitBtn.value = addImageFormMod.value.querySelector(".submit")
            removeActive()
            addCurrent()
            //LISTENERS
            console.log("nextBtnFirst.value  - ", nextBtnFirst.value )
            nextBtnFirst.value.addEventListener("click", function(event){
              console.log("Image Uploaded Clicked ")
              event.preventDefault();
              if(gotImage.value == true){
                removeCurrent()
                addActive()
                slidePage.value.style.marginLeft = "-50%";
                current.value += 1;
                addCurrent()
              }
              else{
                let alertLvl = 1
                errorArray.value.push(alertLvl)
                errorMessage.value = "Please upload an image to proceed"
                errorArray.value.push(errorMessage.value)  
                context.emit("showAlertSub", errorArray)
              }              
            });            
            prevBtnSec.value.addEventListener("click", function(event){
              event.preventDefault();
               removeCurrent()
                slidePage.value.style.marginLeft = "0%";                
                current.value -= 1;
                removeActive()
                addCurrent()
            });
            //MULTIPAGE FORM VALIDATION START
            // PAGE 2
            addImageFormMod.value.querySelector("#imageTitle").addEventListener("blur", validateImageTitle)
            currentSet.value = true
          }
        }
      }
    })

    const cleanUp = async () => {
      errorMessage.value = ""
      errorArray.value = []
      // console.log("Running AddImage Cleanup")
      // console.log("imageUploaded - ", createProgress.imageUploaded)        
      // console.log("createImageDoc - ", createProgress.createImageDoc)        
      // console.log("increaseUserImageCount - ", createProgress.increaseUserImageCount)
      // console.log("increaseClientImageCount - ", createProgress.increaseClientImageCount)
      // console.log("increaseShootImageCount - ", createProgress.increaseShootImageCount)
      // console.log("updateImageDoc - ", createProgress.updateImageDoc)
      // console.log("baseUserImageCount - ", baseUserImageCount)
      // console.log("baseClientImageCount - ", baseClientImageCount)
      // console.log("baseShootImageCount - ", baseShootImageCount)
      // console.log("shootId - ", newSid.value)
      // console.log("clientId - ", store.getSelClient.value)
      // console.log("userId - ", store.getUserId.value)
      // console.log("userShoot Count - ", store.getShootCount.value)
      // console.log("clientShoot Count - ", store.getSelClientNumShoots.value)

      // REMOVE IMAGES
      if(createProgress.imageUploaded){
        let fileUrl = url.value
        const { errorArr:imgDelError} = await useDeleteImage(fileUrl)
        if (imgDelError.length > 0) {
          errorMessage.value = "Error deleting image"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
        }
        console.log("image Deleted - ",fileUrl)
        let rfileUrl = resizedUrl.value
        const { errorArr:rszImgDelError} = await useDeleteImage(rfileUrl)
        if (rszImgDelError.length > 0) {
          errorMessage.value = "Error deleting resized images"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)   
        }
        console.log("resized image Deleted - ", rfileUrl)
      }
      // Image Doc Fail
      if(newImageID.value != null){
          await deleteDoc(doc(db, "images", newImageID.value));
          console.log("Doc Deleted - ", newImageID.value)
      }
      // Increase User Images Fail
      if(createProgress.increaseUserImageCount){
        console.log("userCount userId- ", store.getUserId.value)
        const userRef = doc(db, "users", store.getUserId.value )
        await updateDoc(userRef, {"images.numberImages": baseUserImageCount})
        await updateDoc(userRef, {"images.imageIDs": arrayRemove(newImageID.value)})
        console.log("User Count Fixed & ID Removed")
      }
      if(createProgress.increaseClientImageCount){
        console.log("clientCount clientId- ", store.getSelClient.value) 
        const clientRef = doc(db, "clients", store.getSelClient.value )
        await updateDoc(clientRef, {"images.numberImages": baseClientImageCount})
        await updateDoc(clientRef, {"images.imageIDs": arrayRemove(newImageID.value)})
        console.log("Client Count Fixed & ID Removed")
      }
      if(createProgress.increaseShootImageCount){
        console.log("shootCount shootId- ", store.getSelShoot.value) 
        const shootRef = doc(db, "shoots", store.getSelShoot.value )
        await updateDoc(shootRef, {"images.numberImages": baseShootImageCount})
        await updateDoc(shootRef, {"images.imageIDs": arrayRemove(newImageID.value)})
        console.log("Shoot Count Fixed & ID Removed")
      }
      // removeCurrent()
      // removeActive() 
      context.emit("closeModalSub")
    }


    onBeforeUnmount(() => {  
      nextBtnFirst.value.removeEventListener("click", function () {})
      prevBtnSec.value.removeEventListener("click", function () {})
      addImageFormMod.value.querySelector("#imageTitle").removeEventListener("blur", validateImageTitle)   
      removeCurrent()
      removeActive()          
    })

    onUnmounted(() => {
      console.log("pModal>AddImage - onUnmounted")
      clearVars()
      stopAddImageSubWatch()
      stopWatch()
    })    

    const assignFocus = (focusArea) => {
      console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = addImageFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    const addImageSuccess = () => {
        console.log("pModal>AddImage>Success-Continue - switchModalDashData")
        let subtitle = `${newImageTitle.value} has been added`
        let message2 = "Do you wish to add another image?"
        let modData = {title: "Success", area:"Image", message1: subtitle, message2: message2, cid:store.getSelClient.value, cEmail:store.getSelClientEmail.value, cNumImages:store.getSelClientNumImages.value, cNumOrders:store.getSelClientNumOrders.value,
        sid:store.getSelShoot.value, sTitle:store.getSelShootTitle.value, sNumImages:store.getSelShootNumImages.value, sNumColl:store.getSelShootNumCollections.value, sNumOrders:store.getSelShootNumOrders.value}
        context.emit("switchModalDashData", modData )
      }

    const handleChange = async (e) => {
      console.log("handleChange ")
      //Delete old image
      if(imgHolder.value != null){
        imgHolder.value = null
      }
      if(gotImage.value == true){
        let fileUrl = [url.value]
        const { errorArr:imgDelError} = await useDeleteImage(fileUrl)
        if (imgDelError.length > 0) {
          errorMessage.value = "Error deleting image"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
        }
        console.log("image Deleted - ",fileUrl)
        if(resizedUrl.value){
          let rfileUrl = [resizedUrl.value]
          const { errorArr:rszImgDelError} = await useDeleteImage(rfileUrl)
          if (rszImgDelError > 0) {
            errorMessage.value = "Error deleting resized images"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)   
          }
          console.log("resized image Deleted - ", rfileUrl)
        }
        createProgress.imageUploaded = false
        gotImage.value = false
      }

      imageTitle.value = null
      imageNotes.value = null
      recommendations.value = []
      newImageID.value = null
      newImageTitle.value = null
      fileName.value = null
      fileError.value = null
      file.value = null
      imgHolder.value = null
      imgWidth.value = null
      imgHeight.value = null
      gotImage.value = false
      imageSelected.value = false
      uploading.value = false
      url.value = ""
      // let form = selImg.value.querySelector(".needs-validation")
      let selected = e.target.files[0]
      if (selected && types.includes(selected.type) && selected.size < maxSize) {
        fileName.value = selected.name        
        file.value = selected
        let reader = new FileReader();
        reader.readAsDataURL(selected);
        reader.onload = e => {
          let src = e.target.result;
          let img = new Image();
            img.onload = () => {             
              imgWidth.value = img.width
              imgHeight.value = img.height
            };            
            img.src = src;
        };
        if(file.value != null){
          imgHolder.value = URL.createObjectURL(file.value)
        }
        fileError.value = null
        // console.log("file ", file)
      } else {
        file.value = null
        imgHolder.value = null
        fileError.value = "Please select a JPG or PNG file that is under 25Mb"        
      }
    }

    // handle image upload - use progress bar
    const handleSelectImage = async () => {      
      if (file.value) {
        inProgress.value = true
        uploading.value = true   
    }}
    
    // get url of image from progress bar
    let getUrl = (payload) => {
      url.value = payload.link.value
      // console.log("url value", url.value)
      inProgress.value = false
      uploading.value = false         
    } 

    const handleAddRecommendations = async () => {
      console.log("handleAddRecommendations")
      let form = addRec.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateImageTitle){
        errorMessage.value = "Error Adding Recommendations - Please recheck form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)
      }
      else {
        form.classList.add("was-validated")
        inProgress.value = true
        let functions = getFunctions()
        try {
          let imageInfo = {
            userId: store.getUserId.value,            
            clientId: store.getSelClient.value,
            shootId: store.getSelShoot.value,
            imageTitle: imageTitle.value,
            imageWidth: imgWidth.value, 
            imageHeight: imgHeight.value,
            imageRecommendations: recommendations.value,
            imageNotes: imageNotes.value,
            imageUrl: url.value,
            resizedImageUrl: resizedUrl.value,
            imageFileName: fileName.value,            
            imageCreationDate: imageCreationDate.value
          }   
          console.log("imageInfo - ", imageInfo)
          //assign image details to firestore
          let imageDocResult = await useAddDocument("images", imageInfo)
          if (addImageDocError.value != null) {
            createProgress.createImageDoc = false
            errorMessage.value = "Error creating image document"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return   
          }  
          else{
            createProgress.createImageDoc = true
            console.log("imageDocRes - ", imageDocResult.id)
          }
          let imageIdDetails = { imageId: imageDocResult.id }
          newImageID.value = imageDocResult.id
          newImageTitle.value = imageTitle.value  
          //increment user number images
          try{
             const increaseUserImageCount = httpsCallable(functions, "incrementNumImagesUser") 
             const res = await increaseUserImageCount({uid:store.getUserId.value, iid:newImageID.value}) 
             console.log("res - ", res)
             createProgress.increaseUserImageCount = true
          } catch (error){
            createProgress.increaseUserImageCount = false
            errorMessage.value = "Error Occurred While Increasing Users Image Count"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return                         
          }
          //increment client number images
          try{
             const increaseClientImageCount = httpsCallable(functions, "incrementNumImagesClient") 
             const res1 = await increaseClientImageCount({cid:store.getSelClient.value, iid:newImageID.value}) 
             console.log("res1 - ", res1)
             createProgress.increaseClientImageCount = true
          } catch (error){
            createProgress.increaseClientImageCount = false
            errorMessage.value = "Error Occurred While Increasing Clients Image Count"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return                         
          }
          //increment shoot number images
          try{
             const increaseShootImageCount = httpsCallable(functions, "incrementNumImagesShoot") 
             const res2 = await increaseShootImageCount({sid:store.getSelShoot.value, iid:newImageID.value}) 
             console.log("res2 - ", res2)
             createProgress.increaseShootImageCount = true
          } catch (error){
            createProgress.increaseShootImageCount = false
            errorMessage.value = "Error Occurred While Increasing Shoot Image Count"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            cleanUp()
            return                         
          }
          //add image id to image document 
          await useUpdateDocument("images", newImageID.value, imageIdDetails)
          if (updateImageDocError.value != null){
            createProgress.updateImageDoc = false
            errorMessage.value = "Error Occurred While Updating Image Details"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value)            
            form.classList.remove("was-validated")
            inProgress.value = false         
            context.emit("showAlertSub", errorArray) 
            cleanUp()
            return         
          }
          else{
            createProgress.updateImageDoc = true
          }
          inProgress.value = false
          if(imageCreated.value == true){
            errorMessage.value = "Image successfully added"
            let alertLvl = 2
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value)
            context.emit("showAlertSub", errorArray)  
            addImageSuccess()
           }   
           else{
            errorMessage.value = "An Error Occurred"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false
            cleanUp()
           }           
        }
        catch(error){
          const { code, details } = JSON.parse(JSON.stringify(error))
          errorMessage.value = code + details
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUp()
          return
        }
      }
    }

    return{handleChange, handleSelectImage, handleAddRecommendations, imageTitle, imageNotes, recommendations, firstFocus, assignFocus, inProgress, uploading, gotImage, isPending, store, file, fileError, imageSelected, imgHolder, selImg, uid, cid, sid, getUrl, addImageFormMod, addRec, createProgress }

  }

}
</script>

<style scoped>
/* FILE UPLOAD */

.check-label-sm {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.check-box-sm {
  margin-top: 0.3rem; 
  width: 1.2rem;
  height: 1.2rem;
  margin-left: -1.6rem
}
.check-form-sm {
  margin-bottom: 0;
  min-height: 1.4rem;
  padding-left: 1rem;
}
.img-upload-input{
  height: 0;
  width: 0;
  opacity: 0;
}

.img-upload-input textarea{   
  padding: 0;
  margin: 0
}

.img-upload-out{
  height: 40px;
  font-size: 1.2rem;
}

/* FORM SLIDE */
 ::selection{
    color: #fff;
    background: #00A4B0;
  }

.addImageFormCont .form-wrapper{
    /* width: 100%; */
    overflow: hidden;
  }

.addImageFormCont .form-wrapper .form-container{
    display: flex;
    width: 200%;
    /* background-color: blue; */
  }

.form-container .page{
    width: 50%;
    transition: margin-left 0.3s ease-in-out;
    padding: 0 1rem;
  }

  .addImageFormMod .form-steps{
    display: flex;
    margin: 0.25rem 0;
    user-select: none;
  }

  .sUpLabel{
    font-size: 1rem !important;
    color: #3D3B3A !important;
  }

  .addImageFormCont .form-steps .step p{
    font-weight: 600;
    font-size: 1.1rem;
    color: #3d3b3a;
    margin-bottom: 0.125rem;
  }

   /* CURRENT */
  .addImageFormCont .form-steps .step p.current{
    font-weight: 800;
    color: #00A4B0;
  }

  .form-steps .step .bullet.current{   
    border: 0.125rem solid #00A4B0;
    background-color: #00A4B0;   
  }

  .form-steps .step .bullet span.current{
    color: #FFF;    
  }

  .form-steps .step .bullet{
    height: 2.25rem;
    width: 2.25rem;
    border: 0.125rem solid #bbc4c8;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
  }

  .form-steps .step .bullet.active{
    border-color: #00757D;
  }

  .form-steps .step .bullet span{
    position: absolute;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Old Standard TT', serif;
    color: #bbc4c8;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-steps .step .bullet.active span{
    display: none;
  }

  .form-steps .step .bullet:before,
  .form-steps .step .bullet:after{
    position: absolute;
    content: '';
    bottom: .95rem;
    height: 0.125rem;    
    background: #bbc4c8;
  }

  @media only screen and (max-width:575px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{      
      right: calc(32vw * -1 );     
      width: calc(31vw);
    }
  }

  @media only screen and (max-width:767px) and (min-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.7rem;
      width: 9rem;     
    }
  }
  @media only screen and (min-width:768px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.4rem;
      width: 9rem;
    }
  }

  .form-steps .step .bullet.active:after{
    background: #00A4B0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
  }

  @keyframes animate {
    100%{
      transform: scaleX(1);
    }
  }

  .form-steps .step:last-child .bullet:before,
  .form-steps .step:last-child .bullet:after{
    display: none;
  }

  .form-steps .step p.active{
    color: #00A4B0;
    transition: 0.2s linear;
  }

  .form-steps .step .check{
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  .form-steps .step .check.active{
    display: block;
    color: #fff;
  }

  .check.bi::before {
    font-size: 2.25rem;
    line-height: 0.7;
    vertical-align: none;  
  }  


</style>