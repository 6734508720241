<template>
  <div ref="vidHolder">
    <div class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
      <p class="fs-5 fw-normal text-charcoal">{{modData.message}}</p>                  
    </div>
    <div id="vidHolder" class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
      <div class="d-flex">
        <YouTube 
        :src="source"
        :width=vWidth   
        :height=vHeight   
        @ready="onReady"
        ref="youtube" />        
      </div>                     
    </div>   
    <div class="mt-3 mb-3 col-12 col-sm-11 mx-auto">
      <p class="p-link text-center small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>             
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watchEffect, onBeforeUnmount, onUnmounted } from "vue"
import YouTube from "vue3-youtube"
export default {
  name: "PromoVideoModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "switchModalDashData"],
  components: {YouTube},
  setup(props, context){
    console.log("pModal>Promo - Test-", props.modData)
  
    let youtube = ref(null)
    let vidHolder = ref(null)
    let source = ref("null")
    let vWidth = ref(null)
    let vHeight = ref(null)

    onMounted(() => {
      source.value = props.modData.videoUrl
    })

    const stopHelpModWatch = watchEffect(() => {
      if(vidHolder.value){
        if(vidHolder.value.children.length > 0) { 
          let vContainer = vidHolder.value.querySelector("#vidHolder")
          vWidth.value = vContainer.offsetWidth
          vHeight.value = vContainer.offsetWidth * 0.5625         
        }
      }
    })
    
    onBeforeUnmount(() => {})

    onUnmounted(() => {
      console.log("pModal>Help - onUnmounted")
      stopHelpModWatch()
    })


    function onReady() {
      youtube.value.playVideo()
    }
    let closeSubMod = () => {
      console.log("pModal>Help>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
   
    return{closeSubMod, onReady, youtube, source, vidHolder, vWidth, vHeight}
  }
}
</script>

<style scoped>
  .video-holder {
  min-height: 18vh;
  width: 100%;
  aspect-ratio: 16/9;
}
</style>