<template>
  <div>
    <div v-if='orientation === "portrait-primary" || orientation === "portrait-secondary"'>
      <!-- phone portrait <576px -->
      <div class="container-fluid d-block d-sm-none p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" style="z-index:10 !important; min-height:100vh;">
          <div class="p-0 m-0">
            <div class="d-flex flex-row align-content-center justify-content-evenly">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 35vw;" class="ms-1 mt-1">
              <p @click="toggleTestmode" class="h1 text-end fs-6 text-white text-uppercase text-decoration-none pe-2 pt-3">Launching Soon</p>
            </div>
          </div>
          <div class="p-0 m-0 pt-2 ">
            <p class="h1 text-center text-highlight text-uppercase text-decoration-none display-3 fw-bold p-0 m-0 lh-1">only selling <br>digital files?</p>
          </div>
          <div class="p-0 m-0 mt-2">
            <p class="h1 text-center text-white text-decoration-none text-capitalize fs-5 lh-sm py-1 m-0">Increase after sales with<br> <span class="fw-bold"> augmented reality</span></p>
          </div>
          <div class="p-0 m-0 mt-3 mb-3 flex-fill">         
            <div class="col-10 p-0 m-0 mx-auto">        
              <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-1" :class='{"btn-sm": mobileView}' @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
            </div>
          </div> 
          <div class="p-0 m-0 mt-3">        
            <div class="col-8 mx-auto mt-auto text-center position-relative"> 
              <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 1; width:60%; height:auto; left:19%; top:4%"/>           
              <Transition name="fade-slide" mode="out-in">
                <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
              </Transition>
              <img :src="require('../assets/images/New/Hand.png')" alt="" class="img-fluid align-bottom position-relative" style="z-index: 25; ">
            </div>
          </div>       
          <div class="col-12 bg-charcoal p-0 m-0 mx-auto text-center" style="z-index:5">
            <button type="button" class="w-75 btn btn-charcoal text-uppercase text-whitep-2 " :class='{"btn-sm": mobileView}' @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
          </div>       
          <div class="p-0 mt-auto bg-charcoal">
            <div class="col-8 mx-auto d-flex flex-row align-items-center justify-content-evenly">
              <p class="display-6 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
            </div>
          </div>       
        </div>
      </div>
      <!-- phone portrait 576px > 767px -->
      <div class="container-fluid d-none d-sm-block d-md-none p-0 m-0 bg-img" >
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="p-0 m-0">
            <div class="d-flex flex-row align-content-center justify-content-start">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 25vw;" class="ms-1 mt-1">
              <p @click="toggleTestmode" class="flex-fill h1 text-end display-6 fw-normal text-white text-uppercase text-decoration-none pe-2 pt-3">Launching Soon</p>
            </div>
          </div>
          <div class="p-0 m-0 pt-3 ">
            <p class="h1 text-center text-highlight text-uppercase text-decoration-none display-3 fw-bold p-0 m-0 lh-1">fed up of only <br> selling digital files?</p>
          </div>
          <div class="p-0 m-0 mt-2">
            <p class="h1 text-center text-white text-decoration-none text-capitalize fs-4 lh-sm py-1 m-0">Increase after sales with<span class="fw-bold"> augmented reality</span></p>
          </div>
          <div class="p-0 m-0 mt-3 mb-3 flex-fill">         
            <div class="col-10 mx-auto d-flex flex-row align-content-center justify-content-between gap-3">        
              <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2" @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
              <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2"  @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
            </div>
          </div> 
          <div class="p-0 m-0 mt-3">
            <!-- <phone-slide-show /> -->
            <div class="col-6 mx-auto mt-auto text-center position-relative"> 
              <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 1; width:60%; height:auto; left:19%; top:4%"/>           
              <Transition name="fade-slide" mode="out-in">
                <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
              </Transition>
              <img :src="require('../assets/images/New/Hand.png')" alt="" class="img-fluid align-bottom position-relative" style="z-index: 25; ">
            </div>
          </div>
          <div class="p-0 mt-auto bg-charcoal">
            <div class="col-8 mx-auto d-flex flex-row align-items-center justify-content-evenly">
              <p class="display-6 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
            </div>
          </div>       
        </div>
      </div>
      <!-- phone portrait 768px > 991px -->
      <div class="container-fluid d-none d-md-block d-lg-none p-0 m-0 bg-img" >
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="p-0 m-0">
            <div class="d-flex flex-row align-content-center justify-content-start">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 25vw;" class="ms-1 mt-1">
              <p @click="toggleTestmode" class="flex-fill h1 text-end display-5 fw-normal text-white text-uppercase text-decoration-none pe-4 pt-3">Launching Soon</p>
            </div>
          </div>
          <div class="p-0 m-0 pt-3 ">
            <p class="h1 text-center text-highlight text-uppercase text-decoration-none display-2 fw-bolder p-0 m-0 lh-1">fed up of only <br>selling digital files?</p>
          </div>
          <div class="p-0 m-0 mt-2">
            <p class="h1 text-center text-white text-decoration-none text-capitalize fs-2 lh-sm fw-bold py-1 m-0">Increase after sales with augmented reality</p>
          </div>
          <div class="p-0 m-0 mt-3 mb-3 flex-fill">         
            <div class="col-10 mx-auto d-flex flex-row align-content-center justify-content-between gap-3">        
              <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2 fs-4 " @click="mailingList" ><i class="bi bi-envelope-open-fill pe-4"></i>Stay in touch</button>
              <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2 fs-4"  @click="promoVid" ><i class="bi bi-play-circle-fill pe-4"></i>PHOTAR In Action</button>
            </div>
          </div> 
          <div class="p-0 m-0 mt-3">
            <!-- <phone-slide-show /> -->
            <div class="col-6 mx-auto mt-auto text-center position-relative"> 
              <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 1; width:60%; height:auto; left:19%; top:4%"/>           
              <Transition name="fade-slide" mode="out-in">
                <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
                <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute img-fluid" style="z-index: 2; width:60%; height:auto; left:19%; top:4%"/>
              </Transition>
              <img :src="require('../assets/images/New/Hand.png')" alt="" class="img-fluid align-bottom position-relative" style="z-index: 25; ">
            </div>
          </div>
          <div class="p-0 mt-auto bg-charcoal">
            <div class="col-8 mx-auto d-flex flex-row align-items-center justify-content-evenly">
              <p class="display-6 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
              <p class="display-6 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
            </div>
          </div>       
        </div>
      </div>
    </div>
    <div v-if='orientation === "landscape-primary" || orientation === "landscape-secondary"'>
      <!-- phone landscape < 767px-->
      <div class="container-fluid d-block d-md-none p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" id="mobCSoonContent" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="d-flex flex-row align-items-center justify-content-center p-0 m-0">
            <div class="">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 20vw;" class="ms-1 my-1">
            </div>
            <div class="flex-fill text-end">
              <p @click="toggleTestmode" class="p-0 m-0 h1 fs-5 text-white text-uppercase text-decoration-none pe-2">Launching Soon</p>
            </div>
          </div>
          <div class="flex-fill d-flex flex-row p-0 m-0">
            <div class="d-flex flex-column align-content-start justify-content-start">
              <div class="mx-2 mt-2">
                <p class="h1 text-start text-highlight text-uppercase text-decoration-none display-3 fw-bolder p-0 m-0 lh-1">only selling digital files?</p>
              </div>
              <div class="mx-2 mt-2">
                <p class="h1 text-start text-white text-decoration-none text-capitalize fs-6 lh-sm m-0">PHOTAR uses Augmented Reality to boost sales</p>
                <p class="h1 text-start text-white text-decoration-none small lh-sm m-0 pt-2">Show clients their photos as products in their own home.</p>               
              </div>
              <div class="mx-2 mt-auto d-flex flex-row align-content-center justify-content-evenly gap-4">
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-2" :class='{"btn-sm": mobileView}' @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-2" :class='{"btn-sm": mobileView}' @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
              </div>          
              <div class="mx-4 mt-2 d-flex flex-row align-content-center justify-content-evenly">
                <p class="fs-3 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
              </div>         
            </div>
            <div class="flex-fill d-flex flex-column align-content-center justify-content-end">
              <div class="text-center position-relative">
                <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute image-fluid" style="z-index:1; height:63vh; width:auto; right:20%; top:5%"/>                
                <Transition name="fade-slide" mode="out-in">
                  <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                </Transition>
                <img :src="require('../assets/images/New/Hand.png')" alt="" class="position-relative" style="z-index: 25; height:83vh; width:auto;">               
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- phone landscape 768 > 991px-->
      <div class="container-fluid d-none d-md-block d-lg-none p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" id="mobCSoonContent" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="d-flex flex-row align-items-center justify-content-center p-0 m-0">
            <div class="">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 15vw;" class="ms-1 my-1">
            </div>
            <div class="flex-fill text-end">
              <p @click="toggleTestmode" class="p-0 m-0 h1 fs-5 text-white text-uppercase text-decoration-none pe-2">Launching Soon</p>
            </div>
          </div>
          <div class="flex-fill d-flex flex-row p-0 m-0">
            <div class="d-flex flex-column align-content-start justify-content-start">
              <div class="mx-2 mt-2">
                <p class="h1 text-start text-highlight text-uppercase text-decoration-none display-2 fw-bolder p-0 m-0 lh-1">fed up of only selling digital files?</p>
              </div>
              <div class="mx-2 mt-2">                
                <p class="h1 text-start text-white text-decoration-none text-capitalize fs-4 lh-sm m-0">PHOTAR encourages aftersales by showing clients their photos as products in their own home<br></p>
                <p class="pt-3 h1 text-start text-white text-decoration-none fs-6 lh-sm m-0">Try before you buy with<span class="fw-bolder fs-4 text-capitalize"> augmented reality</span></p>                  
              </div>
              <div class="mx-2 mt-auto d-flex flex-row align-content-center justify-content-evenly gap-4">
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2" @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2" @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
              </div>          
              <div class="mx-4 mt-2 d-flex flex-row align-content-center justify-content-evenly">
                <p class="fs-3 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
              </div>         
            </div>
            <div class="flex-fill d-flex flex-column align-content-center justify-content-end">
              <div class="text-center position-relative">
                <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute image-fluid" style="z-index:1; height:63vh; width:auto; right:20%; top:5%"/>                
                <Transition name="fade-slide" mode="out-in">
                  <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                </Transition>
                <img :src="require('../assets/images/New/Hand.png')" alt="" class="position-relative" style="z-index: 25; height:83vh; width:auto;">               
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- phone landscape 992px > 1199px -->
      <div class="container-fluid d-none d-lg-block d-xl-none p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" id="mobCSoonContent" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="d-flex flex-row align-items-center justify-content-center p-0 m-0">
            <div class="">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 15vw;" class="ms-1 my-1">
            </div>
            <div class="flex-fill text-end">
              <p @click="toggleTestmode" class="p-0 m-0 h1 fs-5 text-white text-uppercase text-decoration-none pe-2">Launching Soon</p>
            </div>
          </div>
          <div class="flex-fill d-flex flex-row p-0 m-0">
            <div class="d-flex flex-column align-content-start justify-content-start">
              <div class="mx-2 mt-2">
                <p class="h1 text-start text-highlight text-uppercase text-decoration-none display-2 fw-bolder p-0 m-0 lh-1">fed up of only selling digital files?</p>
              </div>
              <div class="mx-2 mt-4">                
                <p class="h1 text-start text-white text-decoration-none text-capitalize fs-3 lh-sm m-0">PHOTAR encourages aftersales <br>by showing clients their photos<br> as products in their own home<br></p>
                <p class="pt-4 h1 text-start text-white text-decoration-none fs-5 lh-sm m-0">Try before you buy with<br><span class="fw-bolder fs-3 text-capitalize"> augmented reality</span></p>                 
              </div>
              <div class="mx-2 mt-auto d-flex flex-row align-content-center justify-content-evenly gap-4">
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-2" :class='{"btn-sm": mobileView}' @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-2" :class='{"btn-sm": mobileView}' @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
              </div>          
              <div class="mx-4 mt-2 d-flex flex-row align-content-center justify-content-evenly">
                <p class="fs-3 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
                <p class="fs-3 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
              </div>         
            </div>
            <div class="flex-fill d-flex flex-column align-content-center justify-content-end">
              <div class="text-center position-relative">
                <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute image-fluid" style="z-index:1; height:63vh; width:auto; right:20%; top:5%"/>                
                <Transition name="fade-slide" mode="out-in">
                  <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                  <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:63vh; width:auto; right:20%; top:5%"/>
                </Transition>
                <img :src="require('../assets/images/New/Hand.png')" alt="" class="position-relative" style="z-index: 25; height:83vh; width:auto;">               
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- phone landscape 1200px > 1399px -->
      <div class="container-fluid d-none d-xl-block d-xxl-none p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" id="mobCSoonContent" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="d-flex flex-row align-items-center justify-content-center p-0 m-0">
            <div class="">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 15vw;" class="ms-1 my-1">
            </div>
            <div class="text-end">
              <p @click="toggleTestmode" class="p-0 m-0 ms-5 h1 fs-4 text-white text-uppercase text-decoration-none pe-2">Launching Soon</p>
            </div>
          </div>
          <div class="flex-fill d-flex flex-row p-0 m-0">
            <div class="d-flex flex-column align-content-start justify-content-start">
              <div class="mx-4 mt-3">
                <p class="h1 text-start text-highlight text-uppercase text-decoration-none display-2 fw-bolder p-0 m-0 lh-1">fed up of only selling digital files?</p>
              </div>
              <div class="mx-4 mt-4">
                <p class="h1 text-start text-white text-decoration-none text-capitalize fs-3 lh-sm m-0">PHOTAR encourages aftersales <br>by showing clients their photos <br>as products in their own home<br></p>
                <p class="pt-3 h1 text-start text-white text-decoration-none text-capitalize fs-4 lh-sm m-0">Try before you buy with<span class="fw-bolder fs-2"><br> augmented reality</span></p>             
              </div>               
              <div class="mx-2 mt-auto d-flex flex-row align-content-center justify-content-evenly gap-4">
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm p-2" :class='{"btn-sm": mobileView}' @click="mailingList" ><i class="bi bi-envelope-open-fill pe-2"></i>Stay in touch</button>
                <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow-sm  p-2 " :class='{"btn-sm": mobileView}' @click="promoVid" ><i class="bi bi-play-circle-fill pe-2 fs-6"></i>PHOTAR In Action</button>
              </div>          
              <div class="mx-4 mt-2 d-flex flex-row align-content-center justify-content-evenly">
                <p class="fs-2 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
                <p class="fs-2 p-0 m-0 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
                <p class="fs-2 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
              </div>         
            </div>
            <div class="flex-fill d-flex flex-column align-content-center justify-content-end">
              <div class="text-center position-relative">
                <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute image-fluid" style="z-index:1; height:64vh; width:auto; right:21%; top:4.5%"/>
                <Transition name="fade-slide" mode="out-in">
                  <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:64vh; width:auto; right:21%; top:4.5%"/>
                  <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:64vh; width:auto; right:21%; top:4.5%"/>
                  <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; height:64vh; width:auto; right:21%; top:4.5%"/>
                </Transition>
                <img :src="require('../assets/images/New/Hand.png')" alt="" class="position-relative" style="z-index: 25; height:85vh; width:auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid d-none d-xxl-block p-0 m-0 bg-img">
        <div class="d-flex flex-column p-0 m-0 align-content-start justify-content-start" id="mobCSoonContent" style="z-index:10 !important; min-height:100vh; min-width:100vw;">
          <div class="d-flex flex-row align-items-center justify-content-center p-0 m-0">
            <div class="">
              <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar logo" style="width: 10vw;" class="ms-1 my-1">
            </div>
            <div class="text-end">
              <p @click="toggleTestmode" class="p-0 m-0 ms-5 h1 fs-4 text-white text-uppercase text-decoration-none pe-2">Launching Soon</p>
            </div>
          </div>
          <div class="flex-fill d-flex flex-row p-0 m-0">
            <div class="d-flex flex-column align-content-start justify-content-start">
              <div class="mx-5 mt-5">
                <p class="h1 text-start text-highlight text-uppercase text-decoration-none display-1 fw-bolder p-0 m-0 lh-1">fed up of only<br> selling digital files?</p>
              </div>
              <div class="mx-5 mt-5">
                <p class="h1 text-start text-white text-decoration-none text-capitalize fs-3 lh-sm m-0">PHOTAR encourages aftersales by showing clients their photos <br>as products in their own home.<br></p>
                <p class="pt-4 h1 text-start text-white text-decoration-none fs-4 lh-sm m-0">Try before you buy with<span class="fw-bolder fs-1 text-capitalize"><br> augmented reality</span></p>             
              </div> 
              <div class="mt-auto d-flex flex-row">
                <div class="mx-5 mt-5 col-5 flex-grow-1 ">
                  <p class="h1 text-center text-white text-decoration-none text-capitalize fs-3 lh-sm m-0 mb-2">Join Us</p>
                  <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2 fs-5" :class='{"btn-sm": mobileView}' @click="mailingList" ><i class="bi bi-envelope-open-fill pe-4"></i>Sign up for Newsletter</button>             
                </div>  
                <div class="mx-5 mt-5 col-5 flex-grow-1">
                  <p class="h1 text-center text-white text-decoration-none text-capitalize fs-3 lh-sm m-0 mb-2">Watch Now</p>
                  <button type="button" class="w-100 btn btn-highlight text-uppercase text-white shadow p-2 fs-5" :class='{"btn-sm": mobileView}' @click="promoVid" ><i class="bi bi-play-circle-fill pe-4"></i>Promo Video</button>             
                </div>  
              </div>            
                  
              <div class="col-10 mt-3 mx-5 d-flex flex-row align-content-center justify-content-evenly">
                <p class="fs-2 p-0 m-0 text-white" @click="gotoFacebook" style="cursor: pointer" ><i class="bi bi-facebook"></i></p>
                <p class="fs-2 p-0 m-0 mx-5 text-white" @click="gotoYoutube" style="cursor: pointer" ><i class="bi bi-youtube"></i></p>
                <p class="fs-2 p-0 m-0 text-white" @click="gotoInstagram" style="cursor: pointer"><i class="bi bi-instagram"></i></p>
              </div>          
                    
            </div>
            <div class="flex-fill d-flex flex-column align-content-center justify-content-end">
              <div class="text-end position-relative">
                <img :src="require('../assets/images/New/slide0.jpg')" alt="" class="position-absolute image-fluid" style="z-index:1; max-height:65vh; width:auto; right:6rem; top:4.5%"/>
                <Transition name="fade-slide" mode="out-in">
                  <img v-if="slideNum == 0" :src="require('../assets/images/New/slide1.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; max-height:65vh; width:auto; right:6rem; top:4.5%"/>
                  <img v-else-if="slideNum == 1" :src="require('../assets/images/New/slide2.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; max-height:65vh; width:auto; right:6rem; top:4.5%"/>
                  <img v-else-if="slideNum == 2" :src="require('../assets/images/New/slide3.jpg')" alt="" class="position-absolute image-fluid" style="z-index:2; max-height:65vh; width:auto; right:6rem; top:4.5%"/>
                </Transition>
                <img :src="require('../assets/images/New/Hand.png')" alt="" class="img-fluid align-bottom position-relative" style="z-index: 25; max-height:85vh; width:auto;">      
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import {ref, onMounted} from "vue"
export default {
  name: "ActiveUser",
  props: ["mobileView"],
  // emits: ["showDataModal", "showModal", "toggleTest" ],
  emits: ["showModal", "showDataModal", "showAlertDash", "showSlideModal", "showAlert", "toggleTest"],
  setup(props, context){
    const slideNum = ref(0)    
    onMounted(() => {
      slideNum.value = 0 
      window.addEventListener("orientationchange", handleOrientation)
    })
    const orientation = ref(window.screen.orientation.type)

    const handleOrientation = () => {
      // console.log("running handle orientation - ", window.screen.orientation.type)
      orientation.value = (window.screen.orientation.type)
    }

    const toggleTestmode = () => {
      console.log("running toggle test mode > Home")
      context.emit("toggleTest") 
    }

    setInterval(function() {
      if(slideNum.value == 2){
        slideNum.value = 0
      }
      else{
        slideNum.value++
      }
    }, 3000)

    let promoVid = () => {    
      console.log("Show Promo Video") 
      // console.log("ActiveUser>Help Clicked")
      let subtitle = "Coming Soon"
      let vUrl = "https://www.youtube.com/watch?v=gTYkFpLIbJQ"    
      let vTitle = "Playing the promo video"
      let modData = {title: "PHOTAR", area:"User", message: subtitle, videoUrl:vUrl, vTitle:vTitle}
      context.emit("showDataModal", modData)      
    }
    let mailingList = () => {    
      console.log("Show Mailing List Popup") 
      context.emit("showModal", "Newsletter")      
    }

    const gotoFacebook = () => {
      console.log("gotoFacebook link")
      window.open("https://www.facebook.com/photarUK");
    }
    const gotoInstagram = () => {
      console.log("gotoInstagram link")
      window.open("https://www.instagram.com/photaruk");
    }
    const gotoYoutube = () => {
      console.log("gotoYoutube link")
      window.open("https://www.youtube.com/watch?v=WUmfxDQnCMM");
    }
    return{promoVid, mailingList, gotoFacebook, gotoYoutube, gotoInstagram, slideNum, orientation, toggleTestmode}
  }   
}
</script>

<style scoped>

/* Mobile */
  @media only screen and (max-width:575px) and (orientation: portrait) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG-Mob-V.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media only screen and (max-width:575px) and (orientation: landscape) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG-Mob.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
/* Tablet */
  @media only screen and (min-width:576px) and (max-width:768px) and (orientation: portrait) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG-Tab-V.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media only screen and (min-width:576px) and (max-width:768px) and (orientation: landscape) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG-Tab.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
/* Large */
  @media only screen and (min-width:769px) and (orientation: portrait) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG-V.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media only screen and (min-width:769px) and (orientation: landscape) {
    .bg-img {     
      background-image: url("../assets/images/New/Photar-ComingSoon-BG.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

.fade-slide-leave-active{
  transition: opacity 1s;
}
.fade-slide-enter-active,
.fade-slide-leave-active{
  transition: opacity 1s;
}

.fade-slide-enter-from {
  opacity: 0;  
}
.fade-slide-leave-to{
  opacity: 1;  
} 
</style>