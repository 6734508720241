<template>
  <div ref="vidHolder">
    <div class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
      <p class="fs-5 fw-normal text-charcoal">{{modData.message}}</p>                  
    </div>
    <div id="vidHolder" class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
      <div class="d-flex">
        <YouTube 
        :src="source"
        :width=vWidth   
        :height=vHeight   
        @ready="onReady"
        ref="youtube" />        
      </div>                     
    </div>
    <div class="col-12 col-sm-11 mx-auto mt-2 pt-2 text-center">
      <p class="fs-5 fw-normal text-charcoal p-0 m-0">More Tutorials</p>                  
    </div>
    <div class="mt-1 col-12 col-sm-11 mx-auto">
      <div class="d-flex justify-content-evenly text-decoration-underline p-0 m-0">
        <div v-show="User">
          <p class="p-link fs-6 text-lowlight p-0 m-0" @click="switchVids('User')">My Account</p>
        </div>
        <div v-show="Clients">
          <p class="p-link fs-6 text-lowlight p-0 m-0" @click="switchVids('Clients')">Clients</p>
        </div>
        <div v-show="Shoots">
          <p class="p-link fs-6 text-lowlight p-0 m-0" @click="switchVids('Shoots')">Shoots</p>
        </div>
        <div v-show="Images">
          <p class="p-link fs-6 text-lowlight p-0 m-0" @click="switchVids('Images')">Images</p>
        </div>
      </div>                
    </div>
    <div class="mt-3 mb-3 col-12 col-sm-11 mx-auto">
      <p class="p-link text-center small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>             
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watchEffect, onBeforeUnmount, onUnmounted } from "vue"
import YouTube from "vue3-youtube"
export default {
  name: "HelpModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "switchModalDashData"],
  components: {YouTube},
  setup(props, context){
    console.log("pModal>Help - Test-", props.modData)
    
    let User = ref(true)
    let Clients = ref(true)
    let Shoots = ref(true)
    let Images = ref(true)
    let youtube = ref(null)
    let vidHolder = ref(null)
    let source = ref("null")
    let vWidth = ref(null)
    let vHeight = ref(null)

    onMounted(() => {
      hideShowingLink()
      source.value = props.modData.videoUrl
    })

    const stopHelpModWatch = watchEffect(() => {
      if(vidHolder.value){
        if(vidHolder.value.children.length > 0) { 
          let vContainer = vidHolder.value.querySelector("#vidHolder")
          vWidth.value = vContainer.offsetWidth
          vHeight.value = vContainer.offsetWidth * 0.5625         
        }
      }
    })
    
    onBeforeUnmount(() => {})

    onUnmounted(() => {
      console.log("pModal>Help - onUnmounted")
      stopHelpModWatch()
    })


    function onReady() {
      youtube.value.playVideo()
    }

    let hideShowingLink = () => {
      // console.log("hideShowing")
      let area = props.modData.area
      // console.log("hideShowing" , area)
      if(area === "User"){User.value = false}
      if(area === "Clients"){Clients.value = false}
      if(area === "Shoots"){Shoots.value = false}
      if(area === "Images"){Images.value = false}
    }

    let switchVids = (switchTo) => {
      if(switchTo === "User"){
        let subtitle = "Account Details | Billing"  
        let vUrl = "https://www.youtube.com/watch?v=BEsfZclXt1Q"    
        let vTitle = "Playing the promo video"   
        let modData = {title: "Help", area:"User", message: subtitle, videoUrl:vUrl, vTitle:vTitle}
        context.emit("switchModalDashData", modData )
      }
      if(switchTo === "Clients"){
        let subtitle = "Create | Edit | Delete"    
        let vUrl = "https://www.youtube.com/watch?v=BEsfZclXt1Q"  
        let vTitle = "Playing the clients video"   
        let modData = {title: "Help", area:"Clients", message: subtitle, videoUrl:vUrl, vTitle:vTitle}
        context.emit("switchModalDashData", modData )
      }
      if(switchTo === "Shoots"){
        let subtitle = "Create | Edit | Delete" 
        let vUrl = "https://www.youtube.com/watch?v=BEsfZclXt1Q"    
        let vTitle = "Playing the shoots video"  
        let modData = {title: "Help", area:"Shoots", message: subtitle, videoUrl:vUrl, vTitle:vTitle}
        context.emit("switchModalDashData", modData )
      }
      if(switchTo === "Images"){
        let subtitle = "Upload | Recommend"    
        let vUrl = "https://www.youtube.com/watch?v=BEsfZclXt1Q"  
        let vTitle = "Playing the images video"   
        let modData = {title: "Help", area:"Images", message: subtitle, videoUrl:vUrl, vTitle:vTitle}
        context.emit("switchModalDashData", modData )
      }
    }
    let closeSubMod = () => {
      console.log("pModal>Help>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
   
    return{closeSubMod, User, Clients, Shoots, Images, switchVids, onReady, youtube, source, vidHolder, vWidth, vHeight}
  }
}
</script>

<style scoped>
  .video-holder {
  min-height: 18vh;
  width: 100%;
  aspect-ratio: 16/9;
}
</style>