<template>
  <div>  
    <div class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
      <p class="fs-5 fw-normal text-charcoal text-capitalize">{{modData.message}}</p>                  
    </div>
    <div class="col-1 col-sm-11 mx-auto p-0 m-0 mb-1 text-center">
      <p class="fs-6 fw-normal text-charcoal">{{modData.message2}}</p>                  
    </div>
    <div class="mt-3 col-12 col-sm-11 mx-auto">
      <button v-if="modData.area === 'Client'" type="button" @click="handleDelClient" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Delete Client</button>                 
      <button v-if="modData.area === 'Shoot'" type="button" @click="handleDelShoot" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Delete Shoot</button>                 
      <button v-if="modData.area === 'Image'" type="button" @click="handleDelImage" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Delete Image</button>                 
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>
<script>
import { getFunctions, httpsCallable } from "firebase/functions"
import Spinner from "@/components/Spinner.vue"
import useDeleteImage from "@/composables/delImage"
import delDocument from "@/composables/delDocument"
import getShootUrls from "@/composables/getShootUrls"
import {ref, onMounted, onUnmounted, computed} from "vue"
import { useStore } from "@/composables/store/store"
import { doc, updateDoc, arrayUnion } from "@firebase/firestore"
import { db } from "@/firebase/config"
export default {
  name: "DeleteModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "showAlertSub", "tabReset"],
  components: {Spinner},
  setup(props, context){
    console.log("pModal>Delete -", props.modData)
    let store = useStore();
    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)
    let area = ref(null)
    let delObject = ref(null)
    // let { useDeleteImage} = delImage()
    let { useDeleteDocument, error:deleteDocError } = delDocument()

    onMounted(() => {
      console.log("pModal>Delete - OnMounted - props.modData.obj", props.modData.obj)
      area.value = props.modData.area
      delObject.value = props.modData.obj
      // console.log("area.value - ", area.value )
      // console.log("delObject.value - ", delObject.value )
      // console.log("delObject.value - ", delObject.value.imageHeight )
    })

    onUnmounted(() => {
      console.log("pModal>Delete - onUnmounted")
      clearVars()
    })

    const clearVars = () => {
      console.log("pModal>Delete - clearVars - ", area.value)
      // if(area.value === "Image"){

      // }
      // if(area.value === "Shoot"){

      // }
      // if(area.value === "Client"){

      // }
    }

    //IMAGES
    let deleteImageProgress = {
      gotUrl: false,
      gotResizedUrl: false,
      gotImageID: false,
      decreaseUserImageCount: false,
      decreaseClientImageCount: false,
      decreaseShootImageCount: false,
      imageDeleted: false,
      resizedImageDeleted: false,
      imageDocDeleted: false
    }
    
    let imageDeleted = computed(() => {
      if(deleteImageProgress.gotUrl == true && deleteImageProgress.gotResizedUrl == true && deleteImageProgress.gotImageID == true && deleteImageProgress.decreaseShootImageCount == true && deleteImageProgress.decreaseClientImageCount == true && deleteImageProgress.decreaseUserImageCount == true && deleteImageProgress.imageDeleted == true && deleteImageProgress.resizedImageDeleted == true && deleteImageProgress.imageDocDeleted == true){
        return true
      }
      else {
        return false
      }
    })

    let baseUserImageCount = store.getImageCount.value
    let baseClientImageCount = store.getSelClientNumImages.value
    let baseShootImageCount = store.getSelShootNumImages.value

    const cleanUpImgDelete = async () => {
      console.log("pModal>Delete - cleanUpImgDelete")   
      if(deleteImageProgress.imageDeleted || deleteImageProgress.resizedImageDeleted || deleteImageProgress.imageDocDeleted){
        errorMessage.value = "An Error Occurred while removing image, please contact PHOTAR support"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)   
      }
      else{
        if(deleteImageProgress.decreaseShootImageCount){
          console.log("imageCount shootId- ", store.getSelShoot.value)
          const shootRef = doc(db, "shoot", store.getSelShoot.value )
          await updateDoc(shootRef, {"images.numberImages": baseShootImageCount})
          await updateDoc(shootRef, {"images.imageIDs": arrayUnion(props.modData.iid)})
          console.log("Shoot Count Fixed & ID Readded")
        }
        if(deleteImageProgress.decreaseClientImageCount){
          console.log("imageCount clientId- ", store.getSelClient.value)
          const clientRef = doc(db, "clients", store.getSelClient.value )
          await updateDoc(clientRef, {"images.numberImages": baseClientImageCount})
          await updateDoc(clientRef, {"images.imageIDs": arrayUnion(props.modData.iid)})
          console.log("Client Count Fixed & ID Readded")
        }
        if(deleteImageProgress.decreaseUserImageCount){
          console.log("imageCount userId- ", store.getUserId.value)
          const userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"images.numberImages": baseUserImageCount})
          await updateDoc(userRef, {"images.imageIDs": arrayUnion(props.modData.iid)})
          console.log("User Count Fixed & ID Readded")
        }
      }
    } 

    let handleDelImage = async () => {
      console.log("handleDelImage - ", delObject.value)      
      //check got image id
      if(!delObject.value.imageId || delObject.value.imageId == null || delObject.value.imageId != props.modData.iid){
        errorMessage.value = "Error Deleting Image - No or Invalid Image ID"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        deleteImageProgress.gotImageID = false
        cleanUpImgDelete()
        return
      }
      else{
        console.log("GOT IMAGE ID - ", delObject.value.imageId)
        deleteImageProgress.gotImageID = true
      }
      //check got image url
      if(!delObject.value.imageUrl || delObject.value.imageUrl == null){
        errorMessage.value = "Error Deleting Image - No Image Url"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        deleteImageProgress.gotUrl = false
        cleanUpImgDelete()
        return
      }
      else{
        console.log("GOT IMAGE URL - ", delObject.value.imageUrl)
        deleteImageProgress.gotUrl = true
      }
      //check got resized image url
      if(!delObject.value.resizedImageUrl || delObject.value.resizedImageUrl == null){
        errorMessage.value = "Error Deleting Image - No Resized Image Url"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        deleteImageProgress.gotResizedUrl = false
        cleanUpImgDelete()
        return
      }
      else{
        console.log("GOT RESIZED IMAGE URL - ", delObject.value.resizedImageUrl)
        deleteImageProgress.gotResizedUrl = true
      }
      inProgress.value = true
      let functions = getFunctions()
      let delImgID = [delObject.value.imageId]
      let imageUrl = [delObject.value.imageUrl]
      let imageRszUrl = [delObject.value.resizedImageUrl]
      //reduced shoot image count
      try{              
        const decreaseShootImageCount = httpsCallable(functions, "decreaseNumImagesShoot")
        const res = await decreaseShootImageCount({sid:delObject.value.shootId, iid:delImgID})
        console.log("res - ", res)
        deleteImageProgress.decreaseShootImageCount = true
      } catch (error) {
        deleteImageProgress.decreaseShootImageCount = false
        errorMessage.value = "Error Occurred While Decreasing Shoot Image Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpImgDelete()
        return                         
      }           
      //reduced client image count
      try{             
        const decreaseClientImageCount = httpsCallable(functions, "decreaseNumImagesClient")
        const res2 = await decreaseClientImageCount({cid:delObject.value.clientId, iid:delImgID})
        console.log("res2 - ", res2)
        deleteImageProgress.decreaseClientImageCount = true
      } catch (error) {
        deleteImageProgress.decreaseClientImageCount = false
        errorMessage.value = "Error Occurred While Decreasing Client Image Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpImgDelete()
        return                         
      }           
      //reduced user image count
      try{             
        const decreaseUserImageCount = httpsCallable(functions, "decreaseNumImagesUser")
        const res3 = await decreaseUserImageCount({uid:delObject.value.userId, iid:delImgID})
        console.log("res3 - ", res3)
        deleteImageProgress.decreaseUserImageCount = true
      } catch (error) {
        deleteImageProgress.decreaseUserImageCount = false
        errorMessage.value = "Error Occurred While Decreasing User Image Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpImgDelete()
        return                         
      }    
      if(deleteImageProgress.gotUrl == true && deleteImageProgress.gotResizedUrl == true && deleteImageProgress.gotImageID == true && deleteImageProgress.decreaseShootImageCount == true && deleteImageProgress.decreaseClientImageCount == true && deleteImageProgress.decreaseUserImageCount == true ) 
      {
        //delete resized image
        let { errorArr:rszImgDelError } = await useDeleteImage(imageRszUrl)
        if (rszImgDelError.length > 0) {
          deleteImageProgress.resizedImageDeleted = false
          errorMessage.value = "Error deleting resized images"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpImgDelete()
          return   
        } else {
          deleteImageProgress.resizedImageDeleted = true
        }
        //delete full image
        let { errorArr:imgDelError } = await useDeleteImage(imageUrl)
        if (imgDelError.length > 0) {
          deleteImageProgress.imageDeleted = false
          errorMessage.value = "Error deleting image"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpImgDelete()
          return   
        } else {
          deleteImageProgress.imageDeleted = true
        }
        //delete image doc
        let imageIDs = [delObject.value.imageId]
        await useDeleteDocument("images", imageIDs)
        if (deleteDocError.value != null){
          deleteImageProgress.imageDocDeleted = false
          errorMessage.value = "Error deleting image document"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpImgDelete()
          return                         
        } else {
          deleteImageProgress.imageDocDeleted = true
        }
        if(imageDeleted.value == true){
          errorMessage.value = "Image successfully deleted"
          inProgress.value = false
          let alertLvl = 2
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)
          context.emit("showAlertSub", errorArray)  
          deleteImageSuccess()
        }   
        else{
          errorMessage.value = "An Error Occurred"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUpImgDelete()
          return  
        }           
      }
    }      
        
      
    let deleteImageSuccess = () => {
      console.log("Running delete image success")
      store.deselectImage()
      context.emit("tabReset")
      closeSubMod()
    } 

    //SHOOTS
    let deleteShootProgress = {
      gotShootID: false,
      gotImageIDs: false,
      noImages: false,
      decreaseClientImageCount: false,
      decreaseUserImageCount: false,
      decreaseClientShootCount: false,
      decreaseUserShootCount: false,
      imagesDeleted: false,
      resizedImagesDeleted: false,
      imageDocsDeleted: false,
      shootDocDeleted: false,
    }

    let shootDeleted = computed(() => {
      if((deleteShootProgress.gotShootID == true 
      && deleteShootProgress.gotImageIDs == true 
      && deleteShootProgress.noImages == false 
      && deleteShootProgress.decreaseClientImageCount == true 
      && deleteShootProgress.decreaseUserImageCount == true 
      && deleteShootProgress.decreaseClientShootCount == true 
      && deleteShootProgress.decreaseUserShootCount == true 
      && deleteShootProgress.imagesDeleted == true 
      && deleteShootProgress.resizedImagesDeleted == true 
      && deleteShootProgress.imageDocsDeleted == true
      && deleteShootProgress.shootDocDeleted == true) 
      || (
        deleteShootProgress.gotShootID == true
        && deleteShootProgress.noImages == true 
        && deleteShootProgress.decreaseClientShootCount == true 
        && deleteShootProgress.decreaseUserShootCount == true 
        && deleteShootProgress.shootDocDeleted == true
      )) {
        return true
      } 
      else {
        return false
      }
    })

    const cleanUpShtDelete = async () => {
      console.log("pModal>Delete - cleanUpShtDelete")   
      if(deleteShootProgress.imagesDeleted || deleteShootProgress.resizedImagesDeleted || deleteShootProgress.imageDocsDeleted || deleteShootProgress.shootDocDeleted){   
        errorMessage.value = "An Error Occurred while images from the shoot, please contact PHOTAR support"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        store.deselectImage()
        store.deselectShoot()      
        context.emit("tabReset")
        closeSubMod()
      } 
      else {
        if(deleteShootProgress.decreaseClientImageCount){
          console.log("imageCount clientId- ", store.getSelClient.value)
          let clientRef = doc(db, "clients", store.getSelClient.value )
          await updateDoc(clientRef, {"images.numberImages": baseClientImageCount})
          let imgIDs = [...delObject.value.images.imageIDs]
          console.log("readd img ids to client- ", imgIDs)
          for (let i = 0; i < imgIDs.length; i++)
          {
            await updateDoc(clientRef, {"images.imageIDs": arrayUnion(imgIDs[1])})
          }
          console.log("Client Image Count Fixed & ID Readded")
        }
        if(deleteShootProgress.decreaseUserImageCount){
          console.log("imageCount userId- ", store.UserId.value)
          let userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"images.numberImages": baseUserImageCount})
          let imgIDs = [...delObject.value.images.imageIDs]
          console.log("re add img ids to client- ", imgIDs)
          for (let i = 0; i < imgIDs.length; i++)
          {
            await updateDoc(userRef, {"images.imageIDs": arrayUnion(imgIDs[i])})
          }
          console.log("User Image Count Fixed & ID Readded")
        }
        if(deleteShootProgress.decreaseUserShootCount){
          console.log("shootCount userId- ", store.UserId.value)
          let userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"shoots.numberShoots": baseUserShootCount})
          await updateDoc(userRef, {"shoots.shootIDs": arrayUnion(props.modData.sid)})          
          console.log("User Shoot Count Fixed & ID Readded")
        }
        if(deleteShootProgress.decreaseClientShootCount){
          console.log("shootCount clientId- ", store.getSelClient.value)
          let clientRef = doc(db, "clients", store.getSelClient.value )
          await updateDoc(clientRef, {"shoots.numberShoots": baseClientShootCount})
          await updateDoc(clientRef, {"shoots.shootIDs": arrayUnion(props.modData.sid)})          
          console.log("Client Shoot Count Fixed & ID Readded")
        }
      }
    }
     

    let baseUserShootCount = store.getShootCount.value
    let baseClientShootCount = store.getSelClientNumShoots.value

    let handleDelShoot = async () => {
      console.log("handleDelShoot - ", delObject.value)
      //check got shoot id
      if(!delObject.value.shootId || delObject.value.shootId == null || delObject.value.shootId != props.modData.sid){
        errorMessage.value = "Error Deleting Shoot - No or Invalid Shoot ID"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        deleteShootProgress.gotShootID = false
        cleanUpShtDelete()
        return
      }
      else {
        deleteShootProgress.gotShootID = true
      }
      //get associated image ids
      let functions = getFunctions()
      inProgress.value = true
      let shootIDs = [delObject.value.shootId]
      // console.log("Delete > 370 > shootIDs as Array > ", shootIDs)
      let shtImageIDs
      let shtImageUrls
      let shtImageRszUrls

      if(delObject.value.images.numberImages > 0)
      {
        // console.log("Delete > 376 > Shoot Has Images")
        shtImageIDs = [...delObject.value.images.imageIDs]
        // console.log("Delete > 378 > shtImageIDs - ", shtImageIDs)
        let { documents, documentsRsz} = await getShootUrls("images", ["shootId", "==", delObject.value.id])
        // console.log("Shoot Images Std", documents.value.length)
        // console.log("Shoot Images Resized", documentsRsz.value.length)
        shtImageUrls = [...documents.value]
        shtImageRszUrls = [...documentsRsz.value]
        deleteShootProgress.gotImageIDs = true
        deleteShootProgress.noImages = false
      }
      else{
        console.log("Delete > 408 > Shoot Has No Images")
        deleteShootProgress.gotImageIDs = false
        deleteShootProgress.noImages = true
      }
      //reduce image count on client
      if(deleteShootProgress.noImages == false && deleteShootProgress.gotImageIDs == true){
        try{
          const decreaseClientImageCount = httpsCallable(functions, "decreaseNumImagesClient")
          const res = await decreaseClientImageCount({cid:delObject.value.clientId, iid:shtImageIDs})
          console.log("res - ", res)
          deleteShootProgress.decreaseClientImageCount = true
        } catch (error) {
          deleteShootProgress.decreaseClientImageCount = false
          errorMessage.value = "Error Occurred While Decreasing Client Image Count"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpShtDelete()
          return             
        }
        //reduce image count on user
        try{             
          const decreaseUserImageCount = httpsCallable(functions, "decreaseNumImagesUser")
          const res1 = await decreaseUserImageCount({uid:delObject.value.userId, iid:shtImageIDs})
          console.log("res1 - ", res1)
          deleteShootProgress.decreaseUserImageCount = true
        } catch (error) {
          deleteShootProgress.decreaseUserImageCount = false
          errorMessage.value = "Error Occurred While Decreasing User Image Count"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpShtDelete()
          return                         
        }   
      }   
      //reduce shoot count on client
       try{             
        const decreaseClientShootCount = httpsCallable(functions, "decreaseNumShootsClient")
        const res2 = await decreaseClientShootCount({cid:delObject.value.clientId, sid:shootIDs})
        console.log("res2 - ", res2)
        deleteShootProgress.decreaseClientShootCount = true
      } catch (error) {
        deleteShootProgress.decreaseClientShootCount = false
        errorMessage.value = "Error Occurred While Decreasing Client Shoot Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpShtDelete()
        return                         
      }    
      //reduce shoot count on user
      try{             
        const decreaseUserShootCount = httpsCallable(functions, "decreaseNumShootsUser")
        const res3 = await decreaseUserShootCount({uid:store.getUserId.value, sid:shootIDs})
        console.log("res3 - ", res3)
        deleteShootProgress.decreaseUserShootCount = true
      } catch (error) {
        deleteShootProgress.decreaseUserShootCount = false
        errorMessage.value = "Error Occurred While Decreasing User Shoot Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpShtDelete()
        return                         
      }  
      //delete images and docs   
      if(deleteShootProgress.noImages == false && deleteShootProgress.gotImageIDs == true){
        //if images pass to image id array to delete resized images, images and remove image docs
        await deleteShootImages(shtImageIDs, shtImageUrls, shtImageRszUrls)
      }
      await useDeleteDocument("shoots", shootIDs)
      if (deleteDocError.value != null){
        deleteShootProgress.shootDocDeleted = false
        errorMessage.value = "Error deleting shoot document"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpShtDelete()
        return                         
      } else {
        deleteShootProgress.shootDocDeleted = true
        if(shootDeleted.value == true){
          errorMessage.value = "Shoot successfully deleted"
          inProgress.value = false
          let alertLvl = 2
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)
          context.emit("showAlertSub", errorArray)  
          deleteShootSuccess()
        }   
        else{
          errorMessage.value = "An Error Occurred while deleting this shoot"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUpShtDelete()
          return  
        }  
      }
    } 

    let deleteShootImages = async (sImgIDs, sImgUrls, sImgRszUrls) => {
      console.log("running delete shoot images")
      let { errorArr:rszShtImgDelError } = await useDeleteImage(sImgRszUrls)
      if (rszShtImgDelError > 0) {
        console.log("Error deleting shoot reszied image - ", rszShtImgDelError)
        errorMessage.value = "Error deleting shoot resized images"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray) 
        if(props.modData.area === "Shoot"){
          deleteShootProgress.resizedImagesDeleted = false 
          cleanUpShtDelete() 
        }
        if(props.modData.area === "Client"){
          deleteClientProgress.resizedImagesDeleted = false 
          cleanUpCliDelete() 
        }
        return false                
      } 
      else{
        if(props.modData.area === "Shoot"){
          deleteShootProgress.resizedImagesDeleted = true
        }
        if(props.modData.area === "Client"){
          deleteClientProgress.resizedImagesDeleted = true 
        }        
        //delete normal image
        let { errorArr:shtImgDelError} = await useDeleteImage(sImgUrls)
        if (shtImgDelError.length > 0) {
          console.log("Error deleting shoot standard image - ", shtImgDelError)
          errorMessage.value = "Error deleting shoot standard images"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)  
          if(props.modData.area === "Shoot"){
            deleteShootProgress.imagesDeleted = false 
            cleanUpShtDelete() 
          }
          if(props.modData.area === "Client"){
            deleteClientProgress.imagesDeleted = false 
            cleanUpCliDelete() 
          }         
          return false                   
        } 
        else{
          if(props.modData.area === "Shoot"){
            deleteShootProgress.imagesDeleted = true
          }
          if(props.modData.area === "Client"){
            deleteClientProgress.imagesDeleted = true 
          } 
          //delete image docs       
          await useDeleteDocument("images", sImgIDs)
          if (deleteDocError.value != null){
            errorMessage.value = "Error deleting image document"
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false 
            if(props.modData.area === "Shoot"){
              deleteShootProgress.imageDocsDeleted = false
              cleanUpShtDelete()
            }
            if(props.modData.area === "Client"){
              deleteClientProgress.imageDocsDeleted = false
              cleanUpCliDelete()
            }  
            return false                
          } else {
            if(props.modData.area === "Shoot"){
              deleteShootProgress.imageDocsDeleted = true
            }
            if(props.modData.area === "Client"){
              deleteClientProgress.imageDocsDeleted = true
            }  
            return true
          }
        }
      }
    } 

    let deleteShootSuccess = () => {
      console.log("Running delete shoot success")
      store.deselectImage()
      store.deselectShoot()      
      context.emit("tabReset")
      closeSubMod()
    } 

    //CLIENTS
    let deleteClientProgress = {
      gotClientID: false,
      gotShootIDs: false,
      gotImageIDs: false,
      noShoots: false,
      noImages: false,
      decreaseUserImageCount: false,
      decreaseUserShootCount: false,
      decreaseUserClientCount: false,
      imagesDeleted: false,
      resizedImagesDeleted: false,
      imageDocsDeleted: false,
      shootDocsDeleted: false,
      clientDocsDeleted: false,
      clientAccountDeleted: false,
    }

    let clientDeleted = computed(() => {
      if((deleteClientProgress.gotClientID == true && deleteClientProgress.gotShootIDs == true && deleteClientProgress.gotImageIDs == true && deleteClientProgress.noShoots == false && deleteClientProgress.noImages == false && deleteClientProgress.decreaseUserImageCount == true && deleteClientProgress.decreaseUserShootCount == true && deleteClientProgress.decreaseUserClientCount == true && deleteClientProgress.imagesDeleted == true && deleteClientProgress.resizedImagesDeleted == true && deleteClientProgress.imageDocsDeleted == true && deleteClientProgress.shootDocsDeleted == true && deleteClientProgress.clientDocsDeleted == true && deleteClientProgress.clientAccountDeleted) || (deleteClientProgress.gotClientID == true && deleteClientProgress.gotShootIDs == true && deleteClientProgress.noShoots == false && deleteClientProgress.noImages == true && deleteClientProgress.decreaseUserShootCount == true && deleteClientProgress.decreaseUserClientCount == true && deleteClientProgress.shootDocsDeleted == true && deleteClientProgress.clientDocsDeleted == true && deleteClientProgress.clientAccountDeleted) || (deleteClientProgress.gotClientID == true && deleteClientProgress.noShoots == true && deleteClientProgress.noImages == true && deleteClientProgress.clientDocsDeleted == true && deleteClientProgress.clientAccountDeleted)) {
        return true
      }
      else {
        return false
      }
    })
    
    const cleanUpCliDelete = async () => {
      console.log("pModal>Delete - cleanUpCliDelete")
      if(deleteClientProgress.imagesDeleted || deleteClientProgress.resizedImagesDeleted || deleteClientProgress.imageDocsDeleted || deleteClientProgress.shootDocsDeleted || deleteClientProgress.clientDocsDeleted || deleteClientProgress.clientAccountDeleted){   
        errorMessage.value = "An Error Occurred while deleting this clients data, please contact PHOTAR support"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        store.deselectImage()
        store.deselectShoot()      
        store.deselectClient()      
        context.emit("tabReset")
        closeSubMod()
      }
      else{
       if(deleteClientProgress.decreaseUserImageCount){
          console.log("imageCount userId- ", store.UserId.value)
          let userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"images.numberImages": baseUserImageCount})
          let imgIDs = [...delObject.value.images.imageIDs]
          console.log("re add img ids to client- ", imgIDs)
          for (let i = 0; i < imgIDs.length; i++)
          {
            await updateDoc(userRef, {"images.imageIDs": arrayUnion(imgIDs[i])})
          }
          console.log("User Image Count Fixed & ID Readded")
        }
       if(deleteClientProgress.decreaseUserShootCount){
          console.log("shootCount userId- ", store.UserId.value)
          let userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"shoots.numberShoots": baseUserShootCount})
          let shtIDs = [...delObject.value.shoots.shootIDs]
          console.log("re add shoot ids to client- ", shtIDs)
          for (let i = 0; i < shtIDs.length; i++)
          {
            await updateDoc(userRef, {"images.imageIDs": arrayUnion(shtIDs[i])})
          }
          console.log("User Shoot Count Fixed & ID Readded")
        }
        if(deleteClientProgress.decreaseUserClientCount){
          console.log("clientCount userId- ", store.UserId.value)
          let userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"clients.numberClients": baseUserClientCount})
          await updateDoc(userRef, {"clients.clientIDs": arrayUnion(props.modData.cid)})          
          console.log("User Client Count Fixed & ID Readded")
        }
        store.deselectImage()
        store.deselectShoot()      
        store.deselectClient()      
        context.emit("tabReset")
        closeSubMod()
      } 
    }

    let baseUserClientCount = store.getShootCount.value

    let handleDelClient = async () => {
      console.log("handleDelClient", delObject.value)
      //check got client id
      if(!delObject.value.clientId || delObject.value.clientId == null || delObject.value.clientId != props.modData.cid){
        errorMessage.value = "Error Deleting Client - No or Invalid Client ID"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)
        deleteClientProgress.gotClientID = false
        cleanUpCliDelete()
        return
      }
      else {
        deleteClientProgress.gotClientID = true
      }
      //get associated shoot ids
      let functions = getFunctions()
      inProgress.value = true
      let clientID = [delObject.value.clientId]

      let cliShtIDs
      let cliImageIDs
      let cliImageUrls
      let cliImageRszUrls

      if(delObject.value.shoots.numberShoots > 0)
      {
        deleteClientProgress.noShoots = false
        console.log("Delete > 684 > Client has Shoots")
        cliShtIDs = [...delObject.value.shoots.shootIDs]
        deleteClientProgress.gotShootIDs = true
        if(delObject.value.images.numberImages > 0)
        {
          deleteClientProgress.noImages = false
          console.log("Delete > 691 > Client has Images")
          cliImageIDs = [...delObject.value.images.imageIDs]
          deleteClientProgress.gotImageIDs = true
          let { documents, documentsRsz} = await getShootUrls("images", ["clientId", "==", delObject.value.clientId])
          cliImageUrls = [...documents.value]
          cliImageRszUrls = [...documentsRsz.value]
          deleteClientProgress.gotImageIDs = true
          deleteClientProgress.noImages = false
        }
        else{
          console.log("Delete > 701 > Client Has No Images")
          deleteClientProgress.gotImageIDs = false
          deleteClientProgress.noImages = true
        }
      }
      else{
        console.log("Delete > 707 > Client Has No Shoots or Images")
        deleteClientProgress.noShoots = true
        deleteClientProgress.noImages = true
        deleteClientProgress.gotShootIDs = false
        deleteClientProgress.gotImageIDs = false
      }
      //decrease user image count
      if(deleteClientProgress.noImages == false && deleteClientProgress.gotImageIDs == true) {
        try{
          const decreaseUserImageCount = httpsCallable(functions, "decreaseNumImagesUser")
          const res = await decreaseUserImageCount({uid:store.getUserId.value, iid:cliImageIDs})
          console.log("res - ", res)
          deleteClientProgress.decreaseUserImageCount = true
        } catch (error) {
          deleteClientProgress.decreaseUserImageCount = false
          errorMessage.value = "Error Occurred While Decreasing Users Image Count"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpCliDelete()
          return             
        }
      }
      
      //decrease user shoot count
      if(deleteClientProgress.noShoots == false && deleteClientProgress.gotShootIDs == true) {
        try{
          const decreaseUserShootCount = httpsCallable(functions, "decreaseNumShootsUser")
          const res2 = await decreaseUserShootCount({uid:store.getUserId.value, sid:cliShtIDs})
          console.log("res2 - ", res2)
          deleteClientProgress.decreaseUserShootCount = true
        } catch (error) {
          deleteClientProgress.decreaseUserShootCount = false
          errorMessage.value = "Error Occurred While Decreasing Users Shoot Count"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpCliDelete()
          return             
        }
      }
      //decrease user client count
      try{             
        const decreaseUserClientCount = httpsCallable(functions, "decreaseNumClients")
        const res3 = await decreaseUserClientCount({uid:store.getUserId.value, cid:clientID})
        console.log("res3 - ", res3)
        deleteClientProgress.decreaseUserClientCount = true
      } catch (error) {
        deleteClientProgress.decreaseUserClientCount = false
        errorMessage.value = "Error Occurred While Decreasing Users Client Count"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpCliDelete()
        return                         
      }   
      //delete images and docs 
      if(deleteClientProgress.noImages == false && deleteClientProgress.gotImageIDs == true){
        //if images pass to image id array to delete resized images, images and remove image docs
        await deleteShootImages(cliImageIDs, cliImageUrls, cliImageRszUrls)
      }
      //delete shoot docs
      if(deleteClientProgress.noShoots == false && deleteClientProgress.gotShootIDs == true){
      await useDeleteDocument("shoots", cliShtIDs)
        if (deleteDocError.value != null){
          deleteClientProgress.shootDocsDeleted = false
          errorMessage.value = "Error deleting users shoot documents"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpCliDelete()
          return                         
        } else {
          deleteClientProgress.shootDocsDeleted = true
        }
      }
      //delete client doc
      await useDeleteDocument("clients", clientID)
      if (deleteDocError.value != null){
        deleteClientProgress.clientDocsDeleted = false
        errorMessage.value = "Error deleting users client document"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUpCliDelete()
        return                         
      } else {
        deleteClientProgress.clientDocsDeleted = true
        //remove client account
        try{
          const userClientDelete = httpsCallable(functions, "deleteMyClient")
          const res4 = await userClientDelete({uid:store.getUserId.value, cid:props.modData.cid}) 
          console.log("res4 - ", res4)
          deleteClientProgress.clientAccountDeleted = true
        } catch (error) {
          deleteClientProgress.clientAccountDeleted = false
          errorMessage.value = "Error Occurred While Deleting the Client Account"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false 
          cleanUpCliDelete()
          return                         
        }
        if(clientDeleted.value == true){
          errorMessage.value = "Client successfully deleted"
          inProgress.value = false
          let alertLvl = 2
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)
          context.emit("showAlertSub", errorArray)  
          // console.log("deleteClientProgress.gotClientID - ", deleteClientProgress.gotClientID)
          // console.log("deleteClientProgress.gotShootIDs - ", deleteClientProgress.gotShootIDs)
          // console.log("deleteClientProgress.gotImageIDs - ", deleteClientProgress.gotImageIDs)
          // console.log("deleteClientProgress.noShoots - ", deleteClientProgress.noShoots)
          // console.log("deleteClientProgress.noImages - ", deleteClientProgress.noImages)
          // console.log("deleteClientProgress.decreaseUserImageCount - ", deleteClientProgress.decreaseUserImageCount)
          // console.log("deleteClientProgress.decreaseUserShootCount - ", deleteClientProgress.decreaseUserShootCount)
          // console.log("deleteClientProgress.decreaseUserClientCount - ", deleteClientProgress.decreaseUserClientCount)
          // console.log("deleteClientProgress.imagesDeleted - ", deleteClientProgress.imagesDeleted)
          // console.log("deleteClientProgress.resizedImagesDeleted - ", deleteClientProgress.resizedImagesDeleted)
          // console.log("deleteClientProgress.imageDocsDeleted - ", deleteClientProgress.imageDocsDeleted)
          deleteClientSuccess()
        } 
        else{
          errorMessage.value = "An Error Occurred while deleting this client"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUpCliDelete()
          return  
        }  
      }
    }

    let deleteClientSuccess = () => {
      console.log("Running delete client success")
      store.deselectImage()
      store.deselectShoot()      
      store.deselectClient()      
      context.emit("tabReset")
      closeSubMod()
    } 
    
    let closeSubMod = () => {
      console.log("pModal>Delete>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    return{ closeSubMod, handleDelClient, handleDelShoot, handleDelImage, inProgress}
  }
}
</script>