<template>
  <transition name="my-spinner-animation">
    <div class="my-spinner container-fluid">
      <div class="spin"></div>
    </div>
  </transition>  
</template>

<style>
  .my-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2500;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.5);
  }

  .my-spinner-animation-enter-active,
  .my-spinner-animation-leave-active {
    transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }
  .my-spinner-animation-enter-from,
  .my-spinner-animation-leave-to {
    opacity: 0;
  }

  .spin {
    display: block;
    width: 4rem;
    height: 4rem;
    margin: 2rem auto;
    border: 0.75rem solid transparent;
    border-radius: 50%;
    border-top-color: #00A4B0;
    animation: spin 1s ease infinite;
  }

  @keyframes spin {
    to {transform: rotateZ(360deg);}
  }
</style>