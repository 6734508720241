<template>
  <div id="alertMessageBox">
    <transition name="alrt-fade-slide">
      <div v-if="alertVisible" class="alert col-12 col-md-10 d-flex align-items-center alert-dismissible fade show" :class="alertNumber" role="alert" >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
        <div class="mx-auto">
          {{msg}}
        </div>
        <div>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="alertCloseClkd"></button>
        </div>
      </div>
    </transition>
  </div> 
</template>

<script>

import {ref, onMounted, onUnmounted, computed} from "vue"
export default {
  name: "pAlerts",
  props: ["alertMsg", "alertLevel"],
  emits: ["closeAlert"],
  setup(props, context) {
    // setup(props) {
    const msg = ref("")
    const lvl = ref(null)
    const alertVisible = ref(false)

    const triggerAlert = () => {
      alertVisible.value = true;
      setTimeout(function timeout() {
        alertVisible.value = false
        context.emit("closeAlert")
      }, 10000)
    }

    // const alertLevel = reactive({"alert-contrast":true})
    const alertNumber = computed(() => {
      if (props.alertLevel) {
        if (props.alertLevel == 1){
          return {"alert-contrast":true} 
        } 
        else {
          return {"alert-lowlight":true}
        }
      }
      else {
        return {"alert-contrast":true} 
      }
    })

    const alertCloseClkd = () => {
      alertVisible.value = false
      context.emit("closeAlert")
    }

    onMounted(() => {
      console.log("onMounted - pAlert")
      msg.value = props.alertMsg
      lvl.value = props.alertLevel
      triggerAlert()
      // alertVisible.value = props.alertVis
    })

    onUnmounted(() => {
      console.log("onUnmounted - pAlert") 
      msg.value = null
      lvl.value = null
      alertVisible.value = false
    })

    return {triggerAlert, alertVisible, msg, lvl, alertNumber, alertCloseClkd}
  }
}
</script>

<style>
#alertMessageBox{
  z-index: 5000;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem auto;
}

.alert{
  width: 32rem; 
  max-width: 80%;
  min-width: 20rem;
  box-shadow: 0.125rem 0.25rem 0.25rem 0.2rem rgba(0,0,0,0.5); 
}
  .alrt-fade-slide-enter-from{
    transform: translateY(-10rem);
    opacity: 0;
  }
  .alrt-fade-slide-enter-to{
    transform: translateY(0);
    opacity: 1;
  }
  .alrt-fade-slide-enter-active{
    transition: all 0.8s ease;
  }


  .alrt-fade-slide-leave-from{
    opacity: 1;
    transform: translateY(0);
  }
  .alrt-fade-slide-leave-to{
    transform: translateY(-10rem);
    opacity: 0;
  }
  .alrt-fade-slide-leave-active{
    transition: all 0.8s ease;
  }
</style>