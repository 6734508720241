<template>
  <div id="teleModal" ref="modalEntire">
    <transition name="my-modal-animation">
      <div v-if="type != 'slide'" class="my-modal container-fluid" id="modalWrap">
        <transition name="my-modal-animation-inner">
          <div class="my-modal-inner position-relative p-1 p-md-2 m-0" id="modalInn">
            <div ref="el">
            <!-- CLOSE ICONS -->
              <div>
                <span class="tt position-absolute top-0 end-0" data-bs-placement="left" title="close window">
                  <button v-if="type != 'slide'" class="d-block p-2" @click.prevent="closeMod" style="background-color: rgba(0,0,0,0); border: none;">
                    <icon-base
                      class="icon-close"
                      width="24"
                      height="24"
                      icon-color="#111111"
                      icon-hover-color="#00A4B0"
                      >
                      <icon-close />
                    </icon-base>
                  </button>
                  <button v-else class="d-block p-2" @click.prevent="closeMod" style="background-color: rgba(0,0,0,0); border: none;">
                    <icon-base
                      class="icon-close"
                      width="24"
                      height="24"
                      icon-color="#111111"
                      icon-hover-color="#00A4B0"
                      >
                      <icon-close />
                    </icon-base>
                  </button>
                </span>              
              </div>
              <!-- Modal Title -->
              <div class="row p-0 m-0">
                <div class="col-12 mx-auto text-start text-wrap"> 
                  <p v-if="type === 'Reset Password'" class="display-5 fw-light mb-1 mb-lg-2 text-uppercase text-charcoal lh-sm">Reset</p>
                  <p v-else class="display-5 fw-light mb-1 mb-lg-2 text-uppercase text-charcoal lh-sm">{{type}}</p>
                </div>                 
              </div>
              <div class="modCont px-2 px-md-3" ref="modalContent" id="modCon">
                <!-- LOGIN MODAL -->
                <div v-if="type === 'Login'" class="row p-0 m-0">
                  <Login @closeModalSub="closeMod" @showAlertSub="showAlertBox" @toggleNav="toggleNav" @switchModalSub="switchModalContent"
                  @switchModalDashData="switchModalDashData" :mobileView="mobileView" />                 
                </div>
                <!-- LOGOUT MODAL -->
                <div v-if="type === 'Logout'" class="row p-0 m-0">
                  <Logout @closeModalSub="closeMod" @showAlertSub="showAlertBox" @toggleNav="toggleNav" :mobileView="mobileView" />
                </div>
                <!-- NEWSLETTER MODAL -->
                <div v-if="type === 'Newsletter'" class="row p-0 m-0">
                  <Newsletter @closeModalSub="closeMod" @showAlertSub="showAlertBox" :mobileView="mobileView"/> 
                </div>
                <!-- RESET PASSWORD MODAL -->
                <div v-if="type === 'Reset Password'" class="row p-0 m-0">
                  <ResetPassword @closeModalSub="closeMod" @showAlertSub="showAlertBox" :mobileView="mobileView"/> 
                </div>
                <!-- VERIFY EMAIL MODAL -->
                <div v-if="type === 'Verify Email'" class="row p-0 m-0">
                  <Verify @closeModalSub="closeMod" @showAlertSub="showAlertBox" :mobileView="mobileView"/> 
                </div>
                <!-- CLIENT LOGIN MODAL -->
                <div v-if="type === 'Client Login'" class="row p-0 m-0">
                  <Client :modData="modData" @closeModalSub="closeMod" @showAlertSub="showAlertBox" :mobileView="mobileView"/> 
                </div>
                <!-- SIGNUP MODAL -->
                <div v-if="type === 'Signup'" class="row p-0 m-0">
                  <Signup @closeModalSub="closeMod" @showAlertSub="showAlertBox" @switchModalSub="switchModalContent" @switchModalDashData="switchModalDashData" :mobileView="mobileView"/>
                </div>
                <!-- QUICK SIGNUP MODAL -->
                <div v-if="type === 'Quick Signup'" class="row p-0 m-0">
                  <QuickSignup @closeModalSub="closeMod" @showAlertSub="showAlertBox" @switchModalSub="switchModalContent" :modData="modData" :mobileView="mobileView" />
                </div>
                <!-- ADD CLIENT MODAL -->
                <div v-if="type === 'Add Client'" class="row p-0 m-0">
                  <AddClient @closeModalSub="closeMod" @showAlertSub="showAlertBox" @switchModalDashData="switchModalDashData" :mobileView="mobileView"/>
                </div>
                <!-- ADD SHOOT MODAL -->
                <div v-if="type === 'Add Shoot'" class="row p-0 m-0">
                  <AddShoot @closeModalSub="closeMod" @showAlertSub="showAlertBox" @switchModalDashData="switchModalDashData" :mobileView="mobileView"/>
                </div>
                <!-- ADD IMAGE MODAL -->
                <div v-if="type === 'Add Image'" class="row p-0 m-0">
                  <AddImage @closeModalSub="closeMod" @showAlertSub="showAlertBox" @switchModalDashData="switchModalDashData" :mobileView="mobileView"/>
                </div>
                <!-- SUCCESS MODAL -->
                <div v-if="type === 'Success'" class="row p-0 m-0">
                  <Success 
                  :modData="modData"
                  :mobileView="mobileView"
                  @closeModalSub="closeMod"
                  @switchModalSub="switchModalContent"/>
                </div>
                <!-- HELP MODAL -->
                <div v-if="type === 'Help'" class="row p-0 m-0">
                  <Help 
                  :modData="modData"
                  :mobileView="mobileView"
                  @closeModalSub="closeMod"
                  @switchModalDashData="switchModalDashData"/>
                </div>
                <!-- PROMO VIDEO MODAL -->
                <div v-if="type === 'PHOTAR'" class="row p-0 m-0">
                  <PromoVideo 
                  :modData="modData"
                  :mobileView="mobileView"
                  @closeModalSub="closeMod"
                  @switchModalDashData="switchModalDashData"/>
                </div>
                <!-- SELECT MODAL -->
                <div v-if="type === 'Select'" class="row p-0 m-0">
                  <Select 
                  :modData="modData"
                  :mobileView="mobileView"
                  @closeModalSub="closeMod"
                  @switchModalSub="switchModalContent"
                  />
                </div>
                <!-- DELETE MODAL -->
                <div v-if="type === 'Delete'" class="row p-0 m-0">
                  <Delete 
                  :modData="modData"
                  :mobileView="mobileView"
                  @showAlertSub="showAlertBox" 
                  @closeModalSub="closeMod"
                  @tabReset="tabReset"
                  />
                </div>
                <!-- EDIT MODAL -->
                <div v-if="type === 'Edit'" class="row p-0 m-0">
                  <Edit 
                  :modData="modData"
                  :mobileView="mobileView"
                  @showAlertSub="showAlertBox" 
                  @closeModalSub="closeMod"
                  />
                </div>
                <!-- UPGRADE MODAL -->
                <div v-if="type === 'Upgrade'" class="row p-0 m-0">
                  <Upgrade 
                  :modData="modData"
                  :mobileView="mobileView"
                  @showAlertSub="showAlertBox" 
                  @closeModalSub="closeMod"
                  />
                </div>
                <!-- TRIAL SIGNUP MODAL -->
                <div v-if="type === 'TrialSignup'" class="row p-0 m-0">
                  <TrialSignup 
                  :modData="modData"
                  :mobileView="mobileView"
                  @showAlertSub="showAlertBox" 
                  @closeModalSub="closeMod"
                  />
                </div>                
                <!-- DETAILS MODAL -->
                <div v-if="type === 'Details'" class="row p-0 m-0">
                  <Details 
                  :modData="modData"
                  :mobileView="mobileView"
                  @showAlertSub="showAlertBox" 
                  @closeModalSub="closeMod"
                  @switchModalDashData="switchModalDashData"
                  @switchModalSub="switchModalContent"
                  />
                </div>
              </div>
            </div>  
          </div>
        </transition>
      </div>    
      <div v-else class="my-modal container-fluid" id="modalWrapSlide">
        <transition name="my-modal-animation-inner">
          <div class="position-relative p-1 m-0" id="modalSlide">
            <div ref="elslide">
            <!-- CLOSE ICONS -->
              <div>
                <span class="tt position-absolute top-0 end-0 translate-middle-x" data-bs-placement="left" title="close window">
                <button class="d-block p-2" @click.prevent="closeMod" style="background-color: rgba(0,0,0,0); border: none;">
                    <icon-base
                      class="icon-close"
                      width="24"
                      height="24"
                      icon-color="#111111"
                      icon-hover-color="#00A4B0"
                      >
                      <icon-close />
                    </icon-base>
                  </button>
                </span>              
              </div>
             
              <div class="modCont px-2" ref="modalContent" id="modConSlide">               
                <!-- SLIDESHOW MODAL -->
                <div v-if="type === 'slide'" class="row p-0 m-0">
                  <SlideShow 
                  :modData="modData"
                  :mobileView="mobileView"
                  @closeModalSub="closeMod"
                  />
                </div>                
              </div>
            </div>  
          </div>
        </transition>
      </div>    
    </transition>  
  </div> 
</template>

<script>
import {ref, onMounted, onUnmounted, watchEffect, onBeforeUnmount} from "vue"


import Logout from "../components/ModalContent/Logout.vue"
import Login from "../components/ModalContent/Login.vue"
import ResetPassword from "../components/ModalContent/ResetPassword.vue"
import Newsletter from "../components/ModalContent/Newsletter.vue"
import Signup from "../components/ModalContent/Signup.vue"
import QuickSignup from "../components/ModalContent/QuickSignup.vue"
import Verify from "../components/ModalContent/Verify.vue"
import AddClient from "../components/ModalContent/AddClient.vue"
import AddShoot from "../components/ModalContent/AddShoot.vue"
import AddImage from "../components/ModalContent/AddImage.vue"
import Success from "../components/ModalContent/Success.vue"
import Help from "../components/ModalContent/Help.vue"
import PromoVideo from "../components/ModalContent/PromoVideo.vue"
import Select from "../components/ModalContent/Select.vue"
import Delete from "../components/ModalContent/Delete.vue"
import Edit from "../components/ModalContent/Edit.vue"
import Details from "../components/ModalContent/Details.vue"
import Client from "../components/ModalContent/Client.vue"
import Upgrade from "../components/ModalContent/Upgrade.vue"
import TrialSignup from "../components/ModalContent/TrialSignup.vue"
import SlideShow from "../components/ModalContent/SlideShow.vue"

import IconBase from "./Icons/IconBase.vue"
import IconClose from "./Icons/IconClose.vue"
import {Tooltip} from "bootstrap"
export default {
  name: "pModal",
  props: ["modType", "modData", "mobileView"],
  emits: ["closeModal", "showAlert", "switchModal", "switchModWithData", "toggleNav", "tabReset"],
  components: {IconBase, IconClose, Logout, Login, ResetPassword, Signup, Verify, AddClient, AddShoot, AddImage, Success, Help, Select, Delete, Edit, QuickSignup, Details, Client, Upgrade, SlideShow, TrialSignup, Newsletter, PromoVideo},
  setup(props, context) {
    
    const type = ref("")
    // console.log("props.modType - ", props.modType)
    const modalEntire = ref(null)
    // const modalInn = ref(null)
    const el = ref(null)
    const elslide = ref(null)
    const modalContent = ref(null)
    const switchTo = ref(null)
    let modalSlideBG = ref(null)
    let modalSlideInner = ref(null)
    onMounted(()=> {
      type.value = props.modType      
    })

    const stopModalWatch = watchEffect(() => {
      //tooltips
      if (el.value) {
        // console.log("watchEffect - tooltips - ", el.value)
        let modTTips = el.value.querySelectorAll(".tt")
        // console.log("tooltips - ", modTTips)
        modTTips.forEach(t => {
          new Tooltip(t, { boundary: document.body, trigger: "hover" })
          // console.log("tooltip added")
        })    
        if(type.value === "slide"){
          console.log("is Slide")
          if(modalEntire.value){
            console.log("is modaBG")
            modalSlideBG.value = modalEntire.value.querySelector("#modalWrap") 
            let pModCon = modalEntire.value.querySelector("#modCon")
            modalSlideInner.value = modalEntire.value.querySelector("#modalInn") 
            pModCon.classList.remove("modCont", "px-2", "px-md-3")
            modalSlideBG.value.classList.add("dark-bg")
            modalSlideInner.value.classList.add("slide-holder")
          }
        }    
      }   
    })

    onBeforeUnmount(() => {
      //-----REMOVE TOOLTIPS
      if (el.value) {
        // console.log("watchEffect - tooltips - ", el.value)
        let modTTips = el.value.querySelectorAll(".tt")
        // console.log("pModal onb4Unmount - tooltips - ", modTTips)
        // console.log("pModal onb4Unmount - tooltips - ", modTTips.length)
        if ((modTTips) && (modTTips.length > 0 || modTTips != null))
        {
          // console.log("tooltips - ", modTTips)
          modTTips.forEach(t => {
          let dispTT = Tooltip.getInstance(t)

          dispTT.hide()
          // console.log("tooltips removed")
        })    
        }
            
      }     
      
    })
    onUnmounted(()=> {
      //-----DISABLE WATCH EFFECT
      console.log("onUnmounted - pModal")
      stopModalWatch()
      if(switchTo.value != null)
      {
        context.emit("switchModal", switchTo ) 
      }      
    })
    
    const tabReset = () => {
      console.log("pModal - tabReset")
      context.emit("tabReset")
    }

    const toggleNav = () => {
      console.log("pModal - toggleNav")
      context.emit("toggleNav")
    }

    const closeMod = () => {
      console.log("pModal - closeMod")
      context.emit("closeModal")
    }

    const showAlertBox = (errArr) => {
      console.log("pModal - showAlertBox")
      context.emit("showAlert", errArr)
    }

    const switchModalDashData = (switchModData) => {
      console.log("pModal - switchModalDashData - ", switchModData)
      context.emit("switchModWithData", switchModData)
      switchTo.value = switchModData.title
      context.emit("closeModal")
      
    }    
    const switchModalContent = (switchModTo) => {
      console.log("pModal - switchModalContent - ", switchModTo)
      switchTo.value = switchModTo
      context.emit("closeModal")
    }    
    return { closeMod, showAlertBox, switchModalContent, switchModalDashData, type, el, elslide, modalContent, toggleNav, modalEntire, tabReset }
  } 
}
</script>

<style scoped>
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #00A4B0;
}


.modCont{
  width: 100%;
  background: #fff;
}

.my-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
}
.dark-bg {
  background-color: rgba(0, 0, 0, 0.7);
}
.slide-holder {
  border-radius: 0.25rem !important;
  /* height: 80% !important;   */
  height: fit-content !important; 
  /* height: calc(100% + 500px); */
  /* width: 80% !important; */
  max-width: 95vw !important;
  max-height: vh !important;

  width: fit-content !important;
  /* min-height: 15rem; */
  overflow-y: hidden !important;
  /* max-height: 75%; */
  /* max-width: 75%; */
  /* min-width: 20rem; */
  padding: 1rem !important; 
  box-shadow: 0.125rem 0.25rem 0.25rem 0.2rem rgba(0,0,0,0.5) !important; 
  background-color: #F0F2F5 !important;
}
.my-modal-inner {
  border-radius: 0.25rem;
  height: fit-content;  
  /* height: calc(100% + 500px); */
  width: 30rem; 
  /* min-height: 15rem; */
  overflow-y: auto;
  max-height: 90%;
  max-width: 32rem;
  min-width: 20rem;
  padding: 0.5rem; 
  box-shadow: 0.125rem 0.25rem 0.25rem 0.2rem rgba(0,0,0,0.5); 
  background-color: #fff;
}

.my-modal-animation-enter-active,
.my-modal-animation-leave-active {
  /* transition: opacity 0.4s cubic-bezier(0.52, 0.02, 0.19, 1.02); */
  transition: opacity 0.4s cubic-bezier(0,.12,.14,1);

}
.my-modal-animation-enter-from,
.my-modal-animation-leave-to {
  opacity: 0;
}

.my-modal-animation-inner-enter-active {
  transition: all 0.4s cubic-bezier(0,.12,.14,1);
}
.my-modal-animation-inner-leave-active {
  transition: all 0.4s cubic-bezier(0,.12,.14,1);
}
.my-modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.my-modal-animation-inner-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

</style>