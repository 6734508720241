<template>
  <div>
    <div class="col-12 col-sm-11 mx-auto">
      <p class="fs-6 p-0 m-0 text-center mb-1">Enter your email address to receive the PHOTAR Newsletter</p>                  
    </div>
    <div ref="newsletterFormMod" class="col-12 col-sm-11 p-0 m-0 mx-auto">
      <form class="needs-validation" novalidate @submit.prevent="handleNewsletterSignup">
        <div class="mb-1 pb-3" @click="assignFocus('newsletterEmail')">
          <label for="newsletterEmail" class="form-label" >Email</label>
          <div class="input-group position-relative" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="email" class="form-control" id="newsletterEmail" name="newsletterEmail" placeholder="Enter Email" required v-model="newsletterEmail" autocomplete="off">
            <div class="invalid-tooltip">Please provide a valid Email Address</div>
          </div>
        </div> 
        <div class="mt-2 col-12 mx-auto">
          <button v-show="!newsletterPending" type="submit" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Sign Me Up</button> 
          <button v-show="newsletterPending" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' disabled>In Progress</button>  
        </div>        
      </form>
    </div>                  
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-3 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div> 
  </div>                     
</template>

<script>
import {validateNewsletterEmail} from "../../../public/js/validation.js"
import { ref, watchEffect, onBeforeUnmount, onUnmounted, computed } from "vue"
import { doc, setDoc, Timestamp } from "firebase/firestore";
import {db} from "../../firebase/config"
import Spinner from "@/components/Spinner.vue"

export default {
name: "NewsletterModContent",
emits: ["closeModalSub", "showAlertSub"],
components: {Spinner},
props: ["mobileView"],
setup(props, context){

    const newsletterFormMod = ref(null)
    const firstFocus = ref(null)

    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)
    let newsletterPending = ref(false)
    const newsletterEmail = ref(null)
    const stopNewsletterFormModWatch = watchEffect(() => {
      console.log("watchEffect - stopNewsletterFormModWatch")
      if (firstFocus.value) {
        firstFocus.value.focus()
      }
      if(newsletterFormMod.value) {
        if(newsletterFormMod.value.children.length > 0) {        
          console.log("newsletterFormMod - setting uplisteners")
           newsletterFormMod.value.querySelector("#newsletterEmail").addEventListener("blur", validateNewsletterEmail)
        }
      }
    })

    const signupDate = computed(() => {
      let sUpDate = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
      return sUpDate
    })

    onBeforeUnmount(() => {      
      if(newsletterFormMod.value) {
        newsletterFormMod.value.querySelector("#newsletterEmail").removeEventListener("blur", validateNewsletterEmail)  
        inProgress.value = false     
      }
    })

    onUnmounted(()=> {
      // console.log("pModal>Newsletter - onUnmounted")
      stopNewsletterFormModWatch()          
    })    

    const closeSubMod = () => {
      // console.log("pModal>Newletter>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    const assignFocus = (focusArea) => {
      // console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = newsletterFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    //handle newsletter signup
    const handleNewsletterSignup = () => {
      console.log("handleNewsletterSignup")
      let form = newsletterFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateNewsletterEmail()) {
         errorMessage.value = "Please check you've entered a valid email address"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)        
          newsletterEmail.value = null     
          inProgress.value = false   
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)     
      }
      else {
        form.classList.add("was-validated")
        inProgress.value = true         
        try{
          newsletterPending.value = true
          setDoc(doc(db, "newsletter", newsletterEmail.value), {
            email: newsletterEmail.value, 
            signupDate: signupDate.value
          }).then(() => {
            errorMessage.value = "Congratulations - Newsletter signup complete"
            let alertLvl = 2
            errorArray.value.push(alertLvl)
            errorArray.value.push(errorMessage.value)
            context.emit("showAlertSub", errorArray)    
            context.emit("closeModalSub")
            inProgress.value = false
            newsletterPending.value = false
          })
        } catch(err) {
          errorMessage.value = "An error has occurred please check the email or contact PHOTAR support"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)        
          newsletterEmail.value = null
          inProgress.value = false   
          newsletterPending.value = false
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)
        }
      }
    }
    
    return {handleNewsletterSignup, closeSubMod, firstFocus, inProgress, assignFocus, newsletterFormMod, newsletterEmail, newsletterPending}
}
}
</script>

<style>

</style>