<template>
  <div id="footer" class="bg-charcoal container-fluid">
    <div class="container d-block d-lg-none py-1">
      <div class="row py-1">       
        <div class="col-5 mx-auto text-center">
          <img :src="require('../assets/icons/Photar-Logo-Text-Light.svg')" alt="Photar Text" class="footer-logo-text" style="max-height:2.25rem;">
        </div>
        <div class="col-7 text-center">
         <ul class="list-inline m-0">
           <li class="list-inline-item mx-1">
             <button @click="gotoFacebook" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-facebook"
                width="2.25rem"
                height="2.25rem"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-facebook />
              </icon-base>
            </button>                    
           </li>
           <li class="list-inline-item mx-1">
             <button @click="gotoInstagram" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-instagram"
                width="2.25rem"
                height="2.25rem"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-instagram />
              </icon-base>
            </button>                     
           </li>
           <li class="list-inline-item mx-1">
             <button @click="gotoYoutube" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-youtube"
                width="2.25rem"
                height="2.25rem"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-youtube />
              </icon-base>
            </button>                             
           </li>
         </ul>
        </div> 
      </div>      
    </div>   
    <div class="container d-none d-lg-block py-2">
      <div class="row">
        <div class="col-4 text-start pt-1">
          <router-link :to='{ name: "Home" }'>            
            <img :src="require('../assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar Logo" class="footer-logo-lg">
          </router-link>
        </div>
        <div class="col-4 text-center pt-1">
         <ul class="list-inline m-0">
           <li class="list-inline-item mx-2">             
            <button class="col" @click="gotoFacebook" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-facebook"
                width="48"
                height="48"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-facebook />
              </icon-base>
            </button>                   
           </li>
           <li class="list-inline-item mx-2">             
            <button class="col" @click="gotoInstagram" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-instagram"
                width="48"
                height="48"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-instagram />
              </icon-base>
            </button>                        
           </li>
           <li class="list-inline-item mx-2">            
            <button class="col" @click="gotoYoutube" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-youtube"
                width="48"
                height="48"
                icon-color="#FFFFFF"
                icon-hover-color="#00A4B0"
                >
                <icon-youtube />
              </icon-base>
            </button>                             
           </li>
         </ul>
        </div>   
        <div class="col-4 text-end">
          <p class="small text-white lh-0 p-0 m-0">A division of <a href="https://www.morlove.co.uk/" target="_blank" class="text-decoration-underline text-highlight">MorLove</a></p>   
          <p class="small text-white lh-0 p-0 m-0">&copy; Copyright. All Rights Reserved</p>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "./Icons/IconBase.vue"
import IconFacebook from "./Icons/IconFacebook.vue"
import IconInstagram from "./Icons/IconInstagram.vue"
import IconYoutube from "./Icons/IconYoutube.vue"


export default {
  name: "FooterBar",
  components: {IconBase, IconFacebook, IconInstagram, IconYoutube},
  setup(){
    const gotoFacebook = () => {
      console.log("gotoFacebook link")
      window.open("https://www.facebook.com/photarUK");
    }
    const gotoInstagram = () => {
      console.log("gotoInstagram link")
      window.open("https://www.instagram.com/photaruk");
    }
    const gotoYoutube = () => {
      console.log("gotoYoutube link")
      window.open("https://www.youtube.com/channel/UCAMCPYAerMYlayPxI2EZkXA");
    }

    return {gotoFacebook, gotoInstagram, gotoYoutube}
  }

}
</script>

<style scoped>
.footer-logo-lg{
  height: 2.5rem;
}

</style>