import { ref } from "vue"
// firebase imports

import { getAuth, updateProfile } from "firebase/auth"
const error = ref(null)
const isPending = ref(false)

const updateUserProfile = (data) => {  
  error.value = null
  isPending.value = true  
  const auth = getAuth();
  updateProfile(auth.currentUser, { displayName : data})
  .then(() => {
    error.value = null
    isPending.value = false     
  }).catch((error) => {
    console.log("profileUpdate - Err - ",error)
    error.value = "Could not update user profile"
    isPending.value = false
  });   
}

const profileUpdate = () => {
  return { updateUserProfile, error, isPending }
}

export default profileUpdate