<template>
  <!-- <div id="photar-app" class="container-fluid p-0"> -->
  <div id="photar-app">    
    <NavigationMobile v-if="testing" @closeMobNav="toggleNav" @showModal="showModal" @switchModalDashData="switchModWithData" />
    <div class="content" :class="{'open': showNav}">
      <header v-if="testing" class="top-bar bg-charcoal">
        <div id="logo-icon" v-if="mobileView" class="text-start p-0 m-0 px-2 py-1">
          <a class="d-block d-sm-none" href="#">
            <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 150px;">
          </a>
          <a class="d-none d-sm-block d-md-none" href="#">
            <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 175px;">
          </a>
          <a class="d-none d-md-block d-lg-none" href="#">
            <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 200px;">
          </a>
        </div>
        <div id="navigation-icon" v-if="mobileView" class="ms-auto text-end p-0 m-0" @click="toggleNav">
          <button class="p-2 pe-3" style="background-color: rgba(0,0,0,0); border: none;">
            <icon-base
              class="icon-expand d-block d-sm-none"
              width="32"
              height="32"
              icon-color="#ffffff"
              icon-hover-color="#00A4B0"
              >
              <icon-expand />
            </icon-base>
            <icon-base
              class="icon-expand d-none d-sm-block d-md-none"
              width="40"
              height="40"
              icon-color="#ffffff"
              icon-hover-color="#00A4B0"
              >
              <icon-expand />
            </icon-base>
            <icon-base
              class="icon-expand d-none d-md-block d-lg-none"
              width="48"
              height="48"
              icon-color="#ffffff"
              icon-hover-color="#00A4B0"
              >
              <icon-expand />
            </icon-base>
          </button>      
        </div>
        <Navigation v-if="!mobileView" @showModal="showModal" @switchModalDashData="switchModWithData"/>
      </header>
      <main>
        <div class="spa-body" >
          <router-view v-slot="{Component}">
            <transition name="fade" mode="out-in">
              <component :is="Component" :key="$route.path"
                :mobileView="mobileView"
                @showModal="showModal" 
                @showSlideModal="showSlideModal" 
                @showDataModal="showDataModal" 
                @showAlertDash="showAlert"
                @showAlert="showAlert"
                @toggleTest="toggleTest"
                ref="mainDash">
              </component>
            </transition>   
          </router-view>
        </div>    
      </main>
      <footer v-if="testing" class="mt-auto fixed-bottom" id="foot">
        <Footer />
      </footer> 
    </div>
    <!-- comment out from here -->
    <!-- <div v-if="prelaunch" id="landing" style="height:100vh">
      <router-view v-slot="{Component}">
        <component :is="Component" :key="$route.path"
        :mobileView="mobileView"
        @showDataModal="showDataModal" @showModal="showModal" > 
        </component>
      </router-view>    
    </div>
    <div v-else> -->
      <!-- to here -->
      <!-- <NavigationMobile @closeMobNav="toggleNav" @showModal="showModal" @switchModalDashData="switchModWithData" />
      <div class="content" :class="{'open': showNav}">
        <header class="top-bar bg-charcoal">
          <div id="logo-icon" v-if="mobileView" class="text-start p-0 m-0 px-2 py-1">
            <a class="d-block d-sm-none" href="#">
              <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 150px;">
            </a>
            <a class="d-none d-sm-block d-md-none" href="#">
              <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 175px;">
            </a>
            <a class="d-none d-md-block d-lg-none" href="#">
              <img :src="require('./assets/icons/Photar-Logo-Full-Light.svg')" alt="Photar logo" style="width: 200px;">
            </a>
          </div>
          <div id="navigation-icon" v-if="mobileView" class="ms-auto text-end p-0 m-0" @click="toggleNav">
            <button class="p-2 pe-3" style="background-color: rgba(0,0,0,0); border: none;">
              <icon-base
                class="icon-expand d-block d-sm-none"
                width="32"
                height="32"
                icon-color="#ffffff"
                icon-hover-color="#00A4B0"
                >
                <icon-expand />
              </icon-base>
              <icon-base
                class="icon-expand d-none d-sm-block d-md-none"
                width="40"
                height="40"
                icon-color="#ffffff"
                icon-hover-color="#00A4B0"
                >
                <icon-expand />
              </icon-base>
              <icon-base
                class="icon-expand d-none d-md-block d-lg-none"
                width="48"
                height="48"
                icon-color="#ffffff"
                icon-hover-color="#00A4B0"
                >
                <icon-expand />
              </icon-base>
            </button>      
          </div>
          <Navigation v-if="!mobileView" @showModal="showModal" @switchModalDashData="switchModWithData"/>
        </header>
        <main>
          <div class="spa-body" >
            <router-view v-slot="{Component}">
              <transition name="fade" mode="out-in">
                <component :is="Component" :key="$route.path"
                :mobileView="mobileView"
                @showModal="showModal" 
                @showSlideModal="showSlideModal" 
                @showDataModal="showDataModal" 
                @showAlertDash="showAlert"
                @showAlert="showAlert"
                @toggleTest="toggleTest"
                ref="mainDash"></component>
              </transition>   
            </router-view>
          </div>    
        </main>
        <footer class="mt-auto fixed-bottom" id="foot">
          <Footer />
        </footer> 
      </div> -->
        <!-- comment out from here -->
    <!-- </div> -->
    <!-- to here -->
      <teleport to="#modalWin">
        <p-modal 
          v-if="toggleMod"
          @toggleNav=toggleNav
          @closeModal=closeModal
          @showAlert=showAlert
          @switchModal=switchModal
          @switchModWithData="switchModWithData"
          @tabReset="tabReset"
          :modType="modType"
          :modData="modData"
          :mobileView="mobileView">
        </p-modal>
      </teleport>
      <teleport to="#alertMsg">
        <p-alerts 
          v-if="toggleAlert" 
          :alertMsg="alertMessage" 
          :alertLevel="alertLev"
          @closeAlert=closeAlert>
        </p-alerts>
      </teleport>
    
  </div>  
</template>

<script>
import Navigation from "./components/Navigation.vue"
import NavigationMobile from "./components/NavigationMobile.vue"
import IconBase from "./components/Icons/IconBase.vue"
import IconExpand from "./components/Icons/IconExpand.vue"
import { ref, onMounted } from "vue"
import pModal from "./components/pModal.vue"
import pAlerts from "./components/pAlerts.vue"
import Footer from "./components/Footer.vue"
import { getAuth } from "firebase/auth";

import {initStore} from "./composables/store/store"
  export default {
    name: "PhotarSite",
    components: { Navigation, NavigationMobile, IconBase, IconExpand, pModal, pAlerts, Footer},
    setup() {
      const mobileView = ref(false)
      const showNav = ref(false)
      const mainDash = ref(null)

      const testing = ref(false)
      const toggleMod = ref(false)
      const modType = ref(null)
      const modData = ref(null)

      const toggleAlert = ref(false)
      const alertMessage = ref(null)
      const alertLev = ref(null)
      
      const auth = getAuth();
      const currUser = auth.currentUser;

      if (currUser) {
        testing.value = true
      } else {
        testing.value = false
      }

      // const wHeight = ref(null)
      initStore();

      onMounted(() => {
        handleView();
        window.addEventListener("resize", handleView)
      })

      const toggleTest = () => {
        console.log("Running toggleTest > App")
        testing.value = !testing.value
      }

      const handleView = () => {
        // console.log("run handleView")
        if(window.innerWidth < 767)
        {
          mobileView.value = true
        }
        else
        {
          mobileView.value = false
        }
      }


      const toggleNav = () => {
        if(mobileView.value){
          // console.log("run toggle nav")
        let mobNavBox = document.querySelector(".nav-mob")
        let mobNavH = mobNavBox.offsetHeight - 2
        // console.log("mobNavBox.offsetHeight - ", mobNavBox.offsetHeight)
        // console.log("mobNavH - ", mobNavH)
        let mobNavHeight = `${mobNavH}px`
        // let mNHStr = mobNavHeight.toString()
        // mobNavHeight = mobNavHeight.toString()
        let bodyStyles = document.body.style;
        bodyStyles.setProperty("--mob-menu-H", mobNavHeight);
        // console.log("mobNavHeight - ", mobNavHeight)
        // console.log("mNHStr - ", mNHStr)
        showNav.value= !showNav.value
        }
      }

      const showModal = (modalName) => {
        // console.log("App.vue - show modal - ", modalName)
        // console.log("App.vue - show modal - ", toggleMod.value)
        modType.value = modalName
        toggleMod.value = true
      }
      const showDataModal = (modD) => {
        // console.log("App.vue - show Data modal - ", modD)
        // console.log("App.vue - show modal - ", toggleMod.value)
        modType.value = modD.title
        modData.value = modD
        // console.log("modData - ", modData.value)
        toggleMod.value = true
      }
      const showSlideModal = (modD) => {
        // console.log("App.vue - show Slide Modal - ", modD)
        // console.log("App.vue - show modal - ", toggleMod.value)
        modType.value = "slide"
        modData.value = modD
        console.log("modData - ", modData.value)
        toggleMod.value = true
      }

      const tabReset = () => {
        console.log("App - tabReset")
        // console.log(mainDash.value)
        mainDash.value.tabReset()
        
      }


      const closeModal = () => {
        console.log("App.vue - close modal")
        toggleMod.value = false
      }
      const switchModal = (switchTo) => {
        console.log("App.vue - switchclose modal - ", switchTo.value)
        showModal(switchTo.value)        
      }
      const switchModWithData = (switchModData) => {
        console.log("App.vue - switchModWithData - ", switchModData)
        modType.value = switchModData.title
        modData.value = switchModData
        // console.log("modData - ", modType.value)
        toggleMod.value = true
      }
     
      const showAlert = (alertArray) => {
        console.log("App.vue - show alert - ", alertArray.value)
        // console.log("App.vue - show modal - ", toggleMod.value)
        alertLev.value = alertArray.value[0]
        alertMessage.value = alertArray.value[1]
        toggleAlert.value = true
      }

      const closeAlert = () => {
        console.log("App.vue - close alert")
        alertLev.value = null
        alertMessage.value = null
        toggleAlert.value = false
      }


     


  

      return{mobileView, showNav, toggleNav, showModal, showDataModal, modData, toggleMod, modType, closeModal, switchModal, showAlert, closeAlert, toggleAlert, alertMessage, alertLev, switchModWithData, showSlideModal, tabReset, mainDash, testing, toggleTest}
    }
  }
</script>
<style>
:root {
  --mob-menu-H: 2rem;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; 
}
#photar-app {
  position: relative;
  width: 100%;
  height: 100vh;
  color: #333;
  overflow-x: hidden;
}
.content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  transition: 1s transform cubic-bezier(0,.12,.14,1);
}

.top-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#navigation-icon {
  padding: 0.5rem 0.5rem 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
#logo-icon {
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}

.open {
  transform: translateY(var(--mob-menu-H));
}

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.1s;
}

.fade-enter-from, 
.fade-leave-to{
  opacity: 0;  
}

/* #foot {
  position: absolute;
  bottom: 0;
  width: 100%;
} */
</style>
