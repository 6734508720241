import { createRouter, createWebHistory } from "vue-router"
import { auth } from "../firebase/config"
import Home from "../views/Home.vue"
// import Test from "../views/Test.vue"
import ComingSoon from "../views/ComingSoon.vue"


const requireAuthAccount = (to, from, next) => {
  // console.log("Router index - requireAuthAccount - ")
    let user = auth.currentUser
    if (!user) {
      next({ name: "Home" })
    } else {
      next()
    }
  }
 

// const requireAdminAccount = (to, from, next) => {
const requireAdminAccount = () => {
  console.log("Router index - requireAdminAccount")
  // let { user, valid, access } = getUser() 
  // if (!user) {
  //   next({ name: "Home" })
  // } else if (!valid) {
  //   // next({ name: "VerifyUser"})
  // } else if (access < 10) {
  //   next({ name: "Home" })
  // }else {
  //   next()
  // }
}



const routes = [
  {
    path: "/ComingSoon",
    name: "ComingSoon",
    props: true,
    component: ComingSoon,
  },
  {
    path: "/",
    name: "Home",
    props: true,
    component: Home,
  },
  {
    // path: "/dashboard/:id",
    path: "/dashboard/:id",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    props: true,
    beforeEnter: requireAuthAccount
  }, 
  {
    path: "/admin",
    name: "AdminDash",
    component: () => import("../views/AdminDash.vue"),
    beforeEnter: requireAdminAccount
  },   
  {
    path: "/:pathAll(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue")
    // component: NotFound
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/PrivacyPolicy.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/TermsOfService.vue")
  },
  {
    path: "/verify",
    name: "VerifyAccount",
    component: () => import("../views/VerifyAccount.vue"),
    // beforeEnter: requireAuth
  },
  {
    path: "/problem",
    name: "SignupError",
    component: () => import("../views/SignupError.vue"),
    // beforeEnter: requireAuth
  },
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: Test
  //   // meta:{ 
  //   //   requiresAuth: true,
  //   // }
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes, 
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      console.log("to.hash", to.hash)
      return {
        el: to.hash,
        behavior: "smooth",
      }
    }
    else {
      return savedPosition || new Promise((resolve) => {
        setTimeout(()=> {
          resolve({left:0,  top: 0, behavior: "smooth" })
        }, 500)
      }) 
    }   
  }
})

export default router
