import { ref } from "vue"

// firebase imports
import { db } from "../firebase/config"
import { collection, addDoc } from "firebase/firestore"
const error = ref(null)  
const isPending = ref(false) 

const useAddDocument = async (c, docdata) => {
  isPending.value = true
  // console.log("AddDocument composa - ", docdata)
  try {
    const docRef = await addDoc(collection(db, c), docdata)
    error.value = null
    isPending.value = false
    // console.log("Document written: ", docRef);
    console.log("Document written with ID: ", docRef.id);
    return docRef
  }
  catch(error) {
    console.log("addDocument - Err - ", error)
    error.value = "A problem has occurred during document creation"    
    isPending.value = false  
  }
}

const addDocument = () => {
  return { useAddDocument, error, isPending }
}

export default addDocument