<template>
  <div>
    <div class="col-12 col-sm-11 mx-auto text-center mb-2">
      <p class="h1 fs-5 text-charcoal fw-bolder">Hi {{displayName}}</p>
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center mb-2">
      <p class="fs-6 text-charcoal">{{photographer}} <br>Has created a <span class="fw-bold fs-5">PHOT<span class="text-highlight">AR</span></span> account containing your images</p>
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center mb-2">
      <p class="fs-6 text-charcoal">Download the App to experience your images in AR</p>
    </div>
    <div class="row mx-auto">
      <div class="col-12 col-sm-11 mx-auto text-center mb-2">
        <div class="col-6 text-center">
          <button type="button" class="btn btn-highlight rounded-pill text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>Play Store</button>
        </div>
        <div class="col-6 text-center">
          <button type="button" class="btn btn-highlight rounded-pill text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>App Store</button>
        </div>
      </div>
    </div> 
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
  </div>              
</template>

<script>

import { ref } from "vue"

export default {
name: "ClientLogin",
props: ["modData", "mobileView"],
emits: ["closeModalSub"],
setup(props, context){
    let displayName = ref(props.modData.dName)
    let photographer = ref(props.modData.photographer)
    const closeSubMod = () => {
      console.log("pModal>VerifyEmail>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
    return {displayName, photographer, closeSubMod}
  }
}
</script>

<style>

</style>