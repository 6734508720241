import { ref } from "vue"

// firebase imports
import { db } from "../firebase/config"
import { collection, query, where, getDocs } from "firebase/firestore";

const getShootUrls = async (c, qry) => {
  const documents = ref(null)
  const documentsRsz = ref(null)
  // collection reference
  let colRef = collection(db, c)
  const q = query(colRef, where(...qry))
  
  const querySnapshot = await getDocs(q);  
    let urls = []
    let resizedUrls = []
    querySnapshot.forEach((doc) => {         
      urls.push(doc.data().imageUrl)
      resizedUrls.push(doc.data().resizedImageUrl)
    });
    documents.value = urls
    documentsRsz.value = resizedUrls
  return { documents, documentsRsz }
} 

export default getShootUrls


