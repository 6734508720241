import { ref } from "vue"
// firebase imports
import { db } from "../firebase/config"
import { updateDoc, doc } from "firebase/firestore"
const error = ref(null)  
const isPending = ref(false) 

const useUpdateDocument = async (c, id, d) => {  
  isPending.value = true
  const docRef = doc(db, c, id);
  // console.log("useUpdateDoc - c", c)
  // console.log("useUpdateDoc - id", id)
  // console.log("useUpdateDoc - d", d)  
  try {
    // console.log("useUpdateDoc - trying", docRef)
    const res = await updateDoc(docRef, d)
    // console.log("useUpdateDoc - docUpdated")
    error.value = null
    isPending.value = false 
    console.log("res - ", res)
  }
  catch(err) {
    error.value = err.message
    console.log("Error while updating docs")
    error.value = "Error while updating docs"     
    isPending.value = false 
  }
}

const updateDocument = () => {
  return { useUpdateDocument, error, isPending}
}

export default updateDocument