import { ref } from "vue"

// firebase imports
// import { auth } from "../firebase/config"
import {getAuth, signInWithEmailAndPassword, browserSessionPersistence, browserLocalPersistence } from "firebase/auth"
const error = ref(null)
const isPending = ref(false)

const login = async (email, password, staySignedIn) => { 
  console.log("USELOGIN.JS -Composable > login")
  const auth = getAuth();
  error.value = null
  isPending.value = true
  const persistence = staySignedIn ? browserLocalPersistence : browserSessionPersistence  
  // console.log("persistence", persistence.type)
  await auth.setPersistence(persistence)
  try {
    // console.log("persistence", persistence.type)
    const res = await signInWithEmailAndPassword(auth, email, password)
    error.value = null
    isPending.value = false
    return res
  }
  catch(err) {
    console.log(err.code)
    switch (err.code) {
      case "auth/invalid-email": 
        error.value = "Invalid email"
        isPending.value = false
        break;
      case "auth/user-not-found": 
        error.value = "No account for that email was found"
        isPending.value = false
        break;
      case "auth/wrong-password": 
        error.value = "Incorrect password"
        isPending.value = false
        break;
      case "auth/user-disabled": 
        error.value = "This account has been disabled"
        isPending.value = false
        break;
      default: 
        error.value = "Incorrect Login Credentials"
        isPending.value = false
        break;
    }
  }
}


const useLogin = () => {
  return {error, isPending, login}
}

export default useLogin