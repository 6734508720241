import { storage } from "../firebase/config"
import { ref, deleteObject} from "firebase/storage";


const useDeleteImage = async (fileUrl) => {  
  let errorArr = []
  //DELETE MULTIPLE
  console.log("Use Delete Image Multiple fileUrl - ", fileUrl)
  for (let i = 0; i < fileUrl.length; i++)
  {    
    let fileRef = ref(storage, fileUrl[i])
    await deleteObject(fileRef)
      .then(() => {
        // File deleted successfully
        console.log("File Deleted - ", i)
        console.log("File Deleted - ", fileUrl[i])
      }).catch((err) =>  {  
        console.log("delImage Error - ", err)
        console.log("delImage Error - ", fileUrl[i])
        let msg = "Could not delete the image" + fileUrl[i]
        errorArr.push(msg)
        // error.value = error.message
        // error = "Could not delete the image"
      });
  }

  return {errorArr }
}

export default useDeleteImage

// const delImage = () => {
//   return { useDeleteImage,  }
// }

// export default delImage