import { ref } from "vue"
// firebase imports

import {getAuth, sendEmailVerification } from "firebase/auth"

const error = ref(null)
const emailVerify = () => {  
  console.log("RUNNING SEND EMAIL VERIFY")
  error.value = null
  const auth = getAuth()
  const user = auth.currentUser;
  if(user){
    const actionCodeSettings = {url: `${process.env.VUE_APP_HOST_NAME}/dashboard/${user.uid}`,};
    sendEmailVerification(user, actionCodeSettings)
    .then(() => {
      console.log("Email verification sent" )
      error.value = null
    })
    .catch((error) => {
      console.log(error)
      error.value = "Could not send email Verification"
    })
  }
  else {
    console.log("No Auth User Account")
    error.value = "No Auth User Account - Could not send email Verification"
  }  
}

const sendVerEmail = () => {
  return { emailVerify, error}
}

export default sendVerEmail