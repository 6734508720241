<template>
  <div class="progress-bar" :style='{ width: progress + "%" }'></div>
</template>

<script>
import useStorage from "@/composables/useStorage"
import { watchEffect, ref} from "vue"

export default {
  props: ["file", "id", "cid", "sid"],
  setup(props, context) {
    const { progress, url, error, uploadComplete } = useStorage(props.file, props.id, props.cid, props.sid  )
    const link = ref("")
    watchEffect(() => {
      if (progress.value >= 100 && uploadComplete.value == true) {        
        link.value = url.value
        // console.log("link - ", link.value)        
        setTimeout(() => context.emit("urlLink", {link: link}), 500)
        setTimeout(() => context.emit("complete"), 1500)       
      }
    })
    return { progress, url, error }
  }
}
</script>

<style scoped>
  .progress-bar {
    display: block;
    height: 2rem;
    background: #00a4b0;
    border-radius: 6px;
    transition: width 0.3s ease;
  }
</style>