<template>
  <div>
    <div class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 mt-2 text-center">
      <p class="fs-5 fw-normal text-charcoal p-0 m-0">Your Current Package</p>                  
      <p class="fs-5 fw-normal text-charcoal p-0 m-0 fw-bold">{{modData.obj.subscription}}</p>                  
    </div>
    <div class="col-12 col-sm-11 mx-auto mt-2 text-center">
      <p class="fs-6 fw-normal text-charcoal p-0 m-0">{{durationField}}</p>                  
    </div>
    <div class="col-12 col-sm-11 mx-auto mt-2 mb-4 text-center">
      <p class="fs-6 fw-normal text-charcoal p-0 m-0">{{endDateField}}</p>                  
    </div>
    <div v-if="currSubscription" class="col-12 col-sm-11 mx-auto text-start pb-2 text-truncate">
      <div v-if="isLoading">Loading account information...</div>
      <PriceSelection v-else-if="!currSubscription" />
      <SubscribedAccount v-else :currSubscription="currSubscription" :modData="modData" :mobileView="mobileView" :upgrade="true" @showAlertSub="showAlertSubPass"/>
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>              
</template>

<script>

import Spinner from "@/components/Spinner.vue"
import { onMounted, onUnmounted, ref, watchEffect, computed } from "vue"
import SubscribedAccount from "@/components/SubscribedAccount";
import PriceSelection from "@/components/PriceSelection";
import { getDocs, getFirestore, collection, query, where } from "firebase/firestore"
import { auth } from "../../firebase/config"
export default {
name: "UpgradeUser",
emits: ["closeModalSub", "showAlertSub"],
props: ["modData", "mobileView"],
components: {Spinner, PriceSelection, SubscribedAccount},
setup(props, context){
    let inProgress = ref(false)
    const isLoading = ref(false)
    const currSubscription = ref(null)
    const selectPrice = ref(null)
    const signupPackage = ref("Enhanced")
    const upgbutton = ref(true)
    console.log("modData obj- ", props.modData.obj)
    console.log("modData - ", props.modData.obj.duration)
    let showAlertSubPass = (errorArray) => {
      console.log("pModal>upgrade>showAlertSubPass")
      context.emit("showAlertSub", errorArray)
    }

    const durationField = computed(() => {
      if(props.modData.obj.duration === "oneoff"){
        return "One Off Payment"
      }
      else if(props.modData.obj.duration === "trial"){
        return "Trial Package"
      }
      else if(props.modData.obj.duration === "monthly"){
        return "Monthly Subscription - On Going"
      }
      else if(props.modData.obj.duration === "annually"){
        return "Yearly Subscription - On Going"
      }
      else {
        return "Unavailable"
      }
    })
    const endDateField = computed(() => {
      if(props.modData.obj.duration === "oneoff"){
        let endDate = new Date(props.modData.obj.oneOffEndDate.seconds * 1000). toLocaleDateString()
        return `Expires - ${endDate}`
      }
      else if(props.modData.obj.duration === "trial"){
        let endDate = new Date(props.modData.obj.trialEndDate.seconds * 1000). toLocaleDateString()
        return `Trial Ends - ${endDate}`
      }
      else if((props.modData.obj.duration === "monthly") || (props.modData.obj.duration === "annually")){
        let periodEndDate = new Date(props.modData.obj.renewalDate.seconds * 1000). toLocaleDateString()
        return `Billing Period Ends - ${periodEndDate}`
      }     
      else {
        return "Unavailable"
      }
    })

    const fetchSubscription = async () => {
      console.log("Running fetchSubscription")
      isLoading.value = true;
      const db = getFirestore();
      const subsRef = collection(db,"users", auth.currentUser.uid, "subscriptions");
      const subsQuery = query(subsRef, where("status", "in", ["trialing", "active", "past_due", "unpaid"]));
      currSubscription.value = await getDocs(subsQuery)        
        .then((sub) => 
          sub.docs.length > 0 ? sub.docs[0].data() : null);
          isLoading.value = false;
          console.log("currSubscription - ", currSubscription.value)

      // if(currSubscription.value == null){
      //   console.log("Running fetchSubscription")
      //   isLoading.value = true;
      //   const db = getFirestore();
      //   const subsRef = collection(db,"users", auth.currentUser.uid, "subscriptions");
      //   console.log("Running fetchSubscription - ", subsRef)
      //   const subsQuery = query(subsRef, where("status", "in", ["trialing", "active", "past_due", "unpaid"]));
      //   currSubscription.value = await getDocs(subsQuery)        
      //     .then((sub) => sub.docs.length > 0 ? sub.docs[0].data() : null);
      //     console.log("currSubscription - ", currSubscription.value)

      //     isLoading.value = false;
      // }      
    }

    let stopUpgradeSubWatch = watchEffect(() => {
      console.log("watchEffect - stopUpgradeSubWatch - ", auth.currentUser.uid)
      if(auth.currentUser.uid){
        fetchSubscription()
      }
    })

    onMounted(() => {
      console.log("VERIFY.VUE > OnMounted")
      fetchSubscription()
      // if(!user){
      //   "VERIFY.VUE > OnMounted > noUser"
      //   errorMessage.value = "Please Create an Account and Verify your email to proceed"
      //   let alertLvl = 1
      //   errorArray.value.push(alertLvl)  
      //   errorArray.value.push(errorMessage.value)
      //   context.emit("showAlertSub", errorArray)
      //   context.emit("closeModalSub") 
      // }
    })
    // const resendVerify = async () => {
    //   // console.log("ADD IN EMAIL BOX FOR RESEND VERIFICATION, VALIDATE")
    //   await emailVerify()
    //   if (emailVerError.value != null){
    //     console.log("ERROR VERIFYING EMAIL ", emailVerError.value)
    //     errorMessage.value = emailVerError.value
    //     let alertLvl = 1
    //     errorArray.value.push(alertLvl)  
    //     errorArray.value.push(errorMessage.value)
    //     context.emit("showAlertSub", errorArray)
    //     // context.emit("closeModalSub") 
    //   }
    //   else{
    //     errorMessage.value = "Verification Email has been resent"
    //     let alertLvl = 2
    //     errorArray.value.push(alertLvl)
    //     errorArray.value.push(errorMessage.value)
    //     context.emit("showAlertSub", errorArray)    
    //     // context.emit("closeModalSub")
    //   }
    // }
    onUnmounted(() => {
      stopUpgradeSubWatch()
    })

    const closeSubMod = () => {
      console.log("pModal>VerifyEmail>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
    
    
    return { closeSubMod, inProgress, isLoading, currSubscription, showAlertSubPass, selectPrice, signupPackage, durationField, endDateField, upgbutton }
}
}
</script>

<style>

</style>