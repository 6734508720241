const reSpaces = /^\S*$/;
//QUICK SIGNUP
function validateQkSignupEmail() {
  console.log("Running validate QkSignup Email function")
  let email = document.querySelector("#qSignupEmail")
  let re = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;  
  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    console.log("QkSignup Email -true ")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    console.log("QkSignup Email -true ")
    return false
  }
}

function validateQkSignupPassword() {
  console.log("Running validate QkSignup Password function ")
  let password = document.querySelector("#qSignupPassword");
  let re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[!@#$%^&*])/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    console.log("QkSignup Password -true ")
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    console.log("QkSignup Password -false ")
    return false;
  }
}

function validateQkSignupUsername() {
  console.log("Running validate QkSignup Username function ")
  let username = document.querySelector("#qSignupUsername")
  // console.log("username", username.value)
  let re = /(?=.{4,})/;
  if (re.test(username.value) && reSpaces.test(username.value)) {
    username.classList.remove("is-invalid");
    username.classList.add("is-valid");
    console.log("QkSignup Username -true ")
    return true;
  } else {
    username.classList.remove("is-valid");
    username.classList.add("is-invalid");
    console.log("QkSignup Username -false ")
    return false;
  }
}

//QUICK SIGNUP CONVERT
function validateTrialSignupFirstname() {
  console.log("Running validate Signup Firstname function ")
  let firstname = document.querySelector("#trialSignupFirstname")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(firstname.value) && reSpaces.test(firstname.value)) {
    firstname.classList.remove("is-invalid");
    firstname.classList.add("is-valid");
    return true;
  } else {
    firstname.classList.remove("is-valid");
    firstname.classList.add("is-invalid");
    return false;
  }
}
function validateTrialSignupLastname() {
  console.log("Running validate trial Signup Lastname function ")
  let lastname = document.querySelector("#trialSignupLastname")
  let re = /(?=.{2,})/;
  if (re.test(lastname.value) && reSpaces.test(lastname.value)) {
    lastname.classList.remove("is-invalid");
    lastname.classList.add("is-valid");
    return true;
  } else {
    lastname.classList.remove("is-valid");
    lastname.classList.add("is-invalid");
    return false;
  }
}
function validateTrialSignupAddress1() {
  console.log("Running validate trial Signup Address function ")
  let address1 = document.querySelector("#trialSignupAddress1")
  let re = /(?=.{2,})/;  
  if (re.test(address1.value)) {
    address1.classList.remove("is-invalid");
    address1.classList.add("is-valid");
    return true;
  } else {
    address1.classList.remove("is-valid");
    address1.classList.add("is-invalid");
    return false;
  }

}
function validateTrialSignupPostcode() {
  console.log("Running validate trial Signup Postcode function ")
  let postcode = document.querySelector("#trialSignupPostcode")
  let re = /(?=.{2,})/;
  if (re.test(postcode.value) && reSpaces.test(postcode.value)) {
    postcode.classList.remove("is-invalid");
    postcode.classList.add("is-valid");
    return true;
  } else {
    postcode.classList.remove("is-valid");
    postcode.classList.add("is-invalid");
    return false;
  }
}
function validateTrialSignupPhone() {
  console.log("Running validate trial Signup Phone function ")
  let phone = document.querySelector("#trialSignupPhone")
  let re = /(?=.*[0-9])(?=.{2,})/;
  if (re.test(phone.value) && reSpaces.test(phone.value)) {
    phone.classList.remove("is-invalid");
    phone.classList.add("is-valid");
    return true;
  } else {
    phone.classList.remove("is-valid");
    phone.classList.add("is-invalid");
    return false;
  }
}
//SIGNUP
function validateSignupEmail() {
  console.log("Running validate Signup Email function")
  let email = document.querySelector("#signupEmail")
  let re = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // let re = /^([a-zA-Z0-9_\-?]){3,}@([a-zA-Z]){3,}\.([a-zA-Z]){2,5}$/;

  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    return false
  }
}

function validateSignupPassword() {
  console.log("Running validate Signup Password function ")
  let password = document.querySelector("#signupPassword");
  let re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[!@#$%^&*])/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    return false;
  }
}

function validateSignupUsername() {
  console.log("Running validate Signup Username function ")
  let username = document.querySelector("#signupUsername")
  // console.log("username", username.value)
  let re = /(?=.{4,})/;
  if (re.test(username.value) && reSpaces.test(username.value)) {
    username.classList.remove("is-invalid");
    username.classList.add("is-valid");
    return true;
  } else {
    username.classList.remove("is-valid");

    username.classList.add("is-invalid");
    return false;
  }
}
function validateSignupFirstname() {
  console.log("Running validate Signup Firstname function ")
  let firstname = document.querySelector("#signupFirstname")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(firstname.value) && reSpaces.test(firstname.value)) {
    firstname.classList.remove("is-invalid");
    firstname.classList.add("is-valid");
    return true;
  } else {
    firstname.classList.remove("is-valid");
    firstname.classList.add("is-invalid");
    return false;
  }
}
function validateSignupLastname() {
  console.log("Running validate Signup Lastname function ")
  let lastname = document.querySelector("#signupLastname")
  let re = /(?=.{2,})/;
  if (re.test(lastname.value) && reSpaces.test(lastname.value)) {
    lastname.classList.remove("is-invalid");
    lastname.classList.add("is-valid");
    return true;
  } else {
    lastname.classList.remove("is-valid");
    lastname.classList.add("is-invalid");
    return false;
  }
}
function validateSignupAddress1() {
  console.log("Running validate Signup Address function ")
  let address1 = document.querySelector("#signupAddress1")
  let re = /(?=.{2,})/;  
  if (re.test(address1.value)) {
    address1.classList.remove("is-invalid");
    address1.classList.add("is-valid");
    return true;
  } else {
    address1.classList.remove("is-valid");
    address1.classList.add("is-invalid");
    return false;
  }

}
function validateSignupPostcode() {
  console.log("Running validate Signup Postcode function ")
  let postcode = document.querySelector("#signupPostcode")
  let re = /(?=.{2,})/;
  if (re.test(postcode.value) && reSpaces.test(postcode.value)) {
    postcode.classList.remove("is-invalid");
    postcode.classList.add("is-valid");
    return true;
  } else {
    postcode.classList.remove("is-valid");
    postcode.classList.add("is-invalid");
    return false;
  }
}
function validateSignupPhone() {
  console.log("Running validate Signup Phone function ")
  let phone = document.querySelector("#signupPhone")
  let re = /(?=.*[0-9])(?=.{2,})/;
  if (re.test(phone.value) && reSpaces.test(phone.value)) {
    phone.classList.remove("is-invalid");
    phone.classList.add("is-valid");
    return true;
  } else {
    phone.classList.remove("is-valid");
    phone.classList.add("is-invalid");
    return false;
  }
}
//NEWSLETTER SIGNUP
function validateNewsletterEmail() {
  // console.log("Running validate Email function - ")
  let email = document.querySelector("#newsletterEmail")
  let re = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    return false
  }
}

//LOGIN
function validateLoginEmail() {
  // console.log("Running validate Email function - ")
  let email = document.querySelector("#loginEmail")
  let re = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    return false
  }
}

function validateLoginPassword() {
  // console.log("Running validate Password function ")
  let password = document.querySelector("#loginPassword");
  // let re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[!@#$%^&*])/;
  let re = /(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    return false;
  }
}
//RESET PASSWORD
function validateResetPWEmail() {
  console.log("Running validate ResetPWEmail function - ")
  let email = document.querySelector("#resetPasswordEmail")
  let re = /^([a-zA-Z0-9_\-?]){3,}@([a-zA-Z]){3,}\.([a-zA-Z]){2,5}$/;

  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    return false
  }
}
//CREATE CLIENT
function validateClientEmail() {
  console.log("Running validate Client Email function")
  let email = document.querySelector("#clientEmail")
  let re = /^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (reSpaces.test(email.value) && re.test(email.value)) {
    email.classList.remove("is-invalid")
    email.classList.add("is-valid")
    return true
  } else {
    email.classList.add("is-invalid")
    email.classList.remove("is-valid")
    return false
  }
}

function validateClientPassword() {
  console.log("Running validate Client Password function ")
  let password = document.querySelector("#clientPassword");
  let re = /(?=.{6,})/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    return false;
  }
}

function validateClientUsername() {
  console.log("Running validate client Username function ")
  let username = document.querySelector("#clientUsername")
  // console.log("username", username.value)
  let re = /(?=.{4,})/;
  if (re.test(username.value) && reSpaces.test(username.value)) {
    username.classList.remove("is-invalid");
    username.classList.add("is-valid");
    return true;
  } else {
    username.classList.remove("is-valid");

    username.classList.add("is-invalid");
    return false;
  }
}
function validateClientFirstname() {
  console.log("Running validate Client Firstname function ")
  let firstname = document.querySelector("#clientFirstname")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(firstname.value) && reSpaces.test(firstname.value)) {
    firstname.classList.remove("is-invalid");
    firstname.classList.add("is-valid");
    return true;
  } else {
    firstname.classList.remove("is-valid");
    firstname.classList.add("is-invalid");
    return false;
  }
}
function validateClientLastname() {
  console.log("Running validate Client Lastname function ")
  let lastname = document.querySelector("#clientLastname")
  let re = /(?=.{2,})/;
  if (re.test(lastname.value) && reSpaces.test(lastname.value)) {
    lastname.classList.remove("is-invalid");
    lastname.classList.add("is-valid");
    return true;
  } else {
    lastname.classList.remove("is-valid");
    lastname.classList.add("is-invalid");
    return false;
  }
}

//UPDATE CLIENT
function validateClientUsernameUp() {
  console.log("Running validate client Username for Update function ")
  let username = document.querySelector("#clientDetUsernameUp")
  // console.log("username", username.value)
  let re = /(?=.{4,})/;
  if (re.test(username.value) && reSpaces.test(username.value)) {
    username.classList.remove("is-invalid");
    username.classList.add("is-valid");
    return true;
  } else {
    username.classList.remove("is-valid");

    username.classList.add("is-invalid");
    return false;
  }
}
function validateClientFirstnameUp() {
  console.log("Running validate Client Firstname for Update function ")
  let firstname = document.querySelector("#clientDetFNameUp")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(firstname.value) && reSpaces.test(firstname.value)) {
    firstname.classList.remove("is-invalid");
    firstname.classList.add("is-valid");
    return true;
  } else {
    firstname.classList.remove("is-valid");
    firstname.classList.add("is-invalid");
    return false;
  }
}
function validateClientLastnameUp() {
  console.log("Running validate Client Lastname for Update function ")
  let lastname = document.querySelector("#clientDetLNameUp")
  let re = /(?=.{2,})/;
  if (re.test(lastname.value) && reSpaces.test(lastname.value)) {
    lastname.classList.remove("is-invalid");
    lastname.classList.add("is-valid");
    return true;
  } else {
    lastname.classList.remove("is-valid");
    lastname.classList.add("is-invalid");
    return false;
  }
}
//RESET CLIENT PASSWORD
function validateClientResetPWUp() {
  console.log("Running validate Client Password Reset 1function ")
  let password = document.querySelector("#clientPassword1Up");
  let re = /(?=.{6,})/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    return false;
  }
}
function validateClientResetPWConUp() {
  console.log("Running validate Client Password Reset 2 function ")
  let password = document.querySelector("#clientPassword1Up");
  let re = /(?=.{6,})/;
  if (re.test(password.value) && reSpaces.test(password.value)) {
    password.classList.remove("is-invalid");
    password.classList.add("is-valid");
    return true;
  } else {
    password.classList.add("is-invalid");
    password.classList.remove("is-valid");
    return false;
  }
}
//CREATE SHOOT
function validateShootTitle() {
  console.log("Running validate Shoot Title function ")
  let sTitle = document.querySelector("#shootTitle")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(sTitle.value)) {
    sTitle.classList.remove("is-invalid");
    sTitle.classList.add("is-valid");
    return true;
  } else {
    sTitle.classList.remove("is-valid");
    sTitle.classList.add("is-invalid");
    return false;
  }
}
function validateShootType() {
  console.log("Running validate Shoot Type function ")
  let sType = document.querySelector("#shootType")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(sType.value)) {
    sType.classList.remove("is-invalid");
    sType.classList.add("is-valid");
    return true;
  } else {
    sType.classList.remove("is-valid");
    sType.classList.add("is-invalid");
    return false;
  }
}
//UPDATE SHOOT
function validateShootTitleUp() {
  console.log("Running validate Shoot Title function ")
  let sTitle = document.querySelector("#shootTitleUp")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(sTitle.value)) {
    sTitle.classList.remove("is-invalid");
    sTitle.classList.add("is-valid");
    return true;
  } else {
    sTitle.classList.remove("is-valid");
    sTitle.classList.add("is-invalid");
    return false;
  }
}
function validateShootTypeUp() {
  console.log("Running validate Shoot Type function ")
  let sType = document.querySelector("#shootTypeUp")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(sType.value)) {
    sType.classList.remove("is-invalid");
    sType.classList.add("is-valid");
    return true;
  } else {
    sType.classList.remove("is-valid");
    sType.classList.add("is-invalid");
    return false;
  }
}

//ADD IMAGE
function validateImageTitle() {
  console.log("Running validate Image Title function ")
  let iTitle = document.querySelector("#imageTitle")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(iTitle.value)) {
    iTitle.classList.remove("is-invalid");
    iTitle.classList.add("is-valid");
    return true;
  } else {
    iTitle.classList.remove("is-valid");
    iTitle.classList.add("is-invalid");
    return false;
  }
}
//UPDATE IMAGE
function validateImageTitleUp() {
  console.log("Running validate Image Title function ")
  let iTitle = document.querySelector("#imageTitleUp")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(iTitle.value)) {
    iTitle.classList.remove("is-invalid");
    iTitle.classList.add("is-valid");
    return true;
  } else {
    iTitle.classList.remove("is-valid");
    iTitle.classList.add("is-invalid");
    return false;
  }
}

//UPDATE CLIENT
function validateUserUsernameUp() {
  console.log("Running validate User Username Up function ")
  let username = document.querySelector("#userDetUsernameUp")
  // console.log("username", username.value)
  let re = /(?=.{4,})/;
  if (re.test(username.value) && reSpaces.test(username.value)) {
    username.classList.remove("is-invalid");
    username.classList.add("is-valid");
    return true;
  } else {
    username.classList.remove("is-valid");

    username.classList.add("is-invalid");
    return false;
  }
}
function validateUserPhoneUp() {
  console.log("Running validate User Phone Up function ")
  let phone = document.querySelector("#userDetPhoneUp")
  let re = /(?=.*[0-9])(?=.{2,})/;
  if (re.test(phone.value) && reSpaces.test(phone.value)) {
    phone.classList.remove("is-invalid");
    phone.classList.add("is-valid");
    return true;
  } else {
    phone.classList.remove("is-valid");
    phone.classList.add("is-invalid");
    return false;
  }
}
function validateUserFirstnameUp() {
  console.log("Running validate User Firstname Up function ")
  let firstname = document.querySelector("#userDetFNameUp")
  // console.log("username", username.value)
  let re = /(?=.{2,})/;
  if (re.test(firstname.value) && reSpaces.test(firstname.value)) {
    firstname.classList.remove("is-invalid");
    firstname.classList.add("is-valid");
    return true;
  } else {
    firstname.classList.remove("is-valid");
    firstname.classList.add("is-invalid");
    return false;
  }
}
function validateUserLastnameUp() {
  console.log("Running validate User Lastname Up function ")
  let lastname = document.querySelector("#userDetLNameUp")
  let re = /(?=.{2,})/;
  if (re.test(lastname.value) && reSpaces.test(lastname.value)) {
    lastname.classList.remove("is-invalid");
    lastname.classList.add("is-valid");
    return true;
  } else {
    lastname.classList.remove("is-valid");
    lastname.classList.add("is-invalid");
    return false;
  }
}
function validateUserAddress1Up() {
  console.log("Running validate User Address1 Up function ")
  let address1 = document.querySelector("#userAddress1Up")
  let re = /(?=.{2,})/;  
  if (re.test(address1.value)) {
    address1.classList.remove("is-invalid");
    address1.classList.add("is-valid");
    return true;
  } else {
    address1.classList.remove("is-valid");
    address1.classList.add("is-invalid");
    return false;
  }

}
function validateUserPostcodeUp() {
  console.log("Running validate User Postcode Up function ")
  let postcode = document.querySelector("#userAddressPCodeUp")
  let re = /(?=.{2,})/;
  if (re.test(postcode.value) && reSpaces.test(postcode.value)) {
    postcode.classList.remove("is-invalid");
    postcode.classList.add("is-valid");
    return true;
  } else {
    postcode.classList.remove("is-valid");
    postcode.classList.add("is-invalid");
    return false;
  }
}


//RESEND VERIFY EMAIL
// function validateVerifyEmail() {
//   console.log("Running validate VerifyEmail function - ")
//   let email = document.querySelector("#verifyEmail")
//   let re = /^([a-zA-Z0-9_\-?]){3,}@([a-zA-Z]){3,}\.([a-zA-Z]){2,5}$/;

//   if (reSpaces.test(email.value) && re.test(email.value)) {
//     email.classList.remove("is-invalid")
//     email.classList.add("is-valid")
//     return true
//   } else {
//     email.classList.add("is-invalid")
//     email.classList.remove("is-valid")
//     return false
//   }
// }



export {validateLoginEmail, validateLoginPassword, validateResetPWEmail, validateSignupUsername, validateSignupEmail, validateSignupPassword, validateSignupFirstname, validateSignupLastname, validateSignupPhone, validateSignupAddress1, validateSignupPostcode, validateClientEmail, validateClientPassword, validateClientUsername, validateClientFirstname, validateClientLastname, validateShootTitle, validateShootType, validateImageTitle, validateShootTitleUp, validateShootTypeUp, validateImageTitleUp, validateClientUsernameUp, validateClientFirstnameUp, validateClientLastnameUp, validateClientResetPWUp, validateClientResetPWConUp, validateUserPostcodeUp, validateUserAddress1Up,validateUserLastnameUp, validateUserFirstnameUp, validateUserPhoneUp,validateUserUsernameUp, validateQkSignupUsername, validateQkSignupEmail, validateQkSignupPassword, validateTrialSignupFirstname, validateTrialSignupLastname, validateTrialSignupPostcode, validateTrialSignupPhone, validateTrialSignupAddress1, validateNewsletterEmail}