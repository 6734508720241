<template>
  <path d="M76.53,761.63V285.36c5.68,3.49,12.32,6,16.9,10.63Q196.57,399.48,299.19,503.46c4.43,4.47,8.43,9.36,13.38,14.88-7.27,7.89-13.69,15-20.26,22Q224.6,612.09,156.86,683.85c-14.71,15.64-15.67,32.6-2.9,44.16,12.23,11.08,27.24,9,41.9-6.74,28.15-30.19,55.92-60.73,83.92-91,22.17-24,44.46-47.93,68.62-74,45.07,49.34,90.92,92.69,161.84,93.39,72.09.71,118.23-43.18,166.35-96.89,5.54,8.71,8.5,15.52,13.31,20.58q70.71,74.28,142,148C847,737,861.31,738.29,873.6,725.81c11.82-12,10.81-27.66-3.25-42.65-24.26-25.88-48.78-51.52-73.1-77.34-27.74-29.45-55.38-59-83.8-89.28,75.48-76.74,149.6-152.15,223.92-227.37,2.3-2.33,6.69-2.59,10.1-3.81V761.63c-14.91,40-44.51,53-85.88,52.83Q512,813,162.32,814.45C121,814.62,91.33,801.71,76.53,761.63Z"/>
  <path d="M103.49,224.63c15.21-15.41,34.17-14.8,52.74-14.81q285.4-.13,570.8,0c48.38,0,96.77-.73,145.11.42,16,.38,31.81,5.59,52.05,9.41-6.43,7.8-8.49,10.71-11,13.23q-158.43,161.76-317,323.4c-50.4,51.26-119,50.85-169.36-.54q-156.48-159.6-312.8-319.37C110.57,232.7,107.29,228.82,103.49,224.63Z"/>
</template>

<script>
export default {
  name: "IconMail"
}
</script>
