import { ref, watchEffect } from "vue"
import { storage } from "../firebase/config.js"
import { ref as sRef, uploadBytesResumable, getDownloadURL } from "firebase/storage"

const useStorage = ((file, id, cid, sid) => {
  const error = ref(null)
  const url = ref(null)
  const progress = ref(null) 
  const uploadComplete = ref(false)

 watchEffect(() => {
    //create reference to file
    const filePath = `${id}/${cid}/${sid}/` + file.name
    const storageRef = sRef(storage)
    const imagesRef = sRef(storageRef, "images")
    const fileRef = sRef(imagesRef, filePath)

    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on("state_changed", 
    (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      progress.value = percentage
      // console.log("progress.value - ", progress.value)
      // console.log("percentage - ", percentage)
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
      }
    },
    (err) => {
      switch (err.code) {
        case "storage/unauthorized":
          console.log(err.message)
          error.value = "User does not have permission to access the object"
          console.log(error.value)
          break;
        case "storage/cancelled":
          console.log(err.message)
          error.value = "User cancelled the upload"
          console.log(error.value)
          break;  
        case "storage/unknown":
          console.log(err.message)
          error.value = "Unknown error occurred during upload"
          console.log(error.value)
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        url.value = downloadURL
        // console.log("Use Storage - url.value", url.value);
        uploadComplete.value = true;
      });
    })
  })
  
  return { progress, url, error, uploadComplete}
})
export default useStorage