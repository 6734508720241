<template>
  <div>
    <div class="col-12 col-sm-11 p-0 m-0 mx-auto">
      <p class="fs-6 p-0 m-0 text-center mb-2">Are you sure you wish to Logout?</p>                  
    </div>
    <div class="mt-3 col-12 col-sm-11 mx-auto">    
      <button type="button" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' @click="handleLogout">Logout</button>    
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
  </div>
</template>

<script>
import { getAuth, signOut} from "firebase/auth"
import { useRouter } from "vue-router"
import { ref } from "vue"

export default {
name: "LogoutModContent",
emits: ["closeModalSub", "showAlertSub", "toggleNav"],
props: ["mobileView"],
setup(props, context){

    let errorMessage = ref("")
    let errorArray = ref([])
    let router = useRouter()

    //handle logout
    const handleLogout = () => {
      console.log("pModal>Logout>handleLogout")        
      let auth = getAuth()
      signOut(auth)
      .then(() => {
        errorMessage.value = "Successfully Logged Out"
        let alertLvl = 2
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)
        context.emit("closeModalSub")
        router.push({ name: "Home"})
      })
      .catch((error) => {
        // console.log("Sign Out Error - ", error)
        errorMessage.value = error.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)
        context.emit("showAlertSub", errorArray)
        context.emit("closeModalSub")    
      });
    }

    let closeSubMod = () => {
      console.log("pModal>Logout>closeSubMod - Close Button")
      context.emit("toggleNav")
      context.emit("closeModalSub")
    }

    return {handleLogout, closeSubMod}
}
}
</script>

<style>

</style>