<template>
  <div>
    <div ref="addShootFormMod" class="addShootFormCont">
      <!-- SELECTED CLIENT TO ADD SHOOTS TO -->
      <div v-if="store.getSelClientEmail" class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
        <p class="fs-6 fw-normal text-charcoal">Adding Shoot For: {{store.getSelClientEmail}}</p>
      </div>
      <div v-else class="col-12 col-sm-11 mx-auto p-0 m-0 mb-2 text-center">
        <p class="fs-6 fw-normal text-charcoal"><span class="fw-bold">No Client is Selected</span><br>Please select a client on the dashboard</p>
      </div>
      <!-- ADD SHOOTS FORM -->
      <div v-if="store.getSelClientEmail" class="col-12 col-sm-11 p-0 m-0 mx-auto">
        <form class="needs-validation" id="addshootform" novalidate @submit.prevent="handleAddShoot">
          <div class="mb-1" @click="assignFocus('shootTitle')">
            <label for="shootTitle" class="form-label" >Shoot Title *</label>
            <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
              <input ref="firstFocus" type="text" class="form-control" id="shootTitle" placeholder="Shoot Title" required v-model="shootTitle" autocomplete="off">
              <div class="invalid-tooltip">
                Please provide a valid title at least 4 chars
              </div>
            </div>
          </div>
          <div class="mb-1" @click="assignFocus('shootType')">
            <label for="shootType" class="form-label">Shoot Type *</label>
            <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" name="shootType" id="shootType" placeholder="Shoot Type"  required v-model="shootType" autocomplete="off">          
              <div class="invalid-tooltip">
                Please provide a valid title at least 4 chars
              </div>
            </div>
          </div>
          <div class="mb-1 mt-3" @click="assignFocus('shootLocation')">
            <label for="shootLocation" class="form-label">Shoot Location</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="text" class="form-control" id="shootLocation" placeholder="Shoot Location" v-model="shootLocation" autocomplete="off">          
            </div>
          </div>      
          <div class="mb-1" @click="assignFocus('shootDate')">
            <label for="shootDate" class="form-label">Shoot Date</label>
            <div class="input-group" :class='{"input-group-sm": mobileView}'>
              <input type="date" class="form-control" id="shootDate" v-model="shootDate" autocomplete="off">          
            </div>
          </div>
          <div class="mt-4 mb-3 col-12 col-md-10 col-lg-8  mx-auto">
            <button v-if="!createShootPending && !updateShootPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>Create Shoot</button> 
            <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' disabled>Creating</button> 
          </div>
        </form> 
        <div class="col-12 col-lg-11 mx-auto text-center">
          <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
        </div>     
      </div>
      <div v-else class="mt-3 mb-3 col-12 col-sm-11 mx-auto">
        <button type="button" @click="closeSubMod" class="btn btn-outline-highlight text-highlight text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Dashboard</button>
      </div>
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>

<script>
import { useStore } from "@/composables/store/store"
import Spinner from "@/components/Spinner.vue"
import { getFunctions, httpsCallable } from "firebase/functions"
import addDocument from "@/composables/addDocument"
import updateDocument from "@/composables/updateDocument"
import { ref, watchEffect, onBeforeUnmount, onUnmounted, computed} from "vue"
import {validateShootTitle, validateShootType} from "../../../public/js/validation.js"
import { doc, deleteDoc, updateDoc, arrayRemove, Timestamp} from "firebase/firestore";
import { db } from "@/firebase/config"
export default {
  name: "AddShootModContent",
  props: ["mobileView"],
  emits: ["closeModalSub", "showAlertSub", "switchModalDashData"],
  components: {Spinner},
    setup(props, context){
      let store = useStore();
      let { useAddDocument, error:createShootError, isPending:createShootPending } = addDocument()
      let { useUpdateDocument, error:updateShootError, isPending:updateShootPending } = updateDocument()

      let addShootFormMod = ref(null)
      let firstFocus = ref(null)

      let errorMessage = ref("")
      let errorArray = ref([])
      let inProgress = ref(false)

      let createProgress = {
        createShootDoc: false,
        increaseClientShootCount: false,
        increaseUserShootCount: false,
        updateShootDoc: false
      }

      let shootCreated = computed(() => {
        if(createProgress.createShootDoc == true && createProgress.increaseClientShootCount == true && createProgress.increaseUserShootCount == true && createProgress.updateShootDoc == true){
          return true
        }
        else {
          return false
        }
      })

      let baseUserShootCount = store.getShootCount.value
      let baseClientShootCount = store.getSelClientNumShoots.value
      // Add Shoot vars
      let shootTitle = ref(null)
      let shootType = ref(null)
      let shootLocation = ref(null)
      let shootDate = ref(null)

      let newSid = ref(null)
      let newSTitle = ref(null)

      let shootCreationDate = computed(() => {
        let test = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
        return test
      })
      
      
      const clearVars = () => {
        console.log("Running AddShoot clearVars")
        addShootFormMod.value = null
        firstFocus.value = null
        errorMessage.value = ""
        errorArray.value = []
        inProgress.value = false
        createProgress.createShootDoc = false
        createProgress.increaseClientShootCount = false
        createProgress.increaseUserShootCount = false
        createProgress.updateShootDoc = false
        shootTitle.value = null
        shootType.value = null
        shootLocation.value = null
        shootDate.value = null
        newSid.value = null
        newSTitle.value = null
      }

      let stopCreateShootSubWatch = watchEffect(() => {
        console.log("watchEffect - stopCreateShootSubWatch")
        if (firstFocus.value) {
          firstFocus.value.focus()
        }
        if (addShootFormMod.value) {  
          if(addShootFormMod.value.children.length > 0)
          {
            addShootFormMod.value.querySelector("#shootTitle").addEventListener("blur", validateShootTitle)
            addShootFormMod.value.querySelector("#shootType").addEventListener("blur", validateShootType)
          }
        }
      })

      const shootDateTS = computed(() => {
        if(shootDate.value != null){
          let sDateAdj1 = new Date(shootDate.value)
          let sDateAdj2 = ((sDateAdj1.getTime()) / 1000)
          let sDateTS = new Timestamp(sDateAdj2, 0)
          return sDateTS 
        }else {
          return "n/a"
        }
      })

      onBeforeUnmount(() => {  
        addShootFormMod.value.querySelector("#shootTitle").removeEventListener("blur", validateShootTitle)
        addShootFormMod.value.querySelector("#shootType").removeEventListener("blur", validateShootType)
      })

      onUnmounted(() => {
        console.log("pModal>AddShoot - onUnmounted")
        clearVars()
        stopCreateShootSubWatch()
      }) 

      let assignFocus = (focusArea) => {
        console.log("Running assignFocus", focusArea)
        let focusId = "#" + focusArea
        let clickFocus = addShootFormMod.value.querySelector(`${focusId}`)
        clickFocus.focus()
      }

      const addShootSuccess = () => {
        console.log("pModal>AddShoot>Success-Continue - switchModalDashData")
        let subtitle = `${newSTitle.value} has been created`
        let modData = {title: "Success", area:"Shoot", message: subtitle, message2: "Do you wish to contine and add images for this shoot?", cid:store.getSelClient.value, cEmail:store.getSelClientEmail.value, cNumShoots:store.getSelClientNumShoots.value, cNumImages:store.getSelClientNumImages.value, cNumOrders:store.getSelClientNumOrders.value,  
        sid:newSid.value, sTitle:newSTitle.value, sNumImages:0, sNumColl:0, sNumOrders:0}
        context.emit("switchModalDashData", modData )
      }

      const cleanUp = async () => {
        console.log("Running AddShoot Cleanup")
        errorMessage.value = ""
        errorArray.value = []
        // console.log("createShootDoc - ", createProgress.createShootDoc)        
        // console.log("increaseClientShootCount - ", createProgress.increaseClientShootCount)
        // console.log("increaseUserShootCount - ", createProgress.increaseUserShootCount)
        // console.log("updateShootDoc - ", createProgress.updateShootDoc)
        // console.log("baseUserShootCount - ", baseUserShootCount)
        // console.log("baseClientShootCount - ", baseClientShootCount)
        // console.log("shootId - ", newSid.value)
        // console.log("clientId - ", store.getSelClient.value)
        // console.log("userId - ", store.getUserId.value)
        // console.log("userShoot Count - ", store.getShootCount.value)
        // console.log("clientShoot Count - ", store.getSelClientNumShoots.value)
        // Ensure Shoot Doc Not Created
        if(newSid.value != null){
           await deleteDoc(doc(db, "shoots", newSid.value));
           console.log("Doc Deleted - ", newSid.value)
        }
        if(createProgress.increaseUserShootCount){
          console.log("userCount userId- ", store.getUserId.value)
          const userRef = doc(db, "users", store.getUserId.value )
          await updateDoc(userRef, {"shoots.numberShoots": baseUserShootCount})
          await updateDoc(userRef, {"shoots.shootIDs": arrayRemove(newSid.value)})
          console.log("User Count Fixed & ID Removed")
        }        
        
        if(createProgress.increaseClientShootCount){
          console.log("clientCount clientId- ", store.getSelClient.value) 
          const clientRef = doc(db, "clients", store.getSelClient.value )
          await updateDoc(clientRef, {"shoots.numberShoots": baseClientShootCount})
          await updateDoc(clientRef, {"shoots.shootIDs": arrayRemove(newSid.value)})
          console.log("Client Count Fixed & ID Removed")
        }
        context.emit("closeModalSub")
      }

      let handleAddShoot = async () => {
        console.log("handleAddShoot")
        let form = addShootFormMod.value.querySelector(".needs-validation")
        if ( !form.checkValidity() || !validateShootTitle() || !validateShootType()) {
          errorMessage.value = "Error Creating Shoot - Please recheck form"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)            
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)  
        }
        else {
          form.classList.add("was-validated")
          inProgress.value = true
          try {
            let functions = getFunctions()
            let shootInfo = {
              userId: store.getUserId.value,
              clientId: store.getSelClient.value,
              shootTitle: shootTitle.value,
              shootType: shootType.value,
              shootLocation: shootLocation.value,
              shootDate: shootDateTS.value,
              images: {
                numberImages: 0,
                imageIDs:[],            
              },
              orders: {
                numberOrders: 0,
                orderIDs:[],            
              },
              collections: {
                numberCollections: 0,
                collectionIDs:[],            
              },          
              shootThumbnail: "",
              ShootCreationDate: shootCreationDate.value
            }
            let shootResult = await useAddDocument("shoots", shootInfo)     
            if (createShootError.value != null){
              errorMessage.value = createShootError.value
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                         
            }
            else{
              createProgress.createShootDoc = true
            }
            let shootIdDetails = {
              shootId: shootResult.id
            }
            newSid.value = shootResult.id        
            newSTitle.value = shootTitle.value
            //increment client number shoots
            try {
              const increaseClientShootsNum = httpsCallable(functions, "incrementNumShootsClient")
              const res1 = await increaseClientShootsNum({cid:store.getSelClient.value, sid:newSid.value})
              console.log("res1 - ", res1)
              createProgress.increaseClientShootCount = true
            } catch (error) {
              createProgress.increaseClientShootCount = false
              errorMessage.value = "Error Occurred While Increasing Client Shoot Count"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                         
            }           
            //increment user number shoots
            try {
              const increaseUserShootsNum = httpsCallable(functions, "incrementNumShootsUser")
              const res2 = await increaseUserShootsNum({uid:store.getUserId.value, sid:newSid.value})
              console.log("res2 - ", res2)
              createProgress.increaseUserShootCount = true
            } catch (error) {
              createProgress.increaseUserShootCount = false
              errorMessage.value = "Error Occurred While Increasing User Shoot Count"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return                         
            }           
           
            //add shoot id to shoot document        
            await useUpdateDocument("shoots", newSid.value, shootIdDetails)
            if (updateShootError.value != null){
              createProgress.updateShootDoc = false
              errorMessage.value = "Error Occurred While Updating Shoot Details"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false 
              cleanUp()
              return           
            }
            else{
              createProgress.updateShootDoc = true
            }
            inProgress.value = false
            if(shootCreated.value == true){
               errorMessage.value = "Shoot successfully created"
              let alertLvl = 2
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value)
              context.emit("showAlertSub", errorArray)
              addShootSuccess()  
            } 
            else{
              errorMessage.value = "An Error Occurred"
              let alertLvl = 1
              errorArray.value.push(alertLvl)  
              errorArray.value.push(errorMessage.value) 
              context.emit("showAlertSub", errorArray)             
              inProgress.value = false
              cleanUp()
            }         
          }
          catch(error){
            const { code, details } = JSON.parse(JSON.stringify(error))
            errorMessage.value = code + details
            // errorMessage.value = error.value
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false
            cleanUp()
            return 
          }
        }
      }

      let closeSubMod = () => {
        // console.log("pModal>Help>closeSubMod - Close Button")
        context.emit("closeModalSub")
      }

      return{addShootFormMod, firstFocus, assignFocus, inProgress, handleAddShoot, shootTitle, shootType, shootLocation, shootDate, createShootPending, updateShootPending, store, closeSubMod}
    }
}
</script>

<style>

</style>