<template>
  <div>
    <div class="col-12 col-sm-11 mx-auto p-0 m-0 mb-1 text-center">
      <p class="fs-5 fw-normal lh-sm text-charcoal p-0 m-0">{{modData.message}}</p>                  
    </div>   
    <div class="mt-3 col-12 col-sm-11 mx-auto">
      <button v-if="modData.area === 'Client'" type="button" @click="handleAddShoot" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Add Shoot Now</button>                 
      <button v-if="modData.area === 'Shoot'" type="button" @click="handleAddImage" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Add Image Now</button>                 
      <button v-if="modData.area === 'Image'" type="button" @click="handleAddAnotherImage" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Add Another Image</button>                 
    </div>
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
  </div>
</template>

<script>
import { useStore } from "@/composables/store/store"
export default {
  name: "SuccessModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "switchModalSub"],
  setup(props, context){
    console.log("pModal>Success -", props.modData)
    // provide("CURRENT", current)
    let store = useStore();

    let handleAddShoot = () => {
      console.log("pModal>Success>handleAddShoot - Add shoot now button")
      console.log("Set selected client in store" , props.modData.cid, props.modData.cEmail)
      
      // console.log("Set selected client - email - ", props.modData.cEmail)
      //link in store file"
      store.selectClient(props.modData.cid, props.modData.cEmail, props.modData.cNumShoots, props.modData.cNumImages, props.modData.cNumOrders)
      console.log("Get sel client from store - ", store.getSelClient.value)
      // current.setSelectedClient(props.modData.cid, props.modData.cEmail)
      let switchTo = "Add Shoot"
      context.emit("switchModalSub", switchTo )
    }
    let handleAddImage = () => {
      console.log("pModal>Success>handleAddImage - Add image now button")     
      //link in store file
      store.selectClient(props.modData.cid, props.modData.cEmail, props.modData.cNumShoots, props.modData.cNumImages, props.modData.cNumOrders)
      store.selectShoot(props.modData.sid, props.modData.sTitle, props.modData.sNumImages, props.modData.sNumColl, props.modData.sNumOrders)     
      let switchTo = "Add Image"
      context.emit("switchModalSub", switchTo )
    }
    let handleAddAnotherImage = () => {
      console.log("pModal>Success>handleAddAnotherImage - Add image now button")      
      //link in store file
      store.selectClient(props.modData.cid, props.modData.cEmail, props.modData.cNumShoots, props.modData.cNumImages, props.modData.cNumOrders)
      store.selectShoot(props.modData.sid, props.modData.sTitle, props.modData.sNumImages, props.modData.sNumColl, props.modData.sNumOrders)     
      let switchTo = "Add Image"
      context.emit("switchModalSub", switchTo )
    }

    let closeSubMod = () => {
      console.log("pModal>Success>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    return{handleAddShoot, handleAddImage, handleAddAnotherImage, closeSubMod}
  }
}
</script>

<style>

</style>