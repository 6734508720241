<template>
  <div class="px-2 col-12 col-sm-11 mx-auto" ref="selectCliSht">
    <div v-if="adding === 'shoot'">
      <div v-if='selectedClientId == null || selectedClientId == ""' class="col-12 mx-auto text-center">     
        <p class="fs-5 fw-normal text-charcoal text-capitalize p-0 m-0">{{modData.message1}}</p>                  
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="col-12 mx-auto text-center">
        <p class="fs-6 h1 fw-bold">{{selectedClientEmail}}<br><span class="fs-6 fw-normal text-charcoal">{{modData.message2}}</span></p>                  
      </div>
      <div v-if='selectedClientEmail == "" || selectedClientEmail == null' class="mt-2 col-12 mx-auto">
        <div v-if="snapSelClientsDoc && userClientsSelDoc.length > 0" class="p-0 m-0">
          <ul class="list-unstyled py-0 m-0">
            <li v-for="client in userClientsSelDoc" :key="client.id" class="p-0 m-0 mb-3 row ">
              <div class="d-flex flex-column align-items-center border border-1 border-lightgrey rounded p-1">
                <p class="fs-6 text-truncate p-0 m-0 mb-2">{{ client.email }}</p> 
                <button v-show="adding === 'shoot'" type="button" class="btn btn-highlight w-50 rounded-pill text-white text-uppercase shadow-sm" @click="store.selectClient(client.id, client.email,  client.shoots.numberShoots, client.images.numberImages, client.orders.numberOrders),handleAddShoot()" :class='{"btn-sm": mobileView}'>Select</button> 
              </div> 
            </li>
          </ul>            
        </div>
        <div v-else>
           <p class="fs-5 fw-normal text-center text-charcoal text-capitalize p-0 m-0">Please create a client first</p>
        </div>     
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="mt-3 col-12 mx-auto">
        <button type="button" @click="handleAddShoot" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Create Shoot</button>                 
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="mt-0 col-12 mx-auto py-1">
        <p class="fs-6 fw-bold p-0 m-0 text-charcoal text-uppercase text-center">or</p>                           
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="mt-0 pb-2 col-12 mx-auto">
        <button type="button" @click="store.deselectClient()" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Change Client</button>                 
      </div>
    </div>
    <div v-if="adding === 'image'">
      <div v-if='selectedClientEmail == "" || selectedClientEmail == null' class="col-12 mx-auto text-center">
        <p class="fs-5 fw-normal text-charcoal text-capitalize p-0 m-0">{{modData.message1}}</p>                  
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="col-12 mx-auto text-center">
        <p class="fs-6 small text-muted p-0 m-0">{{selectedClientEmail}}</p>
      </div>      
      <div v-if='selectedClientEmail == "" || selectedClientEmail == null' class="col-12 mx-auto mt-2">
        <div class="p-0 m-0" v-if="snapSelClientsDoc && userClientsSelDoc.length > 0">
          <ul class="list-unstyled py-0 m-0">
            <li v-for="client in userClientsSelDoc" :key="client.id" class="p-0 m-0 mb-2 row">
              <div class="d-flex flex-column align-items-center border border-1 border-lightgrey rounded p-1">
                <p class="fs-6 text-truncate p-0 m-0 mb-2">{{ client.email }}</p> 
                <button v-show="adding === 'image'" type="button" class="btn btn-highlight w-50 rounded-pill text-white text-uppercase btn shadow-sm mb-2" @click="store.selectClient(client.id, client.email, client.shoots.numberShoots, client.images.numberImages, client.orders.numberOrders),gotoSelectShoot()" :class='{"btn-sm": mobileView}'>Select</button> 
              </div>
            </li>
          </ul>  
        </div> 
        <div v-else>
          <p class="fs-5 fw-normal text-center text-charcoal text-capitalize p-0 m-0">Please create a client first</p>
           <button type="button" @click="addClientMod" class="btn btn-charcoal rounded-pill text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}'>Add Client</button>
        </div>                 
      </div>
      <div v-if='selectedClientId != null && selectedClientId != "" && snapSelShootsDoc && clientShootsSelDoc.length > 0' class="col-12 mx-auto text-center mb-2 mt-1">
        <p class="fs-6 p fw-normal text-charcoal">{{modData.message2}}</p>                  
      </div>
      <div v-if='selectedClientEmail != "" && selectedClientEmail != null' class="col-12 mx-auto mt-2 mb-2">
        <div class="p-0 m-0" v-if="snapSelShootsDoc && clientShootsSelDoc.length > 0">
          <ul class="list-unstyled py-0 m-0">
            <li v-for="shoot in clientShootsSelDoc" :key="shoot.id" class="p-0 m-0 mb-3 row">
              <div class="d-flex flex-column align-items-center border border-1 border-lightgrey rounded p-1 text-truncate">
                <p class="fs-6 text-truncate p-0 m-0 mb-2">{{ shoot.shootTitle }}</p>
                <button v-show="adding === 'image'" type="button" class="btn btn-highlight w-50 rounded-pill text-white text-uppercase shadow-sm" @click="store.selectShoot(shoot.id, shoot.shootTitle, shoot.images.numberImages, shoot.collections.numberCollections, shoot.orders.numberOrders),gotoAddImage()" :class='{"btn-sm": mobileView}'>Select</button> 
              </div>            
            </li>
          </ul> 
        </div> 
        <div v-else>
          <p class="fs-5 fw-normal text-center text-charcoal text-capitalize p-0 m-0">Please create a shoot first</p>
          <button type="button" @click="handleAddShoot" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Create Shoot</button>  
        </div>                  
      </div>
    </div>
    <div class="col-12 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-2 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
  </div>
</template>

<script>
import { useStore } from "@/composables/store/store"
import {db} from "../../firebase/config"
import {ref, computed, watchEffect, onBeforeUnmount} from "vue"
import {onSnapshot, query, where, collection} from "firebase/firestore"
export default {
  name: "SelectModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "switchModalSub"],
  setup(props, context){
    console.log("pModal > Select - ", props.modData.area)
    let store = useStore();
    const selectCliSht = ref(null)

     //Clients Doc
    let snapSelClientsDoc = ref(false)
    let userClientsSelDoc = ref([])
    let unsubscribeSelC

    //Shoots Doc
    let snapSelShootsDoc = ref(false)
    let clientShootsSelDoc = ref([])
    let unsubscribeSelS


    const stopSelectWatch = watchEffect(() => {
      if(selectCliSht.value && selectCliSht.value.children.length > 0){
        console.log("running stopSelectWatch - selClient")
        if(snapSelClientsDoc.value != true)
        {
          snapSelUserClientsDoc()
        }
      
        if(snapSelShootsDoc.value != true)
        {
          snapSelClientShootsDoc()
        }
      }
    })
   
    const snapSelUserClientsDoc = () => {
      console.log("running snapSelUserClientsDoc")
      const clientsDocRef = collection(db, "clients")
      const qrySelC = query(clientsDocRef, where("userId", "==", store.getUserId.value))  
      unsubscribeSelC = onSnapshot(qrySelC, (querySnapshot) => {
        const qrySelCRes = []
        querySnapshot.forEach((doc) => {
          qrySelCRes.push({ ...doc.data(), id: doc.id })
        })
        qrySelCRes.sort((a, b) => {
          return b.ClientCreationDate - a.ClientCreationDate;
        })
        console.log("running snapSelUserClientDocs - ", qrySelCRes)
        userClientsSelDoc.value = qrySelCRes        
        snapSelClientsDoc.value = true       
      })
    }

    const snapSelClientShootsDoc = () => {
      console.log("running snapSelClientShootsDoc - ", store.getSelClient.value)
      const shootsDocRef = collection(db, "shoots")
      const qrySelS = query(shootsDocRef, where("clientId", "==", store.getSelClient.value))  
      unsubscribeSelS = onSnapshot(qrySelS, (querySnapshot) => {
        const qrySelSRes = []
        querySnapshot.forEach((doc) => {
          qrySelSRes.push({ ...doc.data(), id: doc.id })
        })
        qrySelSRes.sort((a, b) => {
          return b.shootCreationDate - a.shootCreationDate;
        })
        console.log("running snapSelClientShootsDocs - ", qrySelSRes)
        clientShootsSelDoc.value = qrySelSRes        
        snapSelShootsDoc.value = true       
      })
    }

    onBeforeUnmount(() => {
      if(selectCliSht.value){        
        if(unsubscribeSelC){
          unsubscribeSelC()
        }       
        if(unsubscribeSelS){
          unsubscribeSelS()
        }        
        stopSelectWatch()
        
      }
    })
   
    const selectedClientId = computed(() => store.getSelClient.value );
    const selectedClientEmail = computed(() => store.getSelClientEmail.value );
    const selectedShootId = computed(() => store.getSelShoot.value)
    const selectedShootTitle = computed(() => store.getSelShootTitle.value)

    let adding = computed(() => {
      if(props.modData.area === "Shoot"){
        return "image"
      } 
      else{
        return "shoot"
      }
    })

    let gotoSelectShoot = () => {
      // console.log("Going Select Shoot")
      let switchTo = "Select"
      context.emit("switchModalSub", switchTo )
    }
    let gotoAddImage = () => {
      // console.log("Going Add Image")
      let switchTo = "Add Image"
      context.emit("switchModalSub", switchTo )
    }


    let handleAddShoot = () => {     
      // console.log("Get sel client from store - ", store.getSelClient.value)
      let switchTo = "Add Shoot"
      context.emit("switchModalSub", switchTo )
    }

     let addClientMod = () => {
      // console.log("ClientUsage>Add Client Modal Trigger Clicked")
      // let modData = {title: "Add", area:"Client"}
      // context.emit("showDataModal", modData)
      let switchTo = "Add Client"
      context.emit("switchModalSub", switchTo )
    }

    let closeSubMod = () => {
      // console.log("pModal>Help>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    return{selectCliSht, store, closeSubMod, handleAddShoot, adding, gotoSelectShoot, gotoAddImage, snapSelClientsDoc, snapSelShootsDoc, userClientsSelDoc, clientShootsSelDoc, selectedClientId, selectedClientEmail, selectedShootId, selectedShootTitle, addClientMod }
  }
}
</script>

<style>

</style>