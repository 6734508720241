<template>
  <div>
    <div class="col-12 col-sm-11 mx-auto">
      <p class="fs-6 p-0 m-0 text-center mb-4">Enter your email for password reset link</p>                  
    </div>
    <div ref="resetPwFormMod" class="col-12 col-sm-11 p-0 m-0 mx-auto">
      <form class="needs-validation" novalidate @submit.prevent="handlePwReset">
        <div class="mb-1 pb-3" @click="assignFocus('resetPasswordEmail')">
          <label for="resetPasswordEmail" class="form-label" >Email</label>
          <div class="input-group position-relative" :class='{"input-group-sm": mobileView}'>
            <input ref="firstFocus" type="email" class="form-control" id="resetPasswordEmail" name="resetPasswordEmail" placeholder="Enter Email" required v-model="resetPasswordEmail" autocomplete="off">
            <div class="invalid-tooltip">Please provide a valid Email Address</div>
          </div>
        </div> 
        <div class="mt-2 col-12 mx-auto">
          <button v-show="!pwRstPending" type="submit" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Send Reset Link</button> 
          <button v-show="pwRstPending" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' disabled>Sending..</button>  
        </div>        
      </form>
    </div>                  
    <div class="col-12 col-sm-11 mx-auto text-center">
      <p class="p-link small text-decoration-underline p-0 m-0 mt-3 mb-2" @click.prevent="closeSubMod" >Cancel</p>                 
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div> 
  </div>                     
</template>

<script>
import {validateResetPWEmail} from "../../../public/js/validation.js"
import useResetPassword from "@/composables/useResetPassword"
import { ref, watchEffect, onBeforeUnmount, onUnmounted } from "vue"
 import Spinner from "@/components/Spinner.vue"

export default {
name: "ResetPwModContent",
emits: ["closeModalSub", "showAlertSub"],
components: {Spinner},
props: ["mobileView"],
setup(props, context){

    const resetPwFormMod = ref(null)
    const firstFocus = ref(null)

    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)

    // Reset password vars
    const { resetPW, error:pwRstErr, isPending:pwRstPending } = useResetPassword()
    const resetPasswordEmail = ref(null)

      const stopResetPwModSubWatch = watchEffect(() => {
      console.log("watchEffect - stopResetPwModSubWatch")
      if (firstFocus.value) {
        firstFocus.value.focus()
      }
      if(resetPwFormMod.value) {
        if(resetPwFormMod.value.children.length > 0) {        
          console.log("resetPwFormMod - setting uplisteners")
           resetPwFormMod.value.querySelector("#resetPasswordEmail").addEventListener("blur", validateResetPWEmail)
        }
      }
    })

    onBeforeUnmount(() => {      
      if(resetPwFormMod.value) {
        resetPwFormMod.value.querySelector("#resetPasswordEmail").removeEventListener("blur", validateResetPWEmail)       
      }
    })

    onUnmounted(()=> {
      console.log("pModal>ResetPassword - onUnmounted")
      stopResetPwModSubWatch()           
    })    

    const closeSubMod = () => {
      console.log("pModal>ResetPassword>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }

    const assignFocus = (focusArea) => {
      console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = resetPwFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    //handle passwordReset
    const handlePwReset = async () => {
      console.log("handlePwReset")
      let form = resetPwFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateResetPWEmail()) {
         errorMessage.value = "An error has occurred please check the email entered or contact PHOTAR support"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)        
          resetPasswordEmail.value = null     
          inProgress.value = false   
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)     
      }
      else {
        form.classList.add("was-validated")
        inProgress.value = true 
        let res = await resetPW(resetPasswordEmail.value)
        console.log("res - ", res)
        if (!pwRstErr.value){
          errorMessage.value = "A password reset link has been sent to your email"
          let alertLvl = 2
          errorArray.value.push(alertLvl)
          errorArray.value.push(errorMessage.value)
          context.emit("showAlertSub", errorArray)    
          context.emit("closeModalSub")
          inProgress.value = false
        }
        else {     
          errorMessage.value = "An error has occurred please check the email entered or contact PHOTAR support"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)        
          resetPasswordEmail.value = null     
          inProgress.value = false   
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)
        }
      }
    }
    
    return {handlePwReset, closeSubMod, firstFocus, inProgress, assignFocus, resetPwFormMod, resetPasswordEmail, pwRstPending}
}
}
</script>

<style>

</style>