<template>
  <div>
    <div ref="trialSignupFormMod" class="trialSignupFormCont">
      <!-- SIGNUP FROM TRIAL PROGRESS -->
      <div class="col-12 col-sm-11 mx-auto">
        <div class="form-steps row-cols-2 p-0 m-0">
          <div class="step text-center position-relative">
            <p class="fw-normal">Package</p>
            <div class="bullet">
                <span>1</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
          <div class="step text-center position-relative">
            <p class="fw-normal">Details</p>
            <div class="bullet">
                <span>2</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>        
        </div>        
      </div>
      <!-- SIGNUP FROM TRIAL FORM -->
      <div class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper">
        <form class="needs-validation" id="trialsignupform" novalidate @submit.prevent="handleTrialSignup">
          <!-- SIGNUP FROM TRIAL - PAGE 1 -->
          <div class="page slide-page">
            <div class="col-12 mx-auto text-center">
              <p class="small p-0 m-0 mt-1">Current Package: <span class=" fw-bold">{{modData.obj.subscription}}</span></p>
            </div>
            <div class="row mb-1 gx-1 gx-lg-2">                   
              <div class="col-6">
                <p class="small p-0 m-0 mt-1">Signed Up: <span class=" fw-bold">{{trialStartDate}}</span></p>
                <p class="small p-0 m-0 mt-1">Expires: <span class=" fw-bold">{{trialEndDate}}</span></p>
              </div>
              <div class="col-6 text-end">                
                <p><span class="dispNum display-5 fw-bolder text-highlight m-0 p-0">{{trialRemaining}}</span> Days Remaining</p>
              </div>
            </div>
            <div class="col-12 mx-auto text-center mb-4">
              <label for="trialSignupPackage" class="form-label sUpLabel">Select Package</label>
              <select ref="firstFocus" class="form-select" id="trialSignupPackage" v-model="signupPackage" :class='{"form-select-sm": mobileView}'>
                <option value="Basic">Basic Package</option>
                <option value="Enhanced">Enhanced Package</option>
                <option value="Ultimate">Ultimate Package</option>
              </select>
            </div>
            <div class="col-12 mx-auto p-0 m-0 mb-2 text-center">
              <PriceSelection ref="selectPrice" :signupPackage="signupPackage" />
            </div>
             <div class="col-12 mx-auto text-center">
              <p class="small p-0 m-0 mt-2">By signing up, you agree to our <span class=" p-link text-decoration-underline">Terms of Service</span> and <span class="p-link text-decoration-underline">Privacy Policy</span></p>                 
            </div>
            <div class="mt-4 col-12  mx-auto">    
              <button type="button" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm firstNext next" :class='{"btn-sm": mobileView}'>Continue</button>
            </div>
          </div>
          <!-- SIGNUP FROM TRIAL - PAGE 2 -->
          <div class="page">
            <div class="row mb-1 gx-2">                   
              <div class="col-6" @click="assignFocus('trialSignupFirstname')">
                <!-- ADD SECOND PAGE FOCUS -->
                <label for="trialSignupFirstname" class="form-label sUpLabel" >First Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupFirstname" placeholder="First name" required v-model="trialSignupFirstname" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('trialSignupLastname')">
                <label for="trialSignupLastname" class="form-label sUpLabel" >Last Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupLastname" placeholder="Lastname" required v-model="trialSignupLastname" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('trialSignupPhone')">
                <label for="trialSignupPhone" class="form-label sUpLabel" >Phone *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="tel" class="form-control" id="trialSignupPhone" placeholder=" Phone" required v-model="trialSignupPhone" autocomplete="off">
                  <div class="invalid-tooltip">
                    Numeric values only
                  </div>
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('trialSignupDateBirth')">
                <label for="trialSignupDateBirth" class="form-label sUpLabel" >Date of Birth</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="date" class="form-control dob" id="trialSignupDateBirth" placeholder="Date of Birth" v-model="trialSignupDateBirth" autocomplete="off">                            
                </div>
              </div>
            </div>
            <div class="mb-1 mt-2" @click="assignFocus('trialSignupAddress1')">
              <label for="trialSignupAddress1" class="form-label sUpLabel" >Address Line 1 *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input type="text" class="form-control" id="trialSignupAddress1" placeholder="Address 1" required v-model="trialSignupAddress1" autocomplete="off">
                <div class="invalid-tooltip">
                  Required Field
                </div>
              </div>
            </div>
            <div class="mb-1" @click="assignFocus('trialSignupAddress2')">
              <label for="trialSignupAddress2" class="form-label sUpLabel" >Address Line 2</label>
              <div class="input-group" :class='{"input-group-sm": mobileView}'>
                <input type="text" class="form-control" id="trialSignupAddress2" placeholder="Address 2" v-model="trialSignupAddress2" autocomplete="off">              
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('trialSignupCity')">
                <label for="trialSignupCity" class="form-label sUpLabel" >City / Town</label>
                <div class="input-group " :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupCity" placeholder="City / Town" v-model="trialSignupCity" autocomplete="off">                  
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('trialSignupPostcode')">
                <label for="trialSignupPostcode" class="form-label sUpLabel" >Postcode *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupPostcode" placeholder="Enter Postcode" required v-model="trialSignupPostcode" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
            </div> 
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('trialSignupRegion')">
                <label for="trialSignupRegion" class="form-label sUpLabel" >Region/County</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupRegion" placeholder="Region/County" v-model="trialSignupRegion" autocomplete="off">                  
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('trialSignupCountry')">
                <label for="trialSignupCountry" class="form-label sUpLabel" >Country</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="trialSignupCountry" placeholder="Enter Country" v-model="trialSignupCountry" autocomplete="off">                           
                </div>
              </div>
            </div> 
            <div class="col-12 mx-auto p-0 m-0">
              <div class="mb-0 text-end">
                <p class="p-0 m-0 small text-charcoal"><span class="fs-6">* </span>required field</p>                
              </div>
            </div>    
            <div class="mt-3 col-12 mx-auto">
              <button v-if="!updateDocPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Sign Up</button> 
              <button v-else type="button" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" disabled :class='{"btn-sm": mobileView}'>In Progress</button>               
            </div>         
            <div class="mt-2 mb-3 col-12 mx-auto">
              <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm prev-1 prev" :class='{"btn-sm": mobileView}'>Back</button>  
            </div>    
          </div>
        </form>
      </div>           
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>              
</template>

<script>
import {validateTrialSignupFirstname, validateTrialSignupLastname, validateTrialSignupPhone, validateTrialSignupAddress1, validateTrialSignupPostcode} from "../../../public/js/validation.js"
import Spinner from "@/components/Spinner.vue"
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watchEffect, watch } from "vue"
import PriceSelection from "@/components/PriceSelection";
import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore"
import oneTimePayment from "@/composables/oneTimePayment"
import subscriptionPayment from "@/composables/subscriptionPayment"
import updateDocument from "@/composables/updateDocument"
import { getFunctions, httpsCallable } from "firebase/functions"
import { auth, db} from "@/firebase/config"
// import { useRouter } from "vue-router"
export default {
name: "SignupFromTrial",
emits: ["closeModalSub", "showAlertSub"],
props: ["modData", "mobileView"],
components: {Spinner, PriceSelection},
setup(props, context){
    const trialSignupFormMod = ref(null)
    const firstFocus = ref(null)
    const { useUpdateDocument, error:updateDocError, isPending:updateDocPending } = updateDocument()
    const { useSubscriptionPayment, retError: subError } = subscriptionPayment()
    const { useOneTimePayment, retError: otpError } = oneTimePayment()

    const selectPrice = ref(null)
    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)

    // Trial Signup page progress bar vars
    let slidePage = ref(null)
    let nextBtnFirst = ref(null)
    let prevBtnSec = ref(null)
    let progressText = ref(null)
    let progressCheck = ref(null)
    let bullet = ref(null)
    let bulletSpan = ref(null)
    let current = ref(0)
    let currentSet = ref(false)
    let submitBtn = ref(null) 

    let trialSignupFirstname = ref(null)
    let trialSignupLastname = ref(null)
    let trialSignupPhone = ref(null)
    let trialSignupDateBirth = ref(null)
    let trialSignupAddress1 = ref(null)
    let trialSignupAddress2 = ref(null)
    let trialSignupCity = ref(null)
    let trialSignupPostcode = ref(null)
    let trialSignupRegion = ref(null)
    let trialSignupCountry = ref(null)
    const packagePriceCode = ref("")
    const signupDuration = ref("")
    const signupPackage = ref("Enhanced")

    const initialUserDoc = ref(null)
    let functions = getFunctions()
    const addCurrent = () => {
      // console.log("RUNNING ADDCURRENT - ", current.value)
      bullet.value[current.value].classList.add("current");
      bulletSpan.value[current.value].classList.add("current");
      progressCheck.value[current.value].classList.add("current");
      progressText.value[current.value].classList.add("current");
    }
    const removeCurrent = () => {
      // console.log("RUNNING REMOVECURRENT - ", current.value)
      bullet.value[current.value].classList.remove("current");
      bulletSpan.value[current.value].classList.remove("current");
      progressCheck.value[current.value].classList.remove("current");
      progressText.value[current.value].classList.remove("current");
    }
    const addActive = () => {
      // console.log("RUNNING ADDACTIVE - ", current.value)
      bullet.value[current.value].classList.add("active");
      progressCheck.value[current.value].classList.add("active");
      progressText.value[current.value].classList.add("active");
    }
    const removeActive = () => {
      // console.log("RUNNING REMOVEACTIVE - ", current.value)
      bullet.value[current.value].classList.remove("active");
      progressCheck.value[current.value].classList.remove("active");
      progressText.value[current.value].classList.remove("active");
    }

    let trialSignupProgress = {
      checkUserAcc: false,
      checkUserDoc: false,
      updateDetails: false,
      updateRole: false, 
      paymentSent: false,   
      paymentDone: false   
    }

    let trialConverted = computed(() => {
      if(trialSignupProgress.checkUserAcc == true && trialSignupProgress.checkUserDoc == true && trialSignupProgress.updateDetails == true && trialSignupProgress.paymentSent == true)
      {
        return true
      }
      else {
        return false
      }
    })

    const stopConvertTrialUserWatch = watchEffect(() => {
      console.log("watchEffect - stopConvertTrialUserWatch")
      if(auth.currentUser.uid){
        if (firstFocus.value) {
          firstFocus.value.focus()
        }
        if (trialSignupFormMod.value) {  
          if(trialSignupFormMod.value.children.length > 0){
            //MULTI PAGE FORM CONTROLS
            //SELECTORS
            if(currentSet.value != true)
            {
              slidePage.value = trialSignupFormMod.value.querySelector(".slide-page")
              nextBtnFirst.value = trialSignupFormMod.value.querySelector(".firstNext")
              prevBtnSec.value = trialSignupFormMod.value.querySelector(".prev-1")            
              progressText.value = trialSignupFormMod.value.querySelectorAll(".step p")
              progressCheck.value = trialSignupFormMod.value.querySelectorAll(".step .check")
              bullet.value = trialSignupFormMod.value.querySelectorAll(".step .bullet")
              bulletSpan.value = trialSignupFormMod.value.querySelectorAll(".step .bullet span")
              submitBtn.value = trialSignupFormMod.value.querySelector(".submit")
              addCurrent()
              removeActive()
              //LISTENERS
              nextBtnFirst.value.addEventListener("click", function(event){
                console.log("Continue Clicked ")
                event.preventDefault();
                if(packagePriceCode.value != null)
                {
                  removeCurrent()
                  addActive()
                  slidePage.value.style.marginLeft = "-50%";
                  current.value += 1;
                  addCurrent()
                }
                else{
                  let alertLvl = 1
                  errorArray.value.push(alertLvl)
                  errorMessage.value = "Please Select a package"
                  errorArray.value.push(errorMessage.value)  
                  context.emit("showAlertSub", errorArray)
                }              
              });
              prevBtnSec.value.addEventListener("click", function(event){
                event.preventDefault();
                removeCurrent()
                slidePage.value.style.marginLeft = "0%";                
                current.value -= 1;
                removeActive()
                addCurrent()
              });
              //MULTIPAGE FORM VALIDATION START
              //PAGE 2
              trialSignupFormMod.value.querySelector("#trialSignupFirstname").addEventListener("blur", validateTrialSignupFirstname)
              trialSignupFormMod.value.querySelector("#trialSignupLastname").addEventListener("blur", validateTrialSignupLastname)
              trialSignupFormMod.value.querySelector("#trialSignupPhone").addEventListener("blur", validateTrialSignupPhone)
              trialSignupFormMod.value.querySelector("#trialSignupAddress1").addEventListener("blur", validateTrialSignupAddress1)
              trialSignupFormMod.value.querySelector("#trialSignupPostcode").addEventListener("blur", validateTrialSignupPostcode)
              currentSet.value = true
            }
          }
        }
      }
    })

    onBeforeUnmount(() => {
      nextBtnFirst.value.removeEventListener("click", function () {})
      prevBtnSec.value.removeEventListener("click", function () {})
      trialSignupFormMod.value.querySelector("#trialSignupFirstname").removeEventListener("blur", validateTrialSignupFirstname)
      trialSignupFormMod.value.querySelector("#trialSignupLastname").removeEventListener("blur", validateTrialSignupLastname)
      trialSignupFormMod.value.querySelector("#trialSignupPhone").removeEventListener("blur", validateTrialSignupPhone)
      trialSignupFormMod.value.querySelector("#trialSignupAddress1").removeEventListener("blur", validateTrialSignupAddress1)
      trialSignupFormMod.value.querySelector("#trialSignupPostcode").removeEventListener("blur", validateTrialSignupPostcode)
    })

    onUnmounted(() => {
      console.log("pModal>Trial Signup - onUnmounted")
      clearVars()
      stopConvertTrialUserWatch()
    }) 

    onMounted(() => {
      // setMobileView()
      watch(
        () => selectPrice.value.selectedPrice,
        (newVal) => {
          console.log("watching selected price - ", { newVal }) 
          packagePriceCode.value = newVal
        }
      );
      watch(
        () => selectPrice.value.selectedPriceInterval,
        (newVal2) => {
          console.log("watching selected price interval - ", { newVal2}) 
          signupDuration.value = newVal2
          console.log("signupDuration.value - ", signupDuration.value )
        }
      );
    })

    const signupDate = computed(() => {
      let sUpDate = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
      return sUpDate
    })

    const renewalDate = computed(() => {
      let rnwDate1 = new Date(new Date().setFullYear(new Date().getFullYear() +1))       
      let rnwDate2 = ((rnwDate1.valueOf()) / 1000)
      let rnwDateTS = new Timestamp(rnwDate2, 0)
      return rnwDateTS       
    })

    const reminderDate = computed(() => {
      let remDate1 = new Date()      
      let remDate2 = new Date(remDate1.setFullYear(remDate1.getFullYear(), remDate1.getMonth() + 11))
      let remDate3 = ((remDate2.valueOf()) / 1000) 
      let remDateTS = new Timestamp(remDate3, 0)
      return remDateTS    
    })

    const clearVars = () => {
      console.log("Running TrialSignup clearVars")
      trialSignupFormMod.value = null
      firstFocus.value = null
      errorMessage.value = ""
      errorArray.value = []
      inProgress.value = false
      slidePage.value = null
      nextBtnFirst.value = null
      prevBtnSec.value = null
      progressText.value = null
      progressCheck.value = null
      bullet.value = null
      bulletSpan.value = null
      current.value = 0
      currentSet.value = false
      submitBtn.value = null
      trialSignupFirstname.value = null
      trialSignupLastname.value = null
      trialSignupPhone.value = null
      trialSignupDateBirth.value = null
      trialSignupAddress1.value = null
      trialSignupAddress2.value = null
      trialSignupCity.value = null
      trialSignupPostcode.value = null
      trialSignupRegion.value = null
      trialSignupCountry.value = null
      packagePriceCode.value = ""
      signupDuration.value = ""
      trialSignupProgress.checkUserAcc = false
      trialSignupProgress.checkUserDoc = false
      trialSignupProgress.updateDetails = false
      trialSignupProgress.paymentSent = false
      trialSignupProgress.paymentDone = false
      trialSignupProgress.updateRole = false
      initialUserDoc.value = null
      // trialSignupProgress.updateCounts = false
    }

    const cleanUp = async () => {
      console.log("Running TrialSignup Cleanup - ", trialSignupProgress)
      console.log("Running TrialSignup Cleanup initialUserDoc- ", initialUserDoc.value)
      errorMessage.value = ""
      errorArray.value = []
      if(trialSignupProgress.updateDetails == true){
        await setDoc(doc(db, "users", auth.currentUser.uid), initialUserDoc.value)
      }
      if(trialSignupProgress.updateRole == true){
        const resetRole = httpsCallable(functions, "addAdminRole")
        await resetRole({ email:auth.currentUser.email, accessLevel:2 })        
      }
      //  if(trialSignupProgress.checkUserAcc == true && trialSignupProgress.checkUserDoc == true && trialSignupProgress.updateDetails == true && trialSignupProgress.paymentSent == true)
      context.emit("closeModalSub")
    }

    const assignFocus = (focusArea) => {
      console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = trialSignupFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    function showError() {
      let alertLvl = 1
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value) 
      context.emit("showAlertSub", errorArray)             
      inProgress.value = false 
      cleanUp()
      
    }
    
    const handleTrialSignup = async () => {
      console.log("handleTrailSignup")
      let form = trialSignupFormMod.value.querySelector(".needs-validation")
      if ( !form.checkValidity() || !validateTrialSignupFirstname() || !validateTrialSignupLastname() || !validateTrialSignupPhone() || !validateTrialSignupAddress1() || !validateTrialSignupPostcode) {
        errorMessage.value = "Signup Error - Please recheck signup form"
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value)            
        form.classList.remove("was-validated")
        context.emit("showAlertSub", errorArray)  
        return         
      }
      else {
        form.classList.add("was-validated")
        inProgress.value = true        
        // checkUserAcc
        if(auth.currentUser){
          //check user access is trial         
          await auth.currentUser.getIdTokenResult().then((res) => {
            if(res.claims.accessLevel == 2){
              trialSignupProgress.checkUserAcc = true
            }
            else{
              trialSignupProgress.checkUserAcc = false
              errorMessage.value = "Error Occurred While Locating Trial Account"
              showError()
              return
            }
          })
        } else {
          trialSignupProgress.checkUserAcc = false
          errorMessage.value = "Error Occurred While Locating Trial Account"
          showError()
          return
        }
        // checkUserDoc
        const docRef = doc(db, "users", auth.currentUser.uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          initialUserDoc.value = {...docSnap.data()} 
          trialSignupProgress.checkUserDoc = true
          console.log("initialUserDoc.value - ", initialUserDoc.value)
        } else {
          trialSignupProgress.checkUserDoc = false
          errorMessage.value = "Error Occurred While Locating User Documentation"
          showError()
          return
        }        
        // updateDetails
        const userInfo = {              
          userDetails: {
            firstName: trialSignupFirstname.value,
            lastName: trialSignupLastname.value,
            birthDate: trialSignupDateBirth.value,
            phoneNum: trialSignupPhone.value,
          }, 
          userAddress: {
            address1: trialSignupAddress1.value,
            address2: trialSignupAddress2.value,
            city: trialSignupCity.value,
            postcode: trialSignupPostcode.value,
            region: trialSignupRegion.value,
            country: trialSignupCountry.value,
          },             
          subscription: signupPackage.value,
          signupDate: signupDate.value,
          duration: signupDuration.value,
          renewalDate: renewalDate.value,
          reminderDate: reminderDate.value,
          trialEndDate: "n/a",
          reminder1Date: "n/a",
          reminder2Date: "n/a",
          reminder3Date: "n/a"
        }
        console.log("userInfo - ", userInfo)
        await useUpdateDocument("users", auth.currentUser.uid, userInfo)
        if (updateDocError.value != null){
          trialSignupProgress.updateDetails = false
          errorMessage.value = "Error Occurred While Updating User Documentation"
          showError()
          return
        }
        else{
          trialSignupProgress.updateDetails = true
          let access = 0
          if (signupPackage.value === "Basic")
          {
            access = 4
          }
          else if (signupPackage.value === "Enhanced")
          {
            access = 6
          }
          else if (signupPackage.value === "Ultimate")
          {
            access = 8
          }
          try {
            const updateRole = httpsCallable(functions, "addAdminRole")
            await updateRole({ email:auth.currentUser.email, accessLevel:access })
            .then((res) => {
              trialSignupProgress.updateRole = true
              console.log("res", res)
            }) 
          } catch (error){
            trialSignupProgress.updateRole = true
            errorMessage.value = "Error Occurred While Setting User Role"
            showError()
            return           
          }
          if(signupDuration.value != "oneoff"){
            await useSubscriptionPayment(auth.currentUser.uid, packagePriceCode.value)
              if (subError.value != null)
              {
                trialSignupProgress.paymentSent = false
                errorMessage.value = "Error creating subscription payment"
                showError()
                return
              }
              else {
                trialSignupProgress.paymentSent = true
                if(trialConverted.value == true){
                  errorMessage.value = "Redirecting to secure billing area"
                  let alertLvl = 2
                  errorArray.value.push(alertLvl)  
                  errorArray.value.push(errorMessage.value) 
                  context.emit("showAlertSub", errorArray)             
                  inProgress.value = false 
                  // return 
                }
              }
          }
          else if(signupDuration.value === "oneoff"){
            await useOneTimePayment(auth.currentUser.uid, packagePriceCode.value)
            if (otpError.value != null)
            {
              trialSignupProgress.paymentSent = false
              errorMessage.value = "Error creating one time payment"
              showError()
              return
            } 
            else {
              trialSignupProgress.paymentSent = true
              if(trialConverted.value == true){
                  errorMessage.value = "Redirecting to secure billing area"
                let alertLvl = 2
                errorArray.value.push(alertLvl)  
                errorArray.value.push(errorMessage.value) 
                context.emit("showAlertSub", errorArray)             
                inProgress.value = false 
              }
            }   
          }
        }
      }
    }
   
    const trialStartDate = computed(() => {
      if(props.modData.obj.signupDate){
        let tsD = props.modData.obj.signupDate
        tsD = tsD.toDate()
        tsD = tsD.toLocaleDateString("en-GB", {
          year: "numeric", month:"2-digit", day:"2-digit"
        })
        return tsD
      }
      else {
        return "n/a"
      }
    })
    const trialEndDate = computed(() => {
      if(props.modData.obj.trialEndDate){
        let teD = props.modData.obj.trialEndDate
        teD = teD.toDate()
        teD = teD.toLocaleDateString("en-GB", {
          year: "numeric", month:"2-digit", day:"2-digit"
        })
        return teD
      }
      else {
        return "n/a"
      }
    })
    const trialRemaining = computed(() => {
      if(props.modData.obj.signupDate && props.modData.obj.trialEndDate){
        let teD = props.modData.obj.trialEndDate
        teD = teD.toDate()
        teD = teD.getTime()
        let tsD = props.modData.obj.signupDate
        tsD = tsD.toDate()
        tsD = tsD.getTime()
        let timeDiff = teD - tsD
        let dayDiff = timeDiff / (1000 * 3600 * 24)
        dayDiff = Math.round(dayDiff)   
        return dayDiff        
      }
      else {
        return "n/a"
      }
    })


    let showAlertSubPass = (errorArray) => {
      console.log("pModal>upgrade>showAlertSubPass")
      context.emit("showAlertSub", errorArray)
    }   

    const closeSubMod = () => {
      console.log("pModal>VerifyEmail>closeSubMod - Close Button")
      context.emit("closeModalSub")
    }
    
    // const seeUserDoc = async () => {
    //   const docRef2 = doc(db, "users", auth.currentUser.uid)
    //   const docSnap2 = await getDoc(docRef2)
    //     if (docSnap2.exists()) {
    //       initialUserDoc.value = {...docSnap2.data()}           
    //       console.log("initialUserDoc.value - ", initialUserDoc.value)
    //     } else {
    //       console.log("Error Occurred While Locating User Documentation")
    //     }    
    // }
    
    return { trialSignupFormMod, handleTrialSignup, trialStartDate, trialEndDate, trialRemaining, firstFocus, assignFocus, inProgress, closeSubMod, showAlertSubPass, trialSignupFirstname, trialSignupLastname, trialSignupPhone, trialSignupDateBirth, trialSignupAddress1, trialSignupAddress2, trialSignupCity, trialSignupRegion, trialSignupPostcode, trialSignupCountry, selectPrice, signupPackage, signupDuration, updateDocPending }
}
}
</script>

<style scoped>
  
  ::selection{
    color: #fff;
    background: #00A4B0;
  }

  .trialSignupFormCont .form-wrapper{
    width: 100%;
    overflow: hidden;
  }

  .trialSignupFormCont .form-wrapper form{
    display: flex;
    width: 200%;
  }

  .trialSignupFormCont .form-wrapper form .page{
    width: 50%;
    transition: margin-left 0.3s ease-in-out;
    padding: 0 1rem;
  }

  .trialSignupFormCont .form-steps{
    display: flex;
    margin: 0.25rem 0;
    user-select: none;
  }

  .sUpLabel{
    font-size: 1rem !important;
    color: #3D3B3A !important;
  }

  .trialSignupFormCont .form-steps .step p{
    font-weight: 600;
    font-size: 1.1rem;
    color: #3d3b3a;
    margin-bottom: 0.125rem;
  }

   /* CURRENT */
  .trialSignupFormCont .form-steps .step p.current{
    font-weight: 800;
    color: #00A4B0;
  }

  .form-steps .step .bullet.current{   
    border: 0.125rem solid #00A4B0;
    background-color: #00A4B0;   
  }

  .form-steps .step .bullet span.current{
    color: #FFF;    
  }

  .form-steps .step .bullet{
    height: 2.25rem;
    width: 2.25rem;
    border: 0.125rem solid #bbc4c8;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
  }

  .form-steps .step .bullet.active{
    border-color: #00757D;
    /* background: #00A4B0; */
  }

  .form-steps .step .bullet span{
    position: absolute;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Old Standard TT', serif;
    color: #bbc4c8;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-steps .step .bullet.active span{
    display: none;
  }

  .form-steps .step .bullet:before,
  .form-steps .step .bullet:after{
    position: absolute;
    content: '';
    bottom: 0.95rem;
    height: 0.125rem;    
    background: #bbc4c8;
  }

  @media only screen and (max-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: calc((100vw / 3.6) * -1 );
      width: calc(100vw / 4);  
      /* right: calc((100vw / 5.9) * -1 );
      width: calc(100vw / 6.4);   */
    }
  }

  @media only screen and (min-width:577px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.7rem;
      width: 9rem;
    }
  }

  .form-steps .step .bullet.active:after{
    background: #00A4B0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
  }

  @keyframes animate {
    100%{
      transform: scaleX(1);
    }
  }

  .form-steps .step:last-child .bullet:before,
  .form-steps .step:last-child .bullet:after{
    display: none;
  }

  .form-steps .step p.active{
    color: #00A4B0;
    transition: 0.2s linear;
  }

  .form-steps .step .check{
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  .form-steps .step .check.active{
    display: block;
    color: #fff;
  }

  .check.bi::before {
    font-size: 2.25rem;
    line-height: 0.7;
    vertical-align: none;
  } 
</style>