<template>
  <div>
    <div ref="quickSignupFormMod" class="quickSignupFormCont">
      <!-- QUICK SIGNUP FORM -->
      <div class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper">
        <div class="col-12 mx-auto p-0 m-0 my-1 text-center">
          <p class="fs-5 fw-normal text-charcoal">{{subtitle}}</p>                  
        </div>        
        <form class="needs-validation " id="quicksignupform" novalidate @submit.prevent="handleQuickSignup">
          <div class="col-12 mx-auto p-0 m-0 mb-1" @click="assignFocus('qSignupEmail')">
            <label for="qSignupEmail" class="form-label sUpLabel" >Email *</label>
            <div class="input-group has-validation"  :class='{"input-group-sm": mobileView}'>
              <input ref="firstFocus" type="email" class="form-control" id="qSignupEmail" placeholder="Enter Email" required v-model="qSignupEmail" autocomplete="off">
              <div class="invalid-tooltip">Please provide a valid Email Address</div>
            </div>
          </div>
          <div class="col-12 mx-auto p-0 m-0 mb-1" @click="assignFocus('qSignupUsername')">
            <label for="qSignupUsername" class="form-label sUpLabel" >Username *</label>
            <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
              <input  type="text" class="form-control" id="qSignupUsername" placeholder="Enter Username" required v-model="qSignupUsername" autocomplete="off">
              <div class="invalid-tooltip">
                Please provide a valid Username at least 4 chars
              </div>
            </div>
          </div>
          <div class="col-12 mx-auto p-0 m-0 mb-1" @click="assignFocus('qSignupPassword')">
            <label for="qSignupPassword" class="form-label sUpLabel"  >Password *</label>
            <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
              <input type="password" class="form-control" name="qSignupPassword" id="qSignupPassword" placeholder="Password"  required v-model="qSignupPassword" autocomplete="off">
              <span class="input-group-text border-0">
                <i class="bi bi-eye" id="toggleQSignupPassword" style="cursor: pointer"></i>
              </span>
              <div class="invalid-tooltip">Passwords must contain a Capital letter, number and a special character.</div>
            </div>
          </div>
          <div class="form-check mb-1 mt-3">
            <label class="form-check-label">
            <input type="checkbox" class="form-check-input" id="signupNewsletter"  v-model="qSignupNewsletter">
            <span class="small p text-charcoal">Send Me The Newsletter</span>
            </label>
          </div>         
          <div class="mt-2 mb-2 py-2 col-12 mx-auto">
            <button v-if="!signupPending || !updateProfilePending || !createUserDocPending" type="submit" class="btn btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Sign Up</button>             
            <button v-else class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}' disabled>Signing Up</button> 
          </div>
           <div class="col-12 mx-auto p-0 m-0 mb-3 text-center">
            <p class="fs-6 fw-normal text-charcoal text-capitalize p-0 m-0">Enjoy a <span class=" text-highlight">28 day free trial</span> On Us</p>                 
          </div>           
        </form>
      </div>
      <div class="col-12 col-sm-11 mx-auto text-center">
        <p class="small p-0 m-0 mb-3">By signing up, you agree to our <span class=" p-link text-decoration-underline">Terms of Service</span> and <span class="p-link text-decoration-underline">Privacy Policy</span></p>                 
      </div>        
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>

<script>
  import {validateQkSignupUsername, validateQkSignupEmail, validateQkSignupPassword} from "../../../public/js/validation.js"
  import Spinner from "@/components/Spinner.vue"
  import useSignup from "@/composables/useSignup"
  import setDocument from "@/composables/setDocument"
  import checkDocument from "@/composables/checkDocument"
  import setTimestamp from "@/composables/setTimestamp"
  import sendVerEmail from "@/composables/sendVerEmail"
  import profileUpdate from "@/composables/profileUpdate"
  import { ref, watchEffect, onBeforeUnmount, onUnmounted, computed} from "vue"
  import { getFunctions, httpsCallable } from "firebase/functions"
  import {Tooltip} from "bootstrap"
  import { useRouter } from "vue-router"
  import linkLogin from "@/composables/linkLogin"
  import { Timestamp, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore"
  import { getAuth, deleteUser, signOut } from "firebase/auth";
  import { db } from "@/firebase/config"
export default {
  name: "QuickSignupModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "switchModalSub", "showAlertSub"],
  components: {Spinner},
  setup(props, context){
    console.log("pModal>qSignup -", props.modData)
    let quickSignupFormMod = ref(null)
    let firstFocus = ref(null)
    let router = useRouter()
    let { signup, error:signupError, isPending: signupPending} = useSignup()
    let { useSetDocument, error:createUserDocError, isPending:createUserDocPending } = setDocument()
    let { useCheckDocument, error:checkDocError } = checkDocument()
    let { emailVerify, error:emailVerError} = sendVerEmail()
    let { useSetTimestamp, error:timestampError} = setTimestamp()
    let { updateUserProfile, error:updateProfileError, isPending:updateProfilePending} = profileUpdate()
    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)
    let adminEmail = ref("")
    let newUserUid = ref("")

    // QuickSignup vars
    let qSignupUsername = ref(null)
    let qSignupEmail = ref(null)
    let qSignupPassword = ref(null)
    let qSignupFirstname = ref("n/a")
    let qSignupLastname = ref("n/a")
    let qSignupPhone = ref("n/a")
    let qSignupDateBirth = ref("n/a")
    let qSignupAddress1 = ref("n/a")
    let qSignupAddress2 = ref("n/a")
    let qSignupPostcode = ref("n/a")
    let qSignupCountry = ref("n/a")
    let qSignupPackage = ref("Trial")
    let qSignupDuration = ref("trial")
    let qAccessLevel = 2
    let qSignupNewsletter = ref(true)
    
    let quickSignupComplete = computed(() => {
      if(quickSignupProgress.allUsersChecked == true && quickSignupProgress.userAuthCreated == true && quickSignupProgress.userDocCreated == true && quickSignupProgress.userDocChecked == true && quickSignupProgress.timeStampAdded == true && quickSignupProgress.displayNameAdded == true && quickSignupProgress.socialMediaLinked == true && quickSignupProgress.rolesAdded == true && quickSignupProgress.allUsersUpdated == true ){
        return true
      }
      else {
        return false
      }
    })

    let quickSignupProgress = {      
      allUsersChecked: false,
      userAuthCreated: false,
      userDocCreated: false,
      userDocChecked: false,
      timeStampAdded: false,
      displayNameAdded: false,
      socialMediaLinked: false,
      rolesAdded: false,
      allUsersUpdated: false,
      sentVerifyEmail: false
    }

    const clearVars = () => {
      console.log("Running QuickSignup clearVars")
      // let form = quickSignupFormMod.value.querySelector(".needs-validation")
      // if(form.classList.contains("was-validated")){
      //   form.classList.remove("was-validated")
      // }
      quickSignupFormMod.value = null
      firstFocus.value = null
      errorMessage.value = ""
      errorArray.value = []
      inProgress.value = false
      qSignupUsername.value = null
      qSignupEmail.value = null
      qSignupPassword.value = null   
      adminEmail.value = ""
      newUserUid.value = ""
      quickSignupProgress.allUsersChecked = false
      quickSignupProgress.userAuthCreated = false
      quickSignupProgress.userDocCreated = false
      quickSignupProgress.userDocChecked = false
      quickSignupProgress.timeStampAdded = false
      quickSignupProgress.displayNameAdded = false
      quickSignupProgress.socialMediaLinked = false
      quickSignupProgress.rolesAdded = false
      quickSignupProgress.allUsersUpdated = false
      quickSignupProgress.sentVerifyEmail = false     
    }

    const stopQkSignupModSubWatch = watchEffect(() => {
      console.log("watchEffect - stopQkSignupModSubWatch")
      if (firstFocus.value) {
        firstFocus.value.focus()
      }
      if (quickSignupFormMod.value) {  
        if(quickSignupFormMod.value.children.length > 0){
          //tooltips
          // console.log("watchEffect - tooltips - ", quickSignupFormMod.value)
          let modTTips = quickSignupFormMod.value.querySelectorAll(".tt")
          // console.log("tooltips - ", modTTips)
          modTTips.forEach(t => {
            new Tooltip(t, { boundary: document.body, trigger: "hover" })
            // console.log("tooltip added")
          })  
          quickSignupFormMod.value.querySelector("#qSignupUsername").addEventListener("blur", validateQkSignupUsername)
          quickSignupFormMod.value.querySelector("#qSignupEmail").addEventListener("blur", validateQkSignupEmail)
          quickSignupFormMod.value.querySelector("#qSignupPassword").addEventListener("blur", validateQkSignupPassword)

          let togglePasswordQk = quickSignupFormMod.value.querySelector("#toggleQSignupPassword")
          let passwordQk = quickSignupFormMod.value.querySelector("#qSignupPassword")
          // console.log("Password - ", password)
          togglePasswordQk.addEventListener("click", function () {   
            let dType = passwordQk.getAttribute("type") === "password" ? "text" : "password";
            passwordQk.setAttribute("type", dType);
            togglePasswordQk.classList.toggle("bi-eye")
            togglePasswordQk.classList.toggle("bi-eye-slash")
          })   
        }
      }
    })

    onBeforeUnmount(() => {
      if (quickSignupFormMod.value) {
        let modTTips = quickSignupFormMod.value.querySelectorAll(".tt")
        if ((modTTips) && (modTTips.length > 0 || modTTips != null)) {
          modTTips.forEach(t => {
            let dispTT = Tooltip.getInstance(t)
            dispTT.hide()
          })    
        }   
        quickSignupFormMod.value.querySelector("#toggleQSignupPassword").removeEventListener("click", function () {})
        quickSignupFormMod.value.querySelector("#qSignupUsername").removeEventListener("blur", validateQkSignupUsername)
        quickSignupFormMod.value.querySelector("#qSignupEmail").removeEventListener("blur", validateQkSignupEmail)
        quickSignupFormMod.value.querySelector("#qSignupPassword").removeEventListener("blur", validateQkSignupPassword)
      }     
    })

    onUnmounted(() => {
      console.log("pModal>QkSignup - onUnmounted")
      clearVars()
      stopQkSignupModSubWatch()
      // unsub();
    })

    //SWITCH & CLOSE MODALS
    let signupSubLnk = () => {
      let switchTo = "Signup"
      context.emit("switchModalSub", switchTo )
    }

    const closeSubMod = () => {
      context.emit("closeModalSub")
    }

    let subtitle = computed(() => {
      if(props.modData.area === "Google"){
        return "Signup with Google"
      }
      else{
        return "Signup with Facebook"
      }      
    })

    //SOCIAL MEDIA AUTHS
    let qGoogleAuth = computed(() => {
      if(props.modData.area === "Google"){
        return true
      }
      else{
        return false
      }      
    })
    
    let qFacebookAuth = computed(() => {
     if(props.modData.area === "Facebook"){
        return true
      }
      else{
        return false
      }    
    })


    //DATES - COMPUTED
    let qSignupDate = computed(() => {
      let sUpDate = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
      return sUpDate
    })
    let qTrialEndDate = computed(() => {
      let triEndDate1 = new Date()
      let triEndDate2 = triEndDate1.setDate(triEndDate1.getDate()+28)
      let triEndDate3 = ((triEndDate2.valueOf()) / 1000)
      let triEndDateTS = new Timestamp(triEndDate3, 0)
      return triEndDateTS          
    })
    let reminder1Date = computed(() => {
      let rem1Date1 = new Date()
      let rem1Date2 = rem1Date1.setDate(rem1Date1.getDate()+21)
      let rem1Date3 = ((rem1Date2.valueOf()) / 1000)
      let rem1DateTS = new Timestamp(rem1Date3, 0)
      return rem1DateTS  
    })
    let reminder2Date = computed(() => {
     let rem2Date1 = new Date()
      let rem2Date2 = rem2Date1.setDate(rem2Date1.getDate()+24)
      let rem2Date3 = ((rem2Date2.valueOf()) / 1000)
      let rem2DateTS = new Timestamp(rem2Date3, 0)
      return rem2DateTS 
    })
    let reminder3Date = computed(() => {
      let rem3Date1 = new Date()
      let rem3Date2 = rem3Date1.setDate(rem3Date1.getDate()+27)
      let rem3Date3 = ((rem3Date2.valueOf()) / 1000)
      let rem3DateTS = new Timestamp(rem3Date3, 0)
      return rem3DateTS   
    })
      
    const assignFocus = (focusArea) => {
      console.log("Running assignFocus", focusArea)
      let focusId = "#" + focusArea
      let clickFocus = quickSignupFormMod.value.querySelector(`${focusId}`)
      clickFocus.focus()
    }

    function showError() {
      let alertLvl = 1
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value) 
      context.emit("showAlertSub", errorArray)             
      inProgress.value = false 
      cleanUp()      
    }

    const cleanUp = async () => {
      console.log("QuickSignup>CleanUp")
      errorMessage.value = ""
      errorArray.value = []
      if (quickSignupProgress.allUsersUpdated == true ){
        await deleteDoc(doc(db, "allusers", adminEmail.value));
        console.log("all users Doc Deleted - ", adminEmail.value)
      }
      if (quickSignupProgress.userDocCreated == true){
        await deleteDoc(doc(db, "users", newUserUid.value));
        console.log("user Doc Deleted - ", newUserUid.value)
      }
      if(quickSignupProgress.userAuthCreated == true){
        const auth = getAuth();
        const user = auth.currentUser;
        await deleteUser(user).then(() => {
          console.log("user account deleted")
          signOut(auth)
        }).catch((error) => {
         console.log("error while deleting user - ", error)
        });
      }
      context.emit("closeModalSub")
      
      
    }

    const quickSignupSuccess = () => {
      console.log("pModal>QuickSignup>Success")
      errorMessage.value = "Please verify your email to proceed"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value) 
      context.emit("showAlertSub", errorArray)  
      inProgress.value = false 
      closeSubMod()
      router.push({name: "VerifyAccount"})
    }

    const handleQuickSignup = async () => {
      console.log("handleQuickSignup")
      let form = quickSignupFormMod.value.querySelector(".needs-validation")
      if (!form.checkValidity() || !validateQkSignupEmail() || !validateQkSignupPassword() || !validateQkSignupUsername()) {
        errorMessage.value = "Signup Error - Please recheck signup form"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)            
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)           
      }
      else {
        let functions = getFunctions()
        
        form.classList.add("was-validated")
        inProgress.value = true
        try {          
          // check email not in all users doc
          const allUsersRef = doc(db, "allusers", qSignupEmail.value)
          const allUsersSnap = await getDoc(allUsersRef)
          if (allUsersSnap.exists()) {
            if(allUsersSnap.data().subscription === "Trial"){
              quickSignupProgress.allUsersChecked = false
              errorMessage.value = "Only one trial account allowed per email"
              showError()
              return
            } else{
              quickSignupProgress.allUsersChecked = true
            }
          } else {
            quickSignupProgress.allUsersChecked = true
          }
          // create user auth account
          let authResult = await signup(qSignupEmail.value, qSignupPassword.value)
          if (signupError.value != null){
            quickSignupProgress.userAuthCreated = false
            errorMessage.value = signupError.value
            showError()
            return            
          }
          else{
            quickSignupProgress.userAuthCreated = true
          }         
          // create user info object
          adminEmail.value = authResult.user.email
          newUserUid.value = authResult.user.uid
          const userInfo = {
            userId: authResult.user.uid,
            email: authResult.user.email,
            displayName: qSignupUsername.value,
            password: qSignupPassword.value,
            createdAt: null,
            userDetails: {
              firstName: qSignupFirstname.value,
              lastName: qSignupLastname.value,
              birthDate: qSignupDateBirth.value,
              phoneNum: qSignupPhone.value,
            }, 
            userAddress: {
              address1: qSignupAddress1.value,
              address2: qSignupAddress2.value,
              postcode: qSignupPostcode.value,
              country: qSignupCountry.value,
            },
            clients: {
            numberClients: 0,
            clientIDs:[],           
            },
            shoots: {
            numberShoots: 0,
            shootIDs:[],           
            },
            images: {
              numberImages: 0,
              imageIDs:[],            
            },
            orders: {
              numberOrders: 0,
              orderIDs:[],            
            },
            subscription: qSignupPackage.value,
            duration: qSignupDuration.value,
            newsLetter: qSignupNewsletter.value,
            signupDate: qSignupDate.value,
            trialEndDate: qTrialEndDate.value, 
            renewalDate: "n/a",
            reminders: {
              reminder1Date: reminder1Date.value,
              reminder1Sent: false,
              reminder2Date: reminder2Date.value,
              reminder2Sent: false,
              reminder3Date: reminder3Date.value,
              reminder3Sent: false,
            },
                       
            showTutorials: true,
            showUpgrade: true,
            googleAuth: qGoogleAuth.value,
            facebookAuth: qFacebookAuth.value
          }
          console.log("userInfo - ", userInfo)
          // create user doc in firestore
          await useSetDocument("users", newUserUid.value, userInfo)
          if (createUserDocError.value != null){
            quickSignupProgress.userDocCreated = false
            errorMessage.value = "Error while creating user document"
            showError()
            return 
          }
          else{
            quickSignupProgress.userDocCreated = true
          }   
          // check user doc in firestore
          await useCheckDocument("users", newUserUid.value)
          if (checkDocError.value != null){
            quickSignupProgress.userDocChecked = false
            errorMessage.value = "Error while checking user document"
            showError()
            return 
          }
          else{
            quickSignupProgress.userDocChecked = true
          }
          // set created at timestamp 
          await useSetTimestamp("users", newUserUid.value)
          if (timestampError.value != null){
            quickSignupProgress.timeStampAdded = false
            errorMessage.value = "Error while adding timestamp"
            showError()
            return 
          }
          else{
            quickSignupProgress.timeStampAdded = true
          }
          // update user profile with display name
          await updateUserProfile(qSignupUsername.value)
          if (updateProfileError.value != null){
            quickSignupProgress.displayNameAdded = false
            errorMessage.value = "Error while updating username"
            showError()
            return 
          }
          else{
            quickSignupProgress.displayNameAdded = true
          }
          // link google / fb auth
          if(props.modData.area === "Google") {
            let {error:googleLinkError, googleSignin} = linkLogin()
            await googleSignin()
            if (googleLinkError.value != null){
              quickSignupProgress.socialMediaLinked = false
              errorMessage.value = "Error while linking to Google Signin"
              showError()
              return           
            } else {
              quickSignupProgress.socialMediaLinked = true
            }
          }
          if(props.modData.area === "Facebook") {
            let {error:facebookLinkError, facebookSignin} = linkLogin()        
            await facebookSignin()
            if (facebookLinkError.value != null){
              quickSignupProgress.socialMediaLinked = false
              errorMessage.value = "Error while linking to Facebook Signin"
              showError()
              return           
            } else {
              quickSignupProgress.socialMediaLinked = true
            }
          }
          // add user roles   
          try{
            const setUserRole = httpsCallable(functions, "addAdminRole")
            const res = await setUserRole({ email:adminEmail.value, accessLevel:qAccessLevel })
            console.log("res - ", res)
            quickSignupProgress.rolesAdded = true
          } catch (error) {
            quickSignupProgress.rolesAdded = true
            errorMessage.value = "Error while adding User Roles"
            showError()
            return     
          }
          // update allusers doc with access level
          const userLog = {            
            userId: newUserUid.value,
            email: adminEmail.value,
            displayName: qSignupUsername.value,            
            subscription: qSignupPackage.value,            
            signupDate: qSignupDate.value,
            trialEndDate: qTrialEndDate.value, 
            duration: qSignupDuration.value,
            status: "Active"            
          }          
          let addToAllUsersSnap = await setDoc(doc(db, "allusers", adminEmail.value), userLog)
          console.log("addToAllUsersSnap - ", addToAllUsersSnap)
          quickSignupProgress.allUsersUpdated = true
          // send verify email
          if(quickSignupComplete.value == true){
            await emailVerify(adminEmail.value)
            if (emailVerError.value != null){
              quickSignupProgress.sentVerifyEmail = false
              errorMessage.value = "Error while sending verification email"
              console.log("quickSignupProgress - ", quickSignupProgress)
              showError()
              return 
            }
            else{
              quickSignupProgress.sentVerifyEmail = true
              quickSignupSuccess()
            }
          }
          else{
            errorMessage.value = "Unknown error occurred during signup"           
            console.log("quickSignupProgress - ", quickSignupProgress)
            showError()            
            return 
          }
        }
        catch(error){
          const { code, details } = JSON.parse(JSON.stringify(error))
          errorMessage.value = code + details
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUp()
          return 
        } 
      }
    } 

    return{ quickSignupFormMod, signupPending, createUserDocPending, inProgress, qSignupUsername, qSignupEmail, qSignupPassword, qSignupNewsletter, updateProfilePending, handleQuickSignup, assignFocus, signupSubLnk, subtitle}

  }
 
}
</script>

<style>

</style>