<template>
  <div id="navigation-desktop">
    <div class="container-xl">
      <div class="d-flex align-items-center py-1">
        <div class="p-0 m-0 px-2 py-2">
          <a href="#">
            <img :src="require('../assets/icons/Photar-Logo-Txt-Light.svg')" alt="Photar logo" style="width: 170px;">
          </a>         
        </div>
        <!-- <button @click="setBasicUser">Set Role</button> -->
        <div v-if="user" class="ms-auto">
          <!-- <p class="text-white">valid - {{valid}}</p>
          <p class="text-white">access - {{access}}</p> -->
          <ul >
            <li class="lh-lg">
              <router-link :to='{ name: "Home" }' :class='{active: $route.name==="Home" && $route.hash === ""}' >Home</router-link>
            </li>
            <li v-if="valid == true && access > 1">
              <button class="btn btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="gotoDash()" :class='{hide: $route.name === "Dashboard"}'>Dashboard</button>
              <!-- <router-link  class="btn btn-highlight text-white text-uppercase shadow-sm" :to='{ name: "Dashboard", params: {id: user.uid} }'>Dashboard</router-link>  -->
            </li>
            <li v-if="valid == true && access == 10">
              <button class="btn btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="gotoAdminDash()">Admin</button>
              <!-- <router-link  class="btn btn-highlight text-white text-uppercase shadow-sm" :to='{ name: "AdminDash", params: {id: user.uid} }'>Admin Dashboard</router-link> -->
            </li>
            <li v-if="valid == true && access == 0">
              <button class="btn btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="showModClientLink">App Link</button>
            </li>       
            <li>
              <button class="btn btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="showModDskNav('Logout')">Logout</button>
            </li>       
          </ul>
        </div>
        <div v-else class="ms-auto">
          <ul>    
            <li class="pt-2">
              <router-link :to='{ name: "Home" }' :class='{active: $route.name==="Home" && $route.hash === ""}' >Home</router-link>
            </li>       
            <!-- <li class="pt-2">
              <router-link :to='{ name: "Home", hash:"#learn-more" }' :class='{active: $route.name==="Home" && $route.hash === "#learn-more"}' >More</router-link>
            </li>
            <li class="pt-2">
              <router-link :to='{ name: "Home", hash:"#benefits" }' :class='{active: $route.name==="Home" && $route.hash==="#benefits"}'>Benefits</router-link>
            </li>
            <li class="pt-2">
              <router-link :to='{ name: "Home", hash:"#pricing" }' :class='{active: $route.name==="Home" && $route.hash==="#pricing"}'>Pricing</router-link>
            </li>
            <li class="pt-2">
              <router-link  :to='{ name: "Home", hash:"#contact" }' :class='{active: $route.name==="Home" && $route.hash==="#contact"}'>Contact</router-link>
            </li> -->
            <li>
              <button class="btn btn-lg btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="showModDskNav('Signup')">Signup</button>
            </li>
            <li>
              <button class="btn btn-lg btn-highlight text-white text-uppercase shadow-sm fs-6" type="button" @click="showModDskNav('Login')">Login</button> 
            </li>
          </ul>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted, watchEffect } from "vue"
// import { httpsCallable, getFunctions } from "firebase/functions"
import getUser from "../composables/getUser"
// import { auth } from "../firebase/config"
import { useRouter } from "vue-router"
export default {
name: "NavigationDsk",
emits: ["showModal", "switchModalDashData"],
setup(props, context) {
  const { user, access, valid } = getUser() 
  let router = useRouter()
  // console.log("getUser - user ", user)
  const stopNavUserWatch = watchEffect(() => {
    // console.log("NAVIGATION.VUE - stopNavUserWatch") 
    // console.log("stopNavUserWatch>User - ", user.value) 
    // console.log("stopNavUserWatch>access - ", access.value) 
    // console.log("stopNavUserWatch>valid - ", valid.value) 
    if(valid.value != null){
      if(valid.value == true){
        user.value.getIdTokenResult().then((res) => {
          // console.log("res.claims.accessLevel - ", res.claims.accessLevel)
          if(res.claims.accessLevel == 0){                            
            let modData = {title: "Client Login", dName: user.value.displayName, photographer: res.claims.email }
            context.emit("switchModalDashData", modData )         
          }
        })
      }
    }
  })

  // function showModClientLink(){  
  //   if (auth.currentUser){
  //     // console.log("NAVIGATION.VUE - showModClientLink")             
  //     auth.currentUser.getIdTokenResult().then((res) => {
  //       // console.log("NAVIGATION.VUE - showModClientLink res - ", res)      
  //       if(res.claims.accessLevel == 0){                            
  //         let modData = {title: "Client Login", dName: auth.currentUser.displayName, photographer: res.claims.email }
  //         context.emit("switchModalDashData", modData )         
  //       }
  //     })
  //   }
  // }

  // function setBasicUser(){
    //   console.log("setBasicUser", user.value.email)
    //   const functions = getFunctions()
    //   const setSU = httpsCallable(functions, "setSuperUser")
    //   setSU({ email:user.value.email, accessLevel:4 })
    //   .then((result) => {
    //     console.log("callable function run - ", result)
    //   })
    // }

  onUnmounted(() => {
    stopNavUserWatch()
  })
  const showModDskNav = (modalName) => {  
    console.log("showModal - Navigation ", modalName)    
    context.emit("showModal", modalName) 
  }

  const gotoDash = () => {
    console.log("Running gotoDash")
    router.push({ name: "Dashboard", params: {id: user.value.uid}})  
    
  }
  
  const gotoAdminDash = () => {
    console.log("Running gotoAdminDash")
    router.push({ name: "AdminDash", params: {id: user.value.uid}})  
  }

  return{user, valid, access, showModDskNav, gotoDash, gotoAdminDash}
  }
}
</script>

<style scoped>
#navigation-desktop {
  display: flex;
  width: 100%;
}

.router-link-active {
  color: #FFF !important;
  border: none;
  background-color: transparent;
}

.router-link-active.active{
  color: #00A4B0 !important;
  border-bottom: 2px solid #00A4B0;
}

ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 1.25rem 0 0;
}
li {
  /* font-size: 2rem; */
  padding: 0.125rem 0.6rem;
  cursor: pointer;
  vertical-align: middle;
}
li a {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Lato';
  font-size: 1.2rem;
  margin: 0 0.3rem;
  
}

li a.active {  
  color: #00A4B0 !important;
  border-bottom: 2px solid #00A4B0;

}
li a:hover {
  color: #00A4B0;
}

li button.hide{
  display: none;
}

</style>