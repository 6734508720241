import { ref } from "vue"

// firebase imports
import { db } from "../firebase/config"
import { setDoc, doc } from "firebase/firestore"
const error = ref(null)  
const isPending = ref(false) 

const useSetDocument = async (c, id, d) => {
  // console.log("running useSetDocument coll - ", c)
  // console.log("running useSetDocument id - ", id)
  // console.log("running useSetDocument data - ", d)
  isPending.value = true
  try {
    const res = await setDoc(doc(db, c, id), d)
    error.value = null
    isPending.value = false
    return res
  }
  catch(err) {
    error.value = err.message
    // console.log(err.message)
    error.value = "A problem has occurred while signing up"    
    isPending.value = false  
  }
}

const setDocument = () => {
  return { useSetDocument, error, isPending }
}

export default setDocument