<template>
  <div>
    <div v-if="modData.area === 'User'" ref="userDetailsMod" class="userDetailsModCont">
      <!-- USER DETAILS PROGRESS -->
      <div class="col-12 col-sm-11 mx-auto">
        <div class="form-steps row-cols-2 p-0 m-0">
          <div class="step text-center position-relative">
            <p class="fw-normal">Credentials</p>
            <div class="bullet">
                <span>1</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
          <div class="step text-center position-relative">
            <p class="fw-normal">Account</p>
            <div class="bullet">
                <span>2</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>          
        </div>
      </div>
      <!-- <p v-if="subscription">Subscription - {{subscription}}</p> -->
      <!-- USER DETAILS -->
      <div class="col-12 col-sm-11 p-0 m-0 mx-auto mod-wrapper">
        <div class="form-container">
          <!-- CREDENTIALS - PAGE 1 -->
          <div class="page slide-page">
            <div class="my-2 mx-auto ">
              <div class="row p-0 m-0">
                <div class="col-12 p-0 m-0 mx-auto text-start mb-1 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Username:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Username:</p>
                    <p v-if="detailsObject.displayName" class="m-0 p-0 ps-3 ps-sm-2 text-charcoal text-end text-sm-start text-truncate flex-fill">{{detailsObject.displayName}}</p>
                    <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">An error has occurred</p>
                  </div>
                </div>
                <div  class="col-12 p-0 m-0 mx-auto text-start mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center ">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Name:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Name:</p>
                    <p v-if="detailsObject.userDetails.firstName || detailsObject.userDetails.lastName" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-truncate text-end text-sm-start flex-fill">{{detailsObject.userDetails.firstName}} {{detailsObject.userDetails.lastName}}</p>
                    <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">An error has occurred</p>
                  </div>        
                </div>
              </div>
              <div class="row p-0 m-0 pt-sm-2 pb-sm-2">
                <div class="col-12 col-sm-6 mx-auto text-center m-0 p-0 mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <div class="p-0 m-0">
                      <i class="" style="background-color: rgba(0,0,0,0); border: none;">
                        <icon-base
                          class="iconMail d-block d-sm-none"
                          width="24"
                          height="24"
                          icon-color="#6C757D"
                          icon-hover-color="#6C757D"
                          >
                          <icon-mail />
                        </icon-base>
                        <icon-base
                          class="iconMail d-none d-sm-block"
                          width="28"
                          height="28"
                          icon-color="#6C757D"
                          icon-hover-color="#6C757D"
                          >
                          <icon-mail />
                        </icon-base>
                      </i>
                    </div>
                    <div class="text-truncate flex-fill">
                      <p v-if="detailsObject.email" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate">{{detailsObject.email}}</p>
                      <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate ">An error has occurred</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mx-auto text-center m-0 p-0 mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center ">
                    <div class="p-0 m-0">
                      <i class="" style="background-color: rgba(0,0,0,0); border: none;">
                        <icon-base
                          class="iconPhone d-block d-sm-none"
                          width="24"
                          height="24"
                          icon-color="#6C757D"
                          icon-hover-color="#6C757D"
                          >
                          <icon-phone />
                        </icon-base>
                        <icon-base
                          class="iconPhone d-none d-sm-block"
                          width="28"
                          height="28"
                          icon-color="#6C757D"
                          icon-hover-color="#6C757D"
                          >
                          <icon-phone />
                        </icon-base>
                      </i>
                    </div>
                    <div class="text-truncate flex-fill">
                      <p v-if="detailsObject.userDetails.phoneNum" class="m-0 p-0 ps-3 ps-sm-1 fs-6 text-charcoal text-end text-sm-start text-truncate">{{detailsObject.userDetails.phoneNum}}</p>
                      <p v-else class="m-0 p-0 ps-3 ps-sm-1 fs-6 text-charcoal text-end text-sm-start text-truncate ">An error has occurred</p>
                    </div>
                  </div>
                </div>
              </div>
              
             
              <div class="py-2 mb-2 col-12 mx-auto">
                <button type="button" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="editUserDetails">Edit Credentials</button>                
              </div>              
              <div class="mx-auto text-start mb-0 text-truncate">
                <div class="d-flex flex-row align-items-center">
                  <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Address 1:</p>
                  <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Address 1:</p>
                  <p v-if="detailsObject.userAddress.address1" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start  text-truncate flex-fill">{{detailsObject.userAddress.address1}}</p>
                  <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">An error has occurred</p>
                </div>
              </div>
              <div class="mx-auto text-start mb-0 text-truncate">
                <div class="d-flex flex-row align-items-center">
                  <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Address 2:</p>
                  <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Address 2:</p>
                  <p v-if="detailsObject.userAddress.address2" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">{{detailsObject.userAddress.address2}}</p>
                  <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">n/a</p>
                </div>
              </div>
              <div class="row p-0 m-0 gx-2">
                <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-start mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">City:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">City:</p>
                    <p v-if="detailsObject.userAddress.city" class="m-0 p-0 ps-3 ps-sm-2 text-sm-start fs-6 text-charcoal text-end text-truncate flex-fill">{{detailsObject.userAddress.city}}</p>
                    <p v-else class="m-0 p-0 ps-3 ps-sm-2 text-sm-start fs-6 text-charcoal text-end text-truncate flex-fill">n/a</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-end mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Postcode:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Postcode:</p>
                    <p v-if="detailsObject.userAddress.postcode" class="m-0 p-0 ps-3 ps-sm-2 text-sm-start fs-6 text-charcoal text-end text-truncate flex-fill">{{detailsObject.userAddress.postcode}}</p>
                    <p v-else class="m-0 p-0 ps-3 ps-sm-2 text-sm-start fs-6 text-charcoal text-end text-truncate flex-fill">An error has occurred</p>
                  </div>
                </div>                
              </div>
              <div class="row p-0 m-0 gx-2">
                <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-start mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Region:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Region:</p>
                    <p v-if="detailsObject.userAddress.region" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.userAddress.region}}</p>
                    <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
                  </div>
                </div>
                <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-end mb-0 text-truncate">
                  <div class="d-flex flex-row align-items-center">
                    <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Country:</p>
                    <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Country:</p>
                    <p v-if="detailsObject.userAddress.country" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.userAddress.country}}</p>
                    <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
                  </div>
                </div>
              </div>
              <div class="py-2 mb-4 mb-sm-3 col-12 col-12 mx-auto">
                <button type="button" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="editUserAddress">Edit Address</button>                
              </div>   
              <div class="mb-2 col-12 mx-auto">
                <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="resetUserPassword">Reset Password</button>                
              </div>   
              <div class="mb-3 col-12  mx-auto">
                <button type="button" class="btn w-100 btn-charcoal text-white text-uppercase shadow-sm Next" :class='{"btn-sm": mobileView}' @click="next">Account  <span class="fs-4 ps-1"> &#8594; </span> </button>                
              </div> 
            </div>
          </div>
          <!-- BILLING - PAGE 2 -->
          <div class="page">
            <div class="mt-2 mx-auto p-0 m-0">
              <form id="editUserOptForm" @submit.prevent="handleUpdateUserOpt">
                <div class="row p-0 m-0 ms-5 pt-sm-2 pb-sm-2">
                  <div class="col-12 col-sm-6 p-0 m-0 mb-1 form-check" :class='{"check-form-sm": mobileView}'>
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input " name="showTutorials" id="uTutorialsUp"  v-model="uTutorialsUp" :class='{"check-box-sm": mobileView}'>
                      <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Show Tutorials</span>
                    </label>
                  </div>
                  <div class="col-12 col-sm-6 mb-1 p-0 m-0 form-check" :class='{"check-form-sm": mobileView}'>
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input " name="showUpgrades" id="uUpgradesUp"  v-model="uUpgradesUp" :class='{"check-box-sm": mobileView}'>
                      <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Show Upgrades</span>
                    </label>
                  </div>
                </div>
                
                <div class="mb-1 ms-3 form-check" :class='{"check-form-sm": mobileView}'>
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input " name="receiveNewsletter" id="uNewsletterUp"  v-model="uNewsletterUp" :class='{"check-box-sm": mobileView}'>
                    <span class="small text-charcoal text-truncate" :class='{"check-label-sm": mobileView}'>Receive Newsletter</span>
                  </label>
                </div>
                <div class="pt-4 mb-1 col-12 mx-auto">
                  <button v-if="!updateUserOptionsPending" type="submit" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm " :class='{"btn-sm": mobileView}'>Update Preferences</button> 
                  <button v-else class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' disabled>Updating</button> 
                </div>
              </form>
            </div>
            <div class="mt-2 mx-auto">
              <div class="col-12 col-lg-11 mx-auto mt-2 pb-0">
                <div class="mb-0 text-center">
                  <p class="p-0 m-0 small text-charcoal text-capitalize">Connect with social media <br>faster login &amp; shareable functions</p>                  
                </div>
              </div>           
              <!-- <div class="mx-auto text-center mb-2 text-truncate">
                <p class="small fw-normal m-0 p-0 p-link text-decoration-underline" @click="morePrivacyLink" style="cursor: pointer">More About Privacy</p>
              </div> -->
              <div class="mb-3 mt-2 col-12 mx-auto">
                <div class="row">
                  <div class="col-12 col-md-6 mb-2 mb-md-0">
                    <button type="button" 
                      class="btn w-100 btn-outline-lowlight text-lowlight text-uppercase shadow-sm" 
                      :class='{"btn-sm": mobileView, "googleAuthLink": googleAuthLink}'
                      @click="toggleUserGoogleLink" >
                      <i class="bi bi-google pe-2"></i>
                      {{ googleAuthLink ? "Unlink" : "Sign In"}}
                    </button>                
                  </div>
                  <div class="col-12 col-md-6">
                    <button type="button" 
                    class="btn w-100 btn-outline-lowlight text-lowlight text-uppercase shadow-sm" 
                    :class='{"btn-sm": mobileView, "facebookAuthLink": facebookAuthLink}'
                    @click="toggleUserFacebookLink" >
                    <i class="bi bi-facebook pe-2"></i>
                    {{ facebookAuthLink ? "Unlink" : "Sign In"}}
                  </button>          
                  </div>
              </div> 
            </div>   
           
              <div class="mx-auto text-start mb-0 text-truncate">
                <div class="d-flex flex-row align-items-center">
                  <p class="small fw-normal text-muted m-0 p-0">Current Plan:</p>
                  <p v-if="detailsObject.subscription" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill">{{detailsObject.subscription}}</p>
                  <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill">An error has occurred</p>
                </div>
                <div class="d-flex flex-row align-items-center">
                  <p class="small fw-normal text-muted m-0 p-0">Plan Duration:</p>
                  <p v-if="detailsObject.duration" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill text-capitalize">{{detailsObject.duration}}</p>
                  <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end text-truncate flex-fill">An error has occurred</p>
                </div>
              </div>
              <div v-if="currSubscription" class="col-12 mx-auto text-start pb-2 text-truncate">
                <div v-if="isLoading">Loading account information...</div>
                <PriceSelection v-else-if="!currSubscription" />
                <SubscribedAccount v-else :currSubscription="currSubscription" :modData="modData" :mobileView="mobileView" @showAlertSub="showAlertSubPass"/>
              </div>
            </div>
            <div class="mb-1 mt-2 col-12 mx-auto">
              <button type="button" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' @click="upgradeSubscription" >Upgrade</button> 
            </div>     
                      
            <div class="mb-3 mt-2 col-12 mx-auto">
              <button type="button" class="btn w-100 btn-charcoal text-white text-uppercase shadow-sm Prev" :class='{"btn-sm": mobileView}' @click="prev"><span class="fs-4 pe-2"> &#8592; </span>Credentials</button>                
            </div> 
          </div>
        </div>
      </div>   
    </div>
    <div v-if="modData.area === 'Client'" ref="clientDetailsMod" class="col-12 col-sm-11 p-0 m-0 mx-auto mod-wrapper userDetailsModCont">
      <!-- CREDENTIALS -->
      <div class="my-2 mx-auto px-3">
        <div class="row p-0 m-0">
          <div class="col-12 p-0 m-0 mx-auto text-start pt-1 mb-1 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Username:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Username:</p>
              <p v-if="detailsObject.displayName" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">{{detailsObject.displayName}}</p>
              <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">n/a</p>
            </div>
          </div>
          <div class="col-12 p-0 m-0 mx-auto text-start mb-1 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Name:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Name:</p>
              <p v-if="detailsObject.clientDetails.firstName || detailsObject.clientDetails.lastName" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-truncate text-end text-sm-start flex-fill">{{detailsObject.clientDetails.firstName}} {{detailsObject.clientDetails.lastName}}</p>
              <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">n/a</p>
            </div>        
          </div>
        </div>
        <div class="row p-0 m-0 pb-2">
          <div class="col-12 col-sm-6 mx-auto text-center m-0 p-0 mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <div class="p-0 m-0">
                <i class="" style="background-color: rgba(0,0,0,0); border: none;">
                  <icon-base
                    class="iconMail d-block d-sm-none"
                    width="24"
                    height="24"
                    icon-color="#6C757D"
                    icon-hover-color="#6C757D"
                    >
                    <icon-mail />
                  </icon-base>
                  <icon-base
                    class="iconMail d-none d-sm-block"
                    width="28"
                    height="28"
                    icon-color="#6C757D"
                    icon-hover-color="#6C757D"
                    >
                    <icon-mail />
                  </icon-base>
                </i>
              </div>
              <div class="text-truncate flex-fill">
                <p v-if="detailsObject.email" class="m-0 p-0 ps-3 fs-6 ps-sm-2 text-charcoal text-end text-sm-start text-truncate">{{detailsObject.email}}</p>
                <p v-else class="m-0 p-0 ps-3 fs-6 ps-sm-2 text-charcoal text-end text-sm-start text-truncate ">n/a</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 mx-auto text-center m-0 p-0 mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center ">
              <div class="p-0 m-0">
                <i class="" style="background-color: rgba(0,0,0,0); border: none;">
                  <icon-base
                    class="iconPhone d-block d-sm-none"
                    width="24"
                    height="24"
                    icon-color="#6C757D"
                    icon-hover-color="#6C757D"
                    >
                    <icon-phone />
                  </icon-base>
                  <icon-base
                    class="iconPhone d-none d-sm-block"
                    width="28"
                    height="28"
                    icon-color="#6C757D"
                    icon-hover-color="#6C757D"
                    >
                    <icon-phone />
                  </icon-base>
                </i>
              </div>
              <div class="text-truncate flex-fill">
                <p v-if="detailsObject.clientDetails.phoneNum" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate">{{detailsObject.clientDetails.phoneNum}}</p>
                <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate ">n/a</p>
              </div>
            </div>
          </div>
        </div>
        <div class="py-2 mb-2 col-12 mx-auto">
          <button type="button" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="editClientDetails">Edit Credentials</button>                
        </div>
        <div class="mx-auto text-start mb-0 text-truncate">
          <div class="d-flex flex-row align-items-center">
            <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Address 1:</p>
            <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Address 1:</p>
            <p v-if="detailsObject.clientAddress.address1" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.address1}}</p>
            <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">n/a</p>
          </div>
        </div>
        <div class="mx-auto text-start mb-0 text-truncate">
          <div class="d-flex flex-row align-items-center">
            <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Address 2:</p>
            <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Address 2:</p>
            <p v-if="detailsObject.clientAddress.address2" class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.address2}}</p>
            <p v-else class="m-0 p-0 ps-3 ps-sm-2 fs-6 text-charcoal text-end text-sm-start text-truncate flex-fill">n/a</p>
          </div>
        </div>
        <div class="row p-0 m-0 gx-2">
          <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-start mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">City:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">City:</p>
              <p v-if="detailsObject.clientAddress.city" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.city}}</p>
              <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
            </div>
          </div>
          <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-end mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Postcode:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Postcode:</p>
              <p v-if="detailsObject.clientAddress.postcode" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.postcode}}</p>
              <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
            </div>
          </div>
        </div>
        <div class="row p-0 m-0 gx-2">
          <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-start mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Region:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Region:</p>
              <p v-if="detailsObject.clientAddress.region" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.region}}</p>
              <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
            </div>
          </div>
          <div class="col-12 col-sm-6 p-0 m-0 mx-auto text-end mb-0 text-truncate">
            <div class="d-flex flex-row align-items-center">
              <p class="d-block d-sm-none small fw-normal text-muted m-0 p-0">Country:</p>
              <p class="d-none d-sm-block fw-normal text-muted m-0 p-0">Country:</p>
              <p v-if="detailsObject.clientAddress.country" class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">{{detailsObject.clientAddress.country}}</p>
              <p v-else class="m-0 p-0 ps-3 fs-6 text-charcoal text-end ps-sm-2 text-sm-start text-truncate flex-fill">n/a</p>
            </div>
          </div>
        </div>
        <div class="py-2 mb-1 col-12 mx-auto">
          <button type="button" class="btn w-100 btn-highlight text-white text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="editClientAddress">Edit Address</button>                
        </div> 
        <div class="mx-auto text-center mt-0 text-truncate">
          <div class="d-flex flex-row align-items-center justify-content-evenly">
            <div v-show="detailsObject.shoots.numberShoots > 0" class="d-flex flex-row align-items-center">
              <p class="text-uppercase fw-normal fs-5 text-muted m-0 p-0">Shoots</p>
              <p class="m-0 p-0 ps-1 fs-5 fw-bold text-charcoal text-truncate">{{detailsObject.shoots.numberShoots}}</p>
            </div>
            <div v-if="detailsObject.images.numberImages > 0" class="d-flex flex-row align-items-center">
              <p class="text-uppercase fw-normal fs-5 text-muted m-0 p-0">Images</p>
              <p class="m-0 p-0 ps-1 fs-5 fw-bold text-charcoal text-truncate">{{detailsObject.images.numberImages}}</p>
            </div>
            <div v-if="detailsObject.orders.numberOrders > 0" class="d-flex flex-row align-items-center">
              <p class="text-uppercase fw-normal fs-5 text-muted m-0 p-0">Orders</p>
              <p class="m-0 p-0 ps-1 fs-5 fw-bold text-charcoal text-truncate">{{detailsObject.orders.numberOrders}}</p>
            </div>
          </div>
        </div>
        <div class="mb-3 mt-2 col-12 mx-auto">
          <div class="row">
            <div class="col tt position-relative" data-bs-placement="bottom" title="Send a preview of client login email to yourself">
              <button type="button" class="btn w-100 btn-charcoal text-white text-uppercase shadow-sm"  :class='{"btn-sm": mobileView}' @click="sendPreviewEmail">Preview <i class="bi bi-envelope-fill ps-2"></i></button>   
            </div>
            <div class="col tt position-relative" data-bs-placement="bottom" title="Send this client login email and link">
               <button type="button" class=" btn w-100 btn-charcoal text-white text-uppercase shadow-sm" :class='{"btn-sm": mobileView}' @click="sendClientEmail">Send <i class="bi bi-envelope-fill ps-2"></i></button>
            </div>
          </div>
        </div>
        <!-- <div class="mb-3 mt-2 col-12  mx-auto">
          <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm " :class='{"btn-sm": mobileView}' @click="changeClientPassword">Reset Client Password</button>                
        </div>   -->
      </div>     
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue"
import { ref, watchEffect, onBeforeUnmount, onUnmounted, onMounted} from "vue"
import IconBase from "../Icons/IconBase.vue"
import IconPhone from "../Icons/IconPhone.vue"
import IconMail from "../Icons/IconMail.vue"
import { httpsCallable, getFunctions } from "firebase/functions"
import getUser from "@/composables/getUser"
import { useStore } from "@/composables/store/store"
import updateDocument from "@/composables/updateDocument"
import linkLogin from "@/composables/linkLogin"
import { getDocs, getFirestore, collection, query, where } from "firebase/firestore"
import { auth } from "../../firebase/config"
import SubscribedAccount from "@/components/SubscribedAccount";
import PriceSelection from "@/components/PriceSelection";
// import updateDocument from "@/composables/updateDocument"
import {Tooltip} from "bootstrap"
export default {
  name: "DetailsModal",
  props: ["modData", "mobileView"],
  emits: ["closeModalSub", "showAlertSub", "switchModalSub", "switchModalDashData", "showDataModal"],
  components: {Spinner, IconBase, IconPhone, IconMail, SubscribedAccount, PriceSelection},
  setup(props, context){
    console.log("Details - Obj", props.modData)
    let { useUpdateDocument:UpdateUserOptionsDoc, error:updateUserOptionsError, isPending:updateUserOptionsPending } = updateDocument()
    let store = useStore();
    let { user } = getUser() 
    let errorMessage = ref("")
    let errorArray = ref([])
    let inProgress = ref(false)
    let detailsObject = ref(props.modData.obj)
    let userDetailsMod = ref(null)
    let clientDetailsMod = ref(null)
    let updateUserOptFormMod = ref(null)
    let uTutorialsUp = ref(null)
    let uUpgradesUp = ref(null)
    let uNewsletterUp = ref(null)

    let slidePage = ref(null)
    let nextBtn = ref(null)
    let prevBtn = ref(null)
    let progressText = ref(null)
    let progressCheck = ref(null)
    let bullet = ref(null)
    let bulletSpan = ref(null)
    let current = ref(0)
    let initialSet = ref(false)
    // const function europe-west2";

    const tooltips = ref(null)

    const isLoading = ref(false)
    const currSubscription = ref(null)

    let googleAuthLink = ref(false)
    let facebookAuthLink = ref(false)

    const addCurrent = () => {
      console.log("RUNNING ADDCURRENT - ", current.value)
      bullet.value[current.value].classList.add("current");
      bulletSpan.value[current.value].classList.add("current");
      progressCheck.value[current.value].classList.add("current");
      progressText.value[current.value].classList.add("current");
    }
    const removeCurrent = () => {
      console.log("RUNNING REMOVECURRENT - ", current.value)
      bullet.value[current.value].classList.remove("current");
      bulletSpan.value[current.value].classList.remove("current");
      progressCheck.value[current.value].classList.remove("current");
      progressText.value[current.value].classList.remove("current");
    }
    const addActive = () => {
      console.log("RUNNING ADDACTIVE - ", current.value)
      bullet.value[current.value].classList.add("active");
      progressCheck.value[current.value].classList.add("active");
      progressText.value[current.value].classList.add("active");
    }
    const removeActive = () => {
      console.log("RUNNING REMOVEACTIVE - ", current.value)
      bullet.value[current.value].classList.remove("active");
      progressCheck.value[current.value].classList.remove("active");
      progressText.value[current.value].classList.remove("active");
    }

    let hideTooltips = () => {
      console.log("Running hideTooltips")
      tooltips.value.forEach(t => {
        let dispTT = Tooltip.getInstance(t)
        console.log("Running hideTooltips - ", dispTT)
        dispTT.hide()
      })
    }

    onMounted(()=> {
      if(props.modData.area === "User"){
        tooltips.value =  userDetailsMod.value.querySelectorAll(".tt")
        tooltips.value.forEach(t => {
          new Tooltip(t)
        })
      }
      else{
        tooltips.value =  clientDetailsMod.value.querySelectorAll(".tt")
        tooltips.value.forEach(t => {
          new Tooltip(t)
        })
      }
    })

    let stopDetailsSubWatch = watchEffect(() => {
      console.log("watchEffect - stopDetailsSubWatch")
      if(props.modData.area === "User"){
        if (userDetailsMod.value) {
          if(userDetailsMod.value.children.length > 0){
          //MULTI PAGE CONTROLS
          //SELECTORS
            if(initialSet.value != true) {
              console.log("Initial Set")
              slidePage.value = userDetailsMod.value.querySelector(".slide-page")
              nextBtn.value = userDetailsMod.value.querySelector(".Next")
              prevBtn.value = userDetailsMod.value.querySelector(".Prev")            
              progressText.value = userDetailsMod.value.querySelectorAll(".step p")
              progressCheck.value = userDetailsMod.value.querySelectorAll(".step .check")
              bullet.value = userDetailsMod.value.querySelectorAll(".step .bullet")
              bulletSpan.value = userDetailsMod.value.querySelectorAll(".step .bullet span")
              removeActive()
              addCurrent()
              //LISTENERS
              nextBtn.value.addEventListener("click", function(event){
                console.log("User Details Next Clicked ")
                event.preventDefault();
                removeCurrent()
                addActive()
                slidePage.value.style.marginLeft = "-50%";
                current.value += 1;
                addCurrent()             
              }); 
              prevBtn.value.addEventListener("click", function(event){
                console.log("User Details Prev Clicked")
                event.preventDefault();
                removeCurrent()
                slidePage.value.style.marginLeft = "0%";                
                current.value -= 1;
                removeActive()
                addCurrent()
              }); 
              fetchSubscription()   
              detailsObject.value.showTutorials
              if(detailsObject.value.showTutorials){
                uTutorialsUp.value = true
              }
              else{
                uTutorialsUp.value = false
              }
              if(detailsObject.value.showUpgrade){
                uUpgradesUp.value = true
              }
              else{
                uUpgradesUp.value = false
              }
              if(detailsObject.value.newsLetter){
                uNewsletterUp.value = true
              }   
              else{
                uNewsletterUp.value = false
              }     
              initialSet.value = true
            }    
          }
        } 
        // console.log("detailsObject.value.googleAuth - ", detailsObject.value.googleAuth)
        googleAuthLink.value = detailsObject.value.googleAuth
        facebookAuthLink.value = detailsObject.value.facebookAuth 
        
      }
    })

    onBeforeUnmount(() => {  
      if(props.modData.area === "User"){
      removeActive()
      removeCurrent()
      nextBtn.value.removeEventListener("click", function () {})
      prevBtn.value.removeEventListener("click", function () {})
      }
    })

    onUnmounted(() => {
      console.log("pModal>Details - onUnmounted")
      // removeCurrent()
      // removeActive()
      current.value = 0
      initialSet.value = false 
      stopDetailsSubWatch()
    })    
     
    const fetchSubscription = async () => {
      console.log("Running fetchSubscription")
      isLoading.value = true;
      const db = getFirestore();
      const subsRef = collection(db,"users", auth.currentUser.uid, "subscriptions");
      const subsQuery = query(subsRef, where("status", "in", ["trialing", "active", "past_due", "unpaid"]));
      currSubscription.value = await getDocs(subsQuery)
        .then((sub) => 
        sub.docs.length > 0 ? sub.docs[0].data() : null);    

        isLoading.value = false;
      console.log("currSubscription - ", currSubscription.value)
    }


  
  //USER
  let editUserDetails = () => {
    console.log("Running editUserDetails - ", detailsObject.value)
    let subtitle = "User Details"
    let uDetFields = {
        uUsername:detailsObject.value.displayName,
        uPhone:detailsObject.value.userDetails.phoneNum,
        uFirstname:detailsObject.value.userDetails.firstName,
        uLastname:detailsObject.value.userDetails.lastName}
      let modData = {title: "Edit", area:"User-Details", message:subtitle, obj:uDetFields}
       console.log("DETAILS - modData - ", modData)
      context.emit("switchModalDashData", modData)  
  }
  let editUserAddress = () => {
    console.log("Running editUserAddress")
    let subtitle = "User Address"
    let uAddFields = {
        uAddress1:detailsObject.value.userAddress.address1,
        uAddress2:detailsObject.value.userAddress.address2,
        uCity:detailsObject.value.userAddress.city,
        uPostcode:detailsObject.value.userAddress.postcode,
        uRegion:detailsObject.value.userAddress.region,
        uCountry:detailsObject.value.userAddress.country}
        
      let modData = {title: "Edit", area:"User-Address", message:subtitle, obj:uAddFields}
      // console.log("DETAILS - modData - ", modData)
      context.emit("switchModalDashData", modData)  
  }  
  let resetUserPassword = () => {
    console.log("Running resetUserPassword")
    let switchTo = "Reset Password"
    context.emit("switchModalSub", switchTo)
    hideTooltips() 
  }

  let handleUpdateUserOpt = async () => {
      console.log("handleUpdateUserOptions")
      inProgress.value = true       
      let userOptUpdateInfo = { 
        newsLetter: uNewsletterUp.value,
        showTutorials: uTutorialsUp.value,
        showUpgrade: uUpgradesUp.value
      } 
      console.log("userOptUpdateInfo ", userOptUpdateInfo)
      await UpdateUserOptionsDoc("users", store.getUserId.value, userOptUpdateInfo)
      if (updateUserOptionsError.value != null){
        errorMessage.value = updateUserOptionsError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      inProgress.value = false
      errorMessage.value = "Options successfully updated"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()      
  }

  let morePrivacyLink = () => {
    console.log("Running morePrivacyLink")
  }
  let toggleUserGoogleLink = async () => {
    console.log("Running toggleUserGoogleLink - ", googleAuthLink.value)
    inProgress.value = true
    let {error:googleLinkError, googleSignin, googleSignOut} = linkLogin()
    if(googleAuthLink.value == false){
      await googleSignin()
      if (googleLinkError.value != null){
        errorMessage.value = googleLinkError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      let { useUpdateDocument:updateUserDoc, error:updateUserDocError} = updateDocument()
      let gSignUpd = {"googleAuth": true}
      await updateUserDoc("users", store.getUserId.value, gSignUpd)
      if (updateUserDocError.value != null){
        errorMessage.value = updateUserDocError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      errorMessage.value = "Google Signin Added to PHOTAR"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)
      googleAuthLink.value = !googleAuthLink.value  
      inProgress.value = false 
      closeSubMod()
    }
    else{
      await googleSignOut()
      if (googleLinkError.value != null){
        errorMessage.value = googleLinkError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      let { useUpdateDocument:updateUserDoc, error:updateUserDocError} = updateDocument()
      let gSignUpd = {"googleAuth": false}
      await updateUserDoc("users", store.getUserId.value, gSignUpd)
      if (updateUserDocError.value != null){
        errorMessage.value = updateUserDocError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      errorMessage.value = "Google Signin removed from PHOTAR"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)
      googleAuthLink.value = !googleAuthLink.value
      inProgress.value = false   
      closeSubMod()
    }   
  }
  let toggleUserFacebookLink = async () => {
    console.log("Running toggleUserFacebookLink - ", facebookAuthLink.value)
    inProgress.value = true
    let {error:facebookLinkError, facebookSignin, facebookSignOut} = linkLogin()
    if(facebookAuthLink.value == false){
      await facebookSignin()
      if (facebookLinkError.value != null){
        errorMessage.value = facebookLinkError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      let { useUpdateDocument:updateUserDoc, error:updateUserDocError} = updateDocument()
      let fSignUpd = {"facebookAuth": true}
      await updateUserDoc("users", store.getUserId.value, fSignUpd)
      if (updateUserDocError.value != null){
        errorMessage.value = updateUserDocError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      errorMessage.value = "Facebook Signin Added to PHOTAR"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)
      facebookAuthLink.value = !facebookAuthLink.value  
      inProgress.value = false 
      closeSubMod()
    }
    else {
      await facebookSignOut()
      if (facebookLinkError.value != null){
        errorMessage.value = facebookLinkError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      let { useUpdateDocument:updateUserDoc, error:updateUserDocError} = updateDocument()
      let fSignUpd = {"facebookAuth": false}
      await updateUserDoc("users", store.getUserId.value, fSignUpd)
      if (updateUserDocError.value != null){
        errorMessage.value = updateUserDocError.value
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        return           
      }
      errorMessage.value = "Facebook Signin removed from PHOTAR"
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)
      facebookAuthLink.value = !facebookAuthLink.value
      inProgress.value = false   
      closeSubMod()
    }
  }
  let editUserOptions = () => {
    console.log("Running editUserOptions")
    let subtitle = "User Options"
    let uOptFields = {
        uTutorials:detailsObject.value.showTutorials,
        uUpgrades:detailsObject.value.showUpgrade,
        uNewsletter:detailsObject.value.newsLetter
    }        
    let modData = {title: "Edit", area:"User-Options", message:subtitle, obj:uOptFields}
    // console.log("DETAILS - modData - ", modData)
    context.emit("switchModalDashData", modData)  
  }
  let viewUserBill = () => {
    console.log("Running viewUserBill")
    errorMessage.value = "Redirecting to billing area"
    let alertLvl = 2
    errorArray.value.push(alertLvl)  
    errorArray.value.push(errorMessage.value)
    context.emit("showAlertSub", errorArray)
    openPortal() 
  }
  let changeUserSubscription = () => {
    console.log("Running changeUserSubscription")
  }

  const upgradeSubscription = () => {
      console.log("Details>Upgrade Clicked", props.modData.obj.subscription)
      if(props.modData.obj.subscription != "Trial"){
        let subtitle = "Upgrade" 
        let sub = props.modData.obj.subscription     
        let modData = {title: "Upgrade", area:sub, message: subtitle, obj:props.modData.obj }
         context.emit("switchModalDashData", modData) 
      }
      else{
        let subtitle = "Get Full Access" 
        let sub = props.modData.obj.subscription     
        let modData = {title: "TrialSignup", area:sub, message: subtitle, obj:props.modData.obj }
         context.emit("switchModalDashData", modData) 
      }
      
      // context.emit("upgrade", userDoc.value.subscription)
    }

  let openPortal = async () => {      
      let functions = getFunctions()
      let functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink")
      // const functionRef = functions(functionLocation).httpsCallable("ext-firestore-stripe-payments-createPortalLink")
      // const functionRef = httpsCallable(functions,"ext-firestore-stripe-payments-createPortalLink")
      // console.log("functionRef", functionRef)
      const { data } = await functionRef({ returnUrl: window.location.origin});
      console.log("DATA - ", {data})
      window.location.assign(data.url);
      // console.log("running gotoPortal data", data)
      // if (data.url) {
      //   window.location.assign(data.url);
      // }      
    }

  //CLIENT
    let editClientAddress = () => {
      // console.log("Edit Client Address Clicked", detailsObject.value)
      // shtSelected.value = shoot.id
      store.selectClient(props.modData.obj.id, props.modData.obj.email)
      let subtitle = "Client Address"
      let cAddFields = {
          cAddress1:detailsObject.value.clientAddress.address1,
          cAddress2:detailsObject.value.clientAddress.address2,
          cCity:detailsObject.value.clientAddress.city,
          cPostcode:detailsObject.value.clientAddress.postcode,
          cRegion:detailsObject.value.clientAddress.region,
          cCountry:detailsObject.value.clientAddress.country}
        
      let modData = {title: "Edit", area:"Client-Address", message:subtitle, obj:cAddFields}
      // console.log("DETAILS - modData - ", modData)
      context.emit("switchModalDashData", modData)  
    }
    let editClientDetails = () => {
      // console.log("Edit Client Details Clicked")
      // shtSelected.value = shoot.id
      store.selectClient(props.modData.obj.id, props.modData.obj.email)
      let subtitle = "Client Details"
      let cDetFields = {
          cUsername:detailsObject.value.displayName,
          cEmail:detailsObject.value.email,
          cPhone:detailsObject.value.clientDetails.phoneNum,
          cFirstname:detailsObject.value.clientDetails.firstName,
          cLastname:detailsObject.value.clientDetails.lastName}
      let modData = {title: "Edit", area:"Client-Details", message:subtitle, obj:cDetFields}
      //  console.log("DETAILS - modData - ", modData)
      context.emit("switchModalDashData", modData)  
    }
    let changeClientPassword = async () => {
      // console.log("Change Client Password Clicked", detailsObject.value)
      store.selectClient(props.modData.obj.id, props.modData.obj.email)
      let subtitle = `Selected Client ${props.modData.obj.email}`
      let cFields = {
        cUsername:detailsObject.value.displayName,
        cEmail:detailsObject.value.email}
      let modData = {title: "Edit", area:"Client-Password", message:subtitle, obj:cFields}
      // console.log("DETAILS - modData - ", modData)
      context.emit("switchModalDashData", modData)  
    }
    let sendClientEmail = async () => {
      console.log("sendClientEmail - ", props.modData.obj.email)
      let functions = getFunctions()     
      let userEmail = await user.value.email
      let clientLinkEmail = httpsCallable(functions, "sendClientEmail")
      await clientLinkEmail({username: userEmail, clientusername: props.modData.obj.email, clientpassword: props.modData.obj.password }) 
      //CHECK FOR ERROR
      // if (updateCliAddressError.value != null){
      //   errorMessage.value = updateCliAddressError.value
      //   let alertLvl = 1
      //   errorArray.value.push(alertLvl)  
      //   errorArray.value.push(errorMessage.value) 
      //   context.emit("showAlertSub", errorArray)             
      //   inProgress.value = false 
      //   return           
      // }
      errorMessage.value = `PHOTAR Link and login email sent to ${props.modData.obj.email}`
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()  
    }    
    let sendPreviewEmail = async () => {
      console.log("sendPreviewEmail - ", props.modData.obj.email)
      let functions = getFunctions()     
      let userEmail = await user.value.email
      let previewEmail = httpsCallable(functions, "sendPreviewEmail")
      await previewEmail({username: userEmail, clientusername: props.modData.obj.email, clientpassword: props.modData.obj.password }) 
      //CHECK FOR ERROR
      // if (updateCliAddressError.value != null){
      //   errorMessage.value = updateCliAddressError.value
      //   let alertLvl = 1
      //   errorArray.value.push(alertLvl)  
      //   errorArray.value.push(errorMessage.value) 
      //   context.emit("showAlertSub", errorArray)             
      //   inProgress.value = false 
      //   return           
      // }
      errorMessage.value = `PHOTAR Preview Client email sent to ${user.value.email}`
      let alertLvl = 2
      errorArray.value.push(alertLvl)  
      errorArray.value.push(errorMessage.value)
      context.emit("showAlertSub", errorArray)  
      closeSubMod()
    }


    let showAlertSubPass = (errorArray) => {
      console.log("pModal>details>showAlertSubPass")
      context.emit("showAlertSub", errorArray)
    }
    let closeSubMod = () => {
      console.log("pModal>Edit>closeSubMod - Close Button")
      context.emit("closeModalSub")
      hideTooltips() 
    }

    return {closeSubMod, inProgress, detailsObject, store, editClientAddress, editClientDetails, sendPreviewEmail, sendClientEmail, changeClientPassword, userDetailsMod, editUserDetails, editUserAddress, resetUserPassword,morePrivacyLink, toggleUserGoogleLink, toggleUserFacebookLink, editUserOptions, viewUserBill, changeUserSubscription, googleAuthLink, facebookAuthLink, currSubscription, isLoading, current,showAlertSubPass, clientDetailsMod, updateUserOptFormMod, uTutorialsUp, uUpgradesUp, uNewsletterUp, handleUpdateUserOpt, updateUserOptionsPending, upgradeSubscription}
  }

}
</script>

<style scoped>

/* FORM SLIDE */
 ::selection{
    color: #fff;
    background: #00A4B0;
  }

.userDetailsModCont .mod-wrapper{
    /* width: 100%; */
    overflow: hidden;
  }

.userDetailsModCont .mod-wrapper .form-container{
    display: flex;
    width: 200%;
    /* background-color: blue; */
  }

.form-container .page{
    width: 50%;
    transition: margin-left 0.3s ease-in-out;
    padding: 0 1rem;
  }

  .userDetailsModCont .form-steps{
    display: flex;
    margin: 0.25rem 0;
    user-select: none;
  }

  .sUpLabel{
    font-size: 1rem !important;
    color: #3D3B3A !important;
  }

  .userDetailsModCont .form-steps .step p{
    font-weight: 600;
    font-size: 1.1rem;
    color: #3d3b3a;
    margin-bottom: 0.125rem;
  }

  /* CURRENT */
  .userDetailsModCont .form-steps .step p.current{
    font-weight: 800;
    color: #00A4B0;
  }

  .form-steps .step .bullet.current{   
    border: 0.125rem solid #00A4B0;
    background-color: #00A4B0;   
  }

  .form-steps .step .bullet span.current{
    color: #FFF;    
  }

  .form-steps .step .bullet{
    height: 2.25rem;
    width: 2.25rem;
    border: 0.125rem solid #bbc4c8;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
  }

  .form-steps .step .bullet.active{
    border-color: #00757D;
  }

  .form-steps .step .bullet span{
    position: absolute;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Old Standard TT', serif;
    color: #bbc4c8;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-steps .step .bullet.active span{
    display: none;
  }

  .form-steps .step .bullet:before,
  .form-steps .step .bullet:after{
    position: absolute;
    content: '';
    bottom: 0.95rem;
    height: 0.125rem;    
    background: #bbc4c8;
  }

  @media only screen and (max-width:575px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{      
      right: calc(32vw * -1 );     
      width: calc(31vw);
    }
  }

  @media only screen and (max-width:767px) and (min-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.7rem;
      width: 9rem;     
    }
  }
  @media only screen and (min-width:768px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -9.4rem;
      width: 9rem;
    }
  }

  .form-steps .step .bullet.active:after{
    background: #00A4B0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
  }

  @keyframes animate {
    100%{
      transform: scaleX(1);
    }
  }

  .form-steps .step:last-child .bullet:before,
  .form-steps .step:last-child .bullet:after{
    display: none;
  }

  .form-steps .step p.active{
    color: #00A4B0;
    transition: 0.2s linear;
  }

  .form-steps .step .check{
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  .form-steps .step .check.active{
    display: block;
    color: #fff;
  }

  .check.bi::before {
    font-size: 2.5rem;
    line-height: 1.2rem;
    vertical-align: none;    
  }  

</style>