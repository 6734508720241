<template>
  <div>
    <div ref="signupFormMod" class="signupFormCont">
    <!-- SIGNUP PROGRESS -->
      <div class="col-12 col-sm-11 mx-auto">
        <div class="form-steps row-cols-3 p-0 m-0">
          <div class="step text-center position-relative">
            <p class="fw-normal">Account</p>
            <div class="bullet">
                <span class="">1</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
          <div class="step text-center position-relative">
            <p class="fw-normal">Details</p>
            <div class="bullet">
                <span>2</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
          <div class="step text-center position-relative">
            <p class="fw-normal">Subscription</p>
            <div class="bullet">
                <span>3</span>
            </div>
            <div class="check bi bi-check-circle-fill text-highlight"></div>
          </div>
        </div>
      </div>
    <!-- SIGNUP FORM -->
      <div class="col-12 col-sm-11 p-0 m-0 mx-auto form-wrapper">
        <form class="needs-validation" id="signupform" novalidate @submit.prevent="handleSignup">
          <!-- SIGNUP - PAGE 1 -->
          <div class="page slide-page">
            <div class="mb-1" @click="assignFocus('signupEmail')">
              <label for="signupEmail" class="form-label sUpLabel" >Email *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input ref="firstFocus" type="email" class="form-control" id="signupEmail" placeholder="Enter Email" required v-model="signupEmail" autocomplete="off">
                <div class="invalid-tooltip">Please provide a valid Email Address</div>
              </div>
            </div>
            <div class="mb-1" @click="assignFocus('signupUsername')">
              <label for="signupUsername" class="form-label sUpLabel" >Username *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input type="text" class="form-control" id="signupUsername" placeholder="Enter Username" required v-model="signupUsername" autocomplete="off">
                <div class="invalid-tooltip">
                  Please provide a valid Username at least 4 chars
                </div>
              </div>
            </div>
            
            <div class="mb-1" @click="assignFocus('signupPassword')">
              <label for="signupPassword" class="form-label sUpLabel"  >Password *</label>
              <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                <input type="password" class="form-control" name="signupPassword" id="signupPassword" placeholder="Password"  required v-model="signupPassword" autocomplete="off">
                <span class="input-group-text border-0">
                  <i class="bi bi-eye" id="toggleSignupPassword" style="cursor: pointer;"></i>
                </span>
                <div class="invalid-tooltip">Passwords must contain at least (a Capital letter, a number and a special character).</div>
              </div>
            </div>
            <div class="col-12 mx-auto p-0 m-0">
              <div class="mb-0 text-end">
                <p class="p-0 m-0 small text-charcoal"><span class="fs-6">* </span>required field</p>                
              </div>
            </div>         
            <div class="mt-2 col-12 mx-auto">    
              <button type="button" 
              class="btn btn-highlight text-white text-uppercase w-100 shadow-sm firstNext next"              
              :class='{"btn-sm": mobileView}'>Continue <span class="fs-4 ps-1"> &#8594; </span></button>
            </div>
            <div class="col-12 mx-auto pt-4 mt-3 mb-4">                    
              <div class="row ">
                <div class="col-10 mx-auto border-top border-lightgrey text-center position-relative ">
                  <p class="fs-6 border fw-bold border-lightgrey rounded-pill text-charcoal bg-white w-25 mx-auto position-absolute top-0 start-50 translate-middle">
                    OR
                  </p>
                </div>
              </div>    
            </div>
            <div class="col-12 mx-auto ">
              <div class="mb-1 text-center">
                <p class="p-0 fs-4 m-0 text-capitalize text-highlight fw-bold">28 day<span class="fw-normal fs-5"> Free Trial</span></p>
              </div>
            </div>  
            <div class="col-8 col-lg-6 mx-auto">
              <div class="d-flex justify-content-evenly align-content-center">
                <div>
                  <button class="tt" @click="quickAccessGoogle" style="background-color: rgba(0,0,0,0); border: none; cursor: pointer" data-bs-placement="bottom" title="Quick Access Google ">
                    <IconLargeBase
                      class="icon-lrg-google-access"
                      width="3rem"
                      height="3rem"
                      icon-color="#3D3B3A"
                      icon-hover-color="#00A4B0">
                      <IconQuickAccessGoogle />
                    </IconLargeBase>
                  </button>
                </div>
                <div>
                  <button class="tt" @click="quickAccessFacebook" style="background-color: rgba(0,0,0,0); border: none; cursor: pointer" data-bs-placement="bottom" title="Quick Access Facebook">
                    <IconLargeBase
                      class="icon-lrg-fb-access"
                      width="3rem"
                      height="3rem"
                      icon-color="#3D3B3A"
                      icon-hover-color="#00A4B0">
                      <IconQuickAccessFacebook />
                    </IconLargeBase>
                  </button>
                </div>
              </div>    
            </div>
            <div class="col-12 mx-auto pt-1 pb-2">
              <div class="mb-0 text-center">
                <p class="p-0 m-0 small text-charcoal">No Credit Card Required</p>
                <p class="p-link small p-0 m-0" @click="loginSubLnk">Already have an account? <span class=" text-decoration-underline">Log In</span></p>                  
              </div>
            </div>                          
          </div>
          <!-- SIGNUP - PAGE 2 -->
          <div class="page">
            <div class="row mb-2 gx-2">                   
              <div class="col-12 col-sm-6" @click="assignFocus('signupFirstname')">
                <!-- ADD SECOND PAGE FOCUS -->
                <label for="signupFirstname" class="form-label sUpLabel" >First Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupFirstname" placeholder="First name" required v-model="signupFirstname" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 text-start text-sm-end" @click="assignFocus('signupLastname')">
                <label for="signupLastname" class="form-label sUpLabel" >Last Name *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupLastname" placeholder="Lastname" required v-model="signupLastname" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('signupPhone')">
                <label for="signupPhone" class="form-label sUpLabel" >Phone *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="tel" class="form-control" id="signupPhone" placeholder=" Phone" required v-model="signupPhone" autocomplete="off">
                  <div class="invalid-tooltip">
                    Numeric values only
                  </div>
                </div>
              </div>
              <div class="col-6 text-end" @click="assignFocus('signupDateBirth')">
                <label for="signupDateBirth" class="form-label sUpLabel" >Date of Birth</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="date" class="form-control dob" id="signupDateBirth" placeholder="Date of Birth" v-model="signupDateBirth" autocomplete="off">                            
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-12 col-sm-6" @click="assignFocus('signupAddress1')">
                <label for="signupAddress1" class="form-label sUpLabel" >Address Line 1 *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupAddress1" placeholder="Address 1" required v-model="signupAddress1" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 text-start text-sm-end" @click="assignFocus('signupAddress2')">
                <label for="signupAddress2" class="form-label sUpLabel" >Address Line 2</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupAddress2" placeholder="Address 2" v-model="signupAddress2" autocomplete="off">              
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('signupCity')">
                <label for="signupCity" class="form-label sUpLabel" >City / Town</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupCity" placeholder="City / Town" v-model="signupCity" autocomplete="off">              
                </div>
              </div>            
              <div class="col-6 text-end" @click="assignFocus('signupPostcode')">
                <label for="signupPostcode" class="form-label sUpLabel" >Postcode *</label>
                <div class="input-group has-validation" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupPostcode" placeholder="Enter Postcode" required v-model="signupPostcode" autocomplete="off">
                  <div class="invalid-tooltip">
                    Required Field
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 gx-2">
              <div class="col-6" @click="assignFocus('signupRegion')">
                <label for="signupRegion" class="form-label sUpLabel" >Region/County</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupRegion" placeholder="Region/County" v-model="signupRegion" autocomplete="off">              
                </div>
              </div>    
              <div class="col-6 text-end" @click="assignFocus('signupCountry')">
                <label for="signupCountry" class="form-label sUpLabel" >Country</label>
                <div class="input-group" :class='{"input-group-sm": mobileView}'>
                  <input type="text" class="form-control" id="signupCountry" placeholder="Enter Country" v-model="signupCountry" autocomplete="off">                           
                </div>
              </div>
            </div> 
            <div class="row mb-3 mt-3 mt-sm-5 gx-2">
              <div class="col-6 mx-auto">
                <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm prev-1 prev" :class='{"btn-sm": mobileView}'><span class="fs-4 pe-2"> &#8592; </span>Back</button>  
              </div>
              <div class="col-6 mx-auto">
                <button type="button" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm next-1 next" :class='{"btn-sm": mobileView}'>Next <span class="fs-4 ps-1"> &#8594; </span></button> 
              </div>     
            </div>       
          </div>
          <!-- SIGNUP - PAGE 3 -->
          <div class="page">
            <div class="mb-4">
              <label for="signupPackage" class="form-label">Select Package</label>
              <select class="form-select" id="signupPackage" v-model="signupPackage" :class='{"form-select-sm": mobileView}'>
                <option value="Trial">Free Trial</option>
                <option value="Basic">Basic Package</option>
                <option value="Enhanced">Enhanced Package</option>
                <option value="Ultimate">Ultimate Package</option>
              </select>
            </div> 
            <div>
              <PriceSelection ref="selectPrice" :signupPackage="signupPackage" />
              <!-- <p>{{signupPackage}}</p> -->
            </div>
            <div v-if="signupPackage === 'Trial'">
              <p class="small p-0 m-0 text-center mb-2">Dip your toe into AR with the FREE trial. See how it will boost your business and sales.</p>
            </div>
            
            <div class="form-check mb-2 text-center">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" id="signupNewsletter"  v-model="signupNewsletter">
              <span class="small p text-charcoal">Send Me The Newsletter</span>
              </label>
            </div>
            <div class="col-12 mx-auto text-center">
              <p class="small p-0 m-0 mt-3">By signing up, you agree to our <span class=" p-link text-decoration-underline">Terms of Service</span> and <span class="p-link text-decoration-underline">Privacy Policy</span></p>                 
            </div>   

            <div class="mt-3 col-12 mx-auto">
              <button v-if="!signupPending || !updateProfilePending || !createUserDocPending || !updateDocPending" type="submit" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" :class='{"btn-sm": mobileView}'>Sign Up</button> 
              <button v-else type="button" class="btn w-100 btn-highlight text-white text-uppercase w-100 shadow-sm" disabled :class='{"btn-sm": mobileView}'>In Progress</button>               
            </div>         
            <div class="mt-2 mb-3 col-12 mx-auto">
              <button type="button" class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm prev-2 prev" :class='{"btn-sm": mobileView}'><span class="fs-4 pe-2"> &#8592; </span>Back</button>  
              <!-- <button type="submit" 
                class="btn w-100 btn-outline-highlight text-highlight text-uppercase shadow-sm" 
                :class='{"btn-sm": mobileView}'
                :disabled="!packagePriceCode || isLoading"
                >{{ isLoading ? "Loading..." : "Signup Part 1 Test" }}</button>   -->
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="inProgress">     
      <Spinner />
    </div> 
  </div>
</template>

<script>
  import {validateSignupEmail, validateSignupPassword, validateSignupUsername, validateSignupFirstname, validateSignupLastname, validateSignupPhone, validateSignupAddress1, validateSignupPostcode} from "../../../public/js/validation.js"

  import PriceSelection from "@/components/PriceSelection";

  import Spinner from "@/components/Spinner.vue"
  import useSignup from "@/composables/useSignup"
  import setDocument from "@/composables/setDocument"
  import sendVerEmail from "@/composables/sendVerEmail"
  import checkDocument from "@/composables/checkDocument"
  import oneTimePayment from "@/composables/oneTimePayment"
  import subscriptionPayment from "@/composables/subscriptionPayment"
  import setTimestamp from "@/composables/setTimestamp"
  import updateDocument from "@/composables/updateDocument"
  import profileUpdate from "@/composables/profileUpdate"
  import { getFunctions, httpsCallable } from "firebase/functions"
  import IconLargeBase from "@/components/Icons/IconLargeBase.vue"
  import IconQuickAccessFacebook from "@/components/Icons/IconQuickAccessFacebook.vue"
  import IconQuickAccessGoogle from "@/components/Icons/IconQuickAccessGoogle.vue"
  import { ref, watchEffect, onBeforeUnmount, onUnmounted, onMounted, computed, watch} from "vue"
  import { useRouter } from "vue-router"
  import {Tooltip} from "bootstrap"
  import { Timestamp, doc, getDoc, setDoc, deleteDoc, collection, onSnapshot, addDoc } from "firebase/firestore"
  import { getAuth, deleteUser, signOut } from "firebase/auth";
  import { db } from "@/firebase/config"
  export default {
    name: "SignupModContent",
    emits: ["closeModalSub", "showAlertSub", "switchModalSub", "switchModalDashData"],
    props: ["mobileView"],
    components: {Spinner, IconLargeBase, IconQuickAccessFacebook, IconQuickAccessGoogle, PriceSelection},
    setup(props, context){
      const signupFormMod = ref(null)
      const firstFocus = ref(null)
      const router = useRouter()
      const { signup, error:signupError, isPending: signupPending} = useSignup()
      const { useSetDocument, error:createUserDocError, isPending:createUserDocPending } = setDocument()
      const { useCheckDocument, error:checkDocError } = checkDocument()
      const { emailVerify, error:emailVerError} = sendVerEmail()
      const { useSubscriptionPayment, retError: subError } = subscriptionPayment()
      const { useOneTimePayment, retError: otpError } = oneTimePayment()
      const { useSetTimestamp, error:timestampError} = setTimestamp()
      const { useUpdateDocument, error:updateDocError, isPending:updateDocPending } = updateDocument()
      const { updateUserProfile, error:updateProfileError, isPending:updateProfilePending} = profileUpdate()

      let signupProgress = {      
        allUsersChecked: false,
        userAuthCreated: false,
        userDocCreated: false,
        userDocChecked: false,
        timeStampAdded: false,        
        reminderDetailsAdded: false,
        displayNameAdded: false,
        rolesAdded: false,
        allUsersUpdated: false,
        sentVerifyEmail: false,
        paymentSent: false,
        paymentDone: false,
        trialAccount: false
      }

      const selectPrice = ref(null)
      let errorMessage = ref("")
      let errorArray = ref([])
      let inProgress = ref(false)
      let adminEmail = ref("")
      let newUserUid = ref("")

      // Signup page progress bar vars
      let slidePage = ref(null)
      let nextBtnFirst = ref(null)
      let prevBtnSec = ref(null)
      let nextBtnSec = ref(null)
      let prevBtnThird = ref(null)
      let progressText = ref(null)
      let progressCheck = ref(null)
      let bullet = ref(null)
      let bulletSpan = ref(null)
      let current = ref(0)
      let currentSet = ref(false)
      let submitBtn = ref(null)
     
      // Signup vars
      const signupUsername = ref(null)
      const signupEmail = ref(null)
      const signupPassword = ref(null)
      const signupFirstname = ref(null)
      const signupLastname = ref(null)
      const signupPhone = ref(null)
      const signupDateBirth = ref(null)
      const signupAddress1 = ref(null)
      const signupAddress2 = ref(null)
      const signupCity = ref(null)
      const signupPostcode = ref(null)
      const signupRegion = ref(null)
      const signupCountry = ref(null)
      const signupPackage = ref("Enhanced")
      const packagePriceCode = ref("")
      const signupDuration = ref("")
      const signupNewsletter = ref(true)
      
      const addCurrent = () => {
        console.log("RUNNING ADDCURRENT - ", current.value)
        bullet.value[current.value].classList.add("current");
        bulletSpan.value[current.value].classList.add("current");
        progressCheck.value[current.value].classList.add("current");
        progressText.value[current.value].classList.add("current");
      }
      const removeCurrent = () => {
        console.log("RUNNING REMOVECURRENT - ", current.value)
        bullet.value[current.value].classList.remove("current");
        bulletSpan.value[current.value].classList.remove("current");
        progressCheck.value[current.value].classList.remove("current");
        progressText.value[current.value].classList.remove("current");
      }
      const addActive = () => {
        console.log("RUNNING ADDACTIVE - ", current.value)
        bullet.value[current.value].classList.add("active");
        progressCheck.value[current.value].classList.add("active");
        progressText.value[current.value].classList.add("active");
      }
      const removeActive = () => {
        console.log("RUNNING REMOVEACTIVE - ", current.value)
        bullet.value[current.value].classList.remove("active");
        progressCheck.value[current.value].classList.remove("active");
        progressText.value[current.value].classList.remove("active");
      }

      const stopSignupModSubWatch = watchEffect(() => {
        console.log("watchEffect - stopSignupModSubWatch")
        if (firstFocus.value) {
          firstFocus.value.focus()
        }
        if (signupFormMod.value) {  
          if(signupFormMod.value.children.length > 0){
            //tooltips
            //  console.log("watchEffect - tooltips - ", signupFormMod.value)
              let modTTips = signupFormMod.value.querySelectorAll(".tt")
              // console.log("tooltips - ", modTTips)
              modTTips.forEach(t => {
                new Tooltip(t, { boundary: document.body, trigger: "hover" })
                // console.log("tooltip added")
              })        
            //MULTI PAGE FORM CONTROLS
            //SELECTORS
            if(currentSet.value != true)
            {
              slidePage.value = signupFormMod.value.querySelector(".slide-page")
              nextBtnFirst.value = signupFormMod.value.querySelector(".firstNext")            
              nextBtnSec.value = signupFormMod.value.querySelector(".next-1")
              prevBtnSec.value = signupFormMod.value.querySelector(".prev-1")
              prevBtnThird.value = signupFormMod.value.querySelector(".prev-2")
              progressText.value = signupFormMod.value.querySelectorAll(".step p")
              progressCheck.value = signupFormMod.value.querySelectorAll(".step .check")
              bullet.value = signupFormMod.value.querySelectorAll(".step .bullet")
              bulletSpan.value = signupFormMod.value.querySelectorAll(".step .bullet span")
              submitBtn.value = signupFormMod.value.querySelector(".submit")          
              addCurrent()
              removeActive()
              //LISTENERS
              nextBtnFirst.value.addEventListener("click", function(event){
                // console.log("NextBtn 1st Clicked ")
                event.preventDefault();
                if(validateSignupUsername() && validateSignupEmail() && validateSignupPassword())
                {
                removeCurrent()
                addActive()
                slidePage.value.style.marginLeft = "-33.33%";           
                current.value += 1; 
                addCurrent()
                }
                else{
                  let alertLvl = 1
                  errorArray.value.push(alertLvl)
                  errorMessage.value = "Please complete required fields to proceed"
                  errorArray.value.push(errorMessage.value)  
                  context.emit("showAlertSub", errorArray)
                }              
              });
              nextBtnSec.value.addEventListener("click", function(event){
                event.preventDefault();
                if(validateSignupFirstname() && validateSignupLastname() && validateSignupPhone() &&validateSignupAddress1() && validateSignupPostcode())
                {
                  removeCurrent()
                  addActive()                
                  slidePage.value.style.marginLeft = "-66.66%";               
                  current.value += 1;
                  addCurrent()                
                }
                else {
                  let alertLvl = 1
                  errorArray.value.push(alertLvl)
                  errorMessage.value = "Please complete required fields to proceed"
                  errorArray.value.push(errorMessage.value)  
                  context.emit("showAlertSub", errorArray)
                }             
              });
              prevBtnSec.value.addEventListener("click", function(event){
                event.preventDefault();
                removeCurrent()
                slidePage.value.style.marginLeft = "0%";
                current.value -= 1;
                removeActive()
                addCurrent()             
              });
              prevBtnThird.value.addEventListener("click", function(event){
                event.preventDefault();
                removeCurrent()
                slidePage.value.style.marginLeft = "-33.33%";              
                current.value -= 1;
                removeActive()
                addCurrent()
              });
              
              //MULTIPAGE FORM VALIDATION START
              // PAGE 1
              signupFormMod.value.querySelector("#signupUsername").addEventListener("blur", validateSignupUsername)
              signupFormMod.value.querySelector("#signupEmail").addEventListener("blur", validateSignupEmail)
              signupFormMod.value.querySelector("#signupPassword").addEventListener("blur", validateSignupPassword)
              let togglePassword = signupFormMod.value.querySelector("#toggleSignupPassword")
              let password = signupFormMod.value.querySelector("#signupPassword")
              // console.log("Password - ", password)
              togglePassword.addEventListener("click", function () {   
                let dType = password.getAttribute("type") === "password" ? "text" : "password";
                password.setAttribute("type", dType);
                togglePassword.classList.toggle("bi-eye")
                togglePassword.classList.toggle("bi-eye-slash")
              })
              // PAGE 2
              signupFormMod.value.querySelector("#signupFirstname").addEventListener("blur", validateSignupFirstname)
              signupFormMod.value.querySelector("#signupLastname").addEventListener("blur", validateSignupLastname)
              signupFormMod.value.querySelector("#signupPhone").addEventListener("blur", validateSignupPhone)
              signupFormMod.value.querySelector("#signupAddress1").addEventListener("blur", validateSignupAddress1)
              signupFormMod.value.querySelector("#signupPostcode").addEventListener("blur", validateSignupPostcode)              
              currentSet.value = true
            }
          }
        }
      })

      let signupComplete = computed(() => {
        if((signupProgress.allUsersChecked == true && signupProgress.userAuthCreated == true && signupProgress.userDocCreated == true && signupProgress.userDocChecked == true && signupProgress.timeStampAdded == true && signupProgress.reminderDetailsAdded == true && signupProgress.displayNameAdded == true && signupProgress.rolesAdded == true && signupProgress.allUsersUpdated == true ) && (signupProgress.trialAccount == true || signupProgress.paymentSent == true)){
          return true
        }
        else {
          return false
        }
      })

      onMounted(() => {
        watch(
          () => selectPrice.value.selectedPrice,
          (newVal) => {
            console.log("watching selected price - ", { newVal }) 
            packagePriceCode.value = newVal
          }
        );
        watch(
          () => selectPrice.value.selectedPriceInterval,
          (newVal2) => {
            console.log("watching selected price interval - ", { newVal2}) 
            signupDuration.value = newVal2
            console.log("signupDuration.value - ", signupDuration.value )
          }
        );
      })

      onBeforeUnmount(() => {  
        //-----REMOVE TOOLTIPS
        if (signupFormMod.value) {
          removeCurrent()
          // console.log("RUNNING REMOVEACTIVE - ", current.value)
          for (let i = 0; i < 3; i++)
          {
            if((bullet.value[i]) && (bullet.value[i].classList.contains("active"))){
              bullet.value[i].classList.remove("active");
            }
            if((progressCheck.value[i]) && (progressCheck.value[i].classList.contains("active"))){
              progressCheck.value[i].classList.remove("active");
            }
            if((progressText.value[i]) && (progressText.value[i].classList.contains("active"))){
              progressText.value[i].classList.remove("active");
            }
          }  
          
          // removeActive()
          // console.log("watchEffect - tooltips - ", signupFormMod.value)
          let modTTips = signupFormMod.value.querySelectorAll(".tt")
          // console.log("pModal onb4Unmount - tooltips - ", modTTips)
          // console.log("pModal onb4Unmount - tooltips - ", modTTips.length)
          if ((modTTips) && (modTTips.length > 0 || modTTips != null))
          {
            // console.log("tooltips - ", modTTips)
            modTTips.forEach(t => {
            let dispTT = Tooltip.getInstance(t)

            dispTT.hide()
            // console.log("tooltips removed")
          })    
          }   
        }     
        nextBtnFirst.value.removeEventListener("click", function () {})
        nextBtnSec.value.removeEventListener("click", function () {})
        prevBtnSec.value.removeEventListener("click", function () {})
        prevBtnThird.value.removeEventListener("click", function () {})
        signupFormMod.value.querySelector("#toggleSignupPassword").removeEventListener("click", function () {})
        signupFormMod.value.querySelector("#signupUsername").removeEventListener("blur", validateSignupUsername)
        signupFormMod.value.querySelector("#signupEmail").removeEventListener("blur", validateSignupEmail)
        signupFormMod.value.querySelector("#signupPassword").removeEventListener("blur", validateSignupPassword)
        signupFormMod.value.querySelector("#signupFirstname").removeEventListener("blur", validateSignupFirstname)
        signupFormMod.value.querySelector("#signupLastname").removeEventListener("blur", validateSignupLastname)
        signupFormMod.value.querySelector("#signupPhone").removeEventListener("blur", validateSignupPhone)
        signupFormMod.value.querySelector("#signupAddress1").removeEventListener("blur", validateSignupAddress1)
        signupFormMod.value.querySelector("#signupPostcode").removeEventListener("blur", validateSignupPostcode)
      })

      onUnmounted(() => {
        console.log("pModal>Signup - onUnmounted")
        clearVars()
        stopSignupModSubWatch()
        //Stop watchers of priceselection
      })

      const clearVars = () => {
        console.log("Running Signup clearVars")       
        signupFormMod.value = null
        firstFocus.value = null
        selectPrice.value = null
        errorMessage.value = ""
        errorArray.value = []
        inProgress.value = false

        slidePage.value = null
        nextBtnFirst.value = null
        prevBtnSec.value = null
        nextBtnSec.value = null
        prevBtnThird.value = null
        progressText.value = null
        progressCheck.value = null
        bullet.value = null
        bulletSpan.value = null
        current.value = 0
        currentSet.value = false
        submitBtn.value = null

        signupUsername.value = null
        signupEmail.value = null
        signupPassword.value = null
        signupFirstname.value = null
        signupLastname.value = null
        signupPhone.value = null
        signupDateBirth.value = null
        signupAddress1.value = null
        signupAddress2.value = null
        signupCity.value = null
        signupPostcode.value = null
        signupRegion.value = null
        signupCountry.value = null
        signupPackage.value = "Enhanced"
        packagePriceCode.value = ""
        signupDuration.value = ""
        signupNewsletter.value = true

        signupProgress.allUsersChecked = false
        signupProgress.userAuthCreated = false
        signupProgress.userDocCreated = false
        signupProgress.userDocChecked = false
        signupProgress.timeStampAdded = false
        signupProgress.displayNameAdded = false
        signupProgress.reminderDetailsAdded = false
        signupProgress.rolesAdded = false
        signupProgress.allUsersUpdated = false
        signupProgress.sentVerifyEmail = false  
        signupProgress.paymentSent = false  
        signupProgress.paymentDone = false  
        signupProgress.trialAccount = false  
        adminEmail.value = ""
        newUserUid.value = ""
      }

      function showError() {
        let alertLvl = 1
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)             
        inProgress.value = false 
        cleanUp()      
      }

      const cleanUp = async () => {
        console.log("Signup>CleanUp")
        errorMessage.value = ""
        errorArray.value = []
        if (signupProgress.allUsersUpdated == true ){
          await deleteDoc(doc(db, "allusers", adminEmail.value));
          console.log("all users Doc Deleted - ", adminEmail.value)
        }
        if (signupProgress.userDocCreated == true){
          await deleteDoc(doc(db, "users", newUserUid.value));
          console.log("user Doc Deleted - ", newUserUid.value)
        }
        if(signupProgress.userAuthCreated == true){
          const auth = getAuth();
          const user = auth.currentUser;
          await deleteUser(user).then(() => {
            console.log("user account deleted")
            signOut(auth)
          }).catch((error) => {
          console.log("error while deleting user - ", error)
          });
        }
        closeSubMod()
      }

      const loginSubLnk = () => {
        console.log("pModal>Signup>loginSubLnk - Switch Modal")
        let switchTo = "Login"
        context.emit("switchModalSub", switchTo )
      }

      const closeSubMod = () => {
        console.log("pModal>Signup>closeSubMod - Close Button")
        context.emit("closeModalSub")
      }
      
      const assignFocus = (focusArea) => {
        console.log("Running assignFocus", focusArea)
        let focusId = "#" + focusArea
        let clickFocus = signupFormMod.value.querySelector(`${focusId}`)
        clickFocus.focus()
      }    

      const signupDate = computed(() => {
        let sUpDate = new Timestamp(Timestamp.now().seconds, Timestamp.now().nanoseconds)
        return sUpDate
      })

      const renewalDate = computed(() => {
        let rnwDate1 = new Date(new Date().setFullYear(new Date().getFullYear() +1))    
        // console.log("rnwDate1 - 549" - rnwDate1)   
        let rnwDate2 = ((rnwDate1.valueOf()) / 1000)
        // console.log("rnwDate2 - 549" - rnwDate2) 
        let rnwDateTS = new Timestamp(rnwDate2, 0)
        return rnwDateTS       
      })

      const reminderDate = computed(() => {
        let remDate1 = new Date()      
        let remDate2 = new Date(remDate1.setFullYear(remDate1.getFullYear(), remDate1.getMonth() + 11))
        let remDate3 = ((remDate2.valueOf()) / 1000) 
        let remDateTS = new Timestamp(remDate3, 0)
        return remDateTS    
      })

      let trialEndDate = computed(() => {
        let triEndDate1 = new Date()
        let triEndDate2 = triEndDate1.setDate(triEndDate1.getDate()+28)
        let triEndDate3 = ((triEndDate2.valueOf()) / 1000)
        let triEndDateTS = new Timestamp(triEndDate3, 0)
        return triEndDateTS    
      })

      const reminder1Date = computed(() => {
        let rem1Date1 = new Date()
        let rem1Date2 = rem1Date1.setDate(rem1Date1.getDate()+21)
        let rem1Date3 = ((rem1Date2.valueOf()) / 1000)
        let rem1DateTS = new Timestamp(rem1Date3, 0)
        return rem1DateTS 
      })
      const reminder2Date = computed(() => {
        let rem2Date1 = new Date()
        let rem2Date2 = rem2Date1.setDate(rem2Date1.getDate()+24)
        let rem2Date3 = ((rem2Date2.valueOf()) / 1000)
        let rem2DateTS = new Timestamp(rem2Date3, 0)
        return rem2DateTS 
      })
      const reminder3Date = computed(() => {
        let rem3Date1 = new Date()
        let rem3Date2 = rem3Date1.setDate(rem3Date1.getDate()+27)
        let rem3Date3 = ((rem3Date2.valueOf()) / 1000)
        let rem3DateTS = new Timestamp(rem3Date3, 0)
        return rem3DateTS   
      })
      
      const quickAccessGoogle = (e) => {
        e.preventDefault()
        // console.log("pModal>Signup>QaGoogle - Switch Modal")
        let provider = "Google"
        let subtitle = `Quick Signup with ${provider} authentication`
        let modData = {title: "Quick Signup", area:provider, message: subtitle}
        context.emit("switchModalDashData", modData )
      }

      const quickAccessFacebook = (e) => {
        e.preventDefault()
        // console.log("pModal>Signup>QaFacebook - Switch Modal")
        let provider = "Facebook"
        let subtitle = `Quick Signup with ${provider} authentication`
        let modData = {title: "Quick Signup", area:provider, message: subtitle}
        context.emit("switchModalDashData", modData )
      }
    
      const signupSuccess = () => {
        console.log("pModal>Signup>Success")
        errorMessage.value = "Please verify your email to proceed"
        let alertLvl = 2
        errorArray.value.push(alertLvl)  
        errorArray.value.push(errorMessage.value) 
        context.emit("showAlertSub", errorArray)  
        inProgress.value = false 
        closeSubMod()
        router.push({name: "VerifyAccount"})
      }

      const signupPart1 = async () => {
        console.log("Running Signup Part 1 - Auth")
        let form = signupFormMod.value.querySelector(".needs-validation")
        if ( !form.checkValidity() || !validateSignupEmail() || !validateSignupPassword() || !validateSignupUsername() || !validateSignupFirstname() || !validateSignupLastname() || !validateSignupPhone() || !validateSignupAddress1() || !validateSignupPostcode) {
          errorMessage.value = "Signup Error - Please recheck signup form"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)            
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)           
        }
        else {
          form.classList.add("was-validated")
          // inProgress.value = true
          try {
            // check email not in all users doc
            const allUsersRef = doc(db, "allusers", signupEmail.value)
            const allUsersSnap = await getDoc(allUsersRef)
            if ((allUsersSnap.exists()) && (allUsersSnap.data().subscription === "Trial") && (signupPackage.value === "Trial")) 
            {              
              signupProgress.allUsersChecked = false
              errorMessage.value = "Only one trial account allowed per email"
              showError()
              return
            } else {               
              signupProgress.allUsersChecked = true
              const authResult = await signup(signupEmail.value, signupPassword.value)
              if (signupError.value != null){
              signupProgress.userAuthCreated = false
              errorMessage.value = signupError.value
              showError()
              return            
              } else {
                signupProgress.userAuthCreated = true
                adminEmail.value = authResult.user.email
                newUserUid.value = authResult.user.uid
                createSub()
              }
            }
          }
          catch(error) {
          console.log("signupPart1 Catch Error")
          const { code, details } = JSON.parse(JSON.stringify(error))
          errorMessage.value = code + details
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value) 
          context.emit("showAlertSub", errorArray)             
          inProgress.value = false
          cleanUp()
          return 
          }
        }
      }

      const isLoading = ref(false)
      const createSub = async () => {
        isLoading.value = true
        const collectionRef = collection(db, "users", newUserUid.value, "checkout_sessions" )
        const docRef = await addDoc(collectionRef, {
          price: packagePriceCode.value,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

        onSnapshot(docRef, (snap) => {
          const {error, url } = snap.data()
          if (error) {
            console.error(`An error occurred: ${error.message}`)
            isLoading.value = false
          }
          if(url){
            console.log("Got url - ", url)
            window.location.assign(url)
          }
        })
      }


      const handleSignup = async () => {
        console.log("handleSignup")
        let form = signupFormMod.value.querySelector(".needs-validation")
        if ( !form.checkValidity() || !validateSignupEmail() || !validateSignupPassword() || !validateSignupUsername() || !validateSignupFirstname() || !validateSignupLastname() || !validateSignupPhone() || !validateSignupAddress1() || !validateSignupPostcode) {
          errorMessage.value = "Signup Error - Please recheck signup form"
          let alertLvl = 1
          errorArray.value.push(alertLvl)  
          errorArray.value.push(errorMessage.value)            
          form.classList.remove("was-validated")
          context.emit("showAlertSub", errorArray)           
        }
        else {
          let functions = getFunctions()
          form.classList.add("was-validated")
          inProgress.value = true
          try {
            // check email not in all users doc
            const allUsersRef = doc(db, "allusers", signupEmail.value)
            const allUsersSnap = await getDoc(allUsersRef)
            if (allUsersSnap.exists()) {
              if((allUsersSnap.data().subscription === "Trial") && (signupPackage.value === "Trial")){
                signupProgress.allUsersChecked = false
                errorMessage.value = "Only one trial account allowed per email"
                showError()
                return
              } else {               
                signupProgress.allUsersChecked = true
              }
            } else {
              signupProgress.allUsersChecked = true
            }
            // create user auth account
            let authResult = await signup(signupEmail.value, signupPassword.value)
            if (signupError.value != null){
              signupProgress.userAuthCreated = false
              errorMessage.value = signupError.value
              showError()
              return            
            } else{
              signupProgress.userAuthCreated = true
            }       
            // create user info object
            adminEmail.value = authResult.user.email
            newUserUid.value = authResult.user.uid
            const userInfo = {
              userId: authResult.user.uid,
              email: authResult.user.email,
              displayName: signupUsername.value,
              password: signupPassword.value,
              createdAt: null,
              userDetails: {
                firstName: signupFirstname.value,
                lastName: signupLastname.value,
                birthDate: signupDateBirth.value,
                phoneNum: signupPhone.value,
              }, 
              userAddress: {
                address1: signupAddress1.value,
                address2: signupAddress2.value,
                city: signupCity.value,
                postcode: signupPostcode.value,
                region: signupRegion.value,
                country: signupCountry.value,
              },
              clients: {
              numberClients: 0,
              clientIDs:[],           
              },
              shoots: {
              numberShoots: 0,
              shootIDs:[],           
              },
              images: {
                numberImages: 0,
                imageIDs:[],            
              },
              orders: {
                numberOrders: 0,
                orderIDs:[],            
              },
              subscription: signupPackage.value,              
              newsLetter: signupNewsletter.value,
              signupDate: signupDate.value,              
              showTutorials: true,
              showUpgrade: true,
              googleAuth: false,
              facebookAuth: false
            }
            console.log("userInfo - ", userInfo)
            // create user doc in firestore
            await useSetDocument("users", newUserUid.value, userInfo)
            if (createUserDocError.value != null){
              signupProgress.userDocCreated = false
              errorMessage.value = "Error while creating user document"
              showError()
              return 
            } else{
              signupProgress.userDocCreated = true
            }   
            // check user doc in firestore
            await useCheckDocument("users", newUserUid.value)
            if (checkDocError.value != null){
              signupProgress.userDocChecked = false
              errorMessage.value = "Error while checking user document"
              showError()
              return 
            } else{
              signupProgress.userDocChecked = true
            }
            // set created at timestamp 
            await useSetTimestamp("users", newUserUid.value)
            if (timestampError.value != null){
              signupProgress.timeStampAdded = false
              errorMessage.value = "Error while adding timestamp"
              showError()
              return 
            } else{
              signupProgress.timeStampAdded = true
            }
            // reminder details added
            let reminderDetails
            if(signupPackage.value != "Trial") {
              if(signupDuration.value === "oneoff"){
                reminderDetails = { 
                duration: signupDuration.value,
                oneOffEndDate: renewalDate.value,
                reminderDate: reminderDate.value
                }
              } else {
                reminderDetails = { 
                duration: signupDuration.value,
                renewalDate: renewalDate.value,
                reminderDate: reminderDate.value
              }
              }
              
            } else {
              reminderDetails = { 
                duration: "trial",
                trialEndDate: trialEndDate.value,
                renewalDate: "n/a",
                reminders: {
                  reminder1Date: reminder1Date.value,
                  reminder1Sent: false,
                  reminder2Date: reminder2Date.value,
                  reminder2Sent: false,
                  reminder3Date: reminder3Date.value,
                  reminder3Sent: false,
                }
              }
            }
            await useUpdateDocument("users", newUserUid.value, reminderDetails)
            if (updateDocError.value != null){
              signupProgress.reminderDetailsAdded = false
              errorMessage.value = "Error while adding date info"
              showError()
              return
            } else{
              signupProgress.reminderDetailsAdded = true
            }
            // display name added
            await updateUserProfile(signupUsername.value)
            if (updateProfileError.value != null){
              signupProgress.displayNameAdded = false
              errorMessage.value = "Error while updating username"
              showError()
              return 
            } else{
              signupProgress.displayNameAdded = true
            }
            // roles added
            let access = 0
            if (signupPackage.value === "Basic") {
              access = 4
            } else if (signupPackage.value === "Enhanced") {
              access = 6
            } else if (signupPackage.value === "Ultimate"){
              access = 8
            } else {
              access = 2
            }
            try{
              const setUserRole = httpsCallable(functions, "addAdminRole")
              const res = await setUserRole({ email:adminEmail.value, accessLevel:access })
              console.log("res - ", res)
              signupProgress.rolesAdded = true
            } catch (error) {
              signupProgress.rolesAdded = false
              errorMessage.value = "Error while adding User Roles"
              showError()
              return     
            }
            // allusers updated
            let userLog = {}
            if(signupPackage.value != "Trial") {
              userLog = {
                userId: newUserUid.value,
                email: adminEmail.value,
                displayName: signupUsername.value,            
                subscription: signupPackage.value,            
                signupDate: signupDate.value,
                duration: signupDuration.value,
                EndDate: renewalDate.value,
                status: "Active"  
              }
            }
            else {
              userLog = {
                userId: newUserUid.value,
                email: adminEmail.value,
                displayName: signupUsername.value,            
                subscription: signupPackage.value,            
                signupDate: signupDate.value,
                duration: "trial",
                trialEndDate: trialEndDate.value,
                status: "Active"  
              }
            }
            let addToAllUsersSnap = await setDoc(doc(db, "allusers", adminEmail.value), userLog)
            console.log("addToAllUsersSnap - ", addToAllUsersSnap)
            signupProgress.allUsersUpdated = true   
            //redirect to payment
            if(signupPackage.value != "Trial") {
              console.log("Non Trial", signupDuration.value)
              if(signupDuration.value != "oneoff"){
                await useSubscriptionPayment(newUserUid.value, packagePriceCode.value)
                if (subError.value != null)
                {
                  signupProgress.paymentSent = false
                  errorMessage.value = "Error while creating subscription payment"
                  showError()
                  return     
                }
                else {
                  signupProgress.paymentSent = true
                  errorMessage.value = "Redirecting to secure billing area"
                  let alertLvl = 2
                  errorArray.value.push(alertLvl)  
                  errorArray.value.push(errorMessage.value) 
                  context.emit("showAlertSub", errorArray)
                }
              }
              else if(signupDuration.value === "oneoff"){
                await useOneTimePayment(newUserUid.value, packagePriceCode.value)
                if (otpError.value != null)
                {
                  signupProgress.paymentSent = false
                  errorMessage.value = "Error while creating one off payment"
                  showError()
                  return    
                } 
                else {
                  signupProgress.paymentSent = true
                  errorMessage.value = "Redirecting to secure billing area"
                  let alertLvl = 2
                  errorArray.value.push(alertLvl)  
                  errorArray.value.push(errorMessage.value) 
                  context.emit("showAlertSub", errorArray) 
                }   
              }
            }  
            else{
              signupProgress.trialAccount = true
            }
            if(signupComplete.value == true){
              await emailVerify(adminEmail.value)
              if (emailVerError.value != null){
                signupProgress.sentVerifyEmail = false
                errorMessage.value = "Error while sending verification email"
                console.log("SignupProgress - ", signupProgress)
                showError()
                return 
              }
              else{
                signupProgress.sentVerifyEmail = true
                signupSuccess()
              }
            }
            else{
              errorMessage.value = "Unknown error occurred during signup"           
              console.log("signupProgress - ", signupProgress)
              showError()            
              return 
            }  
          }
          catch(error) {
            const { code, details } = JSON.parse(JSON.stringify(error))
            errorMessage.value = code + details
            let alertLvl = 1
            errorArray.value.push(alertLvl)  
            errorArray.value.push(errorMessage.value) 
            context.emit("showAlertSub", errorArray)             
            inProgress.value = false
            cleanUp()
            return 
          }
        }
      }

      return {loginSubLnk, closeSubMod, firstFocus, assignFocus, inProgress, signupFormMod, handleSignup, signupUsername, signupEmail, signupPassword, signupFirstname, signupLastname, signupPhone, signupDateBirth, signupAddress1, signupAddress2, signupCity, signupPostcode, signupRegion, signupCountry, signupPackage, signupDuration, signupNewsletter, signupPending, createUserDocPending, updateProfilePending, quickAccessGoogle, quickAccessFacebook, selectPrice, updateDocPending, signupPart1, isLoading, packagePriceCode}    
    }
  }
</script>

<style scoped>
  ::selection{
    color: #fff;
    background: #00A4B0;
  }

  .signupFormCont .form-wrapper{
    width: 100%;
    overflow: hidden;
  }

  .signupFormCont .form-wrapper form{
    display: flex;
    width: 300%;
  }

  .signupFormCont .form-wrapper form .page{
    width: 33.33%;
    transition: margin-left 0.3s ease-in-out;
    padding: 0 1rem;
  }

  .signupFormCont .form-steps{
    display: flex;
    margin: 0.25rem 0;
    user-select: none;
  }

  .signupFormCont .form-steps .step p{
    font-weight: 600;
    font-size: 1.1rem;
    color: #3d3b3a;
    margin-bottom: 0.125rem;
  }

  /* CURRENT */
  .signupFormCont .form-steps .step p.current{
    font-weight: 800;
    color: #00A4B0;
  }

  .form-steps .step .bullet.current{   
    border: 0.125rem solid #00A4B0;
    background-color: #00A4B0;   
  }

   .form-steps .step .bullet span.current{
    color: #FFF;    
  }

  .form-steps .step .bullet{
    height: 2.25rem;
    width: 2.25rem;
    border: 0.125rem solid #bbc4c8;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
  }

  .form-steps .step .bullet.active{
    border-color: #00757D;
    /* background: #00A4B0; */
  }

  .form-steps .step .bullet span{
    position: absolute;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Old Standard TT', serif;
    color: #bbc4c8;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-steps .step .bullet.active span{
    display: none;
  }

  .form-steps .step .bullet:before,
  .form-steps .step .bullet:after{
    position: absolute;
    content: '';
    bottom: 0.95rem;
    height: 0.125rem;    
    background: #bbc4c8;
  }

    @media only screen and (max-width:575px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{      
      right: calc(19vw * -1 );     
      width: calc(18vw);
    }
  }

  @media only screen and (max-width:767px) and (min-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -5.9rem;
      width: 5.5rem;     
    }
  }
  @media only screen and (min-width:768px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -5.5rem;
      width: 5.1rem;
    }
  }


  /* @media only screen and (max-width:576px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: calc((100vw / 5.9) * -1 );
      width: calc(100vw / 6.4);  
    }
  }

  @media only screen and (min-width:577px) {
    .form-steps .step .bullet:before,
    .form-steps .step .bullet:after{ 
      right: -6rem;
      width: 5.5rem;
    }
  } */

  .form-steps .step .bullet.active:after{
    background: #00A4B0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
  }

  @keyframes animate {
    100%{
      transform: scaleX(1);
    }
  }

  .form-steps .step:last-child .bullet:before,
  .form-steps .step:last-child .bullet:after{
    display: none;
  }

  .form-steps .step p.active{
    color: #00A4B0;
    transition: 0.2s linear;
  }

  .form-steps .step .check{
    position: absolute;
    left: 50%;
    top: 70%;
    font-size: 1rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  .form-steps .step .check.active{
    display: block;
    color: #fff;
  }

  .check.bi::before {
    font-size: 2.25rem;
    line-height: 0.7;
    vertical-align: none;
  } 

  #signupPassword:focus ~ .bi-bi-eye{
    margin-left: -30px !important; 
    cursor: pointer !important;
    z-index: 5 !important;
  }
  

  .dob{
    font-size: 1rem;
    color: #3D3B3A;
  }

  .sUpLabel{
    font-size: 1rem !important;
    color: #3D3B3A !important;
  }
</style>