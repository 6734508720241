import {ref } from "vue"
// firebase imports
import { db } from "../firebase/config"
import { addDoc, collection, onSnapshot} from "firebase/firestore"
const retError = ref(null)

const useSubscriptionPayment = async (uId, pId) => {   
  retError.value = null
  const collectionRef = collection(db, "users", uId, "checkout_sessions")  
  console.log("SUBSCRIPTIONPAYMENT.JS >")
  const docRef = await addDoc(collectionRef, {
    line_items: [
      {
        price: pId,
        quantity: 1         
      }
    ],
    // price: pId, 
    success_url: "https://photar-site-beta.web.app/verify",
    cancel_url: "https://photar-site-beta.web.app/problem",
    // cancel_url: window.location.origin,
  });
  
  onSnapshot(docRef, (snap) => {
    const {error, url } = snap.data()

    if (error) {
      console.error(`An error occured: ${error.message}`);
    }

    if(url) {
      window.location.assign(url)
    }
  })
}
//     (db, docPath), {     
//       line_items: [
//         {
//           price: pId,
//           quantity: 1         
//         }
//       ],
//       success_url: "https://photar-site-beta.web.app",
//       cancel_url: "https://photar-site-beta.web.app"
//     })
//     onSnapshot(docRef, snapshot => {
//       const {error, url} = snapshot.data()
//       if (error) {
//         console.log("subscription payment error", error.message)
//         // Show an error to your customer
//         retError.value = error.message
//         return error
//       }
//       if (url) {
//         console.log("subscription payment have url", url)
//         // We have a Stripe Checkout URL, let's redirect.
//         retError.value = null
//         window.location.assign(url);
//         return url 
        
//       }
//     })
//   }
//   catch(err){
//     console.log("Error while creating subscription")
//     retError.value = "Error while creating subscription"
//   }
// }

const subscriptionPayment = () => {
  return {useSubscriptionPayment, retError}  
}
export default subscriptionPayment