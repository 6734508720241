<template>
  <div id="navigation-mobile" class="nav-mob mx-auto">
  <!-- <h2>wheight - {{wHeight}}</h2> -->
   <div class="container">
      <div class="d-flex flex-column justify-content-center align-items-center pb-2">  
        <div v-if="user">
          <ul class="p-0 m-0">
            <li>
              <router-link :to='{ name: "Home" }' :class='{active: $route.name==="Home" && $route.hash === ""}' @click="mobileNavClick"><span class="small">Home</span></router-link>
            </li>
            <li v-if="valid == true && access > 1" class="pt-1">
              <button class="btn btn-sm navBtn btn-highlight text-white text-uppercase shadow-sm" @click="mobileNavClick(); gotoDash();" :class='{hide: $route.name === "Dashboard"}'>Dashboard</button> 
             
            </li>
            <li v-if="valid == true && access == 10" class="pt-1">
              <button class="btn btn-sm navBtn btn-highlight text-white text-uppercase shadow-sm" type="button" @click="mobileNavClick(); gotoAdminDash();">Admin Dashboard</button>             
            </li>
            <li v-if="valid == true && access == 0" class="pt-1">
               <button class="btn btn-sm navBtn btn-highlight text-white text-uppercase shadow-sm" type="button" @click="showModClientLink">App Link</button>
            </li>
            <li class="pt-1">
              <button class="btn btn-sm navBtn btn-highlight text-white text-uppercase shadow-sm" type="button" @click="showModMobNav('Logout')">Logout</button>
            </li>                                       
          </ul>
        </div>
        <div v-else>
          <ul class="p-0 m-0">    
            <li>
              <router-link :to='{ name: "Home" }' :class='{active: $route.name==="Home" && $route.hash === ""}' @click="mobileNavClick"><span class="small">Home</span></router-link>
            </li>       
            <!-- <li>
              <router-link :to='{ name: "Home", hash:"#learn-more" }' :class='{active: $route.name==="Home" && $route.hash === "#learn-more"}' @click="mobileNavClick"><span class="small">Learn More</span></router-link>
            </li>
            <li>
              <router-link :to='{ name: "Home", hash:"#benefits" }' :class='{active: $route.name==="Home" && $route.hash==="#benefits"}' @click="mobileNavClick"><span class="small">Benefits</span></router-link>
            </li>
            <li>
              <router-link :to='{ name: "Home", hash:"#pricing" }' :class='{active: $route.name==="Home" && $route.hash==="#pricing"}' @click="mobileNavClick"><span class="small">Pricing</span></router-link>
            </li>
            <li>
              <router-link :to='{ name: "Home", hash:"#contact" }' :class='{active: $route.name==="Home" && $route.hash==="#contact"}' @click="mobileNavClick"><span class="small">Contact</span></router-link>
            </li> -->
            <li class="pt-2">
               <button class="btn navBtn btn-sm btn-highlight text-white text-uppercase shadow-sm" type="button" @click="showModMobNav('Signup')">Signup</button> 
            </li>
            <li class="pt-2">
              <button class="btn navBtn btn-sm btn-highlight text-white text-uppercase shadow-sm" type="button" @click="showModMobNav('Login')">Login</button> 
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { computed } from "vue"
import { onUnmounted, watchEffect } from "vue"
import getUser from "../composables/getUser"
import { auth } from "../firebase/config"
import { useRouter } from "vue-router"
export default {
name: "NavigationMob",
emits: ["closeMobNav", "showModal", "switchModalDashData"],
setup(props, context) {
  let router = useRouter()
  //USER INFO
  const { user, access, valid } = getUser() 
  const mobileNavClick = () => {      
    // console.log("mobileNavClick")  
    context.emit("closeMobNav")  
  }
  
  const stopNavMobUserWatch = watchEffect(() => {
    // console.log("NAVIGATION.VUE - stopNavUserWatch") 
    if(valid.value != null){
      if(valid.value == true){
        showModClientLink()
      }
    }
  })

  function showModClientLink(){  
    if (auth.currentUser){
      auth.currentUser.getIdTokenResult().then((res) => {
        // console.log("NAVIGATION.VUE - showModClientLink res - ", res) 
        if(res.claims.accessLevel == 0){
          console.log("auth.currentUser is a Client - showPopup")                      
          let modData = {title: "Client Login", dName: auth.currentUser.displayName, photographer: res.claims.email }
          context.emit("switchModalDashData", modData )         
        }
      })
    }
  }
   onUnmounted(() => {
    stopNavMobUserWatch()
  })

  // const wHeight = computed(() => window.innerHeight)
  const showModMobNav = (modalName) => {  
    // console.log("showModal - Navigation ", modalName)    
    context.emit("showModal", modalName) 
  }

  const gotoDash = () => {
    // console.log("Running gotoDash")
    router.push({ name: "Dashboard", params: {id: user.value.uid}})  
    
  }
  
  const gotoAdminDash = () => {
    // console.log("Running gotoAdminDash")
    router.push({ name: "AdminDash", params: {id: user.value.uid}})  
  }

  return{user, valid, access, mobileNavClick, showModMobNav, showModClientLink, gotoDash, gotoAdminDash}
  }
}
</script>

<style scoped>
#navigation-mobile {
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
  background-color: #3d3b3a;
}

.router-link-active {
  color: #FFF !important;
  border: none;  
  background-color: transparent;
}
.router-link-active.active{
  color: #00A4B0 !important;
  border-bottom: 2px solid #00A4B0;
}

ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}
 
li {
  padding: 0rem 0.5rem;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}
li a {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Lato';
  font-size: 1.14rem;
  margin: 0 0.3rem;
}

li a.active {  
  color: #00A4B0 !important;
  border-bottom: 2px solid #00A4B0;
}
li a:hover {
  color: #00A4B0;
}

.router-link-active {
  /* text-transform: uppercase; */
  /* box-shadow: 0 0.15rem 0.25rem rgb(17 17 17 / 15%) !important; */
  color: #00A4B0;
  border-color: #00A4B0;
  background-color: transparent;
}

li button.hide{
  display: none;
}

.navBtn{
  min-width: 12rem;
  margin-bottom: 0.3rem;
}
</style>