<template>
  <div class="p-0 m-0 my-3" style="max-height:95vh; max-width:95vh; ">
    <transition name="my-slide-modal-animation">
      <div class="d-flex flex-row justify-content-center align-items-center p-0 m-0 m-2">
      <!-- <div v-if="imgArrLength > 1" class="position-absolute top-50 start-0 translate-middle nav-arrows"> -->
      <div v-if="imgArrLength > 1">
        <button class="d-block" style="background-color: rgba(0,0,0,0); border: none;" @click="prevSlide">
          <icon-base
          class="icon-prev"
          width="32"
          height="32"
          icon-color="#BBC4C8"
          icon-hover-color="#00A4B0">
            <icon-prev />
          </icon-base>
        </button>
      </div>
      <div class="flex-grow-1 text-center">
        <img v-if="imgAspectShowing >= 1" :src="imgUrlShowing" class="mx-auto text-center img-port rounded shadow-sm" :alt="altTxt" style="max-width: 90%; max-height:auto;"/> 
        <img v-else :src="imgUrlShowing" class="img-fluid mx-auto text-center rounded shadow-sm"  :alt="altTxt" style="max-height:90vh; max-width: auto; "/> 
      </div>
      <div v-if="imgArrLength > 1">
        <button class="d-block px-1" style="background-color: rgba(0,0,0,0); border: none;" @click="nextSlide">
          <icon-base
          class="icon-next"
          width="32"
          height="32"
          icon-color="#BBC4C8"
          icon-hover-color="#00A4B0">
            <icon-next />
          </icon-base>
        </button>
      </div>
      </div>
    </transition>
    <div class="row mt-2">
      <div class="col-10 mx-auto m-0 p-0">
        <p class="h1 text-charcoal fs-5 text-center m-0 p-0">{{altTxt}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import IconPrev from "../Icons/IconPrev.vue"
import IconNext from "../Icons/IconNext.vue"
import IconBase from "../Icons/IconBase.vue"
import {ref, watchEffect, onUnmounted, computed} from "vue"
export default {
  name: "SlideshowModal",
  components: {IconBase, IconPrev, IconNext},
  props: ["modData", "mobileView"],
  emits: ["closeModalSub"],
  setup(props){
    const startPos = ref(null)
    const imgsArr = ref(null)
    const populated = ref(false)
    const currPos = ref(0)

    const imgUrlShowing = computed(() => {
      if(imgsArr.value){
        let arr = [...imgsArr.value]
        // let url = arr[currPos.value].resizedImageUrl
        let url = arr[currPos.value].imageUrl
        return url  
       
      }
      else {
        //LINK TO PHOTAR LOGO IMG NOT FOUND
        return 0
      }
    })
    const imgAspectShowing = computed(() => {
      if(imgsArr.value){
        let arr = [...imgsArr.value]
          let width = arr[currPos.value].imageWidth
          let height = arr[currPos.value].imageHeight
          let ar = height / width
          return ar          
                
      }
      else {
        //LINK TO PHOTAR LOGO IMG NOT FOUND
        return 0
      }
    })
    
    const altTxt = computed(() => {
      if(imgsArr.value){
        let arr = [...imgsArr.value]
        let title = arr[currPos.value].imageTitle
        return title
      }
      else {
        //LINK TO PHOTAR LOGO IMG NOT FOUND
        let title = "Image Title Not Available"
        return title
      }
    })   
    
    const doInitialPopulate = () => {
      // console.log("Doing Initial Populate")
      startPos.value = props.modData[2]
      imgsArr.value = props.modData[1]
      populated.value = true
      // console.log("currPos - ", currPos.value)
      setStart()
    }

    function setStart(){
      currPos.value = startPos.value
    }

    let stopSlideShowWatch = watchEffect(() => {
      if(populated.value != true)
      {
        doInitialPopulate()
      } 
    })

    const imgArrLength = computed(() => {
      if(imgsArr.value){
        let arr = [...imgsArr.value]
        let length = arr.length
        // console.log("imgArrLength", length)
        return length
      }
      else {
        return 0
      }
    })

    function nextSlide() {
      // console.log("Next Clicked B4- ", currPos.value)
      if(currPos.value < imgArrLength.value - 1) {
        currPos.value++
      }
      else {
        currPos.value = 0
      }
      // console.log("Next Clicked AFT- ", currPos.value)
    }
    function prevSlide() {
      // console.log("Prev Clicked B4- ", currPos.value)
      if(currPos.value > 0) {
        currPos.value--
      }
      else {
        let arrEnd = imgArrLength.value - 1
        currPos.value = arrEnd
      } 
      // console.log("Prev Clicked AFT - ", currPos.value)
    }

    onUnmounted(() => {
      // initialSet.value = false 
      console.log("SlideShow>stopSlideShowWatch - onUnmounted")
      stopSlideShowWatch()
    }) 


    return {startPos, currPos, imgsArr, altTxt, imgArrLength, nextSlide, prevSlide, imgUrlShowing, imgAspectShowing}
  }

}
</script>

<style scoped>
.img-port{
  max-height: 95vh;
  max-width: auto;
}
.my-slide-modal-animation-enter-active {
  transition: all 0.4s cubic-bezier(0,.12,.14,1);
}
.my-slide-modal-animation-leave-active {
  transition: all 0.4s cubic-bezier(0,.12,.14,1);
}
.my-slide-modal-animation-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.my-slide-modal-animation-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

</style>