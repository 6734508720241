import { ref } from "vue"

// firebase imports
import { db } from "../firebase/config"
import { doc, deleteDoc } from "firebase/firestore"
const error = ref(null)  
const isPending = ref(false)

const useDeleteDocument = async (c, id) => {
  error.value = null
  isPending.value = true
  // console.log("collection", c)    
  // console.log("id", id)
  // console.log("id length", id.length)

  try {
    if(c === "shoots") {
      for (let i = 0; i < id.length; i++)
      {    
        console.log("deleting shoot doc", id[i]," of ", id.length)
        let delShtDoc = id[i]
        await deleteDoc(doc(db, c, delShtDoc))
      }
    }
    else if(c === "clients" ){
      for (let j = 0; j < id.length; j++)
      {
        console.log("deleting client doc", id[j]," of ", id.length)
        let delCltDoc = id[j]
        await deleteDoc(doc(db, c, delCltDoc))
      }      
    }
    else if(c === "images" ){      
      for (let k = 0; k < id.length; k++)
      {    
        console.log("deleting image doc", id[k] ," of ", id.length)
        let delImgDoc = id[k]
        await deleteDoc(doc(db, c, delImgDoc))
      }
    }
    error.value = null
    isPending.value = false
    
  }
  catch(err) {
    error.value = err.message
    // console.log(err.message)
    error.value = "A problem has occurred during document deletion"    
    isPending.value = false  
  }
}

const delDocument = () => {
  return { useDeleteDocument, error, isPending }
}

export default delDocument