import { ref } from "vue"
// import { useRouter } from "vue-router"

// firebase imports
import { auth } from "../firebase/config"
import { onAuthStateChanged } from "firebase/auth"

//refs
const user = ref(auth.currentUser)
const access = ref(null)
const valid = ref(null)

//auth changes
onAuthStateChanged(auth, (_user) => {
  if(!auth.currentUser){
    user.value = null
    access.value = null
    valid.value = null
    // console.log("getUser - Composable - onAuthStateChanged - No one is currently logged in")
  }
  else{
    // console.log("getUser - Composable - onAuthStateChanged. Current user is: ", _user.email)
    user.value = _user
    valid.value = _user.emailVerified    
    //If there is a user get admin status and access level
    if (_user.emailVerified) {
      // console.log("getUser - Composable - User is Logged in and verfied")
      _user.getIdTokenResult(true)
      .then(idTokenResult => {
        access.value = idTokenResult.claims.accessLevel
        console.log("getUser - Composable - User Verified Access", access.value)
      })
    }
    else {
      // console.log("getUser - Composable - User needs to verify email address")      
    }
  }
})

const getUser = () => {
  return { user, access, valid }
}

export default getUser