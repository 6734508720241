<template>
<circle cx="512" cy="512" r="400" style="fill:#fff;opacity:0.02"/><path d="M442.17,509.38c-15.26,0-29.1.08-42.95,0-10.87-.08-12-1.23-12-12-.06-22.68.25-45.36-.23-68-.19-8.83,3-11.81,11.64-11.38,11.76.57,23.58-.15,35.36.24,6.4.2,8.25-2.2,8.28-8.41.14-26.89-.35-53.85,1.4-80.66,2.78-42.75,42.78-81.57,85.68-83.15,32.91-1.21,65.9-.35,98.85-.54,6,0,8.77,2.24,8.73,8.56q-.24,36.73,0,73.47c0,6.39-2.94,8.52-8.87,8.48q-23.58-.18-47.16,0c-19.73.17-29.27,9.94-29.33,29.79,0,14.52.26,29-.11,43.54-.17,6.66,2,9,8.82,8.91,21.76-.31,43.54.14,65.3-.17,7.64-.1,10.3,2.8,9.66,10.25-2.1,24.37-3.91,48.77-5.84,73.15-.55,6.88-5.27,8-10.92,8H564.06c-12.48,0-12.49,0-12.49,12.94q0,122-.07,244c0,12-.22,12.27-12.16,12.3q-42.18.13-84.36,0c-12,0-12.79-.8-12.8-13q-.09-121.55-.08-243.09Z" style="fill:#3b5998"/><path d="M512,929.5C281.37,929.37,94.5,742.3,94.63,511.67A417.6,417.6,0,0,1,349.48,127.32c212.92-88.63,457.38,12.13,546,225.06,88,211.34-10.6,454.11-221,544.3A414.85,414.85,0,0,1,512,929.5Zm0-800c-210.91,0-382.5,171.59-382.5,382.5S301.09,894.5,512,894.5,894.5,722.91,894.5,512,722.91,129.5,512,129.5Z"/>
<!-- <circle cx="512" cy="512" r="400" style="fill:#fff;opacity:0.02"/><path d="M442.17,509.38c-15.26,0-29.1.08-42.95,0-10.87-.08-12-1.23-12-12-.06-22.68.25-45.36-.23-68-.19-8.83,3-11.81,11.64-11.38,11.76.57,23.58-.15,35.36.24,6.4.2,8.25-2.2,8.28-8.41.14-26.89-.35-53.85,1.4-80.66,2.78-42.75,42.78-81.57,85.68-83.15,32.91-1.21,65.9-.35,98.85-.54,6,0,8.77,2.24,8.73,8.56q-.24,36.73,0,73.47c0,6.39-2.94,8.52-8.87,8.48q-23.58-.18-47.16,0c-19.73.17-29.27,9.94-29.33,29.79,0,14.52.26,29-.11,43.54-.17,6.66,2,9,8.82,8.91,21.76-.31,43.54.14,65.3-.17,7.64-.1,10.3,2.8,9.66,10.25-2.1,24.37-3.91,48.77-5.84,73.15-.55,6.88-5.27,8-10.92,8H564.06c-12.48,0-12.49,0-12.49,12.94q0,122-.07,244c0,12-.22,12.27-12.16,12.3q-42.18.13-84.36,0c-12,0-12.79-.8-12.8-13q-.09-121.55-.08-243.09Z" style="fill:#3b5998"/><path d="M512,929.5C281.37,929.37,94.5,742.3,94.63,511.67A417.6,417.6,0,0,1,349.48,127.32c212.92-88.63,457.38,12.13,546,225.06,88,211.34-10.6,454.11-221,544.3A414.85,414.85,0,0,1,512,929.5Zm0-800c-210.91,0-382.5,171.59-382.5,382.5S301.09,894.5,512,894.5,894.5,722.91,894.5,512,722.91,129.5,512,129.5Z"/> -->
</template>

<script>
export default {
  name: "IconQuickAccessFacebook"
}
</script>
