import { ref } from "vue"
import { auth } from "../firebase/config"
import { GoogleAuthProvider, FacebookAuthProvider, linkWithPopup, unlink } from "firebase/auth"

const linkLogin = () => {
  const error = ref(null)  

  function googleSignin() { 
    console.log("doing Google Signin")
    let provider = new GoogleAuthProvider()
    linkWithPopup(auth.currentUser, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const user = result.user
      // var user = result.user;
      console.log("Account Successfully Linked to Google")
      console.log("credential ", credential)
      console.log("user ", user)
    })
    .catch((error) => {
      error.value = "Unable to link account to Google Account"
   });
  }
  function googleSignOut() { 
    console.log("doing Google SignOut", auth.currentUser)  
    if(auth.currentUser.providerData.length > 1){
      for (let i =0; i < auth.currentUser.providerData.length; i++) {
        if (auth.currentUser.providerData[i].providerId === "google.com") {
          unlink(auth.currentUser, auth.currentUser.providerData[i].providerId)
          .then(() => {      
            console.log("Account Unlinked from Google")      
          })
          .catch((error) => {
            console.log("Unable to Unlink from Google")
            error.value = "Unable to Unlink from Google"
         });
        }
      }
    } else {
      console.log("PHOTAR is not linked to any Google Accounts")
      error.value = "PHOTAR is not linked to any Google Accounts"
    }   
  }

  function facebookSignin() {    
    // let auth = getAuth() 
    console.log("doing Facebook Signin")
    let provider = new FacebookAuthProvider()
    linkWithPopup(auth.currentUser, provider)
    .then((result) => {
      const user = result.user;
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      console.log("Account Successfully Linked to Facebook")
      console.log("user ", user)
      console.log("credential ", credential)
      console.log("accessToken ", accessToken)
      return credential
    })
    .catch((error) => {
      // console.log(error.message)
      error.value = "Unable to link account to Facebook Account"
   });
  }

  function facebookSignOut() { 
    console.log("doing Facebook SignOut", auth.currentUser)  
    if(auth.currentUser.providerData.length > 1){
      for (let i =0; i < auth.currentUser.providerData.length; i++) {
        if (auth.currentUser.providerData[i].providerId === "facebook.com") {
          unlink(auth.currentUser, auth.currentUser.providerData[i].providerId)
          .then(() => {      
            console.log("Account Unlinked from Facebook")      
          })
          .catch((error) => {
            console.log("Unable to Unlink from Facebook")
            error.value = "Unable to Unlink from Facebook"
         });
        }
      }
    } else {
      console.log("PHOTAR is not linked to any Facebook Accounts")
      error.value = "PHOTAR is not linked to any Facebook Accounts"
    }   
  }
  return { error, googleSignin, googleSignOut, facebookSignin, facebookSignOut}
}
export default linkLogin