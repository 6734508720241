import { ref } from "vue"
// firebase imports
import { db } from "../firebase/config"
import { updateDoc, doc, serverTimestamp } from "firebase/firestore"
const error = ref(null)  

const useSetTimestamp = async (c, id) => {  
  
  const docRef = doc(db, c, id);
  // const time = serverTimestamp()
  try {
    const updateTimeRes = await updateDoc(docRef, {createdAt: serverTimestamp()});
    error.value = null
    return updateTimeRes
  }
  catch(err) {
    error.value = err.message
    console.log(err.message)
    error.value = "Server Error while adding timestamp"      
  }
}

const setTimestamp = () => {
  return { useSetTimestamp, error}
}

export default setTimestamp